import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { OrderActions } from "redux/actions";
import { handleReducerBuilder, IBaseReducerState } from "utils/redux";

interface IOrderState extends IBaseReducerState {
  order?: any;
}

const initialState: IOrderState = {
  action: "",
  prefix: OrderActions.prefix,
};

const orderSuccess = (state: IOrderState, action: PayloadAction<any>) =>
  handleReducerBuilder(state, action, () => {
    state.order = action.payload;
  });

const OrderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(OrderActions.orderSuccess.request, orderSuccess)
    .addDefaultCase(handleReducerBuilder);
});

export default OrderReducer;
