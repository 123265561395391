import { HeaderContainer } from "components";
import ContentInput from "components/content-input";
import { AdminLayout } from "layouts";
import AdminContainer from "../Container";

export default function PolicyManagement() {
  return (
    <AdminContainer>
      <HeaderContainer title="Quản lí chính sách" otherStyle />
      <ContentInput
        setValue={(value) => {
        }}
      />
    </AdminContainer>
  );
}
