import { SearchOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FormDialog } from "components";
import AppTable from "components/app-table";
import { CurrencyHelper, TableHelper, ToastHelper } from "helpers";
import { isEmpty } from "lodash";
import { IProduct } from "models/IProductModel";
import React, { useEffect, useState } from "react";
import {
  DiscountApiService,
  ProductApiService,
  SubCategoryApiService,
} from "services";

export default function SubCategoriesDiscount({
  discountId,
  visible,
  onCancel,
}: {
  discountId: string;
  visible: boolean;
  onCancel: VoidFunction;
}) {
  const [reload, setReload] = useState(false);
  const [category, setCategory] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);

  const [selectedSubCategory, setSelectedSubCategory] = useState<string[]>([]);

  const getALlCategories = async () => {
    setLoading(true);
    const result = await SubCategoryApiService.getAll();
    if (result.succeeded) {
      const data = result.data;
      setCategory(data);
    }
    setLoading(false);
  };

  const getALlCategoriesByDiscountId = async () => {
    setLoading(true);
    const result = await DiscountApiService.getSubCategoryByDiscountId({
      id: discountId,
      limit: 1000,
      page: 1,
    });
    if (result.succeeded) {
      const data = result.data;
      setSelectedSubCategory(data.results.map((category) => category.id));
    }
    setLoading(false);
  };

  const handelAddCategory = async () => {
    setLoading(true);
    const result = await DiscountApiService.createSubCategoryByDiscountId({
      id: discountId,
      subCategories: selectedSubCategory,
    });
    if (result.succeeded) {
      setReload(true);
      ToastHelper.toastSuccess("Thêm danh mục thành công");
    }
    setLoading(false);
  };

  const handelDeleteProduct = async (row) => {
    setLoading(true);
    const result = await DiscountApiService.createProductByDiscount({
      id: discountId,
      sku: [row?.skuNumber as string],
    });
    if (result.succeeded) {
      setReload(true);
      ToastHelper.toastSuccess("Xóa sản phẩm thành công");
    }
    setLoading(false);
  };

  useEffect(() => {
    getALlCategories();
    getALlCategoriesByDiscountId();
  }, [visible]);

  const columns: GridColDef[] = [
    {
      field: "subName",
      headerName: "Tên",
      flex: 1,
      editable: true,
    },
    {
      field: "subType",
      headerName: "Loại",
      flex: 1,
      editable: true,
    },
  ];
  return (
    <>
      <FormDialog
        maxWidth="lg"
        visible={visible}
        title={"Danh sách danh mục"}
        onCancel={onCancel}
        onOk={handelAddCategory}
        open={false}
      >
        <DataGrid
          autoHeight
          rows={category}
          columns={columns}
          hideFooter
          checkboxSelection
          selectionModel={selectedSubCategory}
          onSelectionModelChange={(rows) => {
            setSelectedSubCategory(rows as string[]);
          }}
        />
      </FormDialog>
    </>
  );
}
