import { Button, Stack, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { FormDialog, HeaderContainer } from "components";
import { TableColumName } from "components/AdminComponents/AdminTable";
import { AlertType, showAlert } from "helpers/alertHelper";
import { AdminLayout } from "layouts";
import _ from "lodash";
import {
  IRateConfiguration,
  IRateConfigurationRequest,
} from "models/configuration";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootReducer } from "redux/configurations/rootReducer";
import { RateConfigurationApiService } from "services";
import AdminContainer from "../../Container";

const textColor = "#000";
const textSecondColor = "#4B4B4B";

export default function RateConfiguration() {
  const user = useSelector((state: RootReducer) => state.IdentityReducer.admin);
  const [rateConfiguration, setRateConfiguration] = useState<
    IRateConfiguration[]
  >([]);
  const [rateConfigurationTemp, setRateConfigurationTemp] = useState<
    Partial<IRateConfiguration>[]
  >([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [idHover, setIdHover] = useState<string>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const configurationResponse = await RateConfigurationApiService.getAll();
      setLoading(false);
      if (
        configurationResponse?.succeeded &&
        !_.isEmpty(configurationResponse.data)
      ) {
        setRateConfiguration(configurationResponse?.data || []);
      } else {
        showAlert("Get Rate Configurations failed", AlertType.ERROR);
      }
    })();
  }, [reload]);

  const defaultRow = (fromValue: number): Partial<IRateConfiguration> => {
    return {
      fromValue,
      toValue: 0,
      value: 0,
    };
  };

  const onOk = () => {
    const checkValue = rateConfigurationTemp.reduce(
      (rates: any[], currentRate, i) => {
        if ((currentRate?.toValue || 0) < (currentRate?.fromValue || 0)) {
          rates.push(false);
        }
        return rates;
      },
      []
    );

    if (checkValue.includes(false)) {
      return;
    }
    const updateRate = async () => {
      const params: IRateConfigurationRequest = {
        userId: user?.userId!,
        rateConfigurationCommandRequests: rateConfigurationTemp,
      };
      setLoading(true);
      const configurationResponse = await RateConfigurationApiService.createNew(
        params
      );
      setLoading(false);
      if (
        configurationResponse?.succeeded &&
        !_.isEmpty(configurationResponse.data)
      ) {
        showAlert("Update Rate Configuration successfully", AlertType.SUCCESS);
        setReload(!reload);
      } else {
        showAlert("Update Rate Configuration failed", AlertType.ERROR);
      }
      setModalVisible(false);
    };
    updateRate();
  };

  const onEditConfig = (index: number, row: Partial<IRateConfiguration>) => {
    let newRateConfig = Array.from(rateConfigurationTemp);
    newRateConfig[index] = row;
    setRateConfigurationTemp(newRateConfig);
  };
  const renderTableBody = () => {
    return (
      <table
        id="responsive-data-table"
        className="table dataTable no-footer"
        style={{ width: "100%", cursor: "pointer", marginTop: 16 }}
        aria-describedby="responsive-data-table_info"
      >
        <thead>
          <tr>
            <TableColumName title="Giá từ (USD)" />
            <TableColumName title="Đến (USD)" />
            <TableColumName title="Phí (VND)" />
          </tr>
        </thead>
        <tbody>
          {_.sortBy(rateConfiguration, (x) => x.fromValue).map((row: any) => (
            <tr
              key={row.id}
              // onClick={() => {
              //   setModalVisible(true);
              //   setRateConfigurationTemp(rateConfiguration);
              // }}
              style={{
                height: 70,
                backgroundColor:
                  idHover === row.id ? "rgba(61, 133, 198, 0.2)" : "",
              }}
              onMouseEnter={() => setIdHover(row.id)}
              onMouseLeave={() => setIdHover(undefined)}
            >
              <td className="sorting_1">{row.fromValue}</td>
              <td className="sorting_1">{row.toValue}</td>
              <td className="sorting_1">{row.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  return (
    <AdminContainer>
      {/* <!-- Container-fluid starts--> */}
      <HeaderContainer
        title={"Cấu hình tỷ lệ"}
        otherStyle
        rightComponent={
          <button
            style={{
              // color: "white",
              minWidth: 100,
              fontWeight: "bold",
              color: "white",
              backgroundColor: "#0046be",
              borderRadius: 10,
              paddingInline: 8,
              paddingBlock: 4,
            }}
            onClick={() => {
              setModalVisible(true);
              setRateConfigurationTemp(rateConfiguration);
            }}
          >
            Chỉnh sửa
          </button>
        }
      />
      {/* <!-- Container-fluid Ends--> */}
      {/* <!-- Container-fluid starts--> */}
      <div className="row">
        <div className="col-12">
          <div className="card card-default" style={{ borderRadius: 16 }}>
            <div className="card-body">
              <div className="table-responsive">
                <div
                  id="responsive-data-table_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  {renderTableBody()}
                  <div className="clear" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormDialog
        visible={modalVisible}
        title={"Chỉnh sửa"}
        maxWidth={"md"}
        cancelText="Đóng"
        onCancel={() => setModalVisible(false)}
        onOk={() => onOk()}
        open={false}
      >
        {!_.isEmpty(rateConfigurationTemp) &&
          _.sortBy(rateConfigurationTemp, (x) => x.fromValue).map(
            (x, index) => {
              return (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: "100%", pt: 2 }}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <Typography sx={{ width: 150 }}>Từ</Typography>
                    <TextField
                      type={"number"}
                      defaultValue={x.fromValue}
                      variant="standard"
                      // disabled={index !== rateConfigurationTemp.length}
                      onChange={(e) => {
                        let row: Partial<IRateConfiguration> = {
                          fromValue: Number(e.target.value),
                          toValue: rateConfigurationTemp[index].toValue,
                          value: rateConfigurationTemp[index].value,
                        };
                        onEditConfig(index, row);
                      }}
                    />
                    <Typography>USD</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <Typography sx={{ width: 100 }}>Đến</Typography>
                    <TextField
                      type={"number"}
                      defaultValue={x.toValue}
                      variant="standard"
                      // disabled={index !== rateConfigurationTemp.length - 1}
                      error={(x?.toValue || 0) < (x?.fromValue || 0)}
                      onChange={(e) => {
                        let row: Partial<IRateConfiguration> = {
                          fromValue: rateConfigurationTemp[index].fromValue,
                          toValue: Number(e.target.value),
                          value: rateConfigurationTemp[index].value,
                        };
                        onEditConfig(index, row);
                      }}
                      onFocus={() => setError("")}
                    />
                    <Typography>USD:</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <TextField
                      type={"number"}
                      defaultValue={x.value}
                      // disabled={index !== rateConfigurationTemp.length - 1}
                      onChange={(e) => {
                        let row: Partial<IRateConfiguration> = {
                          fromValue: rateConfigurationTemp[index].fromValue,
                          toValue: rateConfigurationTemp[index].toValue,
                          value: Number(e.target.value),
                        };
                        onEditConfig(index, row);
                      }}
                      variant="standard"
                    />
                    <Typography>VND</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Button
                      sx={{ width: 90 }}
                      onClick={() => {
                        const newConfig = rateConfigurationTemp.filter(
                          (x, i) => i !== index
                        );
                        setRateConfigurationTemp(newConfig);
                      }}
                      variant="outlined"
                      color="error"
                      // disabled={index !== rateConfigurationTemp.length - 1}
                    >
                      Xóa
                    </Button>
                  </Stack>
                </Stack>
              );
            }
          )}
        <Button
          sx={{ float: "right", mt: 2, width: 90 }}
          variant="contained"
          onClick={() => {
            const newConfig = [
              ...rateConfigurationTemp,
              defaultRow(
                rateConfigurationTemp[rateConfigurationTemp.length - 1]
                  ?.toValue || 0
              ),
            ];
            setRateConfigurationTemp(newConfig);
          }}
        >
          Thêm
        </Button>
        <Typography sx={{ mt: 2, color: "red" }}>{error}</Typography>
      </FormDialog>
      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}
