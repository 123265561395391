import { Breadcrumb as AntBreadcrumb, Row, Space, Typography } from "antd";
import styled from "@emotion/styled";
import { Home } from "lucide-react";

const ContainerStyle = styled(Row)`
  margin-bottom: 1rem;
  padding: 0.5rem 0;
  background: var(--color-bg-grey-light);

  .ant-breadcrumb a:hover {
    background: unset;
  }

  .ant-breadcrumb-link {
    color: var(--color-primary);
    &:hover {
      span {
        color: var(--color-primary);
      }
    }
  }
`;

const MenuStyle = styled(Space.Compact)`
  gap: 8px;
`;

const Breadcrumb = ({ title }) => {
  return (
    <ContainerStyle>
      <AntBreadcrumb
        className="container"
        items={[
          {
            href: "/",
            title: (
              <MenuStyle>
                <Home size={20} strokeWidth={1.5} />
                <Typography.Text>Trang chủ</Typography.Text>
              </MenuStyle>
            ),
          },
          {
            href: "",
            title: <Typography.Text>{title}</Typography.Text>,
          },
        ]}
      />
    </ContainerStyle>
  );
};

export default Breadcrumb;
