import { createReducer } from "@reduxjs/toolkit";
import { ProductActions } from "redux/actions";
import { handleReducerBuilder, IBaseReducerState } from "utils/redux";

interface IProductState extends IBaseReducerState {
  products: [];
  currentProduct?: any;
  productDetail?: any;
  searchName: string;
  orderBy: any;
}

const initialState: IProductState = {
  action: "",
  products: [],
  prefix: ProductActions.prefix,
  currentProduct: {},
  searchName: "",
  orderBy: {
    dailyDeal: 2,
    optionGPU: [],
    optionHardDriveCapacity: [],
    optionOperatingSystem: [],
    optionProcessor: [],
    optionRAMSize: [],
    optionScreenSize: [],
    optionSSDCapacity: [],
    optionStorageType: [],
    optionSeller: [],
    optionCondition: [],
    optionBrand: [],
  },
};

const getAllProduct = (state: IProductState, action: any) =>
  handleReducerBuilder(state, action, () => {
    state.products = action.payload;
  });
const createProduct = (state: IProductState, action: any) => {
  handleReducerBuilder(state, action, () => {
    state.currentProduct = action.payload;
  });
};

const addProductDetail = (state: IProductState, action: any) => {
  handleReducerBuilder(state, action, () => {
    state.productDetail = action.payload;
  });
};

const searchProductName = (state: IProductState, action: any) => {
  handleReducerBuilder(state, action, () => {
    state.searchName = action.payload;
  });
};

const setOrderBy = (state: IProductState, action: any) => {
  handleReducerBuilder(state, action, () => {
    state.orderBy = action.payload;
  });
};

const ProductReducer = createReducer(initialState, (builder) => {
  builder.addCase(ProductActions.getAllProduct.success, getAllProduct);
  builder.addCase(ProductActions.createProduct.success, createProduct);
  builder.addCase(ProductActions.addProductDetail.request, addProductDetail);
  builder
    .addCase(ProductActions.searchProductName.request, searchProductName)
    .addCase(ProductActions.setOrderBy.request, setOrderBy);
});

export default ProductReducer;
