import PropTypes from "prop-types";
import React from "react";
// material-ui
import { Box, Drawer, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

// project imports
import MenuList from "./MenuList";

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = (props: { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props;
  const ref = React.useRef(null);
  const [height, setHeight] = React.useState(0);
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  React.useEffect(() => {
    if (ref) {
      setHeight((ref?.current as any)?.clientHeight);
    }
  }, [ref.current]);

  const drawer = (
    <>
      <div
        ref={ref}
        className="d-flex justify-content-center align-items-center pt-2 pb-4"
      >
        <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
          <LogoSection />
        </Box>
      </div>
      <PerfectScrollbar
        component="div"
        style={{
          height: `calc(100vh - ${height + 20}px)`,
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        <MenuList />
      </PerfectScrollbar>
    </>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{
          flexShrink: { md: 0 },
          width: matchUpMd ? 250 : "auto",
          zIndex: 10,
          borderRight: "1px dashed #00000020",
          display: {
            sm: "block",
            xs: "none",
          },
        }}
        aria-label="mailbox folders"
      >
        {drawer}
      </Box>
      <Drawer
        sx={{
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 250 },
          display: {
            sm: "none",
            xs: "block",
          },
        }}
        open={open}
        onClose={onClose}
      >
        {drawer}
      </Drawer>
    </>
  );
};
const LogoSection = () => (
  <div>
    <img
      style={{ height: "50px", objectFit: "contain" }}
      src="assets/images/logo/logo-ebay.jpg"
      alt="Site Logo"
    />
  </div>
);

export default Sidebar;
