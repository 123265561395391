import React from "react";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { CircularProgress } from "@mui/material";
type AppButtonProps = {
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  loading?: boolean;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export default function Button(props: AppButtonProps) {
  const { loading, title } = props;
  return (
    <button
      className={`btn btn-primary ${loading ? "disabled" : ""}`}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
      {...props}
    >
      {loading ? (
        <CircularProgress size={10} />
      ) : (
        <>
          <AccessAlarmIcon />
          <p
            style={{ marginBottom: 0, paddingRight: "6px", paddingLeft: "6px" }}
          >
            {title}
          </p>
          <AccessAlarmIcon />
        </>
      )}
    </button>
  );
}
