import * as XLSX from "xlsx";
import { FileDownloadOutlined } from "@mui/icons-material";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import DealApiService from "services/DealApiService";
import { getDealSpecs } from "helpers/dealHelper";

const DownloadGoogle = () => {
  const [isExportProducts, setIsExportProducts] = useState(false);

  // Export products excel
  const exportProductsToExcel = async () => {
    setIsExportProducts(true);
    const { succeeded, data } = await DealApiService.getDealsPublic({});
    if (succeeded) {
      const newData: any = [];
      await data.results.forEach((item: any, idx: any) => {
        const productId = item.productId;
        const specs = getDealSpecs(item.specifications);
        if (productId && item.allowGoogleAds) {
          const productInfoDescription = `CPU: ${specs["processorShort"]}, VGA: ${specs["gpuShort"]}, RAM: ${specs["RAM Size"]}, SSD: ${specs["SSD Capacity"]}, Screen: ${specs["screenShort"]}. `;
          const productInfoHighlight = `Thông số sản phẩm: ${specs["processorShort"]} ${specs["screenShort"]} ${specs["RAM Size"]} ${specs["SSD Capacity"]}${specs["screenShort"]}. `;
          newData.push({
            id: `${item.id}`,
            title: `${item.name}`,
            description: `${
              specs["processorShort"] && productInfoDescription
            }Tình trạng: ${
              item.condition
            }. Giá nhìn thấy là giá về tay (đã bao gồm toàn bộ thuế + phí ship). Được bảo hiểm hàng hóa 100% (đơn bị thất lạc hoặc rơi vỡ sẽ được hoàn lại tiền 100%).`,
            availability: item.dealStatus === 0 ? "preorder" : "in_stock", // preorder, in_stock, out_of_stock, backorder
            availability_date:
              item.dealStatus === 0
                ? moment().add(2, "weeks").format("YYYY-MM-DDTHH:mmZZ")
                : "", // (YYYY-MM-DDThh:mm[±hhmm])
            link: `https://haistore.vn/product-item?id=${item.id}&isDeal=true`,
            image_link: item.thumbnail,
            price: `${Math.round(item.tempSellRegularPrice)} VND`,
            sale_price: `${Math.round(item.prices[0].price)} VND`,
            identifier_exists: "",
            gtin: "",
            mpn: "",
            brand: item.category.name,
            product_highlight: `${
              specs["processorShort"]
                ? productInfoHighlight
                : `Tình trạng sản phẩm: ${item.condition}. Được bảo hiểm hàng hóa 100% (đơn bị thất lạc hoặc rơi vỡ sẽ được hoàn lại tiền 100%).`
            }`,
            product_detail: "",
            additional_image_link: "",
            condition: "new", // new, refurbished, used
            adult: "",
            color: "",
            size: "",
            gender: "",
            material: "",
            pattern: "",
            age_group: "",
            multipack: "",
            is_bundle: "",
            unit_pricing_measure: "",
            unit_pricing_base_measure: "",
            min_energy_efficiency_class: "",
            max_energy_efficiency_class: "",
            item_group_id: "",
            sell_on_google_quantity: "",
          });
        }
      });
      const worksheet = await XLSX.utils.json_to_sheet(newData);
      const workbook = await XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Products");
      await XLSX.writeFile(workbook, `haistore-deals.xlsx`);
      setIsExportProducts(false);
    }
  };

  return (
    <Stack direction="row" marginBottom={1} alignItems="center" spacing={2}>
      <Button
        onClick={exportProductsToExcel}
        startIcon={<FileDownloadOutlined />}
      >
        Tải file excel
      </Button>
      {isExportProducts && (
        <Stack direction="row" marginBottom={1} alignItems="center" spacing={1}>
          <CircularProgress size={20} />
          <Typography fontSize="14px" color="#0046be" fontWeight="italic">
            Đang xuất file excel, vui lòng đợi trong giây lát...
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default DownloadGoogle;
