import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IApiResponse, IErrorResponse } from "./BaseApiService";
import qs from "qs";
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  withCredentials: true,
  timeout: 0,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
    "Content-Security-Policy": "upgrade-insecure-requests",
    // "accept-language": "en-Us",
    // timeOffset: Math.round(moment().utcOffset() / 60),
    accept: "*/*",
  },
  paramsSerializer: (params: any) => qs.stringify(params, { indices: false }),
});

axiosInstance.interceptors.response.use(
  (res) => _handleResponse(res),
  (error) => _handleError(error)
);

export default axiosInstance;

//-------------------------------------------
const _handleResponse = <T = any>(
  response: AxiosResponse<T | IErrorResponse>
): IApiResponse<T> => {
  if (response.status === 200) {
    const data = response.data as T;
    return {
      data,
      header: response.headers,
      succeeded: true,
    };
  }
  const error = response.data as IErrorResponse;
  return {
    succeeded: false,
    error: {
      message: error.error_description,
    },
  };
};

const _handleError = <T = any>(err: any): IApiResponse<T> => {
  return {
    succeeded: false,
    error: {
      message: "Network Error",
    },
    errors: [err],
  };
};

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: "/api/chat",
  kanban: "/api/kanban",
  calendar: "/api/calendar",
  auth: {
    me: "/api/auth/me",
    login: "/api/auth/login",
    register: "/api/auth/register",
  },
  mail: {
    list: "/api/mail/list",
    details: "/api/mail/details",
    labels: "/api/mail/labels",
  },
  post: {
    list: "/api/post/list",
    details: "/api/post/details",
    latest: "/api/post/latest",
    search: "/api/post/search",
  },
  product: {
    list: "/api/product/list",
    details: "/api/product/details",
    search: "/api/product/search",
  },
};
