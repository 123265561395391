import { BaseApiService } from "./BaseApiService";

class QuoteApiService extends BaseApiService {
  public pagination = (params: any) =>
    this.get<{
      totalItems: number;
      page: number;
      limit: number;
      results: any[];
      resultsLength: number;
    }>("/pagination", { params });
}

export default new QuoteApiService("/quote");
