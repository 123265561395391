import { BaseApiService } from "./BaseApiService";

class CategoryApiService extends BaseApiService {
  public getCountCategory = () => this.get<any>(`/count-categories`);
  public getAll = () => this.get<any>("/get-all-categories");
  public getAllPagination = (params: any) =>
    this.get<any>("/pagination", { params });
  public updateCategory = (params: any) =>
    this.put<any>("/update-categories", params);
  public createCategory = (params: any) =>
    this.post<any>("/create-categories", params);
  public deleteCategory = (id: any) =>
    this.delete(`/delete-categories?id=${id}`);
}

export default new CategoryApiService("/categories");
