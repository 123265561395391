import { BaseApiService } from "./BaseApiService";

class CarrierApiService extends BaseApiService {
  public getAll = () => this.get("/get-all-guarantee");
  public getGuaranteeById = (id: string) =>
    this.get<any>(`/get-guarantee-by-id?id=${id}`);
  public createGuarantee = (params: {
    name: string;
    value: number;
    description: string;
    isDefault: number;
    userId: string;
  }) => this.post<any>(`/create-guarantee`, { ...params, recStatus: 1 });
  public updateGuarantee = (params: {
    id: string;
    name: string;
    value: number;
    description: string;
    isDefault: number;
    userId: string;
  }) => this.put<any>(`/update-guarantee`, { ...params, recStatus: 1 });
  public deleteGuarantee = (params: { id: string }) =>
    this.delete<any>(`/delete-guarantee`, {
      params,
    });
}

export default new CarrierApiService("/guarantees");
