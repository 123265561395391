import moment from "moment";

export const mmddyyyyFormat = (date: string) => {
  return moment(new Date(date)).format("DD/MM/YYYY");
};

export const hhmmddmmyyyyFormat = (date: string) => {
  return moment(date).format("hh:mm DD/MM/YYYY");
};

export const getTimeFormat = (date: string) => {
  return moment(new Date(date), "h:mm:ss A").format("HH:mm");
};

export const getDayTimeFormat = (date: Date) => {
  return moment(date).format("DD/MM/YYYY h:mm");
};
