import { ICodeType } from "models/ICodeType";
import { BaseApiService } from "./BaseApiService";

class CodeTypeApiService extends BaseApiService {
  public getAllCodeTypeByType = (type: string) =>
    this.get<ICodeType[]>(`/get-all-code-type-by-type?type=${type}`);
  public pagination = (params: any) => this.get("/pagination", { params });
  public updateCodeName = (params: any) =>
    this.put("/update-code-type", params);
}

export default new CodeTypeApiService("/codetype");
