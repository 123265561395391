import { Input, Stack, Typography } from "@mui/material";
import { FormDialog } from "components";
import { TableColumName } from "components/AdminComponents/AdminTable";
import AppTable from "components/app-table";
import { TableHelper } from "helpers";
import { AlertType, showAlert } from "helpers/alertHelper";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootReducer } from "redux/configurations/rootReducer";
import { CodeTypeApiService } from "services";
import AdminContainer from "../../Container";
import "./styles.css";

export default function CodeTypeManagement() {
  const user = useSelector((state: RootReducer) => state.IdentityReducer.admin);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [products, setProducts] = useState<any>([]);
  const [idHover, setIdHover] = useState<string>();
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [filterName, setFilterName] = useState("SHIPMENT");
  const [currentItem, setCurrentItem] = useState<any>({});
  const [editModal, setEditModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [newName, setNewName] = useState("");

  useEffect(() => {
    getProducts();
  }, [searchValue, page, filterName]);

  const getProducts = async () => {
    const params = {
      type: !_.isEmpty(searchValue) ? searchValue : filterName,
      page: page,
      limit: 10,
    };
    setLoading(true);
    const res: any = await CodeTypeApiService.pagination(params);

    setLoading(false);
    if (res.succeeded && !_.isEmpty(res.data)) {
      setProducts(res.data);
      setTotalPage(Math.ceil((res.data?.totalItems || 0) / 10));
    } else {
      showAlert("Lấy dữ liệu thất bại", AlertType.ERROR);
    }
  };

  const handleClick = (item: any) => {
    setEditModal(true);
    setNewName(item?.name);
    setCurrentItem(item);
  };

  const onConfirmOk = async () => {
    setLoading(true);
    const res = await CodeTypeApiService.updateCodeName({
      id: currentItem?.id,
      name: newName,
      userId: user?.user.id,
    });
    setLoading(false);
    if (res?.succeeded) {
      showAlert("Chỉnh sửa thành công", AlertType.SUCCESS);
      setEditModal(false);
      getProducts();
    } else {
      showAlert("Chỉnh sửa thất bại", AlertType.ERROR);
    }
    setConfirmModal(false);
  };

  const renderTableBody = () => {
    return (
      <table
        id="responsive-data-table"
        className="table dataTable no-footer"
        style={{ width: "100%", cursor: "pointer", marginTop: 16 }}
        aria-describedby="responsive-data-table_info"
      >
        <thead>
          <tr>
            {/* <TableColumName title="STT" /> */}
            <TableColumName title="Kiểu" />
            <TableColumName title="Mã" />
            <TableColumName title="Tên" />
            {/* <TableColumName title="Daily Deal" /> */}
          </tr>
        </thead>
        <tbody>
          {products?.results?.map((row: any, index: number) => (
            <tr
              key={row.id}
              onClick={() => handleClick(row)}
              style={{
                height: 70,
                backgroundColor:
                  idHover === row.id ? "rgba(61, 133, 198, 0.2)" : "",
              }}
              onMouseEnter={() => setIdHover(row.id)}
              onMouseLeave={() => setIdHover(undefined)}
            >
              {/* <td>
                <Typography>{index + 1}</Typography>
              </td> */}
              <td>
                <Typography>
                  {row.type}
                  {/* {row.quoteLink} */}
                </Typography>
              </td>
              <td>
                <Typography>{row.code}</Typography>
              </td>
              {/* <td>
                <span
                  className="mb-2 mr-2 badge badge-success"
                  style={{
                    marginTop: 10,
                    backgroundColor: row.status === 1 ? "#018e00" : "#cc0000",
                  }}
                >
                  {row.status === 1 ? "Thành công" : "Thất bại"}
                </span>
              </td> */}
              <td>
                <Typography>{row.name}</Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  const columns = [
    TableHelper.createColumn({
      field: "type",
      flex: 1,
      headerName: "Loại",
    }),
    TableHelper.createColumn({
      field: "code",
      flex: 1,
      headerName: "Tiến dộ",
    }),
    TableHelper.createColumn({
      field: "name",
      flex: 1,
      headerName: "Trạng thái",
    }),
  ];
  return (
    <AdminContainer title={"Quản lý tiến trình"}>
      <AppTable<any, any, any>
        api={CodeTypeApiService.pagination}
        columns={columns}
        onEditRowClick={handleClick}
      />
      {/* <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Orders</h5>
            </div>
            <div className="card-body">
              <div
                id="basicScenario"
                className="product-physical table-responsive"
              >
                <Grid container>
                  <Grid item sx={{ flex: 1 }}>
                    <SearchInput
                      onSearch={(text) => setSearchQuote(text)}
                      placeholder="Crawl Data: Link Ebay,....."
                    />
                  </Grid>
                </Grid>
                <Box sx={{ mt: 2 }} />
                <TableHeaderSearch
                  onSearch={(value) => setSearchValue(value)}
                  rightComponent={
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{ minWidth: 320 }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#000",
                          fontWeight: "300",
                        }}
                      >
                        Trạng thái
                      </Typography>
                      <TextField
                        fullWidth
                        variant="standard"
                        select
                        value={filterName}
                        onChange={(e) => setFilterName(e.target.value)}
                        defaultValue={filterName}
                        sx={{ flex: 1 }}
                      >
                        {[
                          { code: 1, name: "SHIPMENT" },
                          { code: 2, name: "SHIPMENTITEM" },
                          { code: 3, name: "ORDER" },
                          { code: 4, name: "INVOICE" },
                        ].map((x) => (
                          <MenuItem value={x.name} key={x.code}>
                            <Box>{x.name}</Box>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Stack>
                  }
                />
                {!loading ? (
                  renderTableBody()
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // pt: 5,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <TableFooter
                  currentPage={products?.page || 0}
                  onChangePage={(page) => {
                    setPage(page);
                  }}
                  totalPage={totalPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <FormDialog
        visible={editModal}
        title={"Chỉnh sửa"}
        onCancel={() => setEditModal(false)}
        onOk={() => setConfirmModal(true)}
        open={false}
      >
        <Stack alignItems={"center"} justifyContent={"center"} rowGap={2}>
          <Stack
            alignItems={"center"}
            // justifyContent={"space-between"}
            sx={{ flex: 1, minWidth: "100%" }}
            direction="row"
            columnGap={1}
          >
            <Typography style={{ fontWeight: "bold" }}>Type:</Typography>
            <Typography>{currentItem?.type}</Typography>
          </Stack>
          <Stack
            alignItems={"center"}
            // justifyContent={"space-between"}
            sx={{ flex: 1, minWidth: "100%" }}
            direction="row"
            columnGap={1}
          >
            <Typography style={{ fontWeight: "bold" }}>Code:</Typography>
            <Typography>{currentItem?.code}</Typography>
          </Stack>
          <Stack
            alignItems={"center"}
            // justifyContent={"space-between"}
            sx={{ flex: 1, minWidth: "100%" }}
            direction="row"
            columnGap={1}
          >
            <Typography style={{ fontWeight: "bold" }}>Name:</Typography>
            <Input
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              sx={{ width: "100%" }}
            />
          </Stack>
        </Stack>
      </FormDialog>
      <FormDialog
        visible={confirmModal}
        title={"Xác nhận"}
        onCancel={() => setConfirmModal(false)}
        onOk={() => onConfirmOk()}
        open={false}
      >
        <Typography sx={{ pt: 2 }} variant="h6">
          Bạn có chắc chắn muốn thực hiện hành động này?
        </Typography>
      </FormDialog>
      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}

const styles = {
  boxShadow: { boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", p: 1 },
};
