import { Box, Input, Stack, Typography } from "@mui/material";

import { FormDialog, HeaderContainer, SearchInput } from "components";
import CircularProgress from "@mui/material/CircularProgress";
import { AlertType, showAlert } from "helpers/alertHelper";
import { AdminLayout } from "layouts";
import _ from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";

import { useEffect, useState } from "react";
import "./styles.css";

import {
  TableColumName,
  TableFooter,
  TableHeaderSearch,
} from "components/AdminComponents/AdminTable";
import {
  ConfirmModalHelper,
  DateHelper,
  TableHelper,
  ToastHelper,
} from "helpers";
import CrawlMultipleLinkApiService from "services/CrawlMultipleLinkApiService";
import { RootReducer } from "redux/configurations/rootReducer";
import { useSelector } from "react-redux";
import { CrawlApiService } from "services";
import { IApiResponse } from "services/BaseApiService";
import AdminContainer from "../../Container";
import AppInput from "components/app-input";
import AppButton from "components/client/app-button";
import AppTable from "components/app-table";

export default function CrawlMultipleLink() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<any>([]);
  const [idHover, setIdHover] = useState<string>();
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [reload, setReload] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [currentRow, setCurrentRow] = useState<any>();
  const user = useSelector((state: RootReducer) => state.IdentityReducer.admin);

  const crawlMultiple = async () => {
    if (!_.isEmpty(searchValue)) {
      setLoading(true);
      const response = (await CrawlApiService.crawlGroupData(
        searchValue.trim()
      )) as IApiResponse<any>;
      if (response.succeeded) {
        ToastHelper.toastSuccess(`Thành công - ${response.data} sản phẩm`);
      } else {
        ToastHelper.toastError("Không tìm thấy sản phẩm");
      }
      setLoading(false);
    } else {
      ToastHelper.toastError("Link không được để trống");
    }
  };

  const handleDeleteClick = async (row: any) => {
    ConfirmModalHelper.alert({
      message: "Bạn có muốn xóa link này!",
      isCancel: true,
      onConfirm: async () => {
        setLoading(true);
        const res: any = await CrawlMultipleLinkApiService.deleteLink(row.id);
        setLoading(false);
        if (res.succeeded && !_.isEmpty(res.data)) {
          ToastHelper.toastSuccess("Cập nhật thành công");
          setReload(!reload);
        } else {
          ToastHelper.toastError("Xóa thất bại");
        }
      },
    });
  };
  const editLink = async () => {
    setLoading(true);
    let res: any;
    if (!_.isEmpty(currentRow?.id)) {
      res = await CrawlMultipleLinkApiService.update({
        ...currentRow,
        userId: user?.user.id,
        updatedDt: undefined,
      });
    } else {
      res = await CrawlMultipleLinkApiService.createLink({
        ...currentRow,
        userId: user?.user.id,
        carrierName: "EBAY",
        type: "GROUP",
      });
    }
    setLoading(false);
    setEditModal(false);
    if (res.succeeded && !_.isEmpty(res.data)) {
      ToastHelper.toastSuccess("Cập nhật thành công");
      setReload(!reload);
    } else {
      ToastHelper.toastError("Cập nhật thất bại");
    }
    setCurrentRow(undefined);
  };
  const columns = [
    TableHelper.createColumn({
      field: "groupLink",
      flex: 1,
      headerName: "Link",
    }),
    TableHelper.createColumn({
      field: "count",
      headerName: "Số lượng đã cào",
      align: "right",
    }),
    TableHelper.createColumn({
      field: "updatedDt",
      width: 300,
      headerName: "Ngày cập nhật",
      valueGetter: ({ row }) => DateHelper.getDayTimeFormat(row.updatedDt),
    }),
  ];
  return (
    <AdminContainer title="Quản lý link cào dữ liệu nhóm">
      <div className="d-flex flex-1 flex-wrap gap-3 align-items-end w-100 mb-5 mt-2">
        <AppInput
          fullWidth
          title="Link cào"
          placeholder="Nhập link cào nhóm..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <AppButton
        label="Cào"
          variant="primary"
          style={{ height: 40, marginLeft: 10 }}
          onClick={crawlMultiple}
          loading={loading}
        >
          
        </AppButton>
      </div>{" "}
      <AppTable<any, any, any>
        api={CrawlMultipleLinkApiService.pagination}
        columns={columns}
        onEditRowClick={(row) => {
          setEditModal(true);
          setCurrentRow(row);
        }}
        onDeleteRowClick={handleDeleteClick}
      />
      <FormDialog
        visible={editModal}
        title={_.isEmpty(currentRow?.id) ? "Tạo mới" : "Chỉnh sửa"}
        onCancel={() => setEditModal(false)}
        onOk={() => editLink()}
        open={false}
      >
        <AppInput
          fullWidth
          value={currentRow?.groupLink || ""}
          title="Link thay thế"
          placeholder="Nhập link cào thay thế..."
          onChange={(e) =>
            setCurrentRow({ ...currentRow, link: e.target.value })
          }
        />
      </FormDialog>
    </AdminContainer>
  );
}

const styles = {
  boxShadow: { boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", p: 1 },
};
