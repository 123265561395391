import styled from "@emotion/styled";
import { Button, Flex, Grid, Select, Space, Typography } from "antd";
import { StringHelper } from "helpers";
import { useAtom } from "jotai";
import { Filter } from "lucide-react";
import {
  filterQueryAtom,
  isOpenFilterMobileAtom,
} from "pages/client/DealsPage/atoms/dealsAtom";
import { SORT_CLIENT } from "pages/client/DealsPage/configs/dealConfig";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";

const ContainerStyle = styled(Flex)`
  margin-top: 1.15rem;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-base);
  background: var(--color-bg-grey-light);
`;

const ButtonFilterStyle = styled(Button)`
  padding: 0.25rem 0.5rem;

  svg {
    color: var(--color-primary);
  }
`;

const TitleStyle = styled(Typography.Text)`
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--color-primary);
`;

const SelectStyle: any = styled(Select)`
  width: 200px;
`;

const SortBar = () => {
  const history = useHistory();
  const screens = Grid.useBreakpoint();
  const [filterQuery] = useAtom(filterQueryAtom);
  const [isOpenFilterMobile, setIsOpenFilterMobile] = useAtom(
    isOpenFilterMobileAtom
  );

  // Handle - select sort option
  const handleSelectOption = (keySearch: string) => {
    const newFilterQuery = {
      ...filterQuery,
      optionSort: keySearch,
    };
    const querySerialized = StringHelper.paramsSerializer(newFilterQuery);
    history.push(
      querySerialized ? `/deals?${querySerialized}` : `/deals?type=full`
    );
  };

  return (
    <Fragment>
      {/* Title - for small screens */}
      {!screens.lg ? <TitleStyle>Flash Sale</TitleStyle> : null}

      <ContainerStyle justify="space-between">
        {/* Header */}
        <Space>
          {/* Button - toggle filters */}
          <ButtonFilterStyle
            icon={<Filter size={18} />}
            onClick={() => setIsOpenFilterMobile(!isOpenFilterMobile)}
          >
            Bộ lọc
          </ButtonFilterStyle>

          {/* Title - for large screens */}
          {screens.lg ? <TitleStyle>Flash Sale</TitleStyle> : null}
        </Space>

        {/* Sort Bar */}
        <Space>
          {/* Title */}
          <Typography.Text>Sắp xếp</Typography.Text>

          {/* Select */}
          <SelectStyle
            onChange={handleSelectOption}
            defaultValue={"sort1"}
            value={filterQuery.optionSort}
          >
            {SORT_CLIENT.listSortResponse.map((option, idx) => {
              return (
                <Select.Option key={idx} value={option.keySearch}>
                  {option.title}
                </Select.Option>
              );
            })}
          </SelectStyle>
        </Space>
      </ContainerStyle>
    </Fragment>
  );
};

export default SortBar;
