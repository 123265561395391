import { createReducer } from "@reduxjs/toolkit";

interface IUserState {
  userId?: string;
  name?: string;
}

const initialState: IUserState = {};

const UserReducer = createReducer(initialState, (builder) => {});

export default UserReducer;
