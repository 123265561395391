import "./styles.css";

import { AlertType, showAlert } from "helpers/alertHelper";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { CodeTypeApiService, OrderApiService } from "services";
import { CurrencyHelper, TableHelper } from "helpers";
import React, { useEffect, useState } from "react";

import AdminContainer from "../../Container";
import AppTable from "components/app-table";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EditIcon from "@mui/icons-material/Edit";
import { FormDialog } from "components";
import { ICodeType } from "models/ICodeType";
import { IOrderResponse } from "models/IOrderModel";
import InvoiceApiService from "services/InvoiceApiService";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import { RootReducer } from "redux/configurations/rootReducer";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { vnFormatter } from "helpers/currencyHelpers";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface IOrderPaginationResponse {
  totalItems: number;
  page: number;
  limit: number;
  results: IOrderResponse[];
  resultsLength: number;
}
const textColor = "#000";
const textSecondColor = "#4B4B4B";

const DescriptionItem = (props: {
  title: string;
  descriptions: any;
  isLink?: boolean;
  style?: any;
}) => {
  const { title, descriptions, isLink } = props;
  return (
    <Stack direction="row" alignItems="center">
      <Typography
        sx={{ minWidth: 180, fontWeight: 600, ...props.style }}
        color={textColor}
      >
        {title}:{" "}
      </Typography>
      {isLink ? (
        <a
          href={descriptions}
          style={{ display: "contents", color: "blue" }}
          target="_blank"
        >
          {/* {`${_.truncate(descriptions, {
            length: 50,
          })}...`} */}
          {!_.isEmpty(descriptions) && descriptions !== "Không có hình ảnh"
            ? "Xem"
            : ""}
        </a>
      ) : (
        <Typography
          color={textSecondColor}
          sx={{
            display: "contents",
          }}
        >
          {descriptions}
        </Typography>
      )}
    </Stack>
  );
};

const HistoryItem = (props: {
  status: string;
  reason: string;
  dateTime: string;
  bold: boolean;
}) => {
  const { status, dateTime, bold, reason } = props;
  return (
    <Stack>
      {!bold && <Divider sx={{ margin: 0.5 }} />}
      <Typography
        color={bold ? textColor : textSecondColor}
        sx={{ fontWeight: bold ? 600 : "" }}
      >
        {status}
      </Typography>
      {!_.isEmpty(reason) && (
        <Typography color={textSecondColor}>{reason}</Typography>
      )}
      <Typography color={textSecondColor}>
        {moment(dateTime).format("HH:mm A | DD/MM/YYYY")}
      </Typography>
    </Stack>
  );
};

const getColor = (orderStatus: number) => {
  switch (orderStatus) {
    case 0:
      return "#f1c232";
    case 1:
      return "#3d85c6";
    case 2:
      return "#cc0000";
    case 3:
      return "#cc0000";
    case 4:
      return "#f1c232";
    case 5:
      return "#018e00";
    case 6:
      return "#f6b26b";
    case 7:
      return "#f1c232";
    case 8:
      return "#018e00";
    default:
      return "";
  }
};

export default function TrackingOrder() {
  const user = useSelector((state: RootReducer) => state.IdentityReducer.admin);
  const [orders, setOrders] = useState<IOrderPaginationResponse>();
  const [orderDetail, setOrderDetail] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [codeType, setCodeType] = useState<ICodeType[]>([]);
  const [invoicesStatus, setInvoicesStatus] = useState<ICodeType[]>([]);
  const [invoiceDetail, setInvoiceDetail] = useState<any>({});
  const [tab, setTab] = React.useState(0);
  const [shipmentPrice, setShipmentPrice] = useState(
    orderDetail?.thirdShipmentPrice || ""
  );
  const [orderStatus, setOrderStatus] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [idHover, setIdHover] = useState<string>();
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState<number>(7);
  const [updateDepositModal, setUpdateDepositModal] = useState(false);
  const [deposit, setDeposit] = useState<number>();
  const [updateSellPriceModal, setUpdateSellPriceModal] = useState(false);
  const [orderDetailId, setOrderDetailId] = useState<string>("");
  const [sellPrice, setSellPrice] = useState<number>();
  useEffect(() => {
    (() => {
      Promise.all([
        CodeTypeApiService.getAllCodeTypeByType("order")
          .then((res: any) =>
            setCodeType(
              res.data.sort((n1: ICodeType, n2: ICodeType) => n1.code - n2.code)
            )
          )
          .catch(() => showAlert("Get Order Status Failed", AlertType.ERROR)),
        CodeTypeApiService.getAllCodeTypeByType("invoice")
          .then((res: any) =>
            setInvoicesStatus(
              res.data.sort((n1: ICodeType, n2: ICodeType) => n1.code - n2.code)
            )
          )
          .catch(() => showAlert("Get Invoice Status Failed", AlertType.ERROR)),
      ]);
    })();
  }, []);
  useEffect(() => {
    if (openModal) {
      setOrderDetail({});
      setTab(0);
      setInvoiceDetail({});
    }
  }, [openModal]);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response: any = await OrderApiService.getAll({
        nameOrder: searchValue,
        statusOrder: 2,
        page: page,
        limit: 10,
      });
      setLoading(false);
      if (response?.data) {
        setOrders(response.data);
        setTotalPage(Math.ceil((response.data?.totalItems || 0) / 10));
      }
    })();
  }, [reload, searchValue, page, filterStatus]);
  useEffect(() => {
    if (tab === 1 || updateDepositModal) {
      (async () => {
        const orderData: IOrderResponse = orderDetail;
        setLoading(true);
        const response: any = await InvoiceApiService.getInvoiceById(
          orderData.invoiceHeaderId
        );
        setLoading(false);
        if (response?.data) {
          setInvoiceDetail(response.data);
        } else {
          showAlert("Get invoice detail failed", AlertType.ERROR);
        }
      })();
    }
  }, [tab, updateDepositModal]);

  const getOrderById = async (id: string) => {
    setLoading(true);
    const order: any = await OrderApiService.getOrderDetailById(id);
    setLoading(false);
    if (order.succeeded && !_.isEmpty(order.data)) {
      const orderData: IOrderResponse = order.data;
      setOrderDetail(orderData);
      setOrderStatus(orderData.orderStatus);
      setShipmentPrice(orderData.thirdShipmentPrice);
    } else {
      showAlert("Get order error!", AlertType.ERROR);
    }
  };

  const handleClick = (order: any) => {
    setOpenModal(true);
    getOrderById(order.id);
  };

  const onOk = () => {
    setConfirmModal(true);
  };

  const updateDeposit = async () => {
    setLoading(true);
    const res = await OrderApiService.updateDepositPrice({
      orderHeaderIdUpdate: orderDetail.id,
      userId: user?.user?.id,
      depositPricePriceUpdate: deposit,
    });
    setLoading(false);
    setDeposit(undefined);

    if (res?.succeeded) {
      setUpdateDepositModal(false);
      showAlert("Cập nhật thành công", AlertType.SUCCESS);
      setReload(!reload);
    } else {
      showAlert("Đã có lỗi xảy ra, vui lòng thử lại", AlertType.ERROR);
    }
  };

  const updateSellPrice = async () => {
    setLoading(true);
    const res = await OrderApiService.updateSellPriceOrderDetail({
      orderHeaderIdUpdate: orderDetail.id,
      orderDetailIdUpdate: orderDetailId,
      userId: user?.user?.id,
      sellPriceProductUpdate: sellPrice,
    });
    setSellPrice(undefined);
    setLoading(false);
    if (res?.succeeded) {
      showAlert("Cập nhật thành công", AlertType.SUCCESS);
      setUpdateSellPriceModal(false);
      getOrderById(orderDetail.id);
      setReload(!reload);
    } else {
      showAlert("Đã có lỗi xảy ra, vui lòng thử lại", AlertType.ERROR);
    }
  };

  const getListOrderStatus = (orderStatus: number) => {
    let listCodeType = codeType;
    switch (orderStatus) {
      case 0:
        listCodeType = codeType.filter(
          (x) => x.code === 1 || x.code === 3 || x.code === orderStatus
        );
        return listCodeType;
      case 1:
        listCodeType = codeType.filter((x) => x.code === 1 || x.code === 3);
        return listCodeType;
      case 2:
        listCodeType = codeType.filter(
          (x) => x.code === 3 || x.code === orderStatus || x.code === 1
        );
        return listCodeType;
      case 5:
        listCodeType = codeType.filter(
          (x) => x.code === 6 || x.code === orderStatus
        );
        return listCodeType;
      default:
        return listCodeType;
    }
  };
  const onConfirmOk = async () => {
    setLoading(true);
    const thirdShipmentPriceUpdate = (shipmentPrice || "")
      .toString()
      .includes(",")
      ? Number((shipmentPrice || "").toString().replace(/[,]/g, ""))
      : Number(shipmentPrice || 0);
    const param: any = {
      orderIdUpdate: orderDetail.id,
      orderStatusUpdate: orderStatus,
      userId: user?.user?.id,
      reason: "",
    };
    const response: any = await OrderApiService.updateOrder(param);
    setLoading(false);
    if (response.succeeded && !_.isEmpty(response.data.id)) {
      showAlert("Update order status successful!", AlertType.SUCCESS);
      setReload(!reload);
      setOpenModal(false);
      setConfirmModal(false);
    } else {
      showAlert("Update order status error!", AlertType.ERROR);
    }
  };

  const onModalCancel = () => {
    setOpenModal(false);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };
  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const OrderStatus = (row: any) => {
    const status = row.orderStatus as number;
    return (
      <span
        className="mb-2 mr-2 badge badge-success"
        style={{
          marginTop: 10,
          backgroundColor: getColor(status),
        }}
      >
        {codeType.find((x: ICodeType) => x.code === status)?.name}
      </span>
    );
  };

  const columns = [
    TableHelper.createColumn({
      field: "orderHeaderNo",
      headerName: "Mã vận đơn",
    }),
    TableHelper.createColumn({
      field: "orderStatus",
      headerName: "Trạng thái",
      width: 450,
      renderCell: ({ row }) => <OrderStatus {...row} />,
    }),
    TableHelper.createColumn({
      field: "totalOrder",
      headerName: "Tổng tiền",
      align: "right",

      renderCell: ({ row }) => {
        return (
          <Typography fontWeight={"bold"}>
            {CurrencyHelper.vnFormatter(row.totalOrder)}
          </Typography>
        );
      },
    }),
    TableHelper.createColumn({
      field: "name",
      headerName: "Tên",
    }),
    TableHelper.createColumn({
      field: "phone",
      headerName: "SĐT",
    }),
    TableHelper.createColumn({
      field: "email",
      width: 300,
      headerName: "Email",
    }),
    TableHelper.createColumn({
      field: "totalWeight",
      headerName: "Cân nặng",
      fieldType: "number",
      width: 100,
    }),
  ];
  return (
    <AdminContainer title={"Quản lí đơn hàng"}>
      <AppTable<any, any, any>
        api={OrderApiService.getAll}
        params={{
          autoTracking: true,
        }}
        columns={columns}
        onEditRowClick={handleClick}
        renderActions={(row) => {
          return (
            <IconButton
              sx={{
                justifyContent: "center",
              }}
              onClick={() => {
                setUpdateDepositModal(true);
                getOrderById(row.id);
              }}
            >
              <AttachMoneyIcon sx={{ color: "green", fontSize: 20 }} />
            </IconButton>
          );
        }}
      />

      <FormDialog
        maxWidth="lg"
        visible={openModal}
        title={
          tab === 0 && !_.isEmpty(orderDetail)
            ? orderDetail?.orderHeaderNo || ""
            : invoiceDetail?.invoiceHeaderNo || ""
        }
        cancelText="Đóng"
        onCancel={onModalCancel}
        onOk={() => onOk()}
        open={openModal}
        hideDivider
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              <Tab label="Đơn hàng" {...a11yProps(0)} />
              <Tab label="Hóa đơn" {...a11yProps(1)} />
            </Tabs>
          </Box>
          {/* tab 1 */}
          <TabPanel value={tab} index={0}>
            {loading ? (
              <Box
                sx={{
                  p: 3,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Grid container spacing={2} sx={{ marginTop: 0.5 }}>
                <Grid container item md={6} sm={12} spacing={1}>
                  <Grid
                    item
                    md={12}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box
                      className="style-4"
                      sx={{
                        ...styles.boxShadow,
                        height: 140,
                        overflowY: "auto",
                        width: "100%",
                      }}
                    >
                      <DescriptionItem
                        title={"Tên"}
                        descriptions={orderDetail.name || ""}
                      />
                      <DescriptionItem
                        title={"SĐT"}
                        descriptions={orderDetail.phone || ""}
                      />
                      <DescriptionItem
                        title={"Email"}
                        descriptions={orderDetail.email || ""}
                      />
                      <DescriptionItem
                        title={"Địa chỉ"}
                        descriptions={`${orderDetail.address || ""}, ${
                          orderDetail.city || ""
                        }`}
                      />
                      <DescriptionItem
                        title={"Hình ảnh"}
                        descriptions={
                          !_.isEmpty(orderDetail.evidence)
                            ? orderDetail.evidence
                            : "Không có hình ảnh"
                        }
                        isLink
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        ...styles.boxShadow,
                        width: "100%",
                      }}
                    >
                      <TextField
                        value={orderStatus}
                        onChange={(e) => setOrderStatus(Number(e.target.value))}
                        variant="standard"
                        select
                        label={<Typography>Trạng thái đơn hàng</Typography>}
                        fullWidth
                        disabled={
                          orderDetail.orderStatus === 3 ||
                          orderDetail.orderStatus === 4 ||
                          orderDetail.orderStatus === 6 ||
                          orderDetail.orderStatus === 7 ||
                          orderDetail.orderStatus === 8
                        }
                      >
                        {getListOrderStatus(orderDetail.orderStatus).map(
                          (x: ICodeType) => (
                            <MenuItem value={x.code}>{x.name}</MenuItem>
                          )
                        )}
                      </TextField>
                    </Box>
                  </Grid>
                  {/* <Grid item md={6}>
                  <Box sx={styles.boxShadow}>
                    <NumberFormat
                      autoFocus
                      customInput={TextField}
                      value={shipmentPrice}
                      variant="standard"
                      sx={{ width: "100%" }}
                      label="Giá gửi hàng (VND)"
                      onChange={(e: any) => {
                        setShipmentPrice(e.target.value);
                      }}
                      focused
                      // inputProps={{
                      //   readOnly: orderData.allProductInVN ? false : true,

                      // }}
                      disabled={orderDetail.allProductInVN ? false : true}
                      thousandSeparator={true}
                    />
                  </Box>
                </Grid> */}
                </Grid>

                <Grid item md={6} sm={12} sx={{ height: 230, width: "100%" }}>
                  <Grid
                    className="style-4"
                    sx={{
                      ...styles.boxShadow,
                      height: "100%",
                      overflowY: "auto",
                      width: "100%",
                    }}
                  >
                    {orderDetail?.orderHistories?.map((x: any, i: number) => {
                      return (
                        <HistoryItem
                          status={
                            codeType?.find((y) => y.code === x.orderStatus)
                              ?.name || ""
                          }
                          dateTime={x.createdDt}
                          reason={x.reason}
                          bold={i === 0}
                        />
                      );
                    })}
                  </Grid>
                </Grid>

                <Grid
                  className="style-4"
                  item
                  md={12}
                  sm={12}
                  sx={{
                    maxHeight: 245,
                    overflowY: "auto",
                    marginTop: 2,
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Ảnh SP</TableCell>
                          <TableCell align="left">Tên SP</TableCell>
                          <TableCell align="left">Số lượng</TableCell>
                          <TableCell align="left">Cân nặng</TableCell>
                          <TableCell align="left">Bảo hành</TableCell>
                          <TableCell align="left">Giá sản phẩm</TableCell>
                          <TableCell align="left">Giá bảo hành</TableCell>
                          <TableCell align="left">Giá tổng sản phẩm</TableCell>
                          <TableCell align="left">Giá vận chuyển</TableCell>
                          <TableCell align="left">Hành động</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderDetail?.orderDetails?.map((x: any, i: number) => {
                          return (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              {/* <TableCell component="th" scope="row">
                              {i + 1}
                            </TableCell> */}
                              <TableCell
                                onClick={() =>
                                  window.open(
                                    `${x?.product?.orgLink}`,
                                    "_blank"
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <Box
                                  component="img"
                                  sx={{ width: 100, height: 80 }}
                                  src={x.product.mainImage}
                                  alt="img"
                                  className="blur-up lazyloaded"
                                />
                              </TableCell>
                              <TableCell>{x.product.name}</TableCell>
                              <TableCell>{x.quantity}</TableCell>
                              <TableCell>{x.weight}</TableCell>
                              <TableCell>{x.guaranteeDescription}</TableCell>
                              <TableCell>
                                {CurrencyHelper.vnFormatter(x.sellPrice || 0)}
                              </TableCell>
                              <TableCell>
                                {CurrencyHelper.vnFormatter(x.guaranteePrice)}
                              </TableCell>
                              <TableCell>
                                {CurrencyHelper.vnFormatter(
                                  x.totalOrderDetails || 0
                                )}
                              </TableCell>
                              <TableCell>
                                {CurrencyHelper.vnFormatter(
                                  x.shipmentPrice || 0
                                )}
                              </TableCell>
                              <TableCell
                                sx={{ color: "blue", cursor: "pointer" }}
                              >
                                <EditIcon
                                  onClick={() => {
                                    setUpdateSellPriceModal(true);
                                    setOrderDetailId(x.id);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}
          </TabPanel>
          {/* tab 2 */}
          <TabPanel value={tab} index={1}>
            <Grid container spacing={2} sx={{ marginTop: 0.5 }}>
              <Grid
                className="style-4"
                item
                md={6}
                sm={12}
                sx={{ height: 200, overflowY: "auto" }}
              >
                <Box
                  sx={{
                    ...styles.boxShadow,
                    height: "99%",
                    width: "99%",
                  }}
                >
                  <DescriptionItem
                    title={"Trạng thái hàng"}
                    descriptions={
                      invoicesStatus.find(
                        (x) => x.code === invoiceDetail.invoiceStatus
                      )?.name || ""
                    }
                  />

                  <DescriptionItem
                    title={"Tiền chưa trả "}
                    descriptions={
                      vnFormatter(invoiceDetail.totalUnPaidPrice) || ""
                    }
                  />
                  <DescriptionItem
                    title={"Tiền cọc"}
                    descriptions={
                      vnFormatter(invoiceDetail.totalDepositPrice) || ""
                    }
                  />
                  <DescriptionItem
                    title={"Giảm giá"}
                    descriptions={vnFormatter(orderDetail.totalDiscount) || ""}
                  />
                  <DescriptionItem
                    title={"Tổng cộng"}
                    descriptions={
                      vnFormatter(
                        invoiceDetail.totalInvoice + orderDetail.totalDiscount
                      ) || ""
                    }
                  />
                </Box>
              </Grid>

              {/* history */}
              <Grid item md={6} sm={12} sx={{ height: 200 }}>
                <Grid
                  className="style-4"
                  sx={{
                    ...styles.boxShadow,
                    height: "100%",
                    overflowY: "auto",
                  }}
                >
                  {invoiceDetail?.invoiceHistories?.map((x: any, i: number) => {
                    return (
                      <HistoryItem
                        status={
                          invoicesStatus?.find(
                            (y) => y.code === x.invoiceStatus
                          )?.name || ""
                        }
                        reason={x.reason}
                        dateTime={x.createdDt}
                        bold={i === 0}
                      />
                    );
                  })}
                </Grid>
              </Grid>

              {/* list */}
              <Grid
                className="style-4"
                item
                md={12}
                sm={12}
                sx={{
                  maxHeight: 245,
                  overflowY: "auto",
                  marginTop: 2,
                }}
              >
                <TableContainer component={Paper}>
                  <Table
                    stickyHeader
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">STT</TableCell>
                        <TableCell align="left">Ảnh SP</TableCell>
                        <TableCell align="left">Tên </TableCell>
                        <TableCell align="left">Tiền cọc</TableCell>
                        <TableCell align="left">Tổng cộng</TableCell>
                        <TableCell align="left">Chưa trả</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoiceDetail?.invoiceDetails?.map(
                        (x: any, i: number) => {
                          return (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {i + 1}
                              </TableCell>
                              <TableCell>
                                <Box
                                  component="img"
                                  sx={{ width: 100, height: 80 }}
                                  src={x.product.mainImage}
                                  alt="img"
                                  className="blur-up lazyloaded"
                                />
                              </TableCell>
                              <TableCell>{x.product.name}</TableCell>
                              <TableCell>
                                {vnFormatter(x.depositPrice)}
                              </TableCell>
                              <TableCell>
                                {vnFormatter(x.invoicePrice)}
                              </TableCell>
                              <TableCell>
                                {vnFormatter(x.unPaidPrice)}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </FormDialog>
      <FormDialog
        visible={updateDepositModal}
        // title={"Cập nhật tiền cọc"}
        title={orderDetail?.orderHeaderNo || ""}
        onCancel={() => {
          setUpdateDepositModal(false);
          setDeposit(undefined);
        }}
        onOk={() => updateDeposit()}
        open={false}
        maxWidth={"md"}
      >
        <Grid container spacing={2} sx={{ marginTop: 0.5 }}>
          <Grid container item sm={12} spacing={1}>
            <Grid item md={12}>
              <Box
                className="style-4"
                sx={{
                  ...styles.boxShadow,
                  height: 100,
                  overflowY: "auto",
                  flex: 1,
                }}
              >
                <DescriptionItem
                  title={"Tổng giá trị đơn hàng"}
                  descriptions={vnFormatter(invoiceDetail.totalInvoice) || ""}
                />
                <DescriptionItem
                  title={"Tiền cọc"}
                  descriptions={
                    vnFormatter(invoiceDetail.totalDepositPrice) || ""
                  }
                />
                <DescriptionItem
                  title={"Tiền chưa trả"}
                  descriptions={
                    vnFormatter(invoiceDetail.totalUnPaidPrice) || ""
                  }
                />
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box sx={styles.boxShadow}>
                <TextField
                  value={deposit}
                  onChange={(e) => setDeposit(Number(e.target.value))}
                  type={"number"}
                  variant="standard"
                  label={<Typography>Cập nhật tiền cọc</Typography>}
                  fullWidth
                  disabled={orderDetail.orderStatus !== 1}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            className="style-4"
            item
            md={12}
            sm={12}
            sx={{
              maxHeight: 245,
              overflowY: "auto",
              marginTop: 2,
            }}
          ></Grid>
        </Grid>
      </FormDialog>
      <FormDialog
        visible={updateSellPriceModal}
        title={"Cập nhật giá sản phẩm"}
        onCancel={() => {
          setUpdateSellPriceModal(false);
          setSellPrice(undefined);
        }}
        onOk={() => updateSellPrice()}
        open={false}
      >
        <TextField
          value={sellPrice}
          onChange={(e) => setSellPrice(Number(e.target.value))}
          type={"number"}
          variant="standard"
          label={<Typography>Giá mới</Typography>}
          fullWidth
        />
      </FormDialog>
      <FormDialog
        visible={confirmModal}
        title={"Xác nhận"}
        onCancel={() => setConfirmModal(false)}
        onOk={() => onConfirmOk()}
        open={false}
      >
        <Typography variant="h6">
          Bạn có chắc chắn thực hiện hành động này?
        </Typography>
      </FormDialog>
      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}

const styles = {
  boxShadow: { boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", p: 1 },
};
