export const formatter = (value: number | undefined, fixed?: number) => {
  const price =
    value !== null && value !== undefined && value > 0
      ? `${value.toFixed(fixed || 2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : `$0`;

  return price;
};
export const shortFormatter = (value: number) => {
  if (value >= 1000000) {
    return `${formatter(value / 1000000)} tr`;
  }
};

export const vnFormatter = (value: number = 0) => {
  const roundedPrice = Math.round((value / 10000) * 10000);
  const price = Math.round(roundedPrice).toLocaleString("vi", {
    style: "currency",
    currency: "VND",
  });

  return price;
};

export const viCurrencyFormatter = (value: number = 0) => {
  return new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  }).format(value);
};

export const getPercentOfValue = (percentToGet: number, number: number) => {
  return (percentToGet / 100) * number;
};

export const parser = (value: any) => value.replace(/\$\s?|(,*)/g, "");

export const currencyFormatter = (
  currency: number,
  n = 0,
  x = 3,
  s = ".",
  c = ","
) => {
  var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")",
    num = currency.toFixed(Math.max(0, ~~n));

  return (c ? num.replace(".", c) : num).replace(
    new RegExp(re, "g"),
    "$&" + (s || ",")
  );
};
