import { Typography } from "@mui/material";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import React from "react";

type CellType = {
  fieldType?: "string" | "number" | "status";
} & GridColDef;

export const createColumn = (props: CellType) => {
  const { fieldType = "string", ...rest } = props;
  switch (fieldType) {
    case "number":
      return {
        width: 200,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: "right",
        ...rest,
      } as GridColDef;
    case "status":
      return {
        width: 200,
        sortable: false,
        disableColumnMenu: true,
        filterable: false,
        renderCell: (params) => {
          return (
            <Typography sx={{ p: 1, color: "red" }}>
              {params.row[props.field]}
            </Typography>
          );
        },
        ...rest,
      } as GridColDef;
    default:
      return {
        width: 200,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        ...rest,
      } as GridColDef;
  }
};

// export const useColumns = <T extends GridValidRowModel>(
//   callback: (renderCell: (props: CellType) => GridColDef) => GridColDef<T>[]
// ) => {
//   const _renderCell = (props: CellType) => {};
//   return callback(_renderCell);
// };
