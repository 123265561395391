import { ReduxHelper } from "helpers";
import { ELanguage } from "utils/i18n";

export const prefix = "APP_CONFIG";
export const changeLanguage = ReduxHelper.generateLocalAction<ELanguage>(
  prefix,
  "CHANGE_LANGUAGE"
);
export const clearPrevPath = ReduxHelper.generateLocalAction(
  prefix,
  "CLEAR_PREV_PATH"
);
export const prevPath = ReduxHelper.generateLocalAction<string>(
  prefix,
  "PREV_PATH"
);
