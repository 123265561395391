import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FormDialog, HeaderContainer } from "components";
import { CurrencyHelper, DateHelper, TableHelper } from "helpers";
import { AlertType, showAlert } from "helpers/alertHelper";
import TextField from "@mui/material/TextField";
import { AdminLayout } from "layouts";
import _ from "lodash";
import { IConfiguration } from "models/configuration";
import React, { useEffect, useState } from "react";
import { RootReducer } from "redux/configurations/rootReducer";
import { ConfigurationsApiService } from "services";
import {
  TableColumName,
  TableFooter,
  TableHeaderSearch,
} from "components/AdminComponents/AdminTable";
import AdminContainer from "../../Container";
import AppTable from "components/app-table";

const textColor = "#000";
const textSecondColor = "#4B4B4B";

export default function Configurations() {
  const user = useSelector((state: RootReducer) => state.IdentityReducer.admin);
  const [configurations, setConfigurations] = useState<any>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [configurationDetail, setConfigurationDetail] = useState<any>({});
  const [reload, setReload] = useState(false);
  const [idHover, setIdHover] = useState<string>();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState<any>(0);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const configurationResponse = (await ConfigurationsApiService.pagination({
        code: searchValue,
        page,
        limit: 10,
      })) as any;
      setLoading(false);

      if (
        configurationResponse?.succeeded &&
        !_.isEmpty(configurationResponse.data)
      ) {
        setConfigurations(configurationResponse?.data);
        setTotalPage(
          Math.ceil((configurationResponse?.data?.totalItems || 0) / 10)
        );
      } else {
        showAlert("Get configurations failed", AlertType.ERROR);
      }
    })();
  }, [reload, searchValue, page]);

  const onOk = () => {
    (async () => {
      const params = {
        id: configurationDetail.id,
        value: configurationDetail.value,
        userId: user?.userId,
      };
      const configurationResponse =
        await ConfigurationsApiService.updateConfiguration(params);
      if (
        configurationResponse?.succeeded &&
        !_.isEmpty(configurationResponse.data)
      ) {
        showAlert("Update configurations successfully", AlertType.SUCCESS);
        setReload(!reload);
      } else {
        showAlert("Update configurations failed", AlertType.ERROR);
      }
      setModalVisible(false);
    })();
  };
  const renderTableBody = () => {
    return (
      <table
        id="responsive-data-table"
        className="table dataTable no-footer"
        style={{ width: "100%", cursor: "pointer", marginTop: 16 }}
        aria-describedby="responsive-data-table_info"
      >
        <thead>
          <tr>
            <TableColumName title="Mã" />
            <TableColumName title="Giá trị" />
            <TableColumName title="Hành động" />
          </tr>
        </thead>
        <tbody>
          {configurationsData?.results?.map((row: any) => (
            <tr
              key={row.id}
              onClick={() => {
                setModalVisible(true);
                setConfigurationDetail(row);
              }}
              style={{
                height: 70,
                backgroundColor:
                  idHover === row.id ? "rgba(61, 133, 198, 0.2)" : "",
              }}
              onMouseEnter={() => setIdHover(row.id)}
              onMouseLeave={() => setIdHover(undefined)}
            >
              <td className="sorting_1">{row.code}</td>
              <td className="sorting_1">{row.value}</td>
              <td className="sorting_1">{row.name}</td>
              <td
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
              >
                Chỉnh sửa
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const columns = [
    TableHelper.createColumn({
      field: "code",
      flex: 1,
      headerName: "Mã",
    }),
    TableHelper.createColumn({
      field: "value",
      flex: 1,
      headerName: "Dữ liệu",
    }),
  ];

  const onEditRowClick = (row: any) => {
    setModalVisible(true);
    setConfigurationDetail(row);
  };

  const configurationsData: any = configurations;
  return (
    <AdminContainer title={"Cấu hình nâng cao"}>
      <AppTable<any, any, any>
        api={ConfigurationsApiService.pagination}
        columns={columns}
        reload={reload}
        onEditRowClick={onEditRowClick}
      />
      {/* <div className="row">
        <div className="col-12">
          <div className="card card-default" style={{ borderRadius: 16 }}>
            <div className="card-body">
              <div className="table-responsive">
                <div
                  id="responsive-data-table_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <TableHeaderSearch
                    onSearch={(value) => setSearchValue(value)}
                  />
                  {renderTableBody()}
                  <TableFooter
                    currentPage={configurations?.page || 0}
                    onChangePage={(page) => setPage(page)}
                    totalPage={totalPage}
                  />
                  <div className="clear" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <FormDialog
        visible={modalVisible}
        title={"Chỉnh sửa"}
        onCancel={() => setModalVisible(false)}
        onOk={() => onOk()}
        open={false}
      >
        {!_.isEmpty(configurationDetail) && (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ width: "100%" }}
            >
              <Typography>Code</Typography>
              <TextField
                value={configurationDetail.code}
                disabled={true}
                variant="standard"
                fullWidth
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ width: "100%" }}
            >
              <Typography>Value</Typography>
              <TextField
                value={configurationDetail.value}
                onChange={(e) =>
                  setConfigurationDetail({
                    ...configurationDetail,
                    value: e.target.value,
                  })
                }
                variant="standard"
                fullWidth
              />
            </Stack>
          </Stack>
        )}
      </FormDialog>
      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}
