import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AppButton from "components/client/app-button";

class ConfirmDialogManager {
  ref: ConfirmDialog | null = null;
  register(_ref: ConfirmDialog) {
    this.ref = _ref;
  }
  unregister() {
    this.ref = null;
  }
  getDefault() {
    return this.ref;
  }
}

interface IConfirmDialogProps {}
export interface IConfirmDialogState {
  open: boolean;
  type?: "confirm" | "alert";
  message?: string;
  isCancel?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}
export const confirmModalRef = new ConfirmDialogManager();

export default class ConfirmDialog extends React.Component<
  IConfirmDialogProps,
  IConfirmDialogState
> {
  state: Readonly<IConfirmDialogState> = {
    open: false,
  };

  componentDidMount() {
    confirmModalRef.register(this);
  }
  componentWillUnmount() {
    confirmModalRef.unregister();
  }
  _handleOpen = (state: IConfirmDialogState) => {
    this.setState(state);
  };

  _handleConfirm = () => {
    this.setState({ open: false }, () => this.state.onConfirm?.());
  };
  _handleClose = () => {
    this.setState({ open: false }, () => this.state.onCancel?.());
  };
  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this._handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontWeight: "bold",
            color: this.state.type === "confirm" ? "blue" : "red",
          }}
        >
          {this.state.type === "confirm" ? "Xác nhận" : "Cảnh báo"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText color={"black"} id="alert-dialog-description">
            {this.state.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={this._handleClose}>
            Hủy
          </Button>
          <AppButton
            label="Xác nhận"
            variant="primary"
            onClick={this._handleConfirm}
            autoFocus
          ></AppButton>
        </DialogActions>
      </Dialog>
    );
  }
}
