import EditIcon from "@mui/icons-material/Edit";
import { Stack, Switch, Typography } from "@mui/material";
import { FormDialog } from "components";
import { TableColumName } from "components/AdminComponents/AdminTable";
import AppInput from "components/app-input";
import AppTable from "components/app-table";
import AppButton from "components/client/app-button";
import {
  ConfirmModalHelper,
  DateHelper,
  TableHelper,
  ToastHelper,
} from "helpers";
import { AlertType, showAlert } from "helpers/alertHelper";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootReducer } from "redux/configurations/rootReducer";
import { CrawlApiService } from "services";
import { IApiResponse } from "services/BaseApiService";
import CrawlSingleLinkApiService from "services/CrawlSingleLinkApiService";
import AdminContainer from "../../Container";

export default function CrawlSingleLink() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<any>([]);
  const [idHover, setIdHover] = useState<string>();
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [reload, setReload] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [currentRow, setCurrentRow] = useState<any>();
  const [isDailyDeal, setIsDailyDeal] = useState(false);
  const user = useSelector((state: RootReducer) => state.IdentityReducer.admin);

  const crawlSingle = async () => {
    if (!_.isEmpty(searchValue)) {
      setLoading(true);
      const response = (await CrawlApiService.crawlData(
        searchValue.trim()
      )) as IApiResponse<any>;
      if (response.succeeded) {
        ToastHelper.toastSuccess(`Thành công - ${response.data} sản phẩm`);
      } else {
        ToastHelper.toastError("Không tìm thấy sản phẩm");
      }
      setLoading(false);
    } else {
      ToastHelper.toastError("Link không được để trống");
    }
  };

  const handleDeleteClick = async (id: string) => {
    ConfirmModalHelper.alert({
      message: "Bạn có muốn xóa link này!",
      isCancel: true,
      onConfirm: async () => {
        setLoading(true);
        const res: any = await CrawlSingleLinkApiService.deleteLink(id);
        setLoading(false);
        if (res.succeeded && !_.isEmpty(res.data)) {
          ToastHelper.toastSuccess("Xóa thành công");
          setReload(!reload);
        } else {
          ToastHelper.toastSuccess("Xóa thất bại");
        }
      },
    });
  };

  const editLink = async () => {
    setLoading(true);
    let res: any;
    if (!_.isEmpty(currentRow?.id)) {
      res = await CrawlSingleLinkApiService.update({
        ...currentRow,
        link: currentRow.link,
        userId: user?.user.id,
        updatedDt: undefined,
      });
    } else {
      res = await CrawlSingleLinkApiService.createLink({
        ...currentRow,
        userId: user?.user.id,
        carrierName: "EBAY",
        type: "GROUP",
      });
    }
    setLoading(false);
    setEditModal(false);
    if (res.succeeded && !_.isEmpty(res.data)) {
      ToastHelper.toastSuccess("Cập nhật thành công!");
      setReload(!reload);
    } else {
      ToastHelper.toastSuccess("Cập nhật thất bại");
    }
    setCurrentRow(undefined);
  };

  const renderTableBody = () => {
    return (
      <table
        id="responsive-data-table"
        className="table dataTable no-footer"
        style={{ width: "100%", cursor: "pointer", marginTop: 16 }}
        aria-describedby="responsive-data-table_info"
      >
        <thead>
          <tr>
            {/* <TableColumName title="STT" /> */}
            <TableColumName title="Link SP" />
            {/* <TableColumName title="Lấy từ" />
            <TableColumName title="Ngày thực hiện" /> */}
            <TableColumName title="Hành động" />
            {/* <TableColumName title="Daily Deal" /> */}
          </tr>
        </thead>
        <tbody>
          {products?.results?.map((row: any, index: number) => (
            <tr
              key={row.id}
              // onClick={() => handleClick(row)}
              style={{
                height: 70,
                backgroundColor:
                  idHover === row.id ? "rgba(61, 133, 198, 0.2)" : "",
              }}
              onMouseEnter={() => setIdHover(row.id)}
              onMouseLeave={() => setIdHover(undefined)}
            >
              {/* <td>
                <Typography>{index + 1}</Typography>
              </td> */}
              <td>
                <Typography>
                  {_.truncate(row.singleLink, { length: 100 })}
                  {/* {row.quoteLink} */}
                </Typography>
              </td>
              {/* <td>
                <Typography>{row.carrierName}</Typography>
              </td> */}
              {/* <td>
                <span
                  className="mb-2 mr-2 badge badge-success"
                  style={{
                    marginTop: 10,
                    backgroundColor: row.status === 1 ? "#018e00" : "#cc0000",
                  }}
                >
                  {row.status === 1 ? "Thành công" : "Thất bại"}
                </span>
              </td> */}
              {/* <td>
                <Typography>
                  {DateHelper.mmddyyyyFormat(row.createdDt)}
                </Typography>
              </td> */}
              <td
                style={{
                  flexDirection: "row",
                }}
              >
                <EditIcon
                  sx={{ color: "rgba(61, 133, 198)", ml: 3 }}
                  onClick={() => {
                    setEditModal(true);
                    setCurrentRow(row);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const columns = [
    TableHelper.createColumn({
      field: "singleLink",
      flex: 1,
      headerName: "Link",
    }),
    TableHelper.createColumn({
      field: "updatedDt",
      width: 300,
      headerName: "Ngày cập nhật",
      valueGetter: ({ row }) => DateHelper.getDayTimeFormat(row.updatedDt),
    }),
  ];
  return (
    <AdminContainer>
      <div className="d-flex flex-1 flex-wrap gap-3 align-items-end w-100 mb-5 mt-2">
        <AppInput
          fullWidth
          title="Link cào"
          placeholder="Nhập link cào nhóm..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <AppButton
        label="Cào"
          variant="primary"
          style={{ height: 40, marginLeft: 10 }}
          onClick={crawlSingle}
        >
          
        </AppButton>
      </div>{" "}
      <AppTable<any, any, any>
        api={CrawlSingleLinkApiService.pagination}
        columns={columns}
        onEditRowClick={(row) => {
          setEditModal(true);
          setCurrentRow(row);
        }}
        onDeleteRowClick={handleDeleteClick}
      />
      {/* <div className="row">
        <div className="col-sm-12">
          <div className="card">
      
            <div className="card-body">
              <div
                id="basicScenario"
                className="product-physical table-responsive"
              >
          
                <Box sx={{ mt: 2 }} />
                <TableHeaderSearch
                  onSearch={(value) => setSearchValue(value)}
                  rightComponent={
                    <Stack
                      direction={"row"}
                      spacing={2}
                      alignItems={"baseline"}
                    >
                      <Typography>Daily Deal:</Typography>
                      <Switch
                        checked={isDailyDeal}
                        onChange={(e) => setIsDailyDeal(e.target.checked)}
                      />
                    </Stack>
                  }
                />
                {!loading ? (
                  renderTableBody()
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // pt: 5,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <TableFooter
                  currentPage={products?.page || 0}
                  onChangePage={(page) => setPage(page)}
                  totalPage={totalPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <FormDialog
        visible={editModal}
        title={_.isEmpty(currentRow?.id) ? "Tạo mới" : "Chỉnh sửa"}
        onCancel={() => setEditModal(false)}
        onOk={() => editLink()}
        open={false}
      >
        <AppInput
          fullWidth
          title="Link thay thế"
          placeholder="Nhập link cào thay thế..."
          value={currentRow?.singleLink || ""}
          onChange={(e) =>
            setCurrentRow({ ...currentRow, link: e.target.value })
          }
        />
        <Stack direction={"row"} spacing={2} alignItems={"baseline"}>
          <Typography sx={{ pt: 2 }} variant="h6">
            Daily Deal:
          </Typography>
          <Switch
            checked={currentRow?.isDailyDeal || ""}
            onChange={(e) =>
              setCurrentRow({ ...currentRow, isDailyDeal: e.target.checked })
            }
          />
        </Stack>
      </FormDialog>
      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}
