import { Key } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { FormDialog, HeaderContainer } from "components";
import { TableColumName } from "components/AdminComponents/AdminTable";
import AppTable from "components/app-table";
import { DateHelper, TableHelper, ToastHelper } from "helpers";
import _ from "lodash";
import { IUser } from "models/IIdentity";
import moment from "moment";
import AdminContainer from "pages/admin/Container";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootReducer } from "redux/configurations/rootReducer";
import { IdentityApiService } from "services";
import AffiliateApiService from "services/AffiliateApiService";

const UserInfo = (props: {
  title: string;
  value: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  type?: string;
}) => {
  const { title, value, type, disabled, onChange, placeholder } = props;
  return (
    <Stack direction="row" spacing={3}>
      <Typography
        sx={{ fontWeight: 600, paddingTop: 1, minWidth: 150, color: "#000" }}
      >
        {title}
      </Typography>
      <input
        type={type}
        value={value}
        disabled={disabled}
        style={{
          border: "1px solid grey",
          backgroundColor: disabled ? "#f1f1f1" : "white",
        }}
        onChange={onChange}
        placeholder={placeholder}
      ></input>
    </Stack>
  );
};
export default function AffiliateManagement() {
  const user = useSelector((state: RootReducer) => state.IdentityReducer.admin);
  const [currentUser, setCurrentUser] = useState<any>();

  const [userDetail, setUserDetail] = useState<IUser>();
  const [openModal, setOpenModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [idHover, setIdHover] = useState<string>();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [modalPassword, setModalPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (openModal) {
      setUserDetail({} as IUser);
    }
  }, [openModal]);

  const handleClick = async (row: any) => {
    setOpenModal(true);
    setCurrentUser(row);
  };

  const resetPassword = async () => {
    setLoading(true);
    const res = await IdentityApiService.resetPassword({
      id: userDetail?.id,
      password: newPassword,
    });
    setLoading(false);
    setModalPassword(false);
    if (res?.succeeded) {
      ToastHelper.toastSuccess("Đổi mật khẩu thành công");
    } else {
      ToastHelper.toastError("Lỗi đổi mật khẩu");
    }
  };

  const onOk = async () => {
    const result = await AffiliateApiService.updateAffiliate({
      id: currentUser?.id,
      refCode: currentUser?.refCode,
      usePercentage: currentUser?.usePercentage,
      commissionPercentage: currentUser?.commissionPercentage,
      commissionAmount: currentUser?.commissionAmount,
      maximumCommissionAmount: currentUser?.maximumCommissionAmount,
      active: currentUser?.active,
    });
    if (result.succeeded) {
      ToastHelper.toastSuccess("Cập nhật thành công");
    } else {
      ToastHelper.toastError(
        "Cập nhật thất bại, vui lòng liên hệ nhà phát triển để được hỗ trợ  "
      );
    }
    setReload(true);
    setConfirmModal(false);
  };

  const onConfirmOk = async () => {
    const param: any = {
      userId: user?.userId,
      id: userDetail?.id,
      isLocked: userDetail?.isLocked,
    };
    const response: any = await IdentityApiService.updateUserStatus(param);
    if (response.succeeded && !_.isEmpty(response?.data?.id)) {
      ToastHelper.toastSuccess("Cập nhật thông tin thành công");
      setConfirmModal(false);
      setOpenModal(false);
      setReload(!reload);
    } else {
      ToastHelper.toastError("Cập nhật thất bại!");
    }
  };

  const columns = React.useMemo(
    () => [
      TableHelper.createColumn({
        field: "userName",
        headerName: "Họ tên",
        renderCell: ({ row }) => (
          <span>{`${row.user?.firstName + " " + row.user?.lastName}`}</span>
        ),
      }),
      TableHelper.createColumn({
        field: "phone",
        headerName: "Số điện thoại",
        renderCell: ({ row }) => <span>{`${row.user?.phone}`}</span>,
      }),
      TableHelper.createColumn({
        field: "email",
        headerName: "Email",
        width: 400,
        renderCell: ({ row }) => <span>{`${row.user?.email}`}</span>,
      }),
      TableHelper.createColumn({
        field: "used",
        headerName: "Đã sử dụng",
      }),
      TableHelper.createColumn({
        field: "totalAmount",
        headerName: "Lợi nhuận",
      }),
      TableHelper.createColumn({
        field: "refCode",
        headerName: "Mã",
      }),
      TableHelper.createColumn({
        field: "commissionAmount",
        headerName: "Hoa hồng cố định",
      }),
      TableHelper.createColumn({
        field: "commissionPercentage",
        headerName: "Phần trăm hoa hồng",
      }),
      TableHelper.createColumn({
        field: "maximumCommissionAmount",
        headerName: "Hoa hồng tối đa",
      }),
      TableHelper.createColumn({
        field: "usePercentage",
        headerName: "Loại hoa hồng",
        renderCell: ({ row }) => {
          return <span>{row ? "Phần trăm" : "Cố định"}</span>;
        },
      }),
      TableHelper.createColumn({
        field: "approvedDt",
        headerName: "Ngày duyệt",
        renderCell: ({ row }) => {
          return <span>{DateHelper.mmddyyyyFormat(row.approvedDt)}</span>;
        },
      }),
    ],
    []
  );
  return (
    <AdminContainer>
      <HeaderContainer title={"Người dùng"} />
      <AppTable<any, any, any>
        api={AffiliateApiService.getAllAffiliates}
        columns={columns}
        onEditRowClick={handleClick}
      />

      <FormDialog
        maxWidth={"lg"}
        visible={openModal}
        title={`${currentUser?.firstName || ""} ${currentUser?.lastName || ""}`}
        cancelText="Close"
        onCancel={() => setOpenModal(false)}
        onOk={onOk}
        open={openModal}
      >
        {!_.isEmpty(currentUser) ? (
          <Grid container spacing={2} columnSpacing={4} sx={{ pt: 2 }}>
            <Grid item md={6} sm={12}>
              <UserInfo
                title="Họ tên: "
                disabled
                value={
                  currentUser.user?.firstName + " " + currentUser.user?.lastName
                }
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <UserInfo title="Mã: " value={currentUser?.refCode} />
            </Grid>
            <Grid item md={6} sm={12}>
              <UserInfo
                type="number"
                title="Phần trăm : "
                value={currentUser?.commissionPercentage}
                disabled={!currentUser?.usePercentage}
                onChange={(event) => {
                  setCurrentUser({
                    ...currentUser,
                    commissionPercentage: event.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <UserInfo
                type="number"
                title="Cố định: "
                value={currentUser?.commissionAmount}
                disabled={currentUser?.usePercentage}
                onChange={(event) => {
                  setCurrentUser({
                    ...currentUser,
                    commissionAmount: event.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <UserInfo
                type="number"
                title="Hoa hồng tối đa: "
                value={currentUser?.maximumCommissionAmount}
                disabled={!currentUser?.usePercentage}
                onChange={(event) => {
                  setCurrentUser({
                    ...currentUser,
                    maximumCommissionAmount: event.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <Stack direction="row" spacing={3}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    paddingTop: 1,
                    minWidth: 150,
                    color: "#000",
                  }}
                >
                  Loại
                </Typography>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentUser?.usePercentage ? 0 : 1}
                  label="Age"
                  onChange={(event) => {
                    setCurrentUser({
                      ...currentUser,
                      usePercentage: event.target.value === 0 ? true : false,
                    });
                  }}
                >
                  <MenuItem value={0}>Phần trăm</MenuItem>
                  <MenuItem value={1}>Cố định</MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item md={6} sm={12}>
              <Stack direction="row" spacing={3}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    paddingTop: 1,
                    minWidth: 150,
                    color: "#000",
                  }}
                >
                  Hoạt động
                </Typography>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={0}
                  label="Age"
                  onChange={(event) => {
                    setCurrentUser({
                      ...currentUser,
                      active: event.target.value === 0 ? true : false,
                    });
                  }}
                >
                  <MenuItem value={0}>Mở</MenuItem>
                  <MenuItem value={1}>Tắt</MenuItem>
                </Select>
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pt: 5,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </FormDialog>
      <FormDialog
        visible={modalPassword}
        title={"Đổi mật khẩu"}
        onCancel={() => setModalPassword(false)}
        onOk={resetPassword}
        open={false}
      >
        <Typography variant="h6">Mật khẩu mới</Typography>
        <TextField
          variant="standard"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </FormDialog>
      <FormDialog
        visible={confirmModal}
        title={"Confirm"}
        onCancel={() => setConfirmModal(false)}
        onOk={() => onConfirmOk()}
        open={false}
      >
        <Typography variant="h6">
          bạn có chắc chắn muốn thực hiện hành động này?
        </Typography>
      </FormDialog>
      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}
