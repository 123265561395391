import moment from "moment";
import { BaseApiService } from "./BaseApiService";

class UploadBaseService extends BaseApiService {
  uploadImage = (files: any) => {
    const formData = new FormData();
    files.forEach((file: any) => {
      formData.append("files", file);
    });
    // formData.append("title", `IMG_${moment().unix()}`);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return this.post("/upload-image", formData, config);
  };

  // deleteImage = (id: string) => this.delete<string>(`/Picture/delete-picture/${id}`);
}

export default new UploadBaseService("/upload");
