import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import axiosInstance from "services/axios";
import { CodeConfiguration } from "utils/constants";
import { useBoolean } from "./useBoolean";

export const useCheckMaintenance = () => {
  const isChecking = useBoolean();

  const router = useHistory();
  const getByCode = async () => {
    isChecking.onTrue();
    const response: any = await axiosInstance.get(
      `configuration/get-configuration-by-code?code=${CodeConfiguration.WEB_MAINTENANCE}`
    );
    console.log("🚀 ~ getByCode ~ response:", response);

    if (response.succeeded) {
      const isMaintenance = response.data[0].value === "1";
      console.log("🚀 ~ getByCode ~ isMaintenance:", isMaintenance);
      if (isMaintenance) {
        router.push("/maintenance");
      } else {
        isChecking.onFalse();
      }
    } else {
      router.push("/maintenance");
    }
  };

  useEffect(() => {
    if (!window.location.href.includes("maintenance")) {
      getByCode();
    }
  }, [window.location.href]);

  return {
    isChecking,
  };
};
