import { IProduct } from "models/IProductModel";
import { BaseApiService } from "./BaseApiService";

class CrawlApiService extends BaseApiService {
  public crawlData = (links: string) =>
    this.get<IProduct>(`/get-product-by-link-crawl-ebay?links=${links}`);
  public crawlMultipleData = (link: string) =>
    this.get<any>(`/get-product-by-admin-link-crawl-ebay`, {
      params: {
        links: link,
      },
    });
  public crawlGroupData = (link: string) =>
    this.get<any>(`/get-product-by-link-group-crawl-ebay`, {
      params: {
        links: link,
      },
    });
}

export default new CrawlApiService("/crawl-data");
