import { PayloadAction } from "@reduxjs/toolkit";
import { takeEvery } from "redux-saga/effects";
import { UserActions } from "redux/actions";

function* loginAsync(action: PayloadAction) {
  try {
  } catch (error) {}
}

export function* UserWatcher() {
  yield takeEvery(UserActions.login.request, loginAsync);
}
