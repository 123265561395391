import { ReduxHelper } from "helpers";

export const prefix = "PRODUCTS";
export const getAllProduct = ReduxHelper.generateActions<void, any>(
  prefix,
  "GET_ALL_PRODUCT"
);
export const createProduct = ReduxHelper.generateActions<any, any>(
  prefix,
  "CREATE_PRODUCT"
);
export const addProductDetail = ReduxHelper.generateLocalAction<any>(
  prefix,
  "ADD_PRODUCT_DETAIL"
);
export const searchProductName = ReduxHelper.generateLocalAction<any>(
  prefix,
  "SEARCH_PRODUCT_NAME"
);
export const setOrderBy = ReduxHelper.generateLocalAction<any>(
  prefix,
  "SET_ORDER_BY"
);
