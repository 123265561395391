import styled from "@emotion/styled";
import { Grid, notification, Pagination, Row, Space } from "antd";
import CardDeal from "components/CardDeal";
import { StringHelper } from "helpers";
import { getDealRender } from "helpers/dealHelper";
import { useAtom } from "jotai";
import {
  dealsAtom,
  filterQueryAtom,
  isFullCategoryAtom,
  totalDealsAtom,
} from "pages/client/DealsPage/atoms/dealsAtom";
import {
  FILTER_CLIENT,
  INIT_DEALS_PAGE_SIZE,
  SORT_CLIENT,
} from "pages/client/DealsPage/configs/dealConfig";
import { useCallback, useEffect } from "react";
import DealApiService from "services/DealApiService";
import { antdBp } from "themes/antdTheme";

const ContainerStyle = styled(Space)`
  margin: 20px 0;
  width: 100%;

  @media screen and (max-width: ${antdBp.sm}) {
    padding: 0;
  }
`;

const DealsStyle = styled(Row)`
  @media screen and (min-width: ${antdBp.lg}) {
    flex-wrap: wrap;
    gap: 12px;
  }
`;

const PaginationStyle = styled(Row)`
  justify-content: center;
  margin-top: 1rem;
`;

const DealList = () => {
  const screens = Grid.useBreakpoint();
  const [isFullCategory] = useAtom(isFullCategoryAtom);
  const [filterQuery] = useAtom(filterQueryAtom);
  const [deals, setDeals] = useAtom(dealsAtom);
  const [totalDeals, setTotalDeals] = useAtom(totalDealsAtom);

  // Handle - fetch deals with filter queries, page number, limit per page
  const getDeals = useCallback(
    async (page: number) => {
      const fullFilterQuery = {
        ...filterQuery,
        ...FILTER_CLIENT[0].filterMapping[filterQuery.optionPrice],
        ...SORT_CLIENT.sortMapping[filterQuery.optionSort],
      };
      const querySerialized = StringHelper.paramsSerializer(fullFilterQuery);
      const { succeeded, data } = await DealApiService.getDealsFlashSale({
        page,
        filterQuery: querySerialized,
        limit: INIT_DEALS_PAGE_SIZE,
      });
      if (succeeded) {
        setTotalDeals(data.totalItems);
        setDeals(data.results);
      } else {
        notification.error({ message: "Không thể lấy danh sách flash sale" });
      }
    },
    [filterQuery, setDeals, setTotalDeals]
  );

  // Handle - select change page
  const handleChangePage = useCallback(
    (page: number) => {
      getDeals(page);
    },
    [getDeals]
  );

  // Initial loading - get deals when flash sale or has a query filter, sort option
  useEffect(() => {
    const querySerialized = StringHelper.paramsSerializer(filterQuery);
    (querySerialized || isFullCategory) && getDeals(1);
  }, [filterQuery, getDeals, isFullCategory]);

  return (
    <ContainerStyle direction="vertical">
      {/* List - deals */}
      <DealsStyle gutter={screens.xl ? 0 : 12}>
        {deals.length
          ? deals.map((deal: any, idx) => {
              const dealRender = getDealRender(deal);
              return dealRender.thumbnail ? (
                <CardDeal
                  key={idx}
                  col={4}
                  color="neutral"
                  url={`/product-item?id=${dealRender.id}&isDeal=true`}
                  status={dealRender.dealStatus}
                  condition={dealRender.condition}
                  image={dealRender.thumbnail}
                  name={dealRender.name}
                  sellPrice={dealRender.prices[0].price}
                  regularPrice={dealRender.tempSellRegularPrice}
                  specs={dealRender.specifications}
                />
              ) : null;
            })
          : null}
      </DealsStyle>

      {/* Pagination */}
      <PaginationStyle>
        <Pagination
          defaultCurrent={1}
          total={totalDeals}
          defaultPageSize={INIT_DEALS_PAGE_SIZE}
          onChange={handleChangePage}
        />
      </PaginationStyle>
    </ContainerStyle>
  );
};

export default DealList;
