import { all } from "redux-saga/effects";

import {
  UserSaga,
  IdentitySaga,
  ProductSaga,
  CartSaga,
  WishlistSaga,
} from "../sagas";

export default function* watch() {
  yield all([
    UserSaga.UserWatcher(),
    IdentitySaga.IdentityWatcher(),
    ProductSaga.ProductWatcher(),
    CartSaga.CartWatcher(),
    WishlistSaga.Watcher(),
  ]);
}
