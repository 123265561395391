// material-ui

import NavGroup from "./NavGroup";
import { Typography } from "@mui/material";
import utilities from "routers/utilities";

// project imports

// import menuItem from "menu-items";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const NAV_MENU = [
  // {
  //   id: "dashboard",
  //   title: "Thống kê",
  //   children: [
  //     {
  //       id: "main-dashboard",
  //       title: "Tổng quan",
  //       path: "/main-dashboard",
  //       icon: "mdi-view-dashboard ",
  //       type: "item",
  //     },
  //   ],
  // },
  {
    id: "operation",
    title: "Vận hành",
    children: [
      {
        id: "order-management",
        title: "Đơn Hàng",
        path: "/order-management",
        icon: "mdi-book-open",
        type: "item",
      },
      {
        id: "auto-tracking-order",
        title: "Đơn hàng Tự động",
        path: "/auto-tracking-order",
        icon: "mdi-book-open",
        type: "item",
      },
      {
        id: "shipment-management",
        title: "Giao Hàng",
        path: "/shipment-management",
        icon: "mdi-truck-fast",
        type: "item",
      },
    ],
  },
  {
    id: "master-data",
    title: "Dữ liệu chủ",
    children: [
      {
        id: "product-management",
        title: "Sản phẩm",
        path: "/product-management",
        icon: "mdi-archive-cog",
        type: "item",
      },
      {
        id: "user-management",
        title: "Người dùng",
        path: "/user-management",
        icon: "mdi-account-group",
        type: "item",
      },
      {
        id: "affiliate-management",
        title: "Cộng tác viên",
        path: "/affiliate-management",
        icon: "mdi-account-group",
        type: "item",
      },
      // {
      //   id: "affiliate-register-management",
      //   title: "Đăng ký cộng tác",
      //   path: "/affiliate-register-management",
      //   icon: "mdi-account-group",
      //   type: "item",
      // },
      {
        id: "discount-management",
        title: "Mã giảm giá",
        path: "/discount-management",
        icon: "mdi-account-group",
        type: "item",
      },
      {
        id: "deal-management",
        title: "Sản phẩm săn sale",
        path: "/deal-management",
        icon: "mdi-sale",
        type: "item",
      },
    ],
  },
  {
    id: "configuration",
    title: "Cấu hình",
    children: [
      {
        id: "category-configuration",
        title: "Danh mục sản phẩm",
        path: "/category-configuration",
        icon: "mdi-shape",
        type: "item",
      },
      {
        id: "configurations",
        title: "Cấu hình cơ bản",
        path: "/configurations",
        icon: "mdi-wrench-cog",
        type: "item",
      },
      {
        id: "rate-configuration",
        title: "Cấu hình tỉ lệ",
        path: "/rate-configuration",
        icon: "mdi-file-cog",
        type: "item",
      },
      {
        id: "shipping-surcharge-management",
        title: "Cấu hình giá ship",
        path: "/shipping-surcharge-management",
        icon: "mdi-cog-transfer",
        type: "item",
      },
      {
        id: "code-type-management",
        title: "Cấu hình tiến trình",
        path: "/code-type-management",
        icon: "mdi-timer-cog",
        type: "item",
      },
      {
        id: "seller-configuration",
        title: "Cấu hình Seller",
        path: "/seller-configuration",
        icon: "mdi-account-sync",
        type: "item",
      },
      {
        id: "guarantee-configuration",
        title: "Cấu hình bảo hành",
        path: "/guarantee-configuration",
        icon: " mdi-toolbox",
        type: "item",
      },
      {
        id: "hang-fire-configuration",
        title: "Hang Fire",
        path: "/hang-fire-configuration",
        icon: " mdi-toolbox",
        type: "item",
      },
    ],
  },
  {
    id: "crawl-data",
    title: "Cào dữ liệu",
    children: [
      {
        id: "crawl-data",
        title: "Cào dữ liệu",
        path: "/crawl-data",
        icon: "mdi-database-arrow-down",
        type: "item",
      },
      {
        id: "crawl-single-link-management",
        title: "Cào link đơn",
        path: "/crawl-single-link-management",
        icon: "mdi-dice-1",
        type: "item",
      },
      {
        id: "crawl-multiple-link-management",
        title: "Cào link nhóm",
        path: "/crawl-multiple-link-management",
        icon: "mdi-dice-5",
        type: "item",
      },
    ],
  },
  {
    id: "web-configuration",
    title: "Cấu hình trang web",
    icon: "mdi-cart",
    children: [
      {
        id: "top-deals-configuration",
        title: "Cấu hình giá sốc",
        path: "/top-deals-configuration",
        icon: "mdi-file",
        type: "item",
      },

      // {
      //   id: "about-web-configuration",
      //   title: "Về chúng tôi",
      //   path: "/top-deals-management",
      //   icon: "mdi-file",
      //   type: "item",
      // },
      // {
      //   id: "top-deals-web-configuration",
      //   title: "Chính sách",
      //   path: "/top-deals-management",
      //   icon: "mdi-file",
      //   type: "item",
      // },
    ],
  },
];

const MenuList = () => {
  const navItems = NAV_MENU.map((item) => {
    return <NavGroup key={item.id} item={item} />;
  });

  return <>{navItems}</>;
};

export default MenuList;
