import { CurrencyHelper } from "helpers";
import { AdminLayout } from "layouts";
import { IProduct } from "models/IProductModel";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IApiResponse } from "services/BaseApiService";
import { useDispatch, useSelector } from "react-redux";

import ProductApiService from "services/ProductApiService";
import { ProductActions } from "redux/actions";
import { RootReducer } from "redux/configurations/rootReducer";
import _ from "lodash";
import AdminContainer from "../Container";

export default function ProductDetail() {
  let history = useHistory();
  const dispatch = useDispatch();
  let productItem = useSelector(
    (state: RootReducer) => state.ProductReducer.productDetail
  );
  // const [productItem, setProductItem] = useState<any>({});
  const location = useLocation();
  const productId = location.search.split("=")[1];
  let images: any = [];
  let options: any = [];
  useEffect(() => {
    const getProductItem = async () => {
      let response: any = await ProductApiService.getProductItem(productId);
      if (response?.succeeded) {
        // response.data.images = [];
        // response.data.options = [];
        // response?.data?.listProductDetailsGroup.map((x: any) =>
        //   x.map((a: any) => {
        //     if (a.type !== "Image") {
        //       response?.data?.options?.push(a);
        //     } else {
        //       response?.data?.images?.push(a);
        //     }
        //   })
        // );

        // setProductItem(response?.data);
        dispatch(ProductActions.addProductDetail.request(response?.data));
      } else {
        history.push("/product-management");
      }
    };
    if (_.isEmpty(productItem)) {
      getProductItem();
    }

    productItem?.listProductDetailsGroup.map((x: any) =>
      x.map((a: any) => {
        if (a.type !== "Image") {
          options.push(a);
        } else {
          images.push(a);
        }
      })
    );
  }, []);
  useEffect(() => {
    return () => {
      dispatch(ProductActions.addProductDetail.request(undefined));
    };
  }, []);

  return (
    <AdminContainer>
      {/* <!-- Container-fluid starts--> */}
      <div className="container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-lg-6">
              <div className="page-header-left">
                <h3>
                  Product Detail
                  <small>Multikart Admin panel</small>
                </h3>
              </div>
            </div>
            <div className="col-lg-6">
              <ol className="breadcrumb pull-right">
                <li className="breadcrumb-item">
                  <a href="index.html">
                    <i data-feather="home"></i>
                  </a>
                </li>
                <li className="breadcrumb-item">Physical</li>
                <li className="breadcrumb-item active">Product Detail</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}

      {/* <!-- Container-fluid starts--> */}
      {!_.isEmpty(productItem) && (
        <div className="container-fluid">
          <div className="card">
            <div className="row product-page-main card-body">
              <div className="col-xl-4">
                <div
                  className="product-slider owl-carousel owl-theme"
                  id="sync1"
                >
                  <div className="item">
                    <img
                      src={productItem?.mainImage}
                      alt=""
                      className="blur-up lazyloaded"
                      style={{ width: "100%" }}
                    />
                  </div>
                  {/* <div className="item"><img src="../assets/images/pro3/27.jpg" alt="" className="blur-up lazyloaded" /></div>
                                <div className="item"><img src="../assets/images/pro3/1.jpg" alt="" className="blur-up lazyloaded" /></div>
                                <div className="item"><img src="../assets/images/pro3/27.jpg" alt="" className="blur-up lazyloaded" /></div>
                                <div className="item"><img src="../assets/images/pro3/2.jpg" alt="" className="blur-up lazyloaded" /></div>
                                <div className="item"><img src="../assets/images/pro3/1.jpg" alt="" className="blur-up lazyloaded" /></div>
                                <div className="item"><img src="../assets/images/pro3/27.jpg" alt="" className="blur-up lazyloaded" /></div>
                                <div className="item"><img src="../assets/images/pro3/2.jpg" alt="" className="blur-up lazyloaded" /></div> */}
                </div>
                <div
                  className="owl-carousel owl-theme"
                  id="sync2"
                  style={{ margin: "4% 0 2% 0", display: "flex" }}
                >
                  {images?.slice(1, 5).map((x: any) => (
                    <div className="item">
                      <img
                        src={x.value}
                        alt=""
                        className="blur-up lazyloaded"
                        style={{ width: "96%" }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-xl-8">
                <div className="product-page-details product-right mb-0">
                  <h2>{productItem?.name}</h2>
                  {/* <select id="u-rating-fontawesome-o" name="rating" data-current-rating="5" autocomplete="off">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select> */}
                  <hr />
                  <h6 className="product-title">product info</h6>
                  <ul className="shipping-info">
                    <li>Brand: {productItem?.brand}</li>
                    <li>Sku: {productItem?.skuNumber}</li>
                    <li>Model: {productItem?.model}</li>
                    <li>
                      Option:
                      <ul style={{ marginLeft: "20px" }}>
                        {options?.map((x: any) => (
                          <li>
                            - {x.type}: {x.value}
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                  <hr />
                  {/* <p>Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem,</p> */}
                  <div className="product-price digits mt-2">
                    {/* <h3>${productItem.CurrentPrice} <del>${productItem.RegularPrice}</del></h3> */}
                    <h3>
                      {CurrencyHelper.vnFormatter(productItem?.sellPrice)}
                    </h3>
                  </div>
                  {/* <ul className="color-variant">
                                    <li className="bg-light0"></li>
                                    <li className="bg-light1"></li>
                                    <li className="bg-light2"></li>
                                </ul> */}
                  {/* <hr/>
                                <h6 className="product-title size-text">select size <span className="pull-right"><a href="" data-bs-toggle="modal" data-bs-target="#sizemodal">size chart</a></span></h6>
                                <div className="modal fade" id="sizemodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel">Sheer Straight Kurta</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                            </div>
                                            <div className="modal-body"><img src="../assets/images/size-chart.jpg" alt="" className="img-fluid blur-up lazyloaded" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="size-box">
                                    <ul>
                                        <li className="active"><a href="#">s</a></li>
                                        <li><a href="#">m</a></li>
                                        <li><a href="#">l</a></li>
                                        <li><a href="#">xl</a></li>
                                    </ul>
                                </div>
                                <div className="add-product-form">
                                    <h6 className="product-title">quantity</h6>
                                    <fieldset className="qty-box mt-2 ms-0">
                                        <div className="input-group">
                                            <input className="touchspin" type="text" value="1" />
                                        </div>
                                    </fieldset>
                                </div> */}
                  <hr />
                  {/* <h6 className="product-title">Sales Ends In</h6>
                                <div className="timer">
                                    <p id="demo"><span>25 <span className="padding-l">:</span> <span className="timer-cal">Days</span> </span><span>22 <span className="padding-l">:</span> <span className="timer-cal">Hrs</span> </span><span>13 <span className="padding-l">:</span> <span className="timer-cal">Min</span> </span><span>57 <span className="timer-cal">Sec</span></span>
                                    </p>
                                </div> */}
                  <div className="m-t-15">
                    <button className="btn btn-primary m-r-10" type="button">
                      Add To Cart
                    </button>
                    <button className="btn btn-secondary" type="button">
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}
