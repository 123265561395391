import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { ReduxHelper } from "helpers";
import { WishlistActions } from "redux/actions";
import { IBaseReducerState } from "utils/redux";

interface IWishlistState extends IBaseReducerState {}

const initialState: IWishlistState = {
  action: "",
  prefix: WishlistActions.prefix,
};

const clearAction = (state: IWishlistState) => {
  state.action = "";
};

const Reducer = ReduxHelper.createHandleReducer(initialState, (builder) => {
  builder.addCase(WishlistActions.clearAction.request, clearAction);
});

export default Reducer;
