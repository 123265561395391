/* eslint-disable react-hooks/exhaustive-deps */
import AppEffect from "components/Atoms/AppEffect";
import AppProvider from "provider/AppContext";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import RootRouter from "routers/root";
import "utils/i18n/index";
import "react-toastify/dist/ReactToastify.css";
import ConfirmDialog from "components/confirm-dialog";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "services";
import { useCheckMaintenance } from "hooks/useCheckMaintenance";

const Root = () => {
  return (
    <BrowserRouter forceRefresh={true}>
      <HelmetProvider>
        <Helmet
          titleTemplate="HaiStore"
          defaultTitle="Hải Store - Chuyên săn sale hàng Mỹ"
        ></Helmet>
      </HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <AppProvider>
          <RootRouter />
          <ToastContainer />
          <ConfirmDialog />
        </AppProvider>
      </LocalizationProvider>
      <AppEffect />
    </BrowserRouter>
  );
};

export default Root;
