import { ReduxHelper } from "helpers";
import {
  ICreateWishlistRequest,
  IDeleteWishlistRequest,
} from "models/IWishlist";

export const prefix = "WISHLIST";
export const createWishlistItem =
  ReduxHelper.generateActions<ICreateWishlistRequest>(
    prefix,
    "CREATE_WISHLIST_ITEM"
  );
export const deleteWishlistItem =
  ReduxHelper.generateActions<IDeleteWishlistRequest>(
    prefix,
    "UPDATE_WISHLIST_ITEM"
  );

export const clearAction = ReduxHelper.generateLocalAction(
  prefix,
  "CLEAR_WISHLIST_ACTION"
);
