import {
  IOrder,
  IOrderCancelRequest,
  IOrderResponse,
} from "models/IOrderModel";
import { BaseApiService } from "./BaseApiService";

class OrderApiService extends BaseApiService {
  public createOrder = (param: IOrder) =>
    this.post<IOrder, IOrderResponse>("/create-order", param);

  public getAll = (params: any) =>
    this.get<{
      totalItems: number;
      page: number;
      limit: number;
      results: IOrderResponse[];
      resultsLength: number;
    }>("/pagination-all-order", { params });

  public getAllByCustomerId = (params: any) =>
    this.get<IOrderResponse[]>("/pagination-all-order-by-customer-id", {
      params,
    });

  public getOrderDetailById = (orderId: string) =>
    this.get<any>(`/get-order-by-id?orderId=${orderId}`);

  public getOrderDetailByIdByGuest = (params: {
    orderName: string;
    phoneNumber: string;
  }) => this.get<any>("/get-order-detail-by-guest", { params });

  public updateOrder = (param: any) =>
    this.put<any, any>("/update-order", param);

  public cancelOrder = (param: IOrderCancelRequest) =>
    this.put<any, any>("/cancel-order-by-customer", param);

  public updateThirdShipmentPrice = (param: any) =>
    this.put<any, any>("/update-third-shipment-price", param);

  public updateDepositPrice = (params: any) =>
    this.put<any, any>("/update-deposit-price", params);

  public updateSellPriceOrderDetail = (params: any) =>
    this.put<any, any>("/update-sell-price-order-detail", params);

  public updateEvidenceOrder = (param: any) =>
    this.put<any, any>("/update-evidence-order", param);

  public verifyOrderByGuest = (params: {
    orderName: string;
    phoneNumber: string;
  }) => this.get<boolean>("/verify-order-by-guest", { params });
}

export default new OrderApiService("/orders");
