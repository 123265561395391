import { BaseApiService } from "./BaseApiService";

class ShippingSurchargeGroupApiService extends BaseApiService {
  public getAllShippingSurchargeGroup = () =>
    this.get<any>("/get-all-shipping-surcharge-group");
  public getAllShippingSurchargeGroupById = (params: { id: string }) =>
    this.get<any>("/get-shipping-surcharge-group-by-id", { params });
  public pagination = (params: any) => this.get<any>("/pagination", { params });
  public create = (params: any) =>
    this.post("/create-shipping-surcharge-group", params);
  public update = (params: any) =>
    this.put("/update-shipping-surcharge-group", params);
  public deleteShipping = (id: any) =>
    this.delete(`/delete-shipping-surcharge-group?id=${id}`);
}

export default new ShippingSurchargeGroupApiService(
  "/shipping-surcharge-group"
);
