import { Menu, Grid, IconButton, MenuItem, Avatar } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IdentityActions } from "redux/actions";
import { RootReducer } from "redux/configurations/rootReducer";
import MenuIcon from "@mui/icons-material/Menu";
export default function HeaderDashboard(props: { onOpen: () => void }) {
  const { onOpen } = props;
  const dispatch = useDispatch();
  let history = useHistory();
  const user = useSelector((state: RootReducer) => state.IdentityReducer.admin);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    handleClose();
    dispatch(IdentityActions.logoutAdmin.request());
    history.push("/admin");
  };

  return (
    <header className="ec-main-header" id="header">
      <Grid
        container
        style={{
          padding: "20px",
        }}
      >
        <Grid item xs={6}>
          <IconButton
            onClick={handleClick}
            sx={{
              display: {
                sm: "none",
                xs: "block",
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton onClick={handleClick}>
            <Avatar alt="Remy Sharp">H</Avatar>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={logout}>Đăng xuất</MenuItem>
          </Menu>
          {/* <ul className="dropdown-menu dropdown-menu-right ec-dropdown-menu">
                <li className="dropdown-header">
                  <img
                    src="assets/images/logo/logo-ebay.jpg"
                    className="img-circle"
                    alt="User Image"
                    style={{ paddingTop: 8 }}
                  />
                  <div className="d-inline-block">
                    {user?.name || "___"}{" "}
                    <small className="pt-1">{user?.email || "___"}</small>
                  </div>
                </li>
                <li className="dropdown-footer">
                  <a onClick={() => logout()}>
                    <i className="mdi mdi-logout" /> Đăng xuất{" "}
                  </a>
                </li>
              </ul> */}
        </Grid>
      </Grid>
    </header>
  );
}
