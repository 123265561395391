import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

import { IRefreshTokenRequest } from "model/Identity/IRefreshTokenRequest";
import { ITokenResponse } from "model/Identity/ITokenResponse";
import { IdentityActions } from "redux/actions";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import configureStore from "redux/configurations/configureStore";
import moment from "moment";
import qs from "qs";

export interface IApiResponse<T = void> {
  data?: T;
  results?: T;
  header?: any;
  errors?: any;
  succeeded: boolean;
  error?: IApiError;
}
export interface IApiError {
  code?: string | number;
  message: string;
}
export interface IErrorResponse {
  error: string;
  error_description: string;
  error_uri: string;
}

const pathAdmin = [
  "/admin-test",
  "/order-management",
  "/shipment-management",
  "/product-management",
  "/user-management",
  "/product-detail",
  "/shipment-detail",
  "/configurations",
  "/crawl-data",
  "/edit-product",
  "/rate-configuration",
  "/category-configuration",
  "/shipping-surcharge-management",
  "/crawl-single-link-management",
  "/crawl-multiple-link-management",
  "/code-type-management",
  "/affiliate-management",
  "/affiliate-register-management",
  "/discount-management",
  "/auto-tracking-order",
  "/v2/order",
  "/v2/shipment",
  "/deal-management",
];

const _checkIsAdminRouter = (): boolean => {
  var pathname = window.location.pathname;
  var index = pathAdmin.findIndex((x: string) => pathname.includes(x));
  if (index !== -1) {
    return true;
  }
  return false;
};

const useInterceptorToken = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  try {
    const store = configureStore().store;
    const token = store.getState().IdentityReducer.token;
    const tokenAdmin = store.getState().IdentityReducer.tokenAdmin;
    if (_checkIsAdminRouter()) {
      if (!_.isEmpty(tokenAdmin)) {
        config.headers.Authorization = `Bearer ${tokenAdmin}`;
      }
    } else {
      if (!_.isEmpty(token)) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  } catch (error) {
    return config;
  }
};

const API_CONFIG: AxiosRequestConfig = {
  // returnRejectedPromiseOnError: true,
  withCredentials: true,
  timeout: 0,
  baseURL: process.env.REACT_APP_API,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
    "Content-Security-Policy": "upgrade-insecure-requests",
    // "accept-language": "en-Us",
    // timeOffset: Math.round(moment().utcOffset() / 60),
    accept: "*/*",
  },
  paramsSerializer: (params: any) => qs.stringify(params, { indices: false }),
};
export abstract class BaseApiService {
  private instance: AxiosInstance;
  private baseUrl: string;
  constructor(baseUrl: string) {
    this.instance = axios.create(API_CONFIG);
    this.instance.interceptors.request.use(useInterceptorToken);
    // this.instance.interceptors.request.use(this._handleRefreshToken);
    this.instance.interceptors.response.use(
      (res) => {
        this.logoutWhenExpired();
        return res;
      },
      (error: AxiosError) => {
        return Promise.resolve(error.response);
      }
    );
    this.baseUrl = baseUrl;
  }

  private _handleResponse<T = any>(
    response: AxiosResponse<T | IErrorResponse>
  ): IApiResponse<T> {
    if (response.status === 200) {
      const data = response.data as T;
      return {
        data,
        header: response.headers,
        succeeded: true,
      };
    }
    const error = response.data as IErrorResponse;
    return {
      succeeded: false,
      error: {
        message: error.error_description,
      },
    };
  }

  private _handleError<T = any>(): IApiResponse<T> {
    return {
      succeeded: false,
      error: {
        message: "Network Error",
      },
    };
  }

  private _handleRefreshToken = async (
    config: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> => {
    let orgConfig = Object.assign({}, config);
    if (
      !this._checkNeedRefreshToken() ||
      config.url?.includes("/connect/token")
    ) {
      return config;
    }
    const retryReq = new Promise<AxiosRequestConfig>((resolve) => {
      this.subscribeTokenRefresh(async (accessToken) => {
        orgConfig.headers["Authorization"] = "Bearer " + accessToken;
        resolve(orgConfig);
      });
    });
    if (!global.isAppRefreshingToken) {
      if (global.refreshSubscribers === undefined) {
        global.refreshSubscribers = [];
      }
      global.isAppRefreshingToken = true;
      console.log("=====>", global.refreshSubscribers);
      const newToken = await this.refreshToken();
      global.isAppRefreshingToken = false;
      if (newToken) {
        this.onRefreshed(newToken);
      }
    }
    return retryReq;
  };
  private refreshToken = async (): Promise<string | undefined> => {
    const store = configureStore().store;
    const token = store.getState().IdentityReducer.token;
    const params: IRefreshTokenRequest = {
      client_id: process.env.REACT_APP_CLIENT_ID!,
      client_secret: process.env.REACT_APP_CLIENT_SECRET!,
      grant_type: "refresh_token",
      refresh_token: token?.refresh_token!,
    };
    const response = await this.postForm<IRefreshTokenRequest, ITokenResponse>(
      "https://bigdatamasters.cloud:5000/connect/token",
      params
    );
    if (response?.succeeded && response.data) {
      store.dispatch(IdentityActions.setToken.request(response.data));
      return response.data.access_token;
    }
    store.dispatch(IdentityActions.logout.request());
    return undefined;
  };

  private _checkNeedRefreshToken = (): boolean => {
    const store = configureStore().store;
    const expiredAt = store.getState().IdentityReducer.expiredAt;
    if (expiredAt) {
      if (moment().unix() >= expiredAt) {
        return true;
      }
    }
    return false;
  };

  private logoutWhenExpired = (): any => {
    const store = configureStore().store;
    const userReducer = store.getState().IdentityReducer;
    if (_checkIsAdminRouter()) {
      if (userReducer?.expiredAtAdmin) {
        if (moment().unix() >= userReducer?.expiredAtAdmin) {
          store.dispatch(IdentityActions.logoutAdmin.request());
          return <Redirect to="/admin" />;
        }
      }
    } else {
      if (userReducer?.expiredAt) {
        if (moment().unix() >= userReducer?.expiredAt) {
          store.dispatch(IdentityActions.logout.request());
          return <Redirect to="/" />;
        }
      }
    }
  };

  subscribeTokenRefresh(cb: (token: string) => void) {
    if (global.refreshSubscribers === undefined) {
      global.refreshSubscribers = [];
    }
    global.refreshSubscribers.push(cb);
  }
  onRefreshed(token: string) {
    global.refreshSubscribers.map((cb) => cb(token));
  }

  convertFormData(data: any) {
    var formData = new URLSearchParams();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  }

  public async get<T>(url: string, config?: AxiosRequestConfig) {
    try {
      const response = await this.instance.get<T>(
        `${this.baseUrl}${url}`,
        config
      );
      return this._handleResponse<T>(response);
    } catch (error) {
      return this._handleError();
    }
  }
  public async post<P = any, T = any>(
    url: string,
    data?: P,
    config?: AxiosRequestConfig
  ) {
    try {
      const response = await this.instance.post<T>(
        `${this.baseUrl}${url}`,
        data,
        config
      );
      return this._handleResponse<T>(response);
    } catch (error) {
      return this._handleError();
    }
  }
  public async put<P = any, T = any>(
    url: string,
    data?: P,
    config?: AxiosRequestConfig
  ) {
    try {
      const response = await this.instance.put<T>(
        `${this.baseUrl}${url}`,
        data,
        config
      );
      return this._handleResponse<T>(response);
    } catch (error) {
      return this._handleError();
    }
  }
  public async delete<T>(url: string, config?: AxiosRequestConfig) {
    try {
      const response = await this.instance.delete<T>(
        `${this.baseUrl}${url}`,
        config
      );
      return this._handleResponse<T>(response);
    } catch (error) {
      return this._handleError();
    }
  }
  public async postForm<P = void, T = void>(
    url: string,
    data?: P,
    config?: AxiosRequestConfig
  ) {
    try {
      const formConfig: AxiosRequestConfig = {
        ...config,
        headers: {
          ...config?.headers,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const requestUrl = url.includes("http") ? url : `${this.baseUrl}${url}`;
      const formData = this.convertFormData(data);
      const response = await this.instance.post<T>(
        requestUrl,
        formData,
        formConfig
      );
      return this._handleResponse<T>(response);
    } catch (error) {
      return this._handleError();
    }
  }
}
