import { atom } from "jotai";

export const dealsAtom = atom<any[]>([]);
export const totalDealsAtom = atom(0);
export const filtersAtom = atom<any[]>([]);
export const filterQueryAtom = atom<any>({
  optionPrice: undefined,
  optionSort: undefined,
  optionDealStatus: [],
  optionCategory: [],
  optionSeller: [],
  optionBrand: [],
  optionCondition: [],
  optionRAMSize: [],
  optionScreenSize: [],
  optionProcessor: [],
  optionGPU: [],
  optionSSDCapacity: [],
  optionHardDriveCapacity: [],
  optionOperatingSystem: [],
});
export const filterQueryKeysAtom = atom<any>((get) =>
  Object.values(get(filterQueryAtom)).flat()
);
export const isFullCategoryAtom = atom<boolean>(false);
export const currentFullFilterAtom = atom<any[]>([]);
export const isOpenFilterMobileAtom = atom<boolean>(false);
