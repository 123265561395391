import { BaseApiService } from "./BaseApiService";

class HangFireApiService extends BaseApiService {
  public automationGetFacebookReview = () =>
    this.get("/automation-get-facebook-review");
  public automationUpdateDailyDealProduct = () =>
    this.get("/automation-update-daily-deal-product");
  public automationUpdateProduct = () => this.get("/automation-update-product");
  public automationHealCheck = () => this.get("/automation-health-check");
  public automationCrawlFromSingleLink = () =>
    this.get("/automation-crawl-from-single-link");
  public automationCrawlTopDealLink = () =>
    this.get("/automation-crawl-top-deal-link");
  public automationSetDailyDeal = () => this.get("/automation-set-daily-deal");
  public automationCheckCrawlGroupLinkInqueue = () =>
    this.get("/automation-check-crawl-group-link-inqueue");
}

export default new HangFireApiService("/hang-fire");
