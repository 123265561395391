import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import AppButton from "./client/app-button";
interface IProps extends DialogProps {
  visible: boolean;
  title: string;
  difHeader?: React.ReactNode;
  onCancel: () => void;
  onOk: (value?: any) => void;
  hiddenBtnOK?: boolean;
  hiddenBtnCancel?: boolean;
  cancelText?: string;
  okText?: string;
  disabledBtnOK?: boolean;
  hideDivider?: boolean;
  containerStyles?: any;
  onDelete?: () => void;
}

const FormDialog: React.FC<IProps> = ({
  visible,
  title,
  difHeader,
  onCancel,
  onOk,
  hiddenBtnOK,
  hiddenBtnCancel,
  disabledBtnOK,
  maxWidth,
  cancelText = "Huỷ",
  okText = "Lưu",
  children,
  hideDivider,
  containerStyles,
  onDelete,
}) => {
  const handleClose = (event: any, reason: string) => {
    if (reason && reason === "backdropClick") return;
    onCancel();
  };
  return (
    <Dialog
      maxWidth={maxWidth}
      open={visible}
      onClose={handleClose}
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "5px",
          ...containerStyles,
        },
      }}
    >
      {difHeader ? (
        difHeader
      ) : (
        <DialogTitle
          style={{
            textAlign: "center",
            background: "#ffffff",
            textTransform: "uppercase",
          }}
          sx={{ py: 8, pb: 0, pt: 3 }}
        >
          {title}

          <Box
            sx={{ position: "absolute", top: 20, right: 35 }}
            component={"div"}
            onClick={onCancel}
          >
            <CloseIcon sx={{ cursor: "pointer" }} onClick={onCancel} />
          </Box>
        </DialogTitle>
      )}

      <DialogContent sx={{ paddingX: 5, mt: !hideDivider ? 2 : 1 }}>
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          paddingX: 5,
          paddingY: 1,
          pb: 2,
          justifyContent: "space-between",
        }}
      >
        <Box>
          {onDelete && (
            <Button variant="contained" color={"error"} onClick={onDelete}>
              Delete
            </Button>
          )}
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >
          {hiddenBtnCancel ? null : (
            <AppButton label={cancelText} variant="dark" onClick={onCancel} />
          )}
          {hiddenBtnOK ? null : (
            <AppButton
              label={okText}
              variant="primary"
              onClick={onOk}
              disabled={disabledBtnOK}
            />
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
