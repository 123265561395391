import Button from "components/Atoms/Button";
import React from "react";
import { useHistory } from "react-router-dom";

export default function Updating() {
  const history = useHistory();
  return (
    <div className="container" style={{ height: "100vh" }}>
      <div className="flex-1 h-100 frow d-flex justify-content-center align-items-center">
        <div className="p-5 h-100 w-50 flex-column d-flex justify-content-center align-items-center">
          <h1>Tính năng đang được cập nhật</h1>
          <div className="p-5 flex-column d-flex justify-content-center align-items-center">
            <img alt="admin-updating" src={require("./4277423.jpg")} />
          </div>
        </div>
      </div>
    </div>
  );
}
