import { Chip, Grid, Stack, Typography, styled } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { indexContext } from "../../context";
import DealApiService from "services/DealApiService";
import { toastError } from "helpers/toastHelper";
import { vnFormatter } from "helpers/currencyHelpers";
import { capitalize } from "lodash";
import { getDealStatusLabel } from "helpers/dealHelper";

const DealStatus = styled(Chip)<any>(({ dealStatus }) => ({
  color: dealStatus === 1 ? "black" : "white",
  marginRight: "0.5rem",
  fontWeight: 600,
  background:
    dealStatus === 0 ? "#0046be" : dealStatus === 1 ? "#ffe000" : "#d32f2f",
}));

const ProductCard = styled(Stack)`
  cursor: pointer;
  background: #ffffff;
  border-radius: 8px;
  padding: 1rem;
  &:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
      0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
    transform: scale(1.02);
  }
  @media screen and (max-width: 599.9px) {
    background-color: #f7f7f7;
    padding: 0.75rem;
  }
`;

const ProductMediaContainer = styled(Stack)`
  cursor: pointer;
  position: relative;
  height: 220px;
  overflow: hidden;
  @media screen and (max-width: 599.9px) {
    height: auto;
  }
`;

const Products = ({ subCategoryId }: { subCategoryId: string }) => {
  const { isSmScreen, history } = useContext(indexContext);
  const [products, setProducts] = useState<any>([]);

  useEffect(() => {
    getProducts();
  }, []);

  // [GET] products from subCategoryId
  const getProducts = async () => {
    const { succeeded, data } = await DealApiService.getDealsPublic({
      categoryId: subCategoryId,
    });
    if (succeeded) {
      setProducts(data.results);
    } else {
      toastError("Không thể lấy danh sách flash sale");
    }
  };

  return (
    <Grid container alignItems="stretch">
      {products.map((product: any, idx: number) => {
        const isEbayUrl = product.url.toLowerCase().includes("ebay.com");
        const productId = isEbayUrl
          ? product.productId
          : product.url.match(/(?:[?&]id=)([^&]*)/)?.[1] || "";
        const dealLink = `/product-item?id=${product.id}&isDeal=true`;
        const image =
          product.specifications.find((item: any) => item.title === "image")
            ?.value || "";
        const defaultPrice =
          product.prices.find((item: any) => item.guaranteeName === "BH3T")
            ?.price || 0;
        return (
          product.url &&
          product.status &&
          product.allowGoogleAds && (
            <Grid
              item
              key={idx}
              xs={6}
              md={4}
              lg={3}
              padding={1}
              height="unset"
            >
              <ProductCard
                spacing={isSmScreen ? 2 : 3}
                onClick={() => history.push(dealLink)}
              >
                <Stack direction="row">
                  <DealStatus
                    sx={{ fontSize: isSmScreen ? "11px" : "14px" }}
                    size="small"
                    label={getDealStatusLabel(product.dealStatus).title}
                    dealStatus={product.dealStatus}
                  />
                  <Chip
                    sx={{ fontSize: isSmScreen ? "11px" : "14px" }}
                    size="small"
                    label={product.condition}
                    variant="outlined"
                    color="primary"
                  />
                </Stack>
                <ProductMediaContainer
                  alignItems="center"
                  justifyContent="center"
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                    src={image || "/assets/images/common/default_image.png"}
                    alt={product.name}
                  />
                </ProductMediaContainer>
                <Stack spacing={1}>
                  <Typography fontWeight={700}>
                    {capitalize(product.name)}
                  </Typography>
                  <Typography
                    variant="h3"
                    fontWeight={800}
                    color="error"
                    fontSize={isSmScreen ? "1rem" : "1.25rem"}
                  >
                    {vnFormatter(defaultPrice)}
                  </Typography>
                </Stack>
              </ProductCard>
            </Grid>
          )
        );
      })}
    </Grid>
  );
};

export default Products;
