// @mui
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";

import { Image, Typography } from "antd";
import MaintenanceImage from "assets/images/maintenance.png";

// ----------------------------------------------------------------------

export default function Maintenance() {
  return (
    <Stack sx={{ alignItems: "center" }}>
      <Typography.Title>Website đang được bảo trì</Typography.Title>
      <Typography.Text type="secondary">
        Chúng tôi đang làm việc chăm chỉ trên trang này
      </Typography.Text>

      <Box
        sx={{
          p: 2,
          mt: 2,
          background: "#f3f3f3",
          borderRadius: 2,
        }}
      >
        <Typography>
          Vui lòng liên hệ zalo{" "}
          <span
            style={{
              color: "blue",
            }}
          >
            0819153456
          </span>{" "}
          để được hỗ trợ
        </Typography>
      </Box>

      <Image
        src={MaintenanceImage}
        preview={false}
        height={400}
        style={{
          objectFit: "contain",
        }}
      />
    </Stack>
  );
}
