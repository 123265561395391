import styled from "@emotion/styled";
import { Flex, List } from "antd";
import { useAtom } from "jotai";
import { filtersAtom } from "pages/client/DealsPage/atoms/dealsAtom";
import { useCallback, useEffect } from "react";
import DealApiService from "services/DealApiService";
import { FILTER_CLIENT } from "pages/client/DealsPage/configs/dealConfig";
import FilterList from "./FilterList";

const ListStyle = styled(List)`
  margin-top: 20px;
  padding: 0.5rem 1rem 0 1rem;
  border: 1px solid var(--color-border-grey-light);
  border-radius: var(--border-radius-base);

  h5 {
    color: var(--color-primary);
  }
`;

const Filters = () => {
  const [filters, setFilters] = useAtom(filtersAtom);

  // Handle - fetch deal filter list
  const getDealFilters = useCallback(async () => {
    const { succeeded, data } = await DealApiService.getDealFilters({});
    if (succeeded) {
      setFilters(data);
    }
  }, [setFilters]);

  // Initial loading - get filter list backend
  useEffect(() => {
    getDealFilters();
  }, [getDealFilters]);

  return (
    <Flex vertical gap={16} key={1}>
      <ListStyle itemLayout="vertical">
        {/* Filters - client */}
        {FILTER_CLIENT.map((filter: any) => (
          <FilterList filter={filter} />
        ))}

        {/* Filters - backend */}
        {filters.map((filter: any) => (
          <FilterList filter={filter} />
        ))}
      </ListStyle>
    </Flex>
  );
};

export default Filters;
