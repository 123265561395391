import { Box, Stack, Typography, styled } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { indexContext } from "../../context";
import DealApiService from "services/DealApiService";
import { toastError } from "helpers/toastHelper";
import Products from "./Products";
import { upperCase } from "lodash";

interface ISubCategoriesProps {
  parentCategoryId: string;
  parentCategoryName: string;
}

const Container = styled(Stack)`
  background-color: #f7f7f7;
  padding: 1rem;
  @media screen and (max-width: 599.9px) {
    background-color: unset;
    padding: 0;
  }
`;

const SubCategories = ({
  parentCategoryId,
  parentCategoryName,
}: ISubCategoriesProps) => {
  const { hideCategories } = useContext(indexContext);
  const [subCategories, setSubCategories] = useState<any>([]);

  useEffect(() => {
    getSubCategories();
  }, []);

  // [GET] subCategories from parentCategoryId
  const getSubCategories = async () => {
    const { succeeded, data } = await DealApiService.getCategoriesPublic({
      parentCategoryId,
    });
    if (succeeded) {
      data.results.length > 0
        ? setSubCategories(data.results)
        : setSubCategories([
            { id: parentCategoryId, name: parentCategoryName },
          ]);
    } else {
      toastError("Không thể lấy danh mục phụ flash sale");
    }
  };

  return (
    <Stack spacing={3}>
      {subCategories.map((subCategory: any) => {
        if (hideCategories.includes(subCategory.id))
          return <Box key={subCategory.id}></Box>;
        return (
          <Container spacing={1} key={subCategory.id}>
            <Typography
              marginLeft="1rem"
              marginRight="1rem"
              variant="h3"
              key={subCategory.id}
            >
              {subCategory.name}
            </Typography>
            <Products subCategoryId={subCategory.id} />
          </Container>
        );
      })}
    </Stack>
  );
};

export default SubCategories;
