import { Button, List, Tree, Typography } from "antd";
import { StringHelper } from "helpers";
import { useAtom } from "jotai";
import { currentFullFilterAtom, filterQueryAtom, filterQueryKeysAtom } from "pages/client/DealsPage/atoms/dealsAtom";
import { Fragment, useCallback } from "react";
import { useHistory } from "react-router-dom";

const FilterList = ({ filter }) => {
    const history = useHistory();
    const [filterQuery] = useAtom(filterQueryAtom);
    const [filterQueryKeys] = useAtom(filterQueryKeysAtom);
    const [currentFullFilter, setCurrentFullFilter] = useAtom<any[]>(currentFullFilterAtom);

    // Handle - select checkbox option
    const handleSelectOption = useCallback(
        (filter, keySearchs) => {
            const queryName = `option${filter.title.replace(/\s/g, "")}`;
            let newFilterQuery = {
                ...filterQuery,
                [queryName]: filter.title === "Price" ? keySearchs[keySearchs.length - 1] : keySearchs,
            };
            const querySerialized = StringHelper.paramsSerializer(newFilterQuery);
            history.push(querySerialized ? `/deals?${querySerialized}` : `/deals?type=full`);
        },
        [filterQuery, history]
    );

    // Handle - toggle button see all
    const handleToggleFilter = (title: string) => {
        const newCurrentFullFilter = currentFullFilter.includes(title)
            ? currentFullFilter.filter((a) => a !== title)
            : [title, ...currentFullFilter];
        setCurrentFullFilter(newCurrentFullFilter);
    };

    // Handle - recursive render checkbox options
    const renderCheckboxList = (filter: any) => {
        if (!filter?.listFilterResponse) return null;
        return (
            <Fragment>
                {/* Tree - filter's checkbox options */}
                {filter?.listFilterResponse
                    ?.slice(0, currentFullFilter.includes(filter.title) ? undefined : 6)
                    .map((option: any) => {
                        if (option.value !== "JustArrived" && option.value) {
                            return (
                                <Tree.TreeNode
                                    key={option.keySearch}
                                    title={filter.title === "DealStatus" ? option.title : option.value}
                                >
                                    {renderCheckboxList(option)}
                                </Tree.TreeNode>
                            );
                        } else {
                            return null;
                        }
                    })}

                {/* Button - see all or collapse options */}
                {filter?.listFilterResponse?.length > 6 ? (
                    <Tree.TreeNode
                        disabled
                        title={
                            <Button type="link" onClick={() => handleToggleFilter(filter.title)}>
                                {currentFullFilter.includes(filter.title) ? "Thu gọn" : "Xem tất cả"}
                            </Button>
                        }
                        checkable={false}
                    />
                ) : null}
            </Fragment>
        );
    };

    return (
        <List.Item key={filter.keySearch}>
            {/* Title */}
            <Typography.Title level={5}>{filter.displayName}</Typography.Title>

            {/* Tree - filter options */}
            <Tree
                checkable={filter.title !== "Category"}
                selectable={filter.title === "Category"}
                defaultExpandedKeys={filterQueryKeys}
                checkedKeys={filterQueryKeys}
                selectedKeys={[filterQuery?.optionCategory]}
                onCheck={(keySearchs) => handleSelectOption(filter, keySearchs)}
                onSelect={(keySearchs) => handleSelectOption(filter, keySearchs)}
            >
                {renderCheckboxList(filter)}
            </Tree>
        </List.Item>
    );
};

export default FilterList;
