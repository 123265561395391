import {
  IRateConfiguration,
  IRateConfigurationRequest,
} from "models/configuration";
import { BaseApiService } from "./BaseApiService";

class RateConfigurationApiService extends BaseApiService {
  public getAll = () =>
    this.get<IRateConfiguration[]>("/get-all-rate-configurations");

  public createNew = (data: IRateConfigurationRequest) =>
    this.post<IRateConfigurationRequest>(
      `/create-new-rate-configurations`,
      data
    );
  public getSellPrice = (sellPrice: number, subCategoryId?: string) =>
    this.get<IRateConfiguration[]>(`/get-sell-price-rate-configurations`, {
      params: {
        buyCurrentPrice: sellPrice || 0,
      },
    });
  public getSellPriceHaveSubCategory = (
    sellPrice: number,
    subCategoryId?: string
  ) =>
    this.get<number>(`/get-sell-price-rate-configurations`, {
      params: {
        buyCurrentPrice: sellPrice || 0,
        subCategoryId: subCategoryId || "",
      },
    });
}

export default new RateConfigurationApiService("/rateconfigurations");
