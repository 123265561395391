import { IShipment, IShipmentUpdateRequest } from "models/IShipment";
import { BaseApiService } from "./BaseApiService";

class ShipmentApiService extends BaseApiService {
  public getAll = (params: any) =>
    this.get<IShipment[]>("/pagination-all-shipment", { params });

  public getShipmentById = (shipmentId: string) =>
    this.get<any>(`/get-shipment-by-id?shipmentIds=${shipmentId}`);

  public updateShipment = (param: IShipmentUpdateRequest) =>
    this.put<any, any>("/update-shipment", param);
}

export default new ShipmentApiService("/shipments");
