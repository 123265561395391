import { MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { FormDialog, TextMaskCustom } from "components";
import { FormikErrors, useFormik } from "formik";
import _ from "lodash";
import React from "react";

interface IModalUser {
  title: string;
  open: boolean;
  handleSubmit: (values: any) => void;
  onCancel: () => void;
  email: string;
  user?: any;
}
const ModalUser = (props: IModalUser) => {
  const initialValues = {
    phone: props.user ? props.user.user?.phone : "",
    gender: props.user ? props.user.user?.gender : 0,
    // citizenIdentityCard: props.user
    //   ? props.user?.user.citizenIdentityCard
    //   : "",
    address: props.user ? props.user.user?.address : "",
    city: props.user ? props.user.user?.city : "",
    firstName: props.user ? props.user.user?.firstName : "",
    lastName: props.user ? props.user.user?.lastName : "",
  };
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      props.handleSubmit(values);
    },
    validate: (values) => {
      const errors: FormikErrors<any> = {};
      if (_.isEmpty(values.firstName)) {
        errors.name = "Tên không được để trống";
      }
      if (_.isEmpty(values.lastName)) {
        errors.name = "Họ không được để trống";
      }
      if (_.isEmpty(values.phone)) {
        errors.phone = "Điện thoại không được để trống";
      }
      // if (_.isEmpty(values.email)) {
      //   errors.email = "Email can not be blank";
      // }
      // if (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(values.email))) {
      //   errors.email = 'Invalid Email';
      // }
      // if (_.isEmpty(values.citizenIdentityCard)) {
      //   errors.citizenIdentityCard = "Thẻ Căn cước công dân không được để trống";
      // }
      if (_.isEmpty(values.address)) {
        errors.address = "Địa chỉ không được để trống";
      }
      if (_.isEmpty(values.city)) {
        errors.city = "Thành phố không được để trống";
      }
      return errors;
    },
  });
  return (
    <FormDialog
      visible={props.open}
      title={props.title}
      onCancel={() => {
        props.onCancel();
      }}
      onOk={() => formik.handleSubmit()}
      open={false}
      hiddenBtnCancel={props.user ? false : true}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{ padding: "10px" }}>
          <Grid item md={12} sm={12}>
            <TextField
              variant="standard"
              sx={{ width: "100%" }}
              id="email"
              name="email"
              label="Email *"
              value={props.email}
              size="medium"
              disabled
              focused
            />
          </Grid>
          <Grid item md={12} sm={12}>
            <TextField
              variant="standard"
              sx={{ width: "100%" }}
              id="firstName"
              name="firstName"
              label="Tên *"
              error={
                formik.touched.firstName && !_.isEmpty(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              size="medium"
              focused
            />
          </Grid>
          <Grid item md={12} sm={12}>
            <TextField
              variant="standard"
              sx={{ width: "100%" }}
              id="lastName"
              name="lastName"
              label="Họ *"
              error={
                formik.touched.lastName && !_.isEmpty(formik.errors.lastName)
              }
              helperText={formik.touched.lastName && formik.errors.lastName}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              size="medium"
              focused
            />
          </Grid>
          <Grid item md={12} sm={12}>
            <TextField
              variant="standard"
              sx={{ width: "100%" }}
              id="phone"
              name="phone"
              label="Điện thoại *"
              error={formik.touched.phone && !_.isEmpty(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              value={formik.values.phone}
              onChange={formik.handleChange}
              size="medium"
              InputProps={{
                inputComponent: TextMaskCustom as any,
              }}
              focused
            />
          </Grid>
          <Grid item md={12} sm={12}>
            <TextField
              select
              variant="standard"
              sx={{ width: "100%" }}
              id="gender"
              name="gender"
              label="Giới tính *"
              value={formik.values.gender}
              onChange={formik.handleChange}
              size="medium"
              focused
            >
              <MenuItem value={0}>Nam</MenuItem>
              <MenuItem value={1}>Nữ</MenuItem>
            </TextField>
          </Grid>
          {/* <Grid item md={12} sm={12}>
            <TextField
              variant="standard"
              type="text"
              sx={{ width: "100%" }}
              id="citizenIdentityCard"
              name="citizenIdentityCard"
              label="CMND/CCCD *"
              error={
                formik.touched.citizenIdentityCard &&
                !_.isEmpty(formik.errors.citizenIdentityCard)
              }
              helperText={
                formik.touched.citizenIdentityCard &&
                formik.errors.citizenIdentityCard
              }
              value={formik.values.citizenIdentityCard}
              onChange={formik.handleChange}
              size="medium"
              focused
            />
          </Grid> */}
          <Grid item md={12} sm={12}>
            <TextField
              variant="standard"
              type="text"
              sx={{ width: "100%" }}
              id="address"
              name="address"
              label="Địa chỉ *"
              error={
                formik.touched.address && !_.isEmpty(formik.errors.address)
              }
              helperText={formik.touched.address && formik.errors.address}
              value={formik.values.address}
              onChange={formik.handleChange}
              size="medium"
              focused
            />
          </Grid>
          <Grid item md={12} sm={12}>
            <TextField
              variant="standard"
              type="text"
              sx={{ width: "100%" }}
              id="city"
              name="city"
              label="Thành phố *"
              error={formik.touched.city && !_.isEmpty(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              value={formik.values.city}
              onChange={formik.handleChange}
              size="medium"
              focused
            />
          </Grid>
        </Grid>
      </form>
    </FormDialog>
  );
};

export default ModalUser;
