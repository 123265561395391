import { List, Visibility } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { FormDialog, HeaderContainer } from "components";
import AppTable from "components/app-table";
import { DateHelper, TableHelper, ToastHelper } from "helpers";
import { confirm } from "helpers/confirmModalHelper";
import _ from "lodash";
import { IUser } from "models/IIdentity";
import moment, { Moment } from "moment";
import AdminContainer from "pages/admin/Container";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootReducer } from "redux/configurations/rootReducer";
import { DiscountApiService, IdentityApiService } from "services";
import AffiliateApiService from "services/AffiliateApiService";
import AddDiscountModal from "./components/add-discount.modal";
import ProductDiscount from "./components/product-discount";
import SubCategoriesDiscount from "./components/sub-category-discount";

const UserInfo = (props: {
  title: string;
  value: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  type?: string;
}) => {
  const { title, value, type, disabled, onChange, placeholder } = props;
  return (
    <Stack direction="row" spacing={3}>
      <Typography
        sx={{ fontWeight: 600, paddingTop: 1, minWidth: 150, color: "#000" }}
      >
        {title}
      </Typography>
      <input
        type={type}
        value={value}
        disabled={disabled}
        style={{
          border: "1px solid grey",
          backgroundColor: disabled ? "#f1f1f1" : "white",
        }}
        onChange={onChange}
        placeholder={placeholder}
      ></input>
    </Stack>
  );
};
export default function DiscountManagement() {
  const user = useSelector((state: RootReducer) => state.IdentityReducer.admin);
  const [currentUser, setCurrentUser] = useState<any>();
  const [userDetail, setUserDetail] = useState<IUser>();
  const [openModal, setOpenModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [modalPassword, setModalPassword] = useState(false);
  const [modalAddDiscount, setModalAddDiscount] = useState(false);
  const [modalAddCoupon, setModalAddCoupon] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentDiscount, setCurrentDiscount] = useState<any>();
  const [couponCode, setCouponCode] = useState("");

  const [startDate, setStartDate] = React.useState<Moment | null>(null);
  const [endDate, setEndDate] = React.useState<Moment | null>(null);

  const [productVisible, setProductVisible] = useState(false);
  const [subCategoriesVisible, setSubCategoriesVisible] = useState(false);

  useEffect(() => {
    if (openModal) {
      setUserDetail({} as IUser);
    }
  }, [openModal]);

  const handleClick = async (row: any) => {
    setOpenModal(true);
    setCurrentDiscount(row);
  };

  const handleDeleteDiscount = async (row: any) => {
    confirm({
      message: "Bạn có muốn xóa mã giảm giá này không?",
      onConfirm: async () => {
        setLoading(true);
        const res = await DiscountApiService.deleteDiscount({
          id: row?.id,
        });
        setLoading(false);
        if (res?.succeeded) {
          ToastHelper.toastSuccess("Xóa mã giảm giá thành công");
          setReload(true);
        } else {
          ToastHelper.toastError("Xóa mã giảm giá thất bại");
        }
      },
    });
  };

  const handelDeleteCoupon = async (row: any) => {
    confirm({
      message: "Bạn có muốn xóa mã coupon này không?",
      onConfirm: async () => {
        setLoading(true);
        const res = await DiscountApiService.deleteCoupon({
          id: currentDiscount?.id,
          couponCode: row.couponCode,
        });
        setLoading(false);
        setModalAddDiscount(false);
        if (res?.succeeded) {
          ToastHelper.toastSuccess("Xóa mã coupon thành công");
        } else {
          ToastHelper.toastError("Xóa mã coupon thất bại");
        }
      },
    });
  };
  const resetPassword = async () => {
    setLoading(true);
    const res = await IdentityApiService.resetPassword({
      id: userDetail?.id,
      password: newPassword,
    });
    setLoading(false);
    setModalPassword(false);
    if (res?.succeeded) {
      ToastHelper.toastSuccess("Đổi mật khẩu thành công");
    } else {
      ToastHelper.toastError("Lỗi đổi mật khẩu");
    }
  };

  const createCoupon = async () => {
    setLoading(true);
    const res = await DiscountApiService.createCoupon({
      id: currentDiscount?.id,
      couponCode: couponCode,
    });
    setReload(true);
    setLoading(false);
    setModalAddDiscount(false);
    if (res?.succeeded) {
      ToastHelper.toastSuccess("Tạo mã giảm giá thành công");
    } else {
      ToastHelper.toastError("Tạo mã giảm giá thất bại");
    }
  };

  const onOk = async () => {
    const result = await DiscountApiService.updateDiscount({
      id: currentDiscount?.id,
      name: currentDiscount?.name,
      usePercentage: currentDiscount?.usePercentage,
      discountPercentage: currentDiscount?.discountPercentage,
      discountAmount: currentDiscount?.discountAmount,
      maximumDiscountAmount: currentDiscount?.maximumDiscountAmount,
      startDateUtc: moment(currentDiscount?.startDateUtc).utc().format(),
      endDateUtc: moment(currentDiscount?.endDateUtc).utc().format(),
      isEnabled: currentDiscount?.isEnabled,
      limitationTimes: currentDiscount?.limitationTimes,
      discountLimitationType: currentDiscount?.discountLimitationType,
      discountType: currentDiscount?.discountType,
    });
    if (result.succeeded) {
      ToastHelper.toastSuccess("Cập nhật thành công");
    } else {
      ToastHelper.toastError(
        "Cập nhật thất bại, vui lòng liên hệ nhà phát triển để được hỗ trợ  "
      );
    }
    setReload(true);
    setConfirmModal(false);
  };

  const onConfirmOk = async () => {
    const param: any = {
      userId: user?.userId,
      id: userDetail?.id,
      isLocked: userDetail?.isLocked,
    };
    const response: any = await IdentityApiService.updateUserStatus(param);
    if (response.succeeded && !_.isEmpty(response?.data?.id)) {
      ToastHelper.toastSuccess("Cập nhật thông tin thành công");
      setConfirmModal(false);
      setOpenModal(false);
      setReload(!reload);
    } else {
      ToastHelper.toastError("Cập nhật thất bại!");
    }
  };

  const columns = React.useMemo(
    () => [
      TableHelper.createColumn({
        field: "name",
        headerName: "Tên",
      }),
      TableHelper.createColumn({
        field: "discountLimitationType",
        headerName: "Loại mã",
        renderCell: ({ row }) => {
          const value = () => {
            switch (row.discountLimitationType) {
              case 0:
                return "Không giới hạn";
              case 1:
                return "Giới hạn";
              case 2:
                return "Giới hạn theo người";
              default:
                return "Không xác định";
            }
          };

          return <span>{value()}</span>;
        },
      }),
      TableHelper.createColumn({
        field: "limitationTimes",
        headerName: "Số lượng mã",
        renderCell: ({ row }) => {
          const value = () => {
            switch (row.discountLimitationType) {
              case 0:
                return "Vô hạn";
              default:
                return row.limitationTimes;
            }
          };

          return <span>{value()}</span>;
        },
      }),
      TableHelper.createColumn({
        field: "usedTime",
        headerName: "Số lần sử dụng",
      }),
      TableHelper.createColumn({
        field: "usePercentage",
        headerName: "Loại giảm giá",
        renderCell: ({ row }) => {
          return <span>{!row.usePercentage ? "Cố định" : "Phần trăm"}</span>;
        },
      }),
      TableHelper.createColumn({
        field: "discountAmount",
        headerName: "Cố định",
      }),
      TableHelper.createColumn({
        field: "discountPercentage",
        headerName: "Phần trăm",
      }),
      TableHelper.createColumn({
        field: "maximumDiscountAmount",
        headerName: "Giá giảm tối đa",
      }),
      TableHelper.createColumn({
        field: "startDateUtc",
        headerName: "Ngày bắt đầu",
        renderCell: ({ row }) => {
          return (
            <span>
              {row.startDateUtc
                ? DateHelper.mmddyyyyFormat(row.startDateUtc)
                : ""}
            </span>
          );
        },
      }),
      TableHelper.createColumn({
        field: "endDateUtc",
        headerName: "Ngày kết thúc",
        renderCell: ({ row }) => {
          return (
            <span>
              {row.endDateUtc ? DateHelper.mmddyyyyFormat(row.endDateUtc) : ""}
            </span>
          );
        },
      }),
      TableHelper.createColumn({
        field: "isEnable",
        headerName: "Hoạt động",
        renderCell: ({ row }) => {
          return (
            <span>{row?.isEnabled ? "Hoạt động" : "Không hoạt động"}</span>
          );
        },
      }),
    ],
    []
  );

  const columnsDetail = React.useMemo(
    () => [
      TableHelper.createColumn({
        field: "couponCode",
        flex: 1,
        headerName: "Coupon",
      }),
      TableHelper.createColumn({
        field: "qty",
        headerName: "Số lượng",
      }),
      TableHelper.createColumn({
        field: "used",
        headerName: "Đã sử dụng",
        renderCell: ({ row }) => {
          return <span>{row.used ? "Đã sử dụng" : "Chưa sử dụng"}</span>;
        },
      }),
    ],
    []
  );
  return (
    <AdminContainer>
      <HeaderContainer title={"Người dùng"} />

      <AppTable<any, any, any>
        reload={reload}
        onLoadDataComplete={() => setReload(false)}
        api={DiscountApiService.getAllDiscounts}
        columns={columns}
        onAddNewClick={() => setModalAddDiscount(true)}
        onEditRowClick={handleClick}
        onDeleteRowClick={handleDeleteDiscount}
        renderActions={(row) => {
          return (
            <>
              <IconButton
                sx={{
                  justifyContent: "center",
                }}
                onClick={() => {
                  setModalPassword(true);
                  setCurrentDiscount(row);
                }}
              >
                <Visibility sx={{ color: "green", fontSize: 20 }} />
              </IconButton>
              {row.discountType === 2 && (
                <IconButton
                  sx={{
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setCurrentDiscount(row);
                    setProductVisible(true);
                  }}
                >
                  <List sx={{ fontSize: 20 }} />
                </IconButton>
              )}
              {row.discountType === 5 && (
                <IconButton
                  sx={{
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setCurrentDiscount(row);
                    setSubCategoriesVisible(true);
                  }}
                >
                  <List sx={{ color: "secondary.dark", fontSize: 20 }} />
                </IconButton>
              )}
            </>
          );
        }}
      />
      <AddDiscountModal
        visible={modalAddDiscount}
        onClose={() => setModalAddDiscount(false)}
        onCreateSuccess={() => {
          setReload(true);
          setModalAddDiscount(false);
        }}
      />
      <FormDialog
        maxWidth={"lg"}
        visible={openModal}
        title={`Cập nhật mã giảm giá`}
        cancelText="Close"
        onCancel={() => setOpenModal(false)}
        onOk={onOk}
        open={openModal}
      >
        <Grid container spacing={2} columnSpacing={4} sx={{ pt: 2 }}>
          <Grid item md={6} sm={12}>
            <UserInfo title="Họ tên: " disabled value={currentDiscount?.name} />
          </Grid>
          <Grid item md={6} sm={12}>
            <Stack direction="row" spacing={3}>
              <Typography
                sx={{
                  fontWeight: 600,
                  paddingTop: 1,
                  minWidth: 150,
                  color: "#000",
                }}
              >
                Hoạt động
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentDiscount?.isEnabled ? 0 : 1}
                label="Age"
                className="w-100"
                onChange={(event) => {
                  setCurrentDiscount({
                    ...currentDiscount,
                    isEnabled: event.target.value === 0 ? true : false,
                  });
                }}
              >
                <MenuItem value={0}>Mở</MenuItem>
                <MenuItem value={1}>Tắt</MenuItem>
              </Select>
            </Stack>
          </Grid>
          <Grid item md={6} sm={12}>
            <UserInfo
              type="number"
              title="Phần trăm : "
              value={currentDiscount?.discountPercentage}
              disabled={!currentDiscount?.usePercentage}
              onChange={(event) => {
                setCurrentDiscount({
                  ...currentDiscount,
                  discountPercentage: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <UserInfo
              type="number"
              title="Cố định: "
              value={currentDiscount?.discountAmount}
              disabled={currentDiscount?.usePercentage}
              onChange={(event) => {
                setCurrentDiscount({
                  ...currentDiscount,
                  discountAmount: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <UserInfo
              type="number"
              title="Hoa hồng tối đa: "
              value={currentDiscount?.maximumDiscountAmount}
              disabled={!currentDiscount?.usePercentage}
              onChange={(event) => {
                setCurrentDiscount({
                  ...currentDiscount,
                  maximumDiscountAmount: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <Stack direction="row" spacing={3}>
              <Typography
                sx={{
                  fontWeight: 600,
                  paddingTop: 1,
                  minWidth: 150,
                  color: "#000",
                }}
              >
                Loại
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentDiscount?.usePercentage ? 0 : 1}
                label="Age"
                className="w-100"
                onChange={(event) => {
                  setCurrentDiscount({
                    ...currentDiscount,
                    usePercentage: event.target.value === 0 ? true : false,
                  });
                }}
              >
                <MenuItem value={0}>Phần trăm</MenuItem>
                <MenuItem value={1}>Cố định</MenuItem>
              </Select>
            </Stack>
          </Grid>
          <Grid item md={6} sm={12}>
            <Stack direction="row" spacing={3}>
              <Typography
                sx={{
                  fontWeight: 600,
                  paddingTop: 1,
                  minWidth: 150,
                  color: "#000",
                }}
              >
                Ngày bắt đầu
              </Typography>
              <DatePicker
                className="w-100"
                value={
                  currentDiscount?.startDateUtc
                    ? moment(currentDiscount?.startDateUtc)
                    : null
                }
                onChange={(newValue) => {
                  setCurrentDiscount({
                    ...currentDiscount,
                    startDateUtc: newValue?.format(),
                  });
                }}
              />
            </Stack>
          </Grid>

          <Grid item md={6} sm={12}>
            <Stack direction="row" spacing={3}>
              <Typography
                sx={{
                  fontWeight: 600,
                  paddingTop: 1,
                  minWidth: 150,
                  color: "#000",
                }}
              >
                Ngày kết thúc
              </Typography>
              <DatePicker
                className="w-100"
                value={
                  currentDiscount?.endDateUtc
                    ? moment(currentDiscount?.endDateUtc)
                    : null
                }
                onChange={(newValue) => {
                  setCurrentDiscount({
                    ...currentDiscount,
                    endDateUtc: newValue?.format(),
                  });
                }}
              />
            </Stack>
          </Grid>
          <Grid item md={6} sm={12}>
            <Stack direction="row" spacing={3}>
              <Typography
                sx={{
                  fontWeight: 600,
                  paddingTop: 1,
                  minWidth: 150,
                  color: "#000",
                }}
              >
                Loại giới hạn
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentDiscount?.discountLimitationType}
                label="Age"
                className="w-100"
                onChange={(event) => {
                  setCurrentDiscount({
                    ...currentDiscount,
                    discountLimitationType: event.target.value,
                  });
                }}
              >
                <MenuItem value={0}>Không giới hạn</MenuItem>
                <MenuItem value={1}>Giới hạn</MenuItem>
                <MenuItem value={2}>Giới hạn theo người dùng</MenuItem>
              </Select>
            </Stack>
          </Grid>
          <Grid item md={6} sm={12}>
            <UserInfo
              type="number"
              title="Số lượng: "
              value={currentDiscount?.limitationTimes}
              disabled={currentDiscount?.discountLimitationType === 0}
              onChange={(event) => {
                setCurrentDiscount({
                  ...currentDiscount,
                  limitationTimes: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <Stack direction="row" spacing={3}>
              <Typography
                sx={{
                  fontWeight: 600,
                  paddingTop: 1,
                  minWidth: 150,
                  color: "#000",
                }}
              >
                Giảm giá theo
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentDiscount?.discountType}
                className="w-100"
                onChange={(event) => {
                  setCurrentDiscount((prev) => ({
                    ...prev,
                    discountType: event.target.value,
                  }));
                }}
              >
                <MenuItem value={1}>Theo tổng hóa đơn</MenuItem>
                <MenuItem value={2}>Theo sản phẩm</MenuItem>
                <MenuItem value={5}>Theo danh mục</MenuItem>
                <MenuItem value={6}>Theo nhãn hàng</MenuItem>
                <MenuItem value={7}>Theo phân loại</MenuItem>
                <MenuItem value={8}>Theo seller</MenuItem>
                <MenuItem value={10}>Theo giá giao hàng</MenuItem>
                <MenuItem value={20}>Theo giá đơn hàng</MenuItem>
                <MenuItem value={30}>Tất cả sản phẩm</MenuItem>
              </Select>
            </Stack>
          </Grid>
        </Grid>
      </FormDialog>
      <FormDialog
        maxWidth="lg"
        visible={modalPassword}
        title={"Danh sách coupon"}
        onCancel={() => setModalPassword(false)}
        onOk={resetPassword}
        open={false}
      >
        <AppTable<any, any, any>
          api={DiscountApiService.getCouponById}
          params={{
            id: currentDiscount?.id,
          }}
          reload={reload}
          columns={columnsDetail}
          onDeleteRowClick={handelDeleteCoupon}
          onAddNewClick={() => setModalAddCoupon(true)}
          height={500}
        />
      </FormDialog>
      <FormDialog
        visible={modalAddCoupon}
        title={"Thêm mã coupon"}
        onCancel={() => setModalAddCoupon(false)}
        onOk={createCoupon}
        open={false}
      >
        <UserInfo
          title="Mã: "
          value={couponCode}
          onChange={(event) => setCouponCode(event.target.value as string)}
        />
      </FormDialog>

      <ProductDiscount
        discountId={currentDiscount?.id}
        visible={productVisible}
        onCancel={() => setProductVisible(false)}
      />
      <SubCategoriesDiscount
        discountId={currentDiscount?.id}
        visible={subCategoriesVisible}
        onCancel={() => setSubCategoriesVisible(false)}
      />
      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}
