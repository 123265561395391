import { Box, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SearchInput from "components/SearchInput";
import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { IMenuItem, MENU_ADMIN } from "routers/menu";

interface IHeaderContainer {
  title: string;
  rightComponent?: ReactNode;
  otherStyle?: boolean;
  onSearch?: (value: string) => void;
  placeholder?: string;
}

const BasicBreadcrumbs = (props: { router?: IMenuItem }) => {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{ float: "right", marginRight: "1%" }}
    >
      <Link color="inherit" to="/order-management">
        Home
      </Link>
      <Link color="inherit" to={props.router?.path}>
        {props.router?.label}
      </Link>
    </Breadcrumbs>
  );
};

const HeaderContainer = (props: IHeaderContainer) => {
  const location = useLocation();
  const router = MENU_ADMIN.find(
    (x: IMenuItem) => x.path === location.pathname
  );
  return !props.otherStyle ? (
    <div
      className="breadcrumb-wrapper breadcrumb-wrapper-2"
      style={{ paddingInline: 10 }}
    >
      <h1>{props.title}</h1>
      <p className="breadcrumbs">
        <span>
          <a>
            <Link to="/order-management">Trang chủ</Link>
          </a>
        </span>
        <span>
          <i className="mdi mdi-chevron-right"></i>
        </span>
        <Link color="inherit" to={router?.path}>
          {props.title}
        </Link>
      </p>
    </div>
  ) : (
    <>
      <div className="breadcrumb-wrapper d-flex align-items-center justify-content-between">
        <div>
          <h1>{props.title}</h1>
          <p className="breadcrumbs">
            <span>
              <a>
                <Link to="/order-management">Trang chủ</Link>
              </a>
            </span>
            <span>
              <i className="mdi mdi-chevron-right"></i>
            </span>
            {props.title}
          </p>
        </div>
        {props.onSearch && (
          <div style={{ width: 500 }}>
            <SearchInput
              onSearch={(value: string) => props.onSearch!(value)}
              placeholder={props.placeholder}
            />
          </div>
        )}
        <div>{props.rightComponent}</div>
      </div>
      {props.onSearch && (
        <div style={{ width: 500, marginBottom: 16 }}>
          <SearchInput
            onSearch={(value: string) => props.onSearch!(value)}
            placeholder={props.placeholder}
          />
        </div>
      )}
    </>
  );
};

export default HeaderContainer;
