import { toastError } from "helpers/toastHelper";
import { createContext, useEffect, useState } from "react";
import DealApiService from "services/DealApiService";
import { IModalFormProps } from "../components/ModalForm";

// Context - Deals
export const indexContext = createContext<any>({});

export interface ICategory {
  id: string;
  name: string;
  displayOrder: number;
  parentCategoryId: string;
  parentCategory: string;
  status: number;
}

interface IProductSpecification {
  title: "cpu" | "ssd" | "ram" | "screen" | "vga";
  value: string;
}

interface IProductPrice {
  guaranteeId: string;
  price: number;
}

export interface IProductDealStatus {
  value: 0 | 1 | 2 | 3;
  label: "Pre-Order" | "Sắp về" | "Mới về" | "Sẵn hàng";
}

export type TProductCondition = "New Fullbox" | "Outlet" | "Openbox";

export interface IProduct {
  id?: string;
  name: string;
  displayOrder: number;
  dealStatus: IProductDealStatus["value"];
  url: string;
  condition: TProductCondition;
  categoryId: string;
  specifications: IProductSpecification[];
  prices: IProductPrice[];
  status: number;
}

type TFormType = "parentCategory" | "subCategory" | "product";

interface IChangeForm {
  type: TFormType | null;
  currentValue?: any;
}

interface IDialogConfirmProps {
  open: boolean;
  title?: string;
  content?: string;
  disableBackdropClick?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

const IndexContextProvider = ({ children }) => {
  const [parentCategories, setParentCategories] = useState<ICategory[]>([]);
  const [subCategories, setSubCategories] = useState<ICategory[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);

  const [parentCategoryId, setParentCategoryId] = useState<ICategory["id"]>("");
  const [subCategoryId, setSubCategoryId] = useState<ICategory["id"]>("");

  const [changeForm, setChangeForm] = useState<IChangeForm>({ type: null });
  const [modalForm, setModalForm] = useState<IModalFormProps>({
    title: "",
    open: false,
  });
  const [dialogConfirm, setDialogConfirm] = useState<IDialogConfirmProps>({
    open: false,
  });

  // Initial loading get parent categories first
  useEffect(() => {
    getParentCategories();
  }, []);

  // Get sub categories when parent category change
  useEffect(() => {
    getSubCategories(parentCategoryId);
  }, [parentCategoryId]);

  // Get products when sub category change
  useEffect(() => {
    getProducts(subCategoryId || parentCategoryId);
  }, [subCategoryId]);

  // [GET] deals & find parentCategories
  const getParentCategories = async () => {
    const { succeeded, data } = await DealApiService.getCategories({});
    if (succeeded) {
      const dataParentCategories = await data.results.filter(
        (category: ICategory) => !category.parentCategoryId
      );
      setParentCategories(dataParentCategories);
      setParentCategoryId(dataParentCategories[0].id);
      await getSubCategories(dataParentCategories[0].id);
    } else {
      toastError("Không thể lấy danh mục flash sale");
    }
  };

  // [GET] subCategories from parentCategoryId
  const getSubCategories = async (
    parentCategoryId: ICategory["parentCategoryId"]
  ) => {
    const { succeeded, data } = await DealApiService.getCategories({
      parentCategoryId,
    });
    if (succeeded) {
      setSubCategories(data.results);
      setSubCategoryId(data.results[0]?.id);
      await getProducts(data.results[0]?.id || parentCategoryId);
    } else {
      toastError("Không thể lấy danh mục phụ flash sale");
    }
  };

  // [GET] products by categoryId
  const getProducts = async (categoryId: ICategory["id"]) => {
    if (!categoryId) {
      setProducts([]);
      return;
    }
    const { succeeded, data } = await DealApiService.getDeals({
      categoryId,
    });
    if (succeeded) {
      setProducts(data.results);
    } else {
      toastError("Không thể lấy danh sách flash sale");
    }
  };

  const indexContextValue = {
    parentCategories,
    subCategories,
    products,
    parentCategoryId,
    subCategoryId,
    changeForm,
    modalForm,
    dialogConfirm,
    getParentCategories,
    getSubCategories,
    getProducts,
    setProducts,
    setParentCategories,
    setSubCategories,
    setParentCategoryId,
    setSubCategoryId,
    setChangeForm,
    setModalForm,
    setDialogConfirm,
  };

  return (
    <indexContext.Provider value={indexContextValue}>
      {children}
    </indexContext.Provider>
  );
};

export default IndexContextProvider;
