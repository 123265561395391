import { isEmpty } from "lodash";
import { Redirect } from "react-router-dom";
import { useAppSelector } from "redux/configurations";

const AdminGuard = ({ children }) => {
  const token = useAppSelector((state) => state.IdentityReducer.tokenAdmin);
  const isAuthenticated = !isEmpty(token);
  if (!isAuthenticated) {
    return <Redirect to="/admin" />;
  }
  return <>{children}</>;
};

export default AdminGuard;
