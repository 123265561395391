import { Link } from "react-router-dom";

export default function Breadcrumb(props: { title: string; item: string }) {
  return (
    // <!-- Ec breadcrumb start -->
    <div className="sticky-header-next-sec  ec-breadcrumb section-space-mb">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="row ec_breadcrumb_inner">
              <div className="col-md-6 col-sm-12">
                <h2 className="ec-breadcrumb-title">{props.title}</h2>
              </div>
              <div className="col-md-6 col-sm-12">
                {/* <!-- ec-breadcrumb-list start --> */}
                <ul className="ec-breadcrumb-list">
                  <li className="ec-breadcrumb-item">
                    <Link to="/">Trang chủ</Link>
                  </li>
                  <li className="ec-breadcrumb-item active">{props.item}</li>
                </ul>
                {/* <!-- ec-breadcrumb-list end --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <!-- Ec breadcrumb end -->
  );
}
