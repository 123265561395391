export const INIT_DEALS_PAGE_SIZE = 50;

export const MIN_PRICE = "0";
export const MAX_PRICE = "1000000000";

export const FILTER_CLIENT = [
  {
    displayName: "Giá Sản Phẩm",
    title: "Price",
    isFilterClient: true,
    filterMapping: {
      range1: {
        startPrice: 10000000,
        endPrice: 20000000,
      },
      range2: {
        startPrice: 20000000,
        endPrice: 30000000,
      },
      range3: {
        startPrice: 30000000,
        endPrice: 40000000,
      },
      range4: {
        startPrice: 40000000,
        endPrice: 50000000,
      },
      range5: {
        startPrice: 50000000,
        endPrice: 60000000,
      },
      range6: {
        startPrice: 60000000,
        endPrice: 100000000,
      },
    },
    listFilterResponse: [
      {
        title: "10 triệu - 20 triệu ₫",
        value: "10 triệu - 20 triệu ₫",
        keySearch: "range1",
      },
      {
        title: "20 triệu - 30 triệu ₫",
        value: "20 triệu - 30 triệu ₫",
        keySearch: "range2",
      },
      {
        title: "30 triệu - 40 triệu ₫",
        value: "30 triệu - 40 triệu ₫",
        keySearch: "range3",
      },
      {
        title: "40 triệu - 50 triệu ₫",
        value: "40 triệu - 50 triệu ₫",
        keySearch: "range4",
      },
      {
        title: "50 triệu - 60 triệu ₫",
        value: "50 triệu - 60 triệu ₫",
        keySearch: "range5",
      },
      {
        title: "60 triệu - 100 triệu ₫",
        value: "60 triệu - 100 triệu ₫",
        keySearch: "range6",
      },
    ],
  },
];

export const SORT_CLIENT = {
  sortMapping: {
    sort1: undefined,
    sort2: {
      sortDirection: 1,
      sortField: "discount",
    },
    sort3: {
      sortDirection: 0,
      sortField: "tempSellCurrentPrice",
    },
    sort4: {
      sortDirection: 1,
      sortField: "tempSellCurrentPrice",
    },
  },
  listSortResponse: [
    {
      keySearch: "sort1",
      title: "Không phân loại",
    },
    {
      keySearch: "sort2",
      title: "Giảm giá nhiều nhất",
    },
    {
      keySearch: "sort3",
      title: "Giá thấp đến cao",
    },
    {
      keySearch: "sort4",
      title: "Giá cao đến thấp",
    },
  ],
};
