import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { AlertType } from "helpers/alertHelper";
import { AlertActions } from "redux/actions";
import { IApiResponse } from "services/BaseApiService";
import { IBaseReducerState } from "utils/redux";
interface IAlertState extends IBaseReducerState {
  alert: {
    show?: boolean;
    message?: string;
    type?: AlertType;
  };
  loading: boolean;
}

const initialState: IAlertState = {
  alert: {
    show: false,
  },
  loading: false,
  action: "",
  prefix: "ALERT",
};

const showAlert = (
  state: IAlertState,
  action: PayloadAction<{ message: string; type: AlertType }>
) => {
  state.alert = {
    ...action.payload,
    show: true,
  };
};
const closeAlert = (state: IAlertState) => {
  state.alert = {
    show: false,
    message: undefined,
    type: undefined,
  };
};

const loadingPage = (state: IAlertState, action: any) => {
  state.loading = action.payload;
};
const AppReducer = createReducer(initialState, (builder) => {
  builder.addCase(AlertActions.showAlert.request, showAlert);
  builder.addCase(AlertActions.closeAlert.request, closeAlert);
  builder.addCase(AlertActions.loadingPage.request, loadingPage);
  builder.addMatcher(
    (action) => action.type.includes("_FAILED"),
    (state: IAlertState, action: PayloadAction<IApiResponse>) => {
      state.alert = {
        message: action.payload.error?.message,
        type: AlertType.ERROR,
        show: true,
      };
    }
  );
});

export default AppReducer;
