import { SearchOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormDialog } from "components";
import AppTable from "components/app-table";
import { CurrencyHelper, TableHelper, ToastHelper } from "helpers";
import { isEmpty } from "lodash";
import { IProduct } from "models/IProductModel";
import React, { useEffect, useState } from "react";
import { DiscountApiService, ProductApiService } from "services";

export default function ProductDiscount({
  discountId,
  visible,
  onCancel,
}: {
  discountId: string;
  visible: boolean;
  onCancel: VoidFunction;
}) {
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  const [sku, setSku] = useState("");
  const [product, setProduct] = useState<IProduct | undefined>();

  const [modalAddNewProductVisible, setModalAddNewProductVisible] =
    useState(false);

  const handelAddCoupon = () => {
    setModalAddNewProductVisible(true);
  };

  const columns = React.useMemo(
    () => [
      TableHelper.createColumn({
        field: "skuNumber",
        headerName: "SKU",
      }),
      TableHelper.createColumn({
        field: "name",
        flex: 1,
        headerName: "Tên sản phẩm",
      }),
    ],
    []
  );

  const handelSearch = async () => {
    if (isEmpty(sku)) {
      ToastHelper.toastError("Vui lòng nhập SKU");
      return;
    }
    setLoading(true);

    const result = await ProductApiService.getProductBySku(sku);
    if (result.succeeded) {
      const data = result.data;
      setProduct(data);
    } else {
      ToastHelper.toastError("Không tìm thấy sku này");
    }
    setLoading(false);
  };

  const handelAddProduct = async () => {
    setLoading(true);
    const result = await DiscountApiService.createProductByDiscount({
      id: discountId,
      sku: [product?.skuNumber as string],
    });
    if (result.succeeded) {
      setReload(true);
      setSku("");
      ToastHelper.toastSuccess("Thêm sản phẩm thành công");
    }
    setLoading(false);
  };

  const handelDeleteProduct = async (row) => {
    setLoading(true);
    const result = await DiscountApiService.createProductByDiscount({
      id: discountId,
      sku: [row?.skuNumber as string],
    });
    if (result.succeeded) {
      setReload(true);
      setSku("");
      ToastHelper.toastSuccess("Xóa sản phẩm thành công");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isEmpty(product)) {
      setProduct(undefined);
    }
  }, [sku]);

  return (
    <>
      <FormDialog
        maxWidth="lg"
        visible={visible}
        title={"Danh sách sản phẩm"}
        onCancel={onCancel}
        onOk={() => {}}
        open={false}
      >
        <AppTable<any, any, any>
          api={DiscountApiService.getProductsByDiscountId}
          params={{
            id: discountId,
          }}
          reload={reload}
          onLoadDataComplete={() => {
            setReload(false);
          }}
          onDeleteRowClick={handelDeleteProduct}
          columns={columns}
          onAddNewClick={handelAddCoupon}
          height={500}
        />
      </FormDialog>
      <FormDialog
        maxWidth="md"
        visible={modalAddNewProductVisible}
        title={"Thêm sản phẩm mới"}
        onCancel={() => setModalAddNewProductVisible(false)}
        onOk={() => {}}
        hiddenBtnOK
        hiddenBtnCancel
        open={false}
      >
        <Box
          sx={{
            pt: 4,
            minHeight: 400,
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <TextField
              fullWidth
              placeholder="Nhập mã sku"
              onChange={(event) => setSku(event.target.value)}
            />
            <LoadingButton
              loading={loading}
              startIcon={<SearchOutlined />}
              variant="contained"
              onClick={handelSearch}
            >
              Tìm
            </LoadingButton>
          </Stack>
          {product ? (
            <Card>
              <CardContent>
                <Stack
                  direction={"row"}
                  spacing={4}
                  flex={1}
                  height={"100%"}
                  mb={10}
                >
                  <Box
                    component="img"
                    src={product?.mainImage}
                    sx={{ height: 100, width: 100 }}
                  />
                  <Stack spacing={2}>
                    <Typography>{product?.name}</Typography>

                    <Stack direction={"row"} spacing={2}>
                      <Stack direction={"row"} spacing={2}>
                        <Typography fontWeight={"bold"}>Model</Typography>
                        <Typography>{product?.model}</Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={2}>
                        <Typography fontWeight={"bold"}>SKU</Typography>
                        <Typography>{product?.skuNumber}</Typography>
                      </Stack>
                    </Stack>
                    <Typography variant="h5">
                      {CurrencyHelper.vnFormatter(
                        product?.tempSellCurrentPrice
                      )}
                    </Typography>
                  </Stack>
                </Stack>
                <LoadingButton
                  loading={loading}
                  fullWidth
                  variant="contained"
                  onClick={handelAddProduct}
                >
                  Thêm sản phẩm
                </LoadingButton>
              </CardContent>
            </Card>
          ) : (
            <Stack py={5} textAlign={"center"}>
              <Typography>Không tìm thấy sản phẩm</Typography>
            </Stack>
          )}
        </Box>
      </FormDialog>
    </>
  );
}
