import styled from "@emotion/styled";
import { Card, Col, Flex, Grid, Image, Tag, Typography } from "antd";
import { vnFormatter } from "helpers/currencyHelpers";
import { toNumber } from "lodash";
import {
  CircuitBoard,
  CpuIcon,
  HardDrive,
  Laptop2,
  MemoryStick,
} from "lucide-react";
import { Link } from "react-router-dom";
import { antdBp } from "themes/antdTheme";

const ContainerStyle = styled<any>(Col)`
  margin-bottom: 12px;

  &:hover {
    h5 {
      color: var(--ant-color-primary);
    }
    .ant-image-img {
      transform: scale(1.1);
      transition: all 0.4s ease-in-out;
    }
  }

  .ant-card {
    height: 100%;
    overflow: hidden;
  }

  .ant-card-body {
    padding: 0.75rem;
  }

  @media screen and (min-width: ${antdBp.lg}) {
    flex-basis: ${({ col }) => `calc((100% - 48px) / ${col}) `};
    margin-bottom: 0;
  }
`;

const CardStyle = styled<any>(Card)`
  border: ${({ color }) =>
    color === "light" ? "none" : "1px solid var(--color-border-grey-light)"};
`;

const StatusStyle = styled(Flex)`
  .ant-tag {
    @media screen and (max-width: ${antdBp.xs}) {
      padding: 0.05rem 0.25rem 0 0.25rem;
      font-size: 12px;
    }
  }
`;

const ThumbnailStyle = styled(Flex)`
  margin: 16px 0;
  width: auto;
  height: 200px;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${antdBp.sm}) {
    height: 148px;
  }
`;

const TitleStyle = styled(Typography.Title)`
  display: -webkit-box;
  height: 42px;
  white-space: normal;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const PriceStyle = styled(Typography.Title)`
  &.ant-typography {
    margin: 0 !important;
    font-weight: 700;
    color: var(--color-text-red-base);
  }
`;

const DiscountStyle = styled(Flex)`
  margin-top: 8px;
  align-items: center;

  .ant-tag {
    background: var(--color-secondary);
    font-weight: bold;

    @media screen and (max-width: ${antdBp.xs}) {
      padding: 0.05rem 0.25rem 0 0.25rem;
      font-size: 12px;
    }
  }
`;

const RegularPriceStyle = styled(Typography.Text)`
  &.ant-typography {
    text-decoration: line-through;
    color: var(--color-text-grey-light);
  }
`;

const DescriptionStyle = styled(Flex)`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--color-bg-grey-light);

  .ant-typography {
    display: flex;
    align-items: flex-start;
    font-size: 12px;

    svg {
      margin: 2px 4px 0 0;
    }
  }
`;

const CardDeal = ({
  col = 5,
  color = "light",
  url = "",
  status = { title: "", color: "" },
  condition = "",
  image = "",
  name = "",
  sellPrice = "",
  regularPrice = "",
  specs = "",
}) => {
  const screens = Grid.useBreakpoint();

  return (
    <ContainerStyle col={col} span={screens.lg ? 6 : screens.md ? 8 : 12}>
      <Link to={url}>
        <CardStyle
          color={color}
          hoverable
          style={{ marginBottom: screens.xl ? 0 : 12 }}
        >
          <StatusStyle gap={screens.md ? 6 : 2}>
            <Tag bordered={false} color={status.color}>
              {status.title}
            </Tag>
            <Tag bordered={false} color="blue">
              {condition}
            </Tag>
          </StatusStyle>

          <ThumbnailStyle>
            <Image
              style={{ cursor: "pointer" }}
              width="100%"
              height="auto"
              preview={false}
              src={image}
            />
          </ThumbnailStyle>

          <TitleStyle level={5} title={name}>
            {name}
          </TitleStyle>

          <PriceStyle level={4}>{vnFormatter(toNumber(sellPrice))}</PriceStyle>

          <DiscountStyle>
            <Tag bordered={false}>
              -
              {Math.floor(
                ((toNumber(regularPrice) - toNumber(sellPrice)) /
                  toNumber(regularPrice)) *
                  100
              )}
              %
            </Tag>
            <RegularPriceStyle>
              {vnFormatter(toNumber(regularPrice))}
            </RegularPriceStyle>
          </DiscountStyle>

          {specs["processorShort"] ? (
            <DescriptionStyle gap={8}>
              <Typography.Text>
                <CpuIcon size={14} strokeWidth={1.5} />
                {specs["processorShort"]}
              </Typography.Text>
              <Typography.Text>
                <CircuitBoard size={14} strokeWidth={1.5} />
                {specs["gpuShort"]}
              </Typography.Text>
              <Typography.Text>
                <MemoryStick size={14} strokeWidth={1.5} />
                {specs["RAM Size"]}
              </Typography.Text>
              <Typography.Text>
                <HardDrive size={14} strokeWidth={1.5} />
                {specs["SSD Capacity"]}
              </Typography.Text>
              <Typography.Text>
                <Laptop2 size={14} strokeWidth={1.5} />
                {specs["screenShort"]}
              </Typography.Text>
            </DescriptionStyle>
          ) : null}
        </CardStyle>
      </Link>
    </ContainerStyle>
  );
};

export default CardDeal;
