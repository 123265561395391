import { BaseApiService } from "./BaseApiService";

class ShippingSurchargeApiService extends BaseApiService {
  public getAllShippingSurchargeGroup = () =>
    this.get<any>("/get-all-shipping-surcharge-group");

  public getPriceShippingSurchargeById = (
    shippingSurchargeGroupId: string,
    sellPrice: number
  ) =>
    this.get<any>(
      `/get-price-shipping-surcharge-by-id?shippingSurchargeGroupId=${shippingSurchargeGroupId}&sellPrice=${sellPrice}`
    );
  public getShippingSurchargeGroupById = (params: any) =>
    this.get<any>(`/get-shipping-surcharge-by-shipping-surcharge-group-id`, {
      params,
    });

  public create = (params: any) =>
    this.post("/create-shipping-surcharge", params);
}

export default new ShippingSurchargeApiService("/shipping-surcharge");
