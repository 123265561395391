import { BaseApiService } from "./BaseApiService";

class WishlistApiService extends BaseApiService {
  public getAllWishlist = (params: {
    userId: string;
    name?: string;
    page: number;
    limit: number;
  }) => this.get<any>("/pagination", { params });
  public createWishlistItem = (params: any) =>
    this.post("/create-user-wishlist", params);
  public deleteWishlistItem = (params: { userId: string; productId: string }) =>
    this.delete("/delete-user-wishlist", { params });
}

export default new WishlistApiService("/user-wishlist");
