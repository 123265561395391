import { useMediaQuery, useTheme } from "@mui/material";
import { toastError } from "helpers/toastHelper";
import { data } from "jquery";
import { createContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DealApiService from "services/DealApiService";

export const indexContext = createContext<any>({});

interface ICategory {
  id: string;
  name: string;
  displayOrder: number;
  parentCategoryId: string;
  parentCategory: any;
  status: number;
}

const initHideCategories = [
  // "f9b29979-f504-4517-f0a8-08dc037ae7a7", // Legion 5 2023
  // "467d63c2-5057-4423-f0b2-08dc037ae7a7", // Slim 7 2023
  // "e5eaa832-f8fd-4f05-f0b4-08dc037ae7a7", // Legion 9 2023
  // "23077cc2-f13e-4d7e-f0b5-08dc037ae7a7", // Slim 7 2022
  // "d540f886-5c3b-4aa1-f0b9-08dc037ae7a7", // IDEAPAD GAMING 3 2023
  "2091a96f-0224-4770-f0bd-08dc037ae7a7", // GIGABYTE GAMING
  // "ba9dd1dd-3ef6-4d0b-f0be-08dc037ae7a7", // HP GAMING
  // "1667d639-5da1-4d94-f0a6-08dc037ae7a7", // Laptop Ultrabook - Văn Phòng
  "b5985473-f15b-406f-f0a7-08dc037ae7a7", // Hàng săn sale khác ( Bảo hành 1 đổi 1 trong 7 ngày)
];

const IndexContextProvider = ({ children }) => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [parentCategories, setParentCategories] = useState<ICategory[]>([]);
  const [hideCategories, setHideCategories] =
    useState<any[]>(initHideCategories);

  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLgScreen = useMediaQuery(theme.breakpoints.down("lg"));

  // Initial loading get parent categories first
  useEffect(() => {
    getParentCategories();
  }, []);

  const getParentCategories = async () => {
    setLoading(true);
    const { succeeded, data } = await DealApiService.getCategoriesPublic({});
    if (succeeded) {
      const dataParentCategories = await data.results.filter(
        (category: ICategory) => !category.parentCategoryId
      );
      setParentCategories(dataParentCategories);
    } else {
      toastError("Không thể lấy danh mục flash sale");
    }
    setLoading(false);
  };

  const indexContextValue = {
    history,
    parentCategories,
    isMdScreen,
    isSmScreen,
    isLgScreen,
    loading,
    setLoading,
    hideCategories,
    setHideCategories,
  };

  return (
    <indexContext.Provider value={indexContextValue}>
      {children}
    </indexContext.Provider>
  );
};

export default IndexContextProvider;
