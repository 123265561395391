import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import MobileStepper from "@mui/material/MobileStepper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import { encode as base64_encode } from "base-64";
import { useFormik } from "formik";
import { ToastHelper } from "helpers";
import { AlertType, showAlert } from "helpers/alertHelper";
import { AdminLayout } from "layouts";
import _ from "lodash";
import { IProduct } from "models/IProductModel";
import moment from "moment";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { ProductActions } from "redux/actions";
import { ProductApiService } from "services";
import UploadBaseService from "services/UploadBaseService";
import AdminContainer from "../Container";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Input = styled("input")({
  display: "none",
});

export default function AddNewProduct() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [images, setImages] = useState([]);
  const [listFileImage, setListFileImage] = useState<any[]>([]);
  const [reload, setReload] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    buyCurrentPrice: undefined,
    skuNumber: "",
    model: "",
    brand: "",
    ram: "8GB",
    disk: "128GB",
  });

  const location = useLocation();

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = !_.isEmpty(images) ? images.length : 0;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: any) => {
    setActiveStep(step);
  };

  const uploadImage = async () => {
    let dataPost: any = [];
    if (!_.isEmpty(listFileImage)) {
      await UploadBaseService.uploadImage(listFileImage)
        .then((res: any) => {
          if (res.succeeded) {
            res.data.map((x: any) => {
              dataPost.push({
                type: "Image",
                value: base64_encode(x.path),
              });
            });
          } else {
            showAlert("Upload Image Error", AlertType.ERROR);
          }
        })
        .catch((err) => {
          showAlert("Upload Image Error", AlertType.ERROR);
        });
    }
    return { dataPost };
  };
  const formik: any = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (values: any, action: any) => {
      const { dataPost } = await uploadImage();
      let buyCurrentPrice;
      if (values.buyCurrentPrice) {
        buyCurrentPrice = values.buyCurrentPrice.toString().includes(",")
          ? Number(values.buyCurrentPrice.replace(/[,]/g, ""))
          : Number(values.buyCurrentPrice);
      }

      let listProductDetailsRequest = dataPost || [];

      listProductDetailsRequest.push(
        {
          type: "Ram",
          value: values.ram,
        },
        {
          type: "Disk",
          value: values.disk,
        }
      );

      let params: any = {
        orgLink: "",
        categoryId: "427F586C-86C9-4CD5-92AD-7F7D89A612C0",
        skuNumber: values.skuNumber,
        name: values.name,
        model: values.model,
        brand: values.brand,
        carrierId: "",
        buyRegularPrice: 0,
        buyCurrentPrice: buyCurrentPrice,
        buyCurrency: "",
        sellCurrency: "",
        weight: 0,
        mainImage: !_.isEmpty(dataPost.filter((x: any) => x.value === "Image"))
          ? dataPost.filter((x: any) => x.value === "Image")[0].value
          : "",
        recStatus: 1,
        isDisplay: 1,
        createdBy: "",
        createdDt: moment().format(),
        updatedBy: "",
        // updatedDt: moment().format(),
        listProductDetailsRequest,
      };

      if (!_.isEmpty(location.state)) {
        const editProduct = async () => {
          const product: any = await ProductApiService.updateProduct({
            ...initialValues,
            ...params,
            listProductDetailsGroup: undefined,
            ram: undefined,
            disk: undefined,
          });
          if (product.succeeded && !_.isEmpty(product.data)) {
            showAlert("Edit Product Successfully", AlertType.SUCCESS);
          } else {
            showAlert("Edit Product Failed", AlertType.ERROR);
          }
        };
        editProduct();
      } else {
        dispatch(ProductActions.createProduct.request(params));
      }
    },
    validate: (values: any) => {
      let buyCurrentPrice;
      if (values.buyCurrentPrice) {
        buyCurrentPrice = values.buyCurrentPrice.toString().includes(",")
          ? values.buyCurrentPrice.replace(/[,]/g, "")
          : values.buyCurrentPrice;
      }

      const errors: any = {};
      if (_.isEmpty(values.name)) {
        errors.name = "Name can not be blank";
      }
      if (!values.buyCurrentPrice) {
        errors.price = "Price can not be blank";
      }
      if (buyCurrentPrice && parseFloat(buyCurrentPrice) < 0) {
        errors.buyCurrentPrice = "Invalid price";
      }
      if (_.isEmpty(values.skuNumber)) {
        errors.skuNumber = "SkuNumber can not be blank";
      }
      if (_.isEmpty(values.model)) {
        errors.model = "Model can not be blank";
      }
      if (_.isEmpty(values.brand)) {
        errors.brand = "Brand can not be blank";
      }
      return errors;
    },
  });
  useEffect(() => {
    const getProductById = async (id: any) => {
      const product: any = await ProductApiService.getProductItem(id);
      if (product.succeeded && !_.isEmpty(product.data)) {
        const productData: IProduct = product.data;
        // setInitialValues(productData);
        let ram = null;
        let disk = null;
        if (!_.isEmpty(product.data.listProductDetailsGroup)) {
          productData.listProductDetailsGroup.forEach((y: any) =>
            y.forEach((x: any) => {
              if (x.type?.toLowerCase() === "ram") {
                ram = x.value.toUpperCase();
              }
              if (x.type?.toLowerCase() === "disk") {
                disk = x.value.toUpperCase();
              }
            })
          );
        }
        setInitialValues({ ...productData, ram, disk });
      }
    };
    if (!_.isEmpty(location.state)) {
      getProductById(location.state);
    }
  }, []);
  const handleUploadImage = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let arr: any = Array.from(event.target.files);
      setListFileImage([...listFileImage, ...arr]);
      let newList: any = [];
      if (arr.length > 1) {
        arr.forEach((element: any) => {
          if (element.size < 5e6) {
            const image = {
              label: element.name,
              imgPath: URL.createObjectURL(element),
            };
            newList.push(image);
          } else {
            ToastHelper.toastError(
              `Image ${element.name} size is too large. The image size should be less than 5mb`
            );
          }
        });
      } else {
        if (event.target.files[0].size < 5e6) {
          newList.push({
            label: event.target.files[0].name,
            imgPath: URL.createObjectURL(event.target.files[0]),
          });
        } else {
          ToastHelper.toastError(
            `Image ${event.target.files[0].name} size is too large. The image size should be less than 5mb`
          );
        }
      }
      setImages(images.concat(newList));
    }
  };
  return (
    <AdminContainer>
      {/* <!-- Container-fluid starts--> */}
      <div className="container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-lg-6">
              <div className="page-header-left">
                <h3>
                  Add New Product
                  <small>Multikart Admin panel</small>
                </h3>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
      {/* <!-- Container-fluid starts--> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>
                  {!_.isEmpty(location.state) ? "Edit Product" : " Add Product"}
                </h5>
              </div>
              {!_.isEmpty(initialValues) ? (
                <div className="card-body">
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 3fr)",
                      gap: 1,
                      gridTemplateRows: "auto",
                      gridTemplateAreas: `"left right"`,
                      minHeight: 400,
                    }}
                  >
                    <Stack sx={{ gridArea: "left" }} spacing={2}>
                      {!_.isEmpty(images) ? (
                        <Box sx={{ px: 2 }}>
                          <AutoPlaySwipeableViews
                            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                          >
                            {images.map((step: any, index) => (
                              <Stack key={step.label}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                  <Box
                                    component="img"
                                    sx={{
                                      height: "auto",
                                      display: "block",
                                      overflow: "hidden",
                                      width: "100%",
                                    }}
                                    src={step.imgPath}
                                    alt={step.label}
                                  />
                                ) : null}
                              </Stack>
                            ))}
                          </AutoPlaySwipeableViews>
                          <MobileStepper
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                              <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                              >
                                Next
                                {theme.direction === "rtl" ? (
                                  <KeyboardArrowLeft />
                                ) : (
                                  <KeyboardArrowRight />
                                )}
                              </Button>
                            }
                            backButton={
                              <Button
                                size="small"
                                onClick={handleBack}
                                disabled={activeStep === 0}
                              >
                                {theme.direction === "rtl" ? (
                                  <KeyboardArrowRight />
                                ) : (
                                  <KeyboardArrowLeft />
                                )}
                                Back
                              </Button>
                            }
                          />
                        </Box>
                      ) : (
                        <Stack
                          sx={{
                            bgcolor: "#F8F8F9",
                            height: 450,
                            textAlign: "center",
                            paddingTop: "40%",
                          }}
                        >
                          Upload image here
                        </Stack>
                      )}
                      <Stack sx={{ alignItems: "center" }}>
                        <label htmlFor="contained-button-file">
                          <Input
                            accept="image/*"
                            id="contained-button-file"
                            multiple
                            type="file"
                            size={1}
                            onChange={handleUploadImage}
                          />
                          <Button variant="outlined" component="span">
                            Upload
                          </Button>
                        </label>
                      </Stack>
                    </Stack>
                    <form onSubmit={formik.handleSubmit}>
                      <Stack sx={{ gridArea: "right", px: 2 }} spacing={2}>
                        <Box>
                          <TextField
                            variant="standard"
                            type="text"
                            sx={{ width: "100%" }}
                            id="name"
                            label="Name *"
                            error={
                              formik.touched.name &&
                              !_.isEmpty(formik.errors.name)
                            }
                            helperText={
                              formik.touched.name && formik.errors.name
                            }
                            value={formik.values.name || ""}
                            onChange={formik.handleChange}
                            size="medium"
                            focused
                          />
                        </Box>
                        <Box>
                          <NumberFormat
                            customInput={TextField}
                            error={
                              formik.touched.buyCurrentPrice &&
                              !_.isEmpty(formik.errors.buyCurrentPrice)
                            }
                            helperText={
                              formik.touched.buyCurrentPrice &&
                              formik.errors.buyCurrentPrice
                            }
                            value={formik.values.buyCurrentPrice || ""}
                            variant="standard"
                            sx={{ width: "100%" }}
                            id="buyCurrentPrice"
                            label="Price ($) *"
                            onChange={formik.handleChange}
                            size="medium"
                            focused
                            thousandSeparator={true}
                          />
                        </Box>

                        <Box>
                          <TextField
                            variant="standard"
                            type="text"
                            sx={{ width: "100%" }}
                            id="skuNumber"
                            label="SkuNumber *"
                            error={
                              formik.touched.skuNumber &&
                              !_.isEmpty(formik.errors.skuNumber)
                            }
                            helperText={
                              formik.touched.skuNumber &&
                              formik.errors.skuNumber
                            }
                            value={formik.values.skuNumber || ""}
                            onChange={formik.handleChange}
                            size="medium"
                            focused
                          />
                        </Box>
                        <Box>
                          <TextField
                            variant="standard"
                            type="text"
                            sx={{ width: "100%" }}
                            id="model"
                            label="Model *"
                            error={
                              formik.touched.model &&
                              !_.isEmpty(formik.errors.model)
                            }
                            helperText={
                              formik.touched.model && formik.errors.model
                            }
                            value={formik.values.model || ""}
                            onChange={formik.handleChange}
                            size="medium"
                            focused
                          />
                        </Box>
                        <Box>
                          <TextField
                            variant="standard"
                            type="text"
                            sx={{ width: "100%" }}
                            id="brand"
                            label="Brand *"
                            error={
                              formik.touched.brand &&
                              !_.isEmpty(formik.errors.brand)
                            }
                            helperText={
                              formik.touched.brand && formik.errors.brand
                            }
                            value={formik.values.brand || ""}
                            onChange={formik.handleChange}
                            size="medium"
                            focused
                          />
                        </Box>

                        <Box>
                          <Box>
                            <FormControl component="fieldset">
                              <FormLabel component="legend">Ram</FormLabel>
                              <RadioGroup
                                id="ram"
                                row
                                name="ram"
                                value={formik.values.ram || ""}
                                onChange={formik.handleChange}
                              >
                                <FormControlLabel
                                  value="4GB"
                                  control={<Radio />}
                                  label="4GB"
                                />
                                <FormControlLabel
                                  value="8GB"
                                  control={<Radio />}
                                  label="8GB"
                                />
                                <FormControlLabel
                                  value="16GB"
                                  control={<Radio />}
                                  label="16GB"
                                />
                                <FormControlLabel
                                  value="32GB"
                                  control={<Radio />}
                                  label="32GB"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Box>
                          <Box>
                            <FormControl component="fieldset">
                              <FormLabel component="legend">Disk</FormLabel>
                              <RadioGroup
                                row
                                id="disk"
                                name="disk"
                                value={formik.values.disk || ""}
                                onChange={formik.handleChange}
                              >
                                <FormControlLabel
                                  value="120GB"
                                  control={<Radio />}
                                  label="120GB"
                                />
                                <FormControlLabel
                                  value="128GB"
                                  control={<Radio />}
                                  label="128GB"
                                />
                                <FormControlLabel
                                  value="256GB"
                                  control={<Radio />}
                                  label="256GB"
                                />
                                <FormControlLabel
                                  value="512GB"
                                  control={<Radio />}
                                  label="512GB"
                                />
                                <FormControlLabel
                                  value="1TB"
                                  control={<Radio />}
                                  label="1TB"
                                />
                                <FormControlLabel
                                  value="2TB"
                                  control={<Radio />}
                                  label="2TB"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Box>
                        </Box>

                        <div className="text-end">
                          <button className="btn-solid btn" type="submit">
                            OK
                          </button>
                        </div>
                      </Stack>
                    </form>
                  </Box>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}
