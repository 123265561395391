import { PayloadAction, AnyAction } from "@reduxjs/toolkit";
export interface IBaseReducerState {
  prefix: string;
  action: string;
}

export const handleReducerBuilder = (
  state: IBaseReducerState,
  action: PayloadAction<any> | AnyAction,
  builder?: () => void
) => {
  if (action.type.includes(`${state.prefix}/`)) {
    state.action = action.type;
  }
  if (builder) {
    builder();
  }
};
