import { PayloadAction } from "@reduxjs/toolkit";
import { AlertType, showAlert } from "helpers/alertHelper";
import _ from "lodash";
import { call, put, takeEvery } from "redux-saga/effects";
import { ProductActions } from "redux/actions";
import { ProductApiService } from "services";
import { IApiResponse } from "services/BaseApiService";

function* getAllProductAsync(action: PayloadAction) {
  try {
    const response: IApiResponse<any> = yield call(ProductApiService.getAllProduct);
    if (response.succeeded && response.data) {
      yield put(ProductActions.getAllProduct.success(response.data));
    } else {
      yield put(ProductActions.getAllProduct.failed(response));
    }
  } catch (error) {
    console.log("error", error);
  }
}

function* createProduct(action: PayloadAction) {
  try {
    const response: IApiResponse<any> = yield call(ProductApiService.createProduct, action.payload);
    if (response) {
      if (response.succeeded && response.data) {
        yield put(ProductActions.createProduct.success(response.data));
        showAlert("Add Product Successfully", AlertType.SUCCESS);
      } else {
        yield put(ProductActions.createProduct.failed(response));
        showAlert("Create Product Failed", AlertType.ERROR);
      }
    }
  } catch (error) {
    console.log("error", error);
  }
}

export function* ProductWatcher() {
  yield takeEvery(ProductActions.getAllProduct.request, getAllProductAsync);
  yield takeEvery(ProductActions.createProduct.request, createProduct);
}
