import styled from "@emotion/styled";
import { Button, Drawer, Dropdown, Flex, Grid, Space, Typography } from "antd";
import { isOpenMenuMobileAtom } from "components/Header/atoms/headerAtom";
import { useAtom } from "jotai";
import { isEmpty } from "lodash";
import { MailCheck, Menu, Phone, Send, ShoppingCart, User } from "lucide-react";
import { Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IdentityActions } from "redux/actions";
import { RootReducer } from "redux/configurations/rootReducer";

const ContainerStyle = styled(Flex)`
  align-items: center;

  @keyframes btnColorChange {
    0% {
      color: var(--color-secondary);
    }
    100% {
      color: white;
    }
  }

  .btn-new {
    animation: btnColorChange 1s infinite;
  }

  .ant-btn {
    display: flex;
    align-items: center;

    &:hover {
      .ant-btn-icon,
      .ant-typography {
        color: var(--color-secondary);
      }
    }
  }

  .ant-space-compact {
    text-align: left;
  }

  .ant-typography h5 {
    font-size: 0.95rem;
  }

  .ant-btn-icon,
  .ant-typography {
    margin: 0;
    padding: 0;
    line-height: 20px;
    color: #ffffff;
  }
`;

const DrawerContainerStyle = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
  }

  .ant-btn {
    display: flex;
    margin: 8px 0;
    height: fit-content;

    &:hover {
      .ant-btn-icon,
      .ant-typography {
        color: var(--color-secondary);
      }
    }
  }

  .ant-space-compact {
    flex-direction: row;
    gap: 4px;

    span {
      font-weight: bold;
      font-size: 1rem;
      text-transform: lowercase;
    }
  }
`;

const Menus = () => {
  const screens = Grid.useBreakpoint();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector(
    (state: RootReducer) => state.IdentityReducer.token
  );
  const user = useSelector(
    (state: RootReducer) => state.IdentityReducer.user?.user
  );
  const carts = useSelector((state: RootReducer) => state.CartReducer.carts);
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useAtom(isOpenMenuMobileAtom);

  const handleLogout = () => {
    dispatch(IdentityActions.logout.request());
    history.push("/");
  };

  // Config - list menu
  const HEADER_MENUS: any = useMemo(
    () => [
      {
        key: "1",
        link: isEmpty(token) ? "/login" : "/user-profile",
        icon: <User size={26} strokeWidth={1.5} />,
        label: isEmpty(token) ? (
          <Space.Compact direction="vertical">
            <Typography.Title level={5}>Đăng nhập</Typography.Title>
            <Typography.Text>Tài khoản</Typography.Text>
          </Space.Compact>
        ) : (
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <Typography.Link href="/user-profile">
                      Thông tin cá nhân
                    </Typography.Link>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <Typography onClick={handleLogout}>Đăng xuất</Typography>
                  ),
                },
              ],
            }}
          >
            <Space.Compact direction="vertical">
              <Typography.Title level={5}>Xin chào</Typography.Title>
              <Typography.Text>{user?.firstName}</Typography.Text>
            </Space.Compact>
          </Dropdown>
        ),
      },
      {
        key: "2",
        link: "/tracking-order",
        icon: <Send size={26} strokeWidth={1.5} />,
        label: (
          <Space.Compact direction="vertical">
            <Typography.Title level={5}>Theo dõi</Typography.Title>
            <Typography.Text>Đơn hàng</Typography.Text>
          </Space.Compact>
        ),
      },
      // {
      //   key: "3",
      //   link: "/get-quote",
      //   icon: <MailCheck className="btn-new" size={26} strokeWidth={1.5} />,
      //   label: (
      //     <Space.Compact direction="vertical">
      //       <Typography.Title level={5} className="btn-new">
      //         Check giá
      //       </Typography.Title>
      //       <Typography.Text className="btn-new">Ebay tự động</Typography.Text>
      //     </Space.Compact>
      //   ),
      // },
      {
        key: "4",
        link: "/contact-us",
        icon: <Phone size={26} strokeWidth={1.5} />,
        label: (
          <Space.Compact direction="vertical">
            <Typography.Title level={5}>Hotline</Typography.Title>
            <Typography.Text>0903.392.492</Typography.Text>
          </Space.Compact>
        ),
      },
      {
        key: "5",
        link: "/cart",
        icon: <ShoppingCart size={26} strokeWidth={1.5} />,
        label: (
          <Space.Compact direction="vertical">
            <Typography.Title level={5}>Giỏ hàng</Typography.Title>
            <Typography.Text>{carts.length || 0} sản phẩm</Typography.Text>
          </Space.Compact>
        ),
      },
    ],
    [carts]
  );

  return screens.xl ? (
    <ContainerStyle>
      {/* List - menu (large screens) */}
      {HEADER_MENUS.map((menu: any) => (
        <Button key={menu.key} icon={menu.icon} type="link" href={menu.link}>
          {menu.label}
        </Button>
      ))}
    </ContainerStyle>
  ) : (
    <Fragment>
      {/* Button - collapse menus (small screens) */}
      <Button
        onClick={() => setIsOpenMenuMobile(true)}
        type="primary"
        icon={<Menu />}
        size="large"
      />

      {/* Drawer - list menu (small screens) */}
      <DrawerContainerStyle
        title="Menu"
        onClose={() => setIsOpenMenuMobile(false)}
        open={isOpenMenuMobile}
      >
        {HEADER_MENUS.map((menu: any) => (
          <Button key={menu.key} icon={menu.icon} type="link" href={menu.link}>
            {menu.label}
          </Button>
        ))}
      </DrawerContainerStyle>
    </Fragment>
  );
};

export default Menus;
