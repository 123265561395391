export const MAIN_MENU = [
  {
    path: "/",
    name: "Trang chủ",
  },
  {
    path: "/about-us",
    name: "Về chúng tôi",
  },
  {
    path: "#",
    name: "Chính sách - Hướng dẫn",
    menuSub: [
      {
        path: "/purchase-policy",
        name: "Chính sách mua hàng",
      },
      {
        path: "/warranty-policy",
        name: "Chính sách bảo mật",
      },
      {
        path: "/shopping-guide",
        name: "Hướng dẫn mua hàng",
      },
      {
        path: "/receive-quote-guide",
        name: "Hướng dẫn nhận báo giá",
      },
      {
        path: "/question-and-answer",
        name: "Câu hỏi thường gặp",
      },
    ],
  },
  {
    path: "/products",
    name: "Sản phẩm",
  },
  {
    path: "/tracking-order",
    name: "Theo dõi đơn hàng",
  },
  {
    path: "/get-quote",
    name: "Báo giá",
  },
  {
    path: "/contact-us",
    name: "Liên hệ chúng tôi",
  },
];
