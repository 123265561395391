import Box from "@mui/material/Box";
import "./style.css";

export default function CircularIndeterminate(props: { loading: boolean }) {
  return props.loading ? (
    <div
      style={{
        display: "block",
        textAlign: "center",
        left: "0",
        top: "0",
        width: "100%",
        height: "100%",
        zIndex: "9999",
        position: "fixed",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <Box sx={{ position: "relative", top: "50%", left: "0" }}>
        <span className="loader"></span>
      </Box>
    </div>
  ) : null;
}
