import { toast, ToastOptions } from "react-toastify";

const defaultOption: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};
export const toastSuccess = (message: string, options?: ToastOptions) => {
  return toast.success(message, {
    ...defaultOption,
    ...options,
  });
};

export const toastInfo = (message: string, options?: ToastOptions) => {
  return toast.info(message, {
    ...defaultOption,
    ...options,
  });
};
export const toastWarning = (message: string, options?: ToastOptions) => {
  return toast.warning(message, {
    ...defaultOption,
    ...options,
  });
};
export const toastError = (message: string, options?: ToastOptions) => {
  return toast.error(message, {
    ...defaultOption,
    ...options,
  });
};
