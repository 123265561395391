import AdminContainer from "../Container";
import IndexContextProvider from "./context/indexContext";
import { Typography } from "@mui/material";
import DownloadGoogle from "./components/DownloadGoogle";

const DealManagement = () => {
  return (
    <AdminContainer>
      <Typography variant="h3" marginBottom={3}>
        FLASH SALE
      </Typography>
      <IndexContextProvider>
        <DownloadGoogle />
      </IndexContextProvider>
    </AdminContainer>
  );
};

export default DealManagement;
