import {
  Grid,
  Stack,
  Typography,
  TextField,
  MenuItem,
  Pagination,
  Box,
} from "@mui/material";
import SearchInput from "components/SearchInput";
import React, { ReactNode } from "react";

const TableHeaderSearch = (props: {
  onSearch: (value: string) => void;
  placeholder?: string;
  rightComponent?: ReactNode;
}) => {
  return (
    <div
      className="row justify-content-between top-information"
      style={{ marginLeft: -15, marginRight: 0 }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography
            sx={
              {
                // fontSize: "14px",
                //  color: "#000",
                // fontWeight: "300",
              }
            }
          >
            Tìm kiếm:
          </Typography>
          <Box sx={{ width: 500 }}>
            <SearchInput
              onSearch={(value: string) => props.onSearch(value)}
              placeholder={props.placeholder}
            />
          </Box>
        </Stack>
        {props.rightComponent}
      </Grid>
    </div>
  );
};
const TableFooter = (props: {
  onChangePage: (page: number) => void;
  currentPage: number;
  totalPage: number;
}) => {
  return (
    <div
      className="row justify-content-between bottom-information"
      style={{ marginLeft: 0, marginRight: 0 }}
    >
      <div
        className="dataTables_info"
        id="responsive-data-table_info"
        role="status"
        aria-live="polite"
      >
        {/* Showing 1 to 20 of 36 entries */}
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers"
        id="responsive-data-table_paginate"
      >
        <ul className="pagination">
          <Pagination
            className="ec-pro-pagination-inner"
            count={props.totalPage}
            shape="rounded"
            page={props.currentPage}
            onChange={(event: React.ChangeEvent<unknown>, page: number) => {
              props.onChangePage(page);
            }}
          />
        </ul>
      </div>
    </div>
  );
};
const TableColumName = (props: { title: string }) => {
  return (
    <th
      className="sorting sorting_asc"
      tabIndex={0}
      aria-controls="responsive-data-table"
      rowSpan={1}
      colSpan={1}
      aria-sort="ascending"
      // style={{ width: "14px" }}
    >
      {props.title}
    </th>
  );
};

export { TableColumName, TableFooter, TableHeaderSearch };
