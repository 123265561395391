import {
  AddNewProduct,
  CategoryConfiguration,
  CodeTypeManagement,
  CrawlData,
  CrawlMultipleLink,
  CrawlSingleLink,
  OrderManagement,
  PolicyManagement,
  ProductDetail,
  ProductManagement,
  RateConfiguration,
  ShipmentDetail,
  ShipmentManagement,
  ShippingSurchargeManagement,
  TopDealsManagement,
  UserManagement,
} from "pages/admin";

import { ComponentType } from "react";
import Configurations from "pages/admin/Configuration/ConfigurationsManagement";
import { Login } from "@mui/icons-material";
import TrackingOrder from "pages/admin/Operation/TrackingOrder";
import _ from "lodash";

export interface IMenuItem {
  id: string;
  label: string;
  path?: string;
  subItems?: IMenuItem[];
  children?: IMenuItem[];
  component?: ComponentType;
  showInMenu?: boolean;
  exact?: boolean;
  isReportDynamic?: boolean;
  icon?: string;
}

export const MENU_ITEMS: IMenuItem[] = [];

export const MENU_ADMIN: IMenuItem[] = [
  {
    label: "Đơn Hàng",
    id: "order-management",
    showInMenu: true,
    path: "/order-management",
    component: OrderManagement,
    icon: "mdi-cart",
  },
  {
    label: "Đơn Hàng tự động",
    id: "auto-tracking-order",
    showInMenu: true,
    path: "/auto-tracking-order",
    component: TrackingOrder,
    icon: "mdi-cart",
  },
  {
    label: "Giao Hàng",
    id: "shipment-management",
    showInMenu: true,
    path: "/shipment-management",
    component: ShipmentManagement,
    icon: "mdi-car-pickup",
  },
  {
    label: "Sản Phẩm",
    id: "product-management",
    showInMenu: true,
    path: "/product-management",
    component: ProductManagement,
    icon: "mdi-laptop-windows",
  },
  {
    label: "Người Dùng",
    id: "user-management",
    showInMenu: true,
    path: "/user-management",
    component: UserManagement,
    icon: "mdi-account-supervisor",
  },
  {
    label: "Product Detail",
    id: "product-detail",
    showInMenu: false,
    path: "/product-detail",
    component: ProductDetail,
  },
  {
    label: "Shipment Detail",
    id: "shipment-detail",
    showInMenu: false,
    path: "/shipment-detail",
    component: ShipmentDetail,
  },
  {
    label: "Cấu Hình",
    id: "configurations",
    showInMenu: true,
    path: "/configurations",
    component: Configurations,
    icon: "mdi-settings",
  },
  {
    label: "Cào Dữ Liệu",
    id: "crawlData",
    showInMenu: true,
    path: "/crawl-data",
    component: CrawlData,
    icon: "mdi-axe",
  },
  {
    label: "admin",
    id: "admin",
    showInMenu: false,
    path: "/admin",
    component: Login,
  },
  {
    label: "Edit Product",
    id: "edit-product",
    showInMenu: false,
    path: "/edit-product",
    component: AddNewProduct,
  },
  {
    label: "Cấu hình tỷ lệ",
    id: "rate-configuration",
    showInMenu: true,
    path: "/rate-configuration",
    component: RateConfiguration,
    icon: "mdi-currency-usd",
  },
  {
    label: "Danh mục sản phẩm",
    id: "category-configuration",
    showInMenu: true,
    path: "/category-configuration",
    component: CategoryConfiguration,
    icon: "mdi-notification-clear-all",
  },
  {
    label: "Cấu hính giá sốc",
    id: "top-deals-management",
    showInMenu: true,
    path: "/top-deals-management",
    component: TopDealsManagement,
    icon: "mdi-cart",
  },
  {
    label: "Giá vận chuyển",
    id: "shipping-surcharge-management",
    showInMenu: true,
    path: "/shipping-surcharge-management",
    component: ShippingSurchargeManagement,
    icon: "mdi-currency-usd",
  },
  {
    label: "Quản lý link cào dữ liệu đơn",
    id: "crawl-single-link-management",
    showInMenu: true,
    path: "/crawl-single-link-management",
    component: CrawlSingleLink,
    icon: "mdi-format-align-justify",
  },
  {
    label: "Quản lý link cào dữ liệu nhóm",
    id: "crawl-multiple-link-management",
    showInMenu: true,
    path: "/crawl-multiple-link-management",
    component: CrawlMultipleLink,
    icon: "mdi-format-columns",
  },
  {
    label: "Quản lý mã",
    id: "code-type-management",
    showInMenu: true,
    path: "/code-type-management",
    component: CodeTypeManagement,
    icon: "mdi-code-tags",
  },
  {
    label: "Quản lí chính sách",
    id: "code-type-management",
    showInMenu: true,
    path: "/policy-management",
    component: PolicyManagement,
    icon: "mdi-code-tags",
  },
];
