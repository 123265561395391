import { Box, Button, Card, CardContent, Grid, TextField } from "@mui/material";
import { FormikErrors, useFormik } from "formik";
import { AlertType, showAlert } from "helpers/alertHelper";
import _ from "lodash";
import { ILoginRequest } from "model/Identity/ILoginRequest";
import { ILoginAdmin } from "models/IIdentity";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { IdentityActions } from "redux/actions";
import { IdentityApiService } from "services";
import { translations, _t } from "utils/i18n";
import "./styles.css";
import { ToastHelper } from "helpers";
import AppButton from "components/client/app-button";
import { useState, useEffect } from "react";

function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
const Login = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  let history = useHistory();
  const { t } = useTranslation();
  const initialValues: any = {
    username: "",
    password: "",
  };
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const params: ILoginAdmin = {
        userName: values.username,
        password: values.password,
      };
      setLoading(true);
      const response = await IdentityApiService.loginAdmin(params);
      if (_.isEmpty(response?.data?.jwToken)) {
        setLoading(false);
        ToastHelper.toastError(
          "Sai tài khoản hoặc mật khẩu, vui lòng kiểm tra lại!"
        );
        return;
      }
      var decode = parseJwt(response?.data.jwToken);
      dispatch(
        IdentityActions.loginAdmin.request({
          admin: response?.data,
          token: response?.data.jwToken,
          exp: decode.exp,
        })
      );
      history.push("/order-management");
    },
    validate: (values) => {
      const errors: FormikErrors<ILoginRequest> = {};
      if (_.isEmpty(values.username)) {
        errors.username = t(_t(translations.login.blankUserName));
      }
      if (_.isEmpty(values.password)) {
        errors.password = t(_t(translations.login.blankPassword));
      }
      return errors;
    },
  });

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <div className="container-fluid ps-md-0">
      <div className="row g-0">
        <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image" />
        <div className="col-md-8 col-lg-6">
          <div className="login d-flex align-items-center py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-lg-8 mx-auto">
                  <h3 className="login-heading mb-4">Welcome to HAISTORE.VN</h3>
                  <Grid
                    container
                    spacing={2}
                    component="form"
                    onSubmit={formik.handleSubmit}
                  >
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "100%" }}
                        id="username"
                        name="username"
                        label={"Tài khoản"}
                        error={
                          formik.touched.username &&
                          !_.isEmpty(formik.errors.username)
                        }
                        helperText={
                          formik.touched.username && formik.errors.username
                        }
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        size="medium"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "100%" }}
                        error={
                          formik.touched.password &&
                          !_.isEmpty(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        id="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        type="password"
                        name="password"
                        label={"Mật khẩu"}
                        size="medium"
                      />
                    </Grid>
                    <Grid item container justifyContent="center" xs={12}>
                      <AppButton
                      label="Đăng nhập"
                        variant="primary"
                        loading={loading}
                        type="submit"
                        className="w-100"
                      >
                        
                      </AppButton>
                    </Grid>
                  </Grid>
                  {/* 
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="rememberPasswordCheck"
                      />
                      <label className="form-check-label">
                        Remember password
                      </label>
                    </div> */}

                  {/* <div className="d-grid"> */}

                  {/* <div className="text-center">
                        <a className="small" href="#">
                          Forgot password?
                        </a>
                      </div> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
