import React, { PropsWithChildren } from "react";
// material-ui
import { Backdrop, Box, CircularProgress } from "@mui/material";

// third-party
import "react-perfect-scrollbar/dist/css/styles.css";

// import { drawerWidth } from "store/constant";

// ==============================|| SIDEBAR DRAWER ||============================== //

type IAdminProps = {
  title?: string;
  loading?: boolean;
} & PropsWithChildren<any>;

type AdminContextProps = {};
export const AdminContext = React.createContext<AdminContextProps>({});
export const useAdminContext = () =>
  React.useContext<AdminContextProps>(AdminContext);

const AdminContainer = (props: IAdminProps) => {
  const { title, children, loading } = props;

  return (
    <AdminContext.Provider value={{}}>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        {title && (
          <div className="breadcrumb-wrapper-2 mb-2">
            <h2>{title}</h2>
          </div>
        )}
        {children}
        <Backdrop sx={{ color: "#fff", zIndex: 8999999 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </AdminContext.Provider>
  );
};

export default AdminContainer;
