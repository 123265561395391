import { Grid, Image } from "antd";
import ImgLogoWhite from "assets/images/logo/logo_retangle_white.svg";
import { Link } from "react-router-dom";

const Logo = () => {
  const screens = Grid.useBreakpoint();

  return (
    <Link to="/" title="Trang chủ">
      <Image
        alt="haistore logo"
        width={screens.md ? 160 : 120}
        height="auto"
        preview={false}
        src={ImgLogoWhite}
      />
    </Link>
  );
};

export default Logo;
