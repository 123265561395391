import styled from "@emotion/styled";
import { Input } from "antd";
import { searchTextAtom } from "components/Header/atoms/headerAtom";
import { useAtom } from "jotai";
import { Search } from "lucide-react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ProductActions } from "redux/actions";

const ContainerStyle = styled(Input)`
  .ant-input-suffix {
    cursor: pointer;

    &:hover {
      color: var(--color-primary);
    }
  }
`;

const SearchBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useAtom(searchTextAtom);

  // Handle - press enter search bar
  const handleEnter = useCallback(
    (e: any) => {
      (e.key === "Enter" || e.keyCode === 13) && handleSearchProduct();
    },
    [searchText]
  );

  // Handle - search
  const handleSearchProduct = useCallback(() => {
    dispatch(ProductActions.searchProductName.request(searchText));
    history.push({
      pathname: "/products",
      search: `?searchName=${searchText}`,
    });
  }, [searchText]);

  return (
    <ContainerStyle
      placeholder="Nhập thông tin sản phẩm bạn muốn mua..."
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      onKeyUp={(e) => handleEnter(e)}
      suffix={<Search onClick={() => handleSearchProduct()} />}
    />
  );
};

export default SearchBar;
