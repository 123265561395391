import { PayloadAction } from "@reduxjs/toolkit";
import { createHandleReducer } from "helpers/reduxHelpers";
import moment from "moment";
import { AppConfigActions } from "redux/actions";
import i18n, { ELanguage } from "utils/i18n";
import { IBaseReducerState } from "utils/redux";
interface IAppState extends IBaseReducerState {
  language: ELanguage;
  prevPath: string;
}

const initialState: IAppState = {
  language: ELanguage.EN,
  action: "",
  prefix: AppConfigActions.prefix,
  prevPath: "",
};

const setPrevPath = (state: IAppState, action: PayloadAction<string>) => {
  state.prevPath = action.payload;
};

const clearPrevPath = (state: IAppState, action: PayloadAction) => {
  state.prevPath = "";
};

const reducer = createHandleReducer(initialState, (builder) => {
  builder
    .addCase(
      AppConfigActions.changeLanguage.request,
      (state: IAppState, action: PayloadAction<ELanguage>) => {
        state.language = action.payload;
        moment.locale(action.payload);
        i18n.changeLanguage(action.payload);
        // window.location.reload();
      }
    )
    .addCase(AppConfigActions.prevPath.request, setPrevPath)
    .addCase(AppConfigActions.clearPrevPath.request, clearPrevPath);
});

export default reducer;
