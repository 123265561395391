import { IUserInfo } from "model/Identity/IUserInfo";
import { ILoginAdmin, IUserRequest } from "models/IIdentity";
import { BaseApiService } from "./BaseApiService";
class IdentityApiService extends BaseApiService {
  public getUserInfo = () => this.get("/connect/userinfo");
  public googleAuthenticate = (token: any) =>
    this.post("/google-authenticate", token);
  public updateUser = (param: IUserRequest) =>
    this.put<any, any>("/update-client-information", param);
  public getAllUser = (params: any) => this.get("/pagination", { params });
  public getUserById = (id: string) => this.get(`/get-user-by-id?id=${id}`);
  public updateUserStatus = (params: any) =>
    this.put(`/update-status-user`, params);
  public facebookAuthenticate = (token: any) =>
    this.post("/facebook-authenticate", token);
  public loginAdmin = (data: ILoginAdmin) =>
    this.post<any>(`/admin-authenticate`, data);
  public getBalanceById = (id: string) =>
    this.get(`/get-account-balance-by-id?id=${id}`);
  public resetPassword = (params: any) => this.put("reset-password", params);
  public registerUser = (params: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    dateOfBirth?: string;
    gender: number;
    address: string;
    city: string;
  }) => this.post<any, IUserInfo>(`/register`, params);
}

export default new IdentityApiService("/identity");
