import { IProduct } from "models/IProductModel";
import { BaseApiService } from "./BaseApiService";

class CrawlSingleLinkApiService extends BaseApiService {
  public pagination = (params: any) => this.get("/pagination", { params });
  public deleteLink = (id: any) =>
    this.delete(`/delete-crawl-automation-single-link?id=${id}`);
  public update = (params: any) =>
    this.put("/update-crawl-automation-single-link", params);
  public createLink = (params: any) =>
    this.post("/create-crawl-automation-single-link", params);
}

export default new CrawlSingleLinkApiService("/crawl-automation-single-link");
