import AdminGuard from "guards/AdminGuard";
import ClientGuard from "guards/ClientGuard";
import PageNotFound from "pages/PageNotFound";
import { Route, Switch } from "react-router-dom";
import { ADMIN_ROUTE, CLIENT_ROUTE, NO_AUTH_ROUTER } from "./const";
import ClientLayout from "layouts/ClientLayout";
import { Login } from "pages/admin";
import { AdminLayout } from "layouts";
import CompactLayout from "layouts/CompatLayout";
import Maintaining from "pages/Maintenance";

export default function RootRouter() {
  return (
    <Switch>
      <Route path={"/admin"} component={Login} />
      {NO_AUTH_ROUTER.map((route, key) => {
        const Page = route.component;
        return (
          <Route
            key={`no-auth-router-${key}`}
            exact
            {...route}
            component={(props) => (
              <ClientLayout>
                <Page {...props} />
              </ClientLayout>
            )}
          />
        );
      })}
      <Route
        exact
        path={ADMIN_ROUTE.map((route) => route.path)}
        component={AdminRoute}
      />

      {CLIENT_ROUTE.map((route, key) => {
        const Page = route.component;

        return (
          <Route
            key={`client-router-${key}`}
            exact
            {...route}
            component={(props) => (
              <ClientGuard>
                <Page {...props} />
              </ClientGuard>
            )}
          />
        );
      })}
      <Route
        path={"/maintenance"}
        component={() => (
          <CompactLayout>
            <Maintaining />
          </CompactLayout>
        )}
      />
      <Route path={"*"} component={PageNotFound} />
    </Switch>
  );
}

const AdminRoute = () => {
  return (
    <AdminGuard>
      <AdminLayout>
        <Switch>
          {ADMIN_ROUTE.map((route) => {
            const Page = route.component;
            return (
              <Route
                exact
                path={route.path}
                component={(props) => (
                  <AdminGuard key={route.id}>
                    <Page {...props} />
                  </AdminGuard>
                )}
              />
            );
          })}
        </Switch>
      </AdminLayout>
    </AdminGuard>
  );
};
