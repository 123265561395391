import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";

// material-ui
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
  const history = useHistory();
  const selected = history.location.pathname === item.path;
  const isOutline = selected ? "" : "-outline";
  const icon = `mdi ${item.icon}${isOutline}`;

  return (
    <ListItemCustomButton
      disabled={item.disabled}
      component={Link}
      sx={{
        borderRadius: `6px`,
        mb: 0.5,
        alignItems: "center",
        backgroundColor: level > 1 ? "transparent !important" : "inherit",
        height: 44,
        "&.Mui-selected": {
          backgroundColor: "#00000050",
          fontWeight: "bold",
          ":hover": {
            backgroundColor: "#00000020",
          },
        },
        "&.Mui-focusVisible": {
          backgroundColor: "#00000020",
        },
        ":hover": {
          backgroundColor: "#00000020",
        },
      }}
      selected={selected}
      to={item.path}
    >
      <ListItemIcon sx={{ minWidth: !item?.icon ? 18 : 26 }}>
        <Typography
          className={icon}
          sx={{
            color: "black",
            marginRight: 1,
            fontSize: 20,
          }}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            sx={{
              fontWeight: selected ? "bold" : "normal",
              color: selected ? "white" : "black",
              fontSize: 15,
            }}
          >
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography variant="caption" display="block" gutterBottom>
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemCustomButton>
  );
};

const ListItemCustomButton = <C extends React.ElementType>(
  props: ListItemButtonProps<C, { component?: C }>
) => {
  return <ListItemButton {...props} />;
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
