import { LazyExoticComponent, Suspense, lazy } from "react";

import AffiliateManagement from "pages/admin/MasterData/Affiliate";
import DealManagement from "pages/admin/DealManagement";
import Deals from "pages/client/DealsPage";
import DiscountManagement from "pages/admin/MasterData/Discount";
import GoogleShopping from "pages/client/GoogleShopping";
import LuckyWheel from "pages/client/LuckyWheel";
import TrackingOrder from "pages/admin/Operation/TrackingOrder";
import Updating from "pages/admin/Updating";
import Maintaining from "pages/Maintenance";

const Loadable = (Component: LazyExoticComponent<() => JSX.Element>) => () => {
  return (
    <Suspense
      fallback={
        <div id="ec-overlay">
          <span className="loader_img"></span>
        </div>
      }
    >
      <Component />
    </Suspense>
  );
};

//Lazy import client page
const AboutUs = Loadable(lazy(() => import("pages/client/AboutUs")));
const Balance = Loadable(lazy(() => import("pages/client/Balance")));
const Cart = Loadable(lazy(() => import("pages/client/Cart")));
const CheckOut = Loadable(lazy(() => import("pages/client/CheckOut")));
const ContactUs = Loadable(lazy(() => import("pages/client/ContactUs")));
const GetQuote = Loadable(lazy(() => import("pages/client/GetQuote")));
const Home = Loadable(lazy(() => import("pages/client/HomePage")));
const NewLogin = Loadable(lazy(() => import("pages/client/NewLogin")));
const OrderComplete = Loadable(
  lazy(() => import("pages/client/OrderComplete"))
);
const OrderCustomer = Loadable(
  lazy(() => import("pages/client/OrderCustomer"))
);
const OrderList = Loadable(lazy(() => import("pages/client/OrderList")));
const OrderSuccess = Loadable(lazy(() => import("pages/client/OrderSuccess")));
const OrderTracking = Loadable(
  lazy(() => import("pages/client/OrderTracking"))
);
const ProductItem = Loadable(lazy(() => import("pages/client/ProductItem")));
const Products = Loadable(lazy(() => import("pages/client/Products")));

const PurchasePolicy = Loadable(
  lazy(() => import("pages/client/PurchasePolicy"))
);

const QuestionAndAnswer = Loadable(lazy(() => import("pages/client/Q&A")));
const ReceiveQuoteGuide = Loadable(
  lazy(() => import("pages/client/ReceiveQuoteGuide"))
);
const ShoppingGuide = Loadable(
  lazy(() => import("pages/client/ShoppingGuide"))
);
const UserLogin = Loadable(lazy(() => import("pages/client/UserLogin")));
const UserProfile = Loadable(lazy(() => import("pages/client/UserProfile")));
const WarrantyPolicy = Loadable(
  lazy(() => import("pages/client/WarrantyPolicy"))
);
const InstallmentPolicy = Loadable(
  lazy(() => import("pages/client/InstallmentPolicy"))
);
const Wishlist = Loadable(lazy(() => import("pages/client/Wishlist")));
const ReturnPolicy = Loadable(lazy(() => import("pages/client/ReturnPolicy")));
const RegisterAffiliate = Loadable(
  lazy(() => import("pages/client/RegisterAffiliate"))
);
const RegisterAffiliateSuccess = Loadable(
  lazy(() => import("pages/client/RegisterAffiliateSuccess"))
);

//Lazy import admin page
const AddNewProduct = Loadable(lazy(() => import("pages/admin/AddNewProduct")));
const CategoryConfiguration = Loadable(
  lazy(() => import("pages/admin/Configuration/CategoryConfiguration"))
);
const CodeTypeManagement = Loadable(
  lazy(() => import("pages/admin/Configuration/CodeTypeManagement"))
);
const CrawlData = Loadable(lazy(() => import("pages/admin/Crawl/CrawlData")));
const CrawlMultipleLink = Loadable(
  lazy(() => import("pages/admin/Crawl/CrawlMultipleLink"))
);
const CrawlSingleLink = Loadable(
  lazy(() => import("pages/admin/Crawl/CrawlSingleLink"))
);

const OrderManagement = Loadable(
  lazy(() => import("pages/admin/Operation/OrderManagement"))
);
const PolicyManagement = Loadable(
  lazy(() => import("pages/admin/PolicyManagement"))
);
const ProductDetail = Loadable(lazy(() => import("pages/admin/ProductDetail")));
const ProductManagement = Loadable(
  lazy(() => import("pages/admin/MasterData/ProductManagement"))
);
const RateConfiguration = Loadable(
  lazy(() => import("pages/admin/Configuration/RateConfiguration"))
);
const ShipmentDetail = Loadable(
  lazy(() => import("pages/admin/ShipmentDetail"))
);
const ShipmentManagement = Loadable(
  lazy(() => import("pages/admin/Operation/ShipmentManagement"))
);
const ShippingSurchargeManagement = Loadable(
  lazy(() => import("pages/admin/Configuration/ShippingSurchargeManagement"))
);
const TopDealsConfiguration = Loadable(
  lazy(() => import("pages/admin/WebConfiguration/TopDealsConfiguration"))
);
const UserManagement = Loadable(
  lazy(() => import("pages/admin/UserManagement"))
);
const CollaboratorManagement = Loadable(
  lazy(() => import("pages/admin/UserManagement"))
);
const ConfigurationsManagement = Loadable(
  lazy(() => import("pages/admin/Configuration/ConfigurationsManagement"))
);
const SellerConfiguration = Loadable(
  lazy(() => import("pages/admin/Configuration/SellerConfiguration"))
);
const HangFireConfiguration = Loadable(
  lazy(() => import("pages/admin/Configuration/HangFireConfiguration"))
);
const GuaranteeConfiguration = Loadable(
  lazy(() => import("pages/admin/Configuration/GuaranteeConfiguration"))
);
const AffiliateRegister = Loadable(
  lazy(() => import("pages/admin/MasterData/AffiliateRegister"))
);
const Login = Loadable(lazy(() => import("pages/admin/Login")));
export const NO_AUTH_ROUTER = [
  {
    id: "home",
    path: "/",
    component: Home,
  },
  {
    id: "login",
    path: "/login",
    component: UserLogin,
  },
  {
    id: "contact-us",
    path: "/contact-us",
    component: ContactUs,
  },
  {
    id: "about-us",
    path: "/about-us",
    component: AboutUs,
  },
  {
    id: "products",
    path: "/products",
    component: Products,
  },
  {
    id: "product-item",
    path: "/product-item",
    component: ProductItem,
  },
  {
    id: "cart",
    path: "/cart",
    component: Cart,
  },
  {
    id: "warranty-policy",
    path: "/warranty-policy",
    component: WarrantyPolicy,
  },
  {
    id: "purchase-policy",
    path: "/purchase-policy",
    component: PurchasePolicy,
  },
  {
    id: "shopping-guide",
    path: "/shopping-guide",
    component: ShoppingGuide,
  },
  {
    id: "receive-quote-guide",
    path: "/receive-quote-guide",
    component: ReceiveQuoteGuide,
  },
  {
    id: "question-and-answer",
    path: "/question-and-answer",
    component: QuestionAndAnswer,
  },
  {
    id: "get-quote",
    path: "/get-quote",
    component: GetQuote,
  },
  {
    id: "checkout",
    path: "/checkout",
    component: CheckOut,
  },
  {
    id: "order-success",
    path: "/order-success",
    component: OrderSuccess,
  },
  {
    id: "tracking-order",
    path: "/tracking-order",
    component: OrderTracking,
  },
  {
    id: "order-customer",
    path: "/order-customer",
    component: OrderCustomer,
  },
  {
    id: "user-profile",
    path: "/user-profile",
    component: UserProfile,
  },
  {
    id: "balance",
    path: "/balance",
    component: Balance,
  },
  {
    id: "select-type-login",
    path: "/select-type-login",
    component: NewLogin,
  },
  {
    id: "order-complete",
    path: "/order-complete",
    component: OrderComplete,
  },
  {
    id: "order-tracking",
    path: "/order-tracking",
    component: OrderList,
  },
  {
    id: "return-policy",
    path: "/return-policy",
    component: ReturnPolicy,
  },
  {
    id: "installment-policy",
    path: "/installment-policy",
    component: InstallmentPolicy,
  },
  {
    id: "register-affiliate",
    path: "/register-affiliate",
    component: RegisterAffiliate,
  },
  {
    id: "register-affiliate-success",
    path: "/register-affiliate-success",
    component: RegisterAffiliateSuccess,
  },
  // {
  //   id: "lucky-wheel",
  //   path: "/lucky-wheel",
  //   component: LuckyWheel,
  // },
  {
    id: "deals",
    path: "/deals",
    component: Deals,
  },
  {
    id: "google-shopping",
    path: "/google-shopping",
    component: GoogleShopping,
  },
];

export const CLIENT_ROUTE = [
  {
    id: "wishlist",
    path: "/wishlist",
    component: Wishlist,
  },
];

export const ADMIN_ROUTE = [
  {
    id: "main-dashboard",
    path: "/main-dashboard",
    component: Updating,
  },
  {
    id: "order-management",
    path: "/order-management",
    component: OrderManagement,
  },
  {
    id: "auto-tracking-order",
    path: "/auto-tracking-order",
    component: TrackingOrder,
  },
  {
    id: "shipment-management",
    path: "/shipment-management",
    component: ShipmentManagement,
  },
  {
    id: "product-management",
    path: "/product-management",
    component: ProductManagement,
  },
  {
    id: "user-management",
    path: "/user-management",
    component: UserManagement,
  },
  {
    id: "affiliate-management",
    path: "/affiliate-management",
    component: AffiliateManagement,
  },
  {
    id: "affiliate-register-management",
    path: "/affiliate-register-management",
    component: AffiliateRegister,
  },
  {
    id: "discount-management",
    path: "/discount-management",
    component: DiscountManagement,
  },
  {
    id: "ticket-management",
    path: "/ticket-management",
    component: UserManagement,
  },
  {
    id: "product-detail",
    path: "/product-detail",
    component: ProductDetail,
  },
  {
    id: "shipment-detail",
    path: "/shipment-detail",
    component: ShipmentDetail,
  },
  {
    id: "configurations",
    path: "/configurations",
    component: ConfigurationsManagement,
  },
  {
    id: "crawlData",
    path: "/crawl-data",
    component: CrawlData,
  },
  {
    id: "admin",
    path: "/admin",
    component: Login,
  },
  {
    id: "edit-product",
    path: "/edit-product",
    component: AddNewProduct,
  },
  {
    id: "rate-configuration",
    path: "/rate-configuration",
    component: RateConfiguration,
  },
  {
    id: "category-configuration",
    path: "/category-configuration",
    component: CategoryConfiguration,
  },
  {
    id: "top-deals-configuration",
    path: "/top-deals-configuration",
    component: TopDealsConfiguration,
  },
  {
    id: "shipping-surcharge-management",
    path: "/shipping-surcharge-management",
    component: ShippingSurchargeManagement,
  },
  {
    id: "crawl-single-link-management",
    path: "/crawl-single-link-management",
    component: CrawlSingleLink,
  },
  {
    id: "crawl-multiple-link-management",
    path: "/crawl-multiple-link-management",
    component: CrawlMultipleLink,
  },
  {
    id: "code-type-management",
    path: "/code-type-management",
    component: CodeTypeManagement,
  },
  {
    id: "policy-management",
    path: "/policy-management",
    component: PolicyManagement,
  },
  {
    id: "seller-configuration",
    path: "/seller-configuration",
    component: SellerConfiguration,
  },
  {
    id: "hang-fire-configuration",
    path: "/hang-fire-configuration",
    component: HangFireConfiguration,
  },
  {
    id: "guarantee-configuration",
    path: "/guarantee-configuration",
    component: GuaranteeConfiguration,
  },
  {
    id: "deal-management",
    path: "/deal-management",
    component: DealManagement,
  },
];
