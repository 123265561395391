import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw, ContentState } from "draft-js";

type TContentInput = {
  setValue: (value: string) => void;
};

function ContentInput(props: TContentInput) {
  const { setValue } = props;
  const handleSetValue = (value: any) => {
    setValue(draftToHtml(convertToRaw(value.getCurrentContent())));
  };
  return (
    <Editor
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      onEditorStateChange={handleSetValue}
    />
  );
}
export default ContentInput;
