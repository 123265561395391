import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import "./styles.css";

type TGoogleButton = {
  onSuccess?: (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {};
  onFailure?: (error: any) => {};
};

const index = (props: TGoogleButton) => {
  const { onFailure, onSuccess } = props;
  return (
    <GoogleLogin
      clientId="326412531502-54neoo612qamq37j8pjgn2l80clk0rko.apps.googleusercontent.com"
      buttonText="Google Login"
      onSuccess={onSuccess}
      onFailure={onFailure}
      render={(render) => {
        return (
          <button
            className="fb-login-button d-flex flex-wrap gap-2 align-items-center justify-content-center"
            onClick={render.onClick}
            disabled={render.disabled}
          >
            <img
              src={"assets/images/logo/google-icon.svg"}
              alt="React Logo"
              style={{
                width: "26px",
                height: "26px",
              }}
            />
            <span className="fb-login-button-title">Đăng nhập với Google</span>
          </button>
        );
      }}
      className="login-fb"
    />
  );
};

export default index;
