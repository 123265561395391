import {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from "react-facebook-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import "./styles.css";

type TFacebookLoginButton = {
  onSuccess: (
    userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse
  ) => {};
  onFailure?: (response: ReactFacebookFailureResponse) => {};
};

const index = (props: TFacebookLoginButton) => {
  const { onFailure, onSuccess } = props;
  return (
    <FacebookLogin
      appId="772211480780593"
      autoLoad={true}
      fields="name,email,picture"
      callback={onSuccess}
      onFailure={onFailure}
      language="vi"
      cssClass="fb"
      icon="fa-facebook"
      render={(render) => {
        return (
          <button
            className="google-login-button d-flex flex-wrap gap-2 align-items-center justify-content-center"
            onClick={render.onClick}
            disabled={render.isDisabled || !render.isSdkLoaded}
          >
            <img
              src={"assets/images/logo/facebook-icon.svg"}
              alt="React Logo"
              style={{
                width: "26px",
                height: "26px",
              }}
            />
            <span className="google-login-button-title">
              Đăng nhập với Facebook
            </span>
          </button>
        );
      }}
    />
  );
};

export default index;
