import styled from "@emotion/styled";
import { Col, Grid, Image, Layout, Row, Space, Typography } from "antd";
import ImgLogo from "assets/images/logo/logo_retangle_black.png";
import ImgFb from "assets/images/icon/icon_fb.png";
import ImgZalo from "assets/images/icon/icon_zalo.png";
import ImgTiktok from "assets/images/icon/icon_tiktok.png";
import ImgYoutube from "assets/images/icon/icon_youtube.png";

const ContainerStyle = styled(Layout.Footer)`
  display: flex;
  justify-content: center;
  background-color: var(--color-bg-grey-light);
  padding: 1rem 0 2rem 0;
`;

const ContentStyle = styled(Row)`
  padding: 0 2rem;
`;

const LogoStyle = styled(Image)`
  margin: 1rem 0 0.5rem 0;
  cursor: pointer;
  max-width: 150px;
`;

const MenuTitleStyle = styled(Typography.Title)`
  margin-top: 1rem;
  &.ant-typography {
    font-weight: bold;
    color: var(--color-primary);
  }
`;

const MenuLinkStyle = styled(Typography.Link)`
  &.ant-typography {
    color: var(--ant-color-text);

    &:hover {
      color: var(--color-primary);
    }
  }
`;

const IconSocialStyle = styled(Image)`
  cursor: pointer;
  width: 24px !important;
`;

const FOOTER_SOCIALS = [
  {
    icon: ImgFb,
    url: "https://www.facebook.com/haistore.vnchuyensansalehangMy",
  },
  {
    icon: ImgZalo,
    url: "https://zalo.me/haistorevn24",
  },
  {
    icon: ImgTiktok,
    url: "https://www.tiktok.com/@haistore.vn",
  },
  {
    icon: ImgYoutube,
    url: "https://www.youtube.com/@conghai105",
  },
];

const FOOTER_MENUS = [
  {
    title: "Thông tin",
    subMenus: [
      {
        title: "Về chúng tôi",
        url: "/about-us",
      },
      {
        title: "Theo dõi đơn hàng",
        url: "/tracking-order",
      },
      {
        title: "Liên hệ tư vấn",
        url: "/contact-us",
      },
      {
        title: "Báo giá tự động ebay",
        url: "/get-quote",
      },
    ],
  },
  {
    title: "Tài khoản",
    subMenus: [
      {
        title: "Đăng nhập",
        url: "/login",
      },
      {
        title: "Lịch sử mua hàng",
        url: "/order-customer",
      },
      {
        title: "Sản phẩm yêu thích",
        url: "/wishlist",
      },
      {
        title: "Đăng ký cộng tác viên",
        url: "/register-affiliate",
      },
    ],
  },
  {
    title: "Dịch vụ",
    subMenus: [
      {
        title: "Chính sách mua hàng",
        url: "/purchase-policy",
      },
      {
        title: "Chính sách bảo hành",
        url: "/return-policy",
      },
      {
        title: "Chính sách trả góp",
        url: "/installment-policy",
      },
      {
        title: "Hướng dẫn mua hàng",
        url: "/shopping-guide",
      },
    ],
  },
  {
    title: "Sản phẩm",
    subMenus: [
      {
        title: "Sản phẩm giá sốc",
        url: "/products?dailyDeal=1&sortId=2&sortDirection=ascend&sortField=name",
      },
      {
        title: "Sản phẩm săn sale",
        url: "/deals?type=full",
      },
      {
        title: "Sản phẩm giảm giá",
        url: "/products?status=2&sortId=4&sortDirection=descend&sortField=discount&dailyDeal=2&searchName=",
      },
      {
        title: "",
        component: (
          <Space>
            {FOOTER_SOCIALS.map((icon) => (
              <Typography.Link href={icon.url}>
                <IconSocialStyle
                  width="100%"
                  height="auto"
                  preview={false}
                  src={icon.icon}
                />
              </Typography.Link>
            ))}
          </Space>
        ),
      },
    ],
  },
];

const Footer = () => {
  const screens = Grid.useBreakpoint();

  return (
    <ContainerStyle>
      <ContentStyle className="container" gutter={16}>
        {/* Footer - main information */}
        <Col span={screens.lg ? 8 : 24}>
          <Space direction="vertical">
            <LogoStyle
              width="100%"
              height="auto"
              preview={false}
              src={ImgLogo}
            />
            <Typography.Text>
              <b>Địa chỉ: </b>
              18 Thân Văn Nhiếp, P.An Phú, TP.Thủ Đức
            </Typography.Text>
            <Typography.Text>
              <b>Điện thoại: </b>
              0903.392.492
            </Typography.Text>
            <Typography.Text>
              <b>Email: </b>
              sub.haistore@gmail.com
            </Typography.Text>
          </Space>
        </Col>

        {/* Footer - menu links */}
        {FOOTER_MENUS.map((menu) => {
          return (
            <Col span={screens.lg ? 4 : 24}>
              <MenuTitleStyle level={5}>{menu.title}</MenuTitleStyle>
              <Space direction="vertical">
                {menu.subMenus.map(
                  (subMenu) =>
                    subMenu.component || (
                      <MenuLinkStyle href={subMenu.url}>
                        {subMenu.title}
                      </MenuLinkStyle>
                    )
                )}
              </Space>
            </Col>
          );
        })}
      </ContentStyle>
    </ContainerStyle>
  );
};

export default Footer;
