import { PayloadAction } from "@reduxjs/toolkit";
import { ToastHelper } from "helpers";
import { AlertType, showAlert } from "helpers/alertHelper";
import _ from "lodash";
import {
  ICreateWishlistRequest,
  IDeleteWishlistRequest,
} from "models/IWishlist";
import { call, put, takeEvery } from "redux-saga/effects";
import { ProductActions, WishlistActions } from "redux/actions";
import { ProductApiService } from "services";
import { IApiResponse } from "services/BaseApiService";
import WishlistApiService from "services/WishlistApiService";

function* createWishlistItem(action: PayloadAction<ICreateWishlistRequest>) {
  try {
    const response: IApiResponse<any> = yield call(
      WishlistApiService.createWishlistItem,
      action.payload
    );
    if (response) {
      if (response.succeeded && response.data) {
        yield put(WishlistActions.createWishlistItem.success());
        ToastHelper.toastSuccess("Thêm mục ưa thích thành công");
      } else {
        yield put(WishlistActions.createWishlistItem.failed(response));
        ToastHelper.toastError("Thêm mục quan tâm thất bại");
      }
    }
  } catch (error) {
    ToastHelper.toastError("Thêm mục quan tâm thất bại");
  }
}

function* deleteWishlistItem(action: PayloadAction<IDeleteWishlistRequest>) {
  try {
    const response: IApiResponse<any> = yield call(
      WishlistApiService.deleteWishlistItem,
      action.payload
    );
    if (response) {
      if (response.succeeded && response.data) {
        yield put(WishlistActions.deleteWishlistItem.success());
        ToastHelper.toastSuccess("Xóa thành công");
      } else {
        yield put(WishlistActions.deleteWishlistItem.failed(response));
        ToastHelper.toastError("Xóa thất bại");
      }
    }
  } catch (error) {
    ToastHelper.toastError("Xóa thất bại");
  }
}

export function* Watcher() {
  yield takeEvery(
    WishlistActions.createWishlistItem.request,
    createWishlistItem
  );
  yield takeEvery(
    WishlistActions.deleteWishlistItem.request,
    deleteWishlistItem
  );
}
