export const antdTheme = {
  cssVar: { key: "app" },
  hashed: false,
  token: {
    colorPrimary: "#0046BE",
    colorBgContainer: "#FFFFFF",

    fontFamily: "SFProDisplay",
    fontWeight: 500,
  },
  components: {
    Layout: {
      headerPadding: "0",
      bodyBg: "#FFFFFF",
      algorithm: true,
    },
    Menu: {
      fontSize: 16,
    },
    Tag: {
      fontSizeSM: 14,
    },
    Button: {
      controlHeight: 36,
    },
    Tree: {
      nodeSelectedBg: "#0046BE",
    },
    Input: {
      controlHeight: 40,
    },
  },
};

export const antdBp = {
  xl: "1450px",
  lg: "1200px",
  md: "768px",
  sm: "576px",
  xs: "380px",
};
