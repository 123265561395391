import React from "react";
import { Fab } from "@mui/material";
import { Call } from "@mui/icons-material";

export default function HotlineFloatingButton() {
  return (
    <Fab
      color="primary"
      aria-label="hotline-floating-button"
      onClick={() => window.open("tel:+84819153456", "_target")}
    >
      <Call />
    </Fab>
  );
}
