import styled from "@emotion/styled";
import { Flex, Image, Layout } from "antd";
import ImgHeaderTopbar from "assets/images/banner/header_banner_topbar.png";
import { useHistory } from "react-router-dom";
import { antdBp } from "themes/antdTheme";
import Logo from "./components/Logo";
import Menus from "./components/Menus";
import SearchBar from "./components/SearchBar";
import { Fragment } from "react";

const TopBannerStyle = styled(Image)`
  cursor: pointer;

  @media screen and (max-width: ${antdBp.sm}) {
    display: flex;
  }
`;

const ContainerStyle = styled(Layout.Header)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 76px;
  background: var(--color-primary);
`;

const ContentStyle = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;

  @media screen and (max-width: ${antdBp.md}) {
    gap: 10px;
  }
`;

const LogoSearchStyle = styled(Flex)`
  align-items: center;
  gap: 40px;
  width: 100%;
  height: fit-content;

  @media screen and (max-width: ${antdBp.md}) {
    gap: 20px;
  }
`;

const Header = () => {
  const history = useHistory();

  return (
    <Fragment>
      {/* Top bar - banner minigame */}
      {/* <TopBannerStyle
        alt="haistore minigame"
        preview={false}
        src={ImgHeaderTopbar}
        onClick={() => history.push("/lucky-wheel")}
      /> */}

      {/* Header bar */}
      <ContainerStyle>
        <ContentStyle className="container">
          {/* Left Block - logo & search bar */}
          <LogoSearchStyle>
            {/* Logo */}
            <Logo />

            {/* Search bar */}
            <SearchBar />
          </LogoSearchStyle>

          {/* Right Block - list menu */}
          <Menus />
        </ContentStyle>
      </ContainerStyle>
    </Fragment>
  );
};

export default Header;
