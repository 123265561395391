import { IProduct } from "models/IProductModel";
import { BaseApiService } from "./BaseApiService";

class DailyDealPositionApiService extends BaseApiService {
  public getAllDailyDealPosition = () =>
    this.get<IProduct>(`/get-all-dailydeal-position`);
  public createDailyDealPosition = (params: {
    listPostionDailyRequest: {
      productId: string;
      position: number;
    }[];
  }) => this.post<any>(`/create-dailydeal-position`, params);
}

export default new DailyDealPositionApiService("/dailydeal-position");
