import { BaseApiService } from "./BaseApiService";

class SubCategoryApiService extends BaseApiService {
  public getAll = () => this.get<any>("/get-all-sub-categories");
  public getById = (id: string) =>
    this.get<any>(`/get-sub-categories-by-category-id?categoryId=${id}`);
  public create = (params: any) => this.post("/create-sub-categories", params);
  public update = (params: any) => this.put("/update-sub-categories", params);
  public deleteSubCategory = (id: any) =>
    this.delete(`/delete-sub-categories?id=${id}`);
}

export default new SubCategoryApiService("/sub-categories");
