import { IProduct } from "models/IProductModel";
import { BaseApiService } from "./BaseApiService";

class CrawlMultipleLinkApiService extends BaseApiService {
  public pagination = (params: any) => this.get("/pagination", { params });
  public deleteLink = (id: any) =>
    this.delete(`/delete-crawl-automation-multiple-link?id=${id}`);
  public update = (params: any) =>
    this.put("/update-crawl-automation-multiple-link", params);
  public createLink = (params: any) =>
    this.post("/create-crawl-automation-multiple-link", params);
}

export default new CrawlMultipleLinkApiService(
  "/crawl-automation-multiple-link"
);
