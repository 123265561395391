import { BaseApiService } from "./BaseApiService";

class AffiliateApiService extends BaseApiService {
  public getAllRequestedAffiliates = (params: any) =>
    this.get<any>(`/get-all-requested-affiliates`, { params });
  public getAllAffiliates = (params: any) =>
    this.get<any>("/get-all-affiliates", { params });
  public getAffiliateById = (params: any) =>
    this.get<any>("/get-affiliate-by-id", { params });
  public getAffiliateByRefCode = (params: any) =>
    this.get<any>("/get-affiliate-by-ref-code", { params });
  public registerAffiliate = (params: { userId: string }) =>
    this.post<any>("/register-affiliate", params);
  public approveAffiliate = (params: any) =>
    this.put<any>("/approve-affiliate", params);
  public updateAffiliate = (params: any) =>
    this.put<any>("/update-affiliate", params);
  public deleteAffiliate = (params: any) =>
    this.delete(`/delete-affiliate`, params);
}

export default new AffiliateApiService("/affiliates");
