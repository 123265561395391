import styled from "@emotion/styled";
import { Col, Drawer, Grid, Row } from "antd";
import Breadcrumb from "components/Breadcrumb";
import { useQuery } from "hooks/useQuery";
import { useAtom, useSetAtom } from "jotai";
import { Fragment, useEffect } from "react";
import {
  filterQueryAtom,
  isFullCategoryAtom,
  isOpenFilterMobileAtom,
} from "./atoms/dealsAtom";
import DealList from "./components/DealList";
import Filters from "./components/Filters";
import SortBar from "./components/SortBar";

const ContainerStyle = styled(Row)`
  flex-direction: column;
  padding-top: 0.5rem;
`;

const DrawerStyle = styled(Drawer)`
  .ant-drawer-body {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
`;

const DealsPage = () => {
  const params = useQuery();
  const screens = Grid.useBreakpoint();
  const setIsFullCategory = useSetAtom(isFullCategoryAtom);
  const setFilterQuery = useSetAtom(filterQueryAtom);
  const [isOpenFilterMobile, setIsOpenFilterMobile] = useAtom(
    isOpenFilterMobileAtom
  );

  // Initial loading - check is flash sale & set filter query with sort query
  useEffect(() => {
    setIsFullCategory(params.get("type") === "full" ? true : false);
    setFilterQuery({
      optionPrice: params.get("optionPrice") || undefined,
      optionSort: params.get("optionSort") || undefined,
      optionDealStatus: params.getAll("optionDealStatus") || [],
      optionCategory: params.get("optionCategory") || [],
      optionSeller: params.getAll("optionSeller") || [],
      optionBrand: params.getAll("optionBrand") || [],
      optionCondition: params.getAll("optionCondition") || [],
      optionRAMSize: params.getAll("optionRAMSize") || [],
      optionProcessor: params.getAll("optionProcessor") || [],
      optionScreenSize: params.getAll("optionScreenSize") || [],
      optionGPU: params.getAll("optionGPU") || [],
      optionSSDCapacity: params.getAll("optionSSDCapacity") || [],
      optionHardDriveCapacity: params.getAll("optionHardDriveCapacity") || [],
      optionOperatingSystem: params.getAll("optionOperatingSystem") || [],
    });
  }, []);

  return (
    <Fragment>
      {/* Breadcrumb */}
      <Breadcrumb title="Flash Sale" />

      {/* Section */}
      <ContainerStyle className="container">
        {/* Drawer - filter for mobile screens */}
        <DrawerStyle
          title="Bộ lọc sản phẩm"
          open={isOpenFilterMobile}
          placement="left"
          destroyOnClose={true}
          onClose={() => setIsOpenFilterMobile(false)}
        >
          <Filters />
        </DrawerStyle>

        {/* Row - filters & deals */}
        <Row gutter={20}>
          {/* Left column - filters */}
          <Col span={screens.lg ? 5 : 0}>
            <Filters />
          </Col>

          {/* Right column - sort bar & list deal */}
          <Col span={screens.lg ? 19 : 24}>
            {/* Bar - sort */}
            <SortBar />

            {/* List - deals */}
            <DealList />
          </Col>
        </Row>
      </ContainerStyle>
    </Fragment>
  );
};

export default DealsPage;
