import { Button, Divider, Stack, TextField, Typography } from "@mui/material";
import { FormDialog, HeaderContainer } from "components";
import {
  TableColumName,
  TableFooter,
  TableHeaderSearch,
} from "components/AdminComponents/AdminTable";
import { AlertType, showAlert } from "helpers/alertHelper";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootReducer } from "redux/configurations/rootReducer";
import { CategoryApiService, SubCategoryApiService } from "services";
import { IApiResponse } from "services/BaseApiService";
import "./styles.css";
import AppTable from "components/app-table";
import { TableHelper } from "helpers";
import AdminContainer from "pages/admin/Container";

export default function CategoryConfiguration() {
  const user = useSelector((state: RootReducer) => state.IdentityReducer.admin);

  const [modalVisible, setModalVisible] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [idHover, setIdHover] = useState<string>();
  const [categories, setCategories] = useState<any>([]);
  const [currentCategory, setCurrentCategory] = useState<any>({});
  const [listSubCategoryEdit, setListSubCategoryEdit] = useState<any>([]);
  const [listSubCategory, setListSubCategory] = useState<any>([]);
  const [isAddNew, setIsAddNew] = useState(false);
  const [subCategoryIndex, setSubCategoryIndex] = useState<number>();
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await CategoryApiService.getAllPagination({
        name: searchValue,
        page,
        limit: 10,
      });
      setLoading(false);
      const resData = response as IApiResponse<any>;
      if (resData?.succeeded) {
        setCategories(resData.data);
        setTotalPage(Math.ceil((resData.data?.totalItems || 0) / 10));
      } else {
        showAlert("Get categories failed", AlertType.ERROR);
      }
    })();
  }, [searchValue, page, reload]);

  useEffect(() => {
    if (!modalVisible) {
      setCurrentCategory({});
      setListSubCategoryEdit([]);
    }
  }, [modalVisible]);

  const onConfirmOk = async () => {
    if (_.isEmpty(currentCategory?.id)) {
      setConfirmModal(false);
      showAlert("Vui lòng bấm tạo danh mục cha trước khi lưu", AlertType.ERROR);
      return;
    }
    setLoading(true);

    const createParams = listSubCategoryEdit.filter((x) => _.isEmpty(x.id));
    createParams.forEach(async (x) => {
      const res = await SubCategoryApiService.create({
        ...x,
        subType: null,
        categoryId: currentCategory?.id,
        userId: user?.user?.id,
      });
      if (res?.succeeded) {
        showAlert("Cập nhật thành công", AlertType.SUCCESS);
      } else {
        showAlert("Lỗi cập nhật ", AlertType.ERROR);
      }
    });
    const updateParams = listSubCategoryEdit.filter((x) => !_.isEmpty(x.id));
    if (JSON.stringify(updateParams) !== JSON.stringify(listSubCategory)) {
      // updateParams.forEach(async (x) => {
      const data = _.find(
        updateParams,
        (y, i) => listSubCategory[i].subName !== y.subName
      );
      if (!_.isEmpty(data)) {
        const res = await SubCategoryApiService.update({
          ...data,
          userId: user?.user?.id,
        });
        if (res?.succeeded) {
          showAlert("Cập nhật thành công", AlertType.SUCCESS);
        } else {
          showAlert("Lỗi cập nhật ", AlertType.ERROR);
        }
      }
      // });
    }
    setReload(!reload);
    setLoading(false);
    setModalVisible(false);
    setConfirmModal(false);
  };
  const onDeleteCategory = async () => {
    const response: any = await CategoryApiService.deleteCategory(
      currentCategory.id
    );
    if (response?.succeeded && !_.isEmpty(response?.data)) {
      showAlert("Xóa thành công", AlertType.SUCCESS);
      setReload(!reload);
      setModalVisible(false);
    } else {
      showAlert("Đã có lỗi xảy ra", AlertType.ERROR);
    }
  };
  const handleChangeCategory = async (params: any) => {
    setLoading(true);
    let response: any;
    if (_.isEmpty(params?.id)) {
      response = await CategoryApiService.createCategory(params);
      if (response.succeeded && !_.isEmpty(response?.data)) {
        showAlert("Thêm mới thành công", AlertType.SUCCESS);
        setReload(!reload);
        setCurrentCategory(response?.data);
      } else {
        showAlert("Lỗi tạo danh mục", AlertType.ERROR);
      }
    } else {
      response = await CategoryApiService.updateCategory(params);
      if (response.succeeded && !_.isEmpty(response?.data?.id)) {
        showAlert("Cập nhật thành công", AlertType.SUCCESS);
        setReload(!reload);
      } else {
        showAlert("Lỗi cập nhật ", AlertType.ERROR);
      }
    }
    setLoading(false);
  };

  const onOk = () => {
    setConfirmModal(true);
  };

  const handleClick = (row: any) => {
    setIsAddNew(false);
    setModalVisible(true);
    getSubCategory(row.id);
    setCurrentCategory(row);
  };

  const getSubCategory = async (id: string) => {
    setLoading(true);
    const response = await SubCategoryApiService.getById(id);
    setLoading(false);
    const resData = response as IApiResponse<any>;
    if (resData?.succeeded) {
      setListSubCategory(resData.data);
      setListSubCategoryEdit(
        resData.data?.map((x) => {
          return { ...x };
        })
      );
    } else {
      showAlert("Get sub categories failed", AlertType.ERROR);
    }
  };

  const checkDisableInput = (i: number) => {
    if (
      JSON.stringify(listSubCategoryEdit) !== JSON.stringify(listSubCategory)
    ) {
      return (
        !_.isEmpty(listSubCategory[i]?.id) &&
        i !== subCategoryIndex &&
        listSubCategory[i].subName === listSubCategoryEdit[i].subName
      );
    }
  };

  const onDeleteSubCategory = async (row: any, i: number) => {
    const response: any = await SubCategoryApiService.deleteSubCategory(row.id);
    if (response?.succeeded && !_.isEmpty(response?.data)) {
      showAlert("Xóa thành công", AlertType.SUCCESS);
      const newList = Array.from(listSubCategoryEdit).filter(
        (x: any) => x?.id !== response?.data?.id
      );
      setListSubCategoryEdit(newList);
    } else {
      showAlert("Đã có lỗi xảy ra", AlertType.ERROR);
    }
  };

  const renderTableBody = () => {
    return (
      <table
        id="responsive-data-table"
        className="table dataTable no-footer"
        style={{ width: "100%", cursor: "pointer", marginTop: 16 }}
        aria-describedby="responsive-data-table_info"
      >
        <thead>
          <tr>
            <TableColumName title="STT" />
            <TableColumName title="Danh mục" />
            <TableColumName title="Hành động" />
          </tr>
        </thead>
        <tbody>
          {categories?.results?.map((row: any, index: number) => (
            <tr
              key={row.id}
              style={{
                height: 70,
                backgroundColor:
                  idHover === row.id ? "rgba(61, 133, 198, 0.2)" : "",
              }}
              onMouseEnter={() => setIdHover(row.id)}
              onMouseLeave={() => setIdHover(undefined)}
            >
              <td className="sorting_1">
                <Typography variant="button">{index + 1}</Typography>
              </td>
              <td>
                <Typography>{row.name}</Typography>
              </td>
              <td
                style={{
                  color: "blue",
                  cursor: "pointer",
                  fontWeight: "bold",
                  fontSize: 15,
                }}
                onClick={() => handleClick(row)}
              >
                Chỉnh sửa
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const columns = [
    TableHelper.createColumn({
      field: "name",
      flex: 1,
      headerName: "Tên danh mục",
    }),
  ];
  return (
    <AdminContainer>
      {/* <!-- Container-fluid starts--> */}
      <HeaderContainer
        title={"Danh mục sản phẩm"}
        otherStyle
        rightComponent={
          <button
            style={{
              // color: "white",
              minWidth: 100,
              fontWeight: "bold",
              color: "white",
              backgroundColor: "#0046be",
              borderRadius: 10,
              paddingInline: 8,
              paddingBlock: 4,
            }}
            onClick={() => {
              setIsAddNew(true);
              setModalVisible(true);
            }}
          >
            Thêm mới
          </button>
        }
      />
      <AppTable<any, any, any>
        api={CategoryApiService.getAllPagination}
        columns={columns}
        reload={reload}
        onEditRowClick={handleClick}
      />
      {/* <!-- Container-fluid Ends--> */}
      {/* <!-- Container-fluid starts--> */}
      {/* <div className="row">
        <div className="col-12">
          <div className="card card-default" style={{ borderRadius: 16 }}>
            <div className="card-body">
              <div className="table-responsive">
                <div
                  id="responsive-data-table_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <TableHeaderSearch
                    onSearch={(value) => setSearchValue(value)}
                  />
                  {renderTableBody()}
                  <TableFooter
                    currentPage={categories?.page || 0}
                    onChangePage={(page) => setPage(page)}
                    totalPage={totalPage}
                  />
                  <div className="clear" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <FormDialog
        // maxWidth=""
        cancelText="Đóng"
        visible={modalVisible}
        title={"Chỉnh sửa danh mục"}
        onCancel={() => setModalVisible(false)}
        onOk={() => onOk()}
        open={false}
        // hiddenBtnCancel
        // hiddenBtnOK
        onDelete={isAddNew ? undefined : onDeleteCategory}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
          sx={{ width: "100%", pt: 1 }}
        >
          <Typography sx={{ color: "black", fontWeight: "bold" }}>
            Danh mục cha:
          </Typography>
          <TextField
            sx={{ flex: 1 }}
            value={currentCategory.name}
            onChange={(e) =>
              setCurrentCategory({ ...currentCategory, name: e.target.value })
            }
            variant="standard"
          />
          <Button
            onClick={() =>
              handleChangeCategory({
                id: currentCategory?.id,
                type: currentCategory?.type,
                name: currentCategory?.name,
                userId: user?.user?.id,
              })
            }
            variant="outlined"
            color="primary"
          >
            {isAddNew ? "Tạo" : "Cập nhật"}
          </Button>
        </Stack>
        <Divider sx={{ my: 1, color: "black" }} />
        {!_.isEmpty(listSubCategoryEdit) && (
          <>
            <Stack
              // direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{ width: "100%", pt: 1 }}
            >
              <Typography sx={{ color: "black", fontWeight: "bold" }}>
                Danh mục con
              </Typography>

              <Divider />
            </Stack>
            <Stack
              // direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{ width: "100%" }}
            >
              {listSubCategoryEdit?.map((x: any, i: number) => {
                return (
                  <Stack
                    key={i}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ width: "100%" }}
                  >
                    <TextField
                      sx={{ flex: 1 }}
                      value={x.subName}
                      variant="standard"
                      onChange={async (e) => {
                        setSubCategoryIndex(i);
                        let newList: any = Array.from(listSubCategoryEdit);
                        newList[i].subName = e.target.value;
                        setListSubCategoryEdit(newList);
                      }}
                      disabled={checkDisableInput(i)}
                    />
                    {/* <Button sx={{ width: 90 }} onClick={() => {}} variant="outlined" color="primary">
                      Lưu
                    </Button> */}
                    <Button
                      sx={{ width: 90 }}
                      onClick={() => onDeleteSubCategory(x, i)}
                      variant="outlined"
                      color="error"
                    >
                      Xóa
                    </Button>
                  </Stack>
                );
              })}
            </Stack>
          </>
        )}
        <Button
          sx={{ float: "right", mt: 2, width: 195 }}
          variant="contained"
          disabled={
            !_.isEmpty(listSubCategoryEdit)
              ? _.isEmpty(
                  listSubCategoryEdit[listSubCategoryEdit?.length - 1]?.subName
                )
              : _.isEmpty(currentCategory.name)
          }
          onClick={() => {
            let newList: any = Array.from(listSubCategoryEdit);
            newList.push({
              ...listSubCategoryEdit[0],
              id: undefined,
              subName: "",
            });
            setListSubCategoryEdit(newList);
          }}
        >
          Thêm dòng mới
        </Button>
      </FormDialog>

      <FormDialog
        visible={confirmModal}
        title={"Confirm"}
        onCancel={() => setConfirmModal(false)}
        onOk={() => onConfirmOk()}
        open={false}
      >
        <Typography variant="h6">
          Bạn có chắc muốn thực hiện hành động này?
        </Typography>
      </FormDialog>
      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}

const styles = {
  boxShadow: { boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", p: 1 },
};
