import Root from "Root";
import { Provider as ReduxProvider } from "react-redux";
import { Provider as JotaiProvider } from "jotai";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "redux/configurations/configureStore";
import ThemeProvider from "themes/ThemeContext";
import { ConfigProvider } from "antd";
import { antdTheme } from "themes/antdTheme";
import "./styles/antdVariables.css";
import "utils/i18n/index";
import "./App.css";
import { Loader } from "lucide-react";

const App = () => {
  const { store, persistor } = configureStore();
  return (
    <ReduxProvider store={store}>
      <JotaiProvider>
        <PersistGate persistor={persistor} loading={<Loader />}>
          <ThemeProvider>
            <ConfigProvider theme={antdTheme}>
              <Root />
            </ConfigProvider>
          </ThemeProvider>
        </PersistGate>
      </JotaiProvider>
    </ReduxProvider>
  );
};

export default App;
