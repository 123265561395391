import { ICodeType } from "models/ICodeType";
import { BaseApiService } from "./BaseApiService";

class ConfigurationsApiService extends BaseApiService {
  public getByCode = (code: string) =>
    this.get<ICodeType[]>(`/get-configuration-by-code?code=${code}`);
  public getAll = () => this.get<ICodeType[]>(`/get-all-configurations`);
  public getById = (id: string) =>
    this.get<ICodeType[]>(`/get-configuration-by-id?id=${id}`);
  public updateConfiguration = (param: any) =>
    this.put<any, any>("/update-configuration", param);
  public pagination = (params: any) => this.get("/pagination", { params });
}

export default new ConfigurationsApiService("/configuration");
