import PropTypes from "prop-types";
import { useMemo } from "react";
// @mui
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";
import componentStyleOverrides from "./compStyleOverride";

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const isLight = "light";

  const themeOptions = useMemo(
    () => ({
      // palette: isLight ? palette.light : palette.dark,
      // typography,
      //   breakpoints,
      // shape: { borderRadius: 8 },
      //   direction: themeDirection,
      //   shadows: isLight ? shadows.light : shadows.dark,
      //   customShadows: isLight ? customShadows.light : customShadows.dark,
      palette: {
        primary: {
          main: "#0046be",
        },
        secondary: {
          main: "#ffe000",
        },
        success: {
          main: "#49bd65",
        },
      },
      typography: {
        fontFamily: [
          "Inter, sans-serif",
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        h1: {
          fontSize: "1.5rem",
          fontWeight: 600,
        },
        h2: {
          fontSize: "1.6rem",
          fontWeight: 600,
        },
        h3: {
          fontSize: "1.2rem",
          fontWeight: 600,
        },
        h6: {
          fontSize: "1rem",
          fontWeight: 600,
          lineHeight: "128%",
        },
        allVariants: {
          color: "black",
        },
        button: {
          textTransform: "capitialize",
        },
      },
    }),
    []
  );

  const theme = createTheme(themeOptions as any);
  theme.components = componentStyleOverrides(theme);

  return (
    <MUIThemeProvider theme={theme}>
      {/* <CssBaseline /> */}
      {children}
    </MUIThemeProvider>
  );
}
