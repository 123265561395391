import { Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { FormDialog } from "components";
import { Formik, FormikHelpers } from "formik";
import { ToastHelper } from "helpers";
import { IDiscount } from "model/discount";
import moment from "moment";
import React, { ChangeEvent, useState } from "react";
import { DiscountApiService } from "services";

const UserInfo = (props: {
  title: string;
  name?: string;
  value?: string | number;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  type?: string;
}) => {
  const { title, value, type, disabled, onChange, placeholder } = props;
  return (
    <Stack direction="row" spacing={3}>
      <Typography
        sx={{ fontWeight: 600, paddingTop: 1, minWidth: 150, color: "#000" }}
      >
        {title}
      </Typography>
      <input
        type={type}
        value={value}
        disabled={disabled}
        style={{
          border: "1px solid grey",
          backgroundColor: disabled ? "#f1f1f1" : "white",
        }}
        onChange={onChange}
        placeholder={placeholder}
      ></input>
    </Stack>
  );
};
export default function AddDiscountModal({
  visible,
  onClose,
  onCreateSuccess,
}: {
  visible: boolean;
  onClose: () => void;
  onCreateSuccess: () => void;
}) {
  const [initialValues] = useState<IDiscount>({
    name: "",
    isEnabled: true,
    usePercentage: true,
    discountAmount: 0,
    discountPercentage: 0,
    maximumDiscountAmount: 0,
    maximumDiscountedQuantity: 0,
    startDateUtc: moment().format(),
    endDateUtc: moment().format(),
    discountLimitationType: 0,
    limitationTimes: 0,
    discountType: 1,
  });
  const handelSubmitForm = (
    values: IDiscount,
    formikHelpers: FormikHelpers<IDiscount>
  ) => {
    createDiscount(values);
  };

  const createDiscount = async (values: IDiscount) => {
    const result = await DiscountApiService.createDiscount({
      name: values?.name,
      usePercentage: values?.usePercentage,
      discountPercentage: values?.discountPercentage,
      discountAmount: values?.discountAmount,
      maximumDiscountAmount: values?.maximumDiscountAmount,
      startDateUtc: values?.startDateUtc,
      endDateUtc: values?.endDateUtc,
      isEnabled: values?.isEnabled,
      couponCode: values?.couponCode,
      limitationTimes: values?.limitationTimes,
      discountLimitationType: values?.discountLimitationType,
      discountType: values?.discountType,
    });
    if (result.succeeded) {
      ToastHelper.toastSuccess("Thêm thành công");
      onCreateSuccess();
    } else {
      ToastHelper.toastError(
        "Thêm thất bại, vui lòng liên hệ nhà phát triển để được hỗ trợ  "
      );
    }
  };

  return (
    <Formik<IDiscount>
      initialValues={initialValues}
      onSubmit={handelSubmitForm}
    >
      {({ values, submitForm, setFieldValue }) => {
        const handelValueChange = (
          key: string,
          event: ChangeEvent<HTMLInputElement>
        ) => {
          setFieldValue(key, event.target.value);
        };
        return (
          <FormDialog
            maxWidth={"lg"}
            visible={visible}
            title={"Thêm mã giảm giá"}
            cancelText="Close"
            onCancel={onClose}
            onOk={submitForm}
            open={visible}
          >
            <Grid container spacing={2} columnSpacing={4} sx={{ pt: 2 }}>
              <Grid item md={6} sm={12}>
                <UserInfo
                  title="Tên: "
                  placeholder="Nhập tên mã giảm giá"
                  value={values.name}
                  onChange={(event) => handelValueChange("name", event)}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <UserInfo
                  title="Code: "
                  placeholder="Nhập mã giảm giá"
                  value={values.couponCode}
                  onChange={(event) => handelValueChange("couponCode", event)}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <UserInfo
                  type="number"
                  title="Phần trăm : "
                  value={values.discountPercentage}
                  onChange={(event) =>
                    handelValueChange("discountPercentage", event)
                  }
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <UserInfo
                  type="number"
                  title="Cố định: "
                  value={values.discountAmount}
                  onChange={(event) => {
                    handelValueChange("discountAmount", event);
                  }}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <UserInfo
                  type="number"
                  title="Hoa hồng tối đa: "
                  value={values.maximumDiscountAmount}
                  onChange={(event) => {
                    handelValueChange("maximumDiscountAmount", event);
                  }}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <Stack direction="row" spacing={3}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      paddingTop: 1,
                      minWidth: 150,
                      color: "#000",
                    }}
                  >
                    Loại
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values?.usePercentage ? "enable" : "disable"}
                    label="Age"
                    className="w-100"
                    onChange={(event) => {
                      setFieldValue(
                        "usePercentage",
                        event.target.value === "enable" ? true : false
                      );
                    }}
                  >
                    <MenuItem value={"enable"}>Phần trăm</MenuItem>
                    <MenuItem value={"disable"}>Cố định</MenuItem>
                  </Select>
                </Stack>
              </Grid>
              <Grid item md={6} sm={12}>
                <Stack direction="row" spacing={3}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      paddingTop: 1,
                      minWidth: 150,
                      color: "#000",
                    }}
                  >
                    Ngày bắt đầu
                  </Typography>
                  <DatePicker
                    className="w-100"
                    value={
                      values?.startDateUtc ? moment(values?.startDateUtc) : null
                    }
                    onChange={(newValue) => {
                      setFieldValue("startDateUtc", newValue?.format());
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item md={6} sm={12}>
                <Stack direction="row" spacing={3}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      paddingTop: 1,
                      minWidth: 150,
                      color: "#000",
                    }}
                  >
                    Ngày kết thúc
                  </Typography>
                  <DatePicker
                    className="w-100"
                    value={
                      values?.endDateUtc ? moment(values?.endDateUtc) : null
                    }
                    onChange={(newValue) => {
                      setFieldValue("endDateUtc", newValue?.format());
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item md={6} sm={12}>
                <Stack direction="row" spacing={3}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      paddingTop: 1,
                      minWidth: 150,
                      color: "#000",
                    }}
                  >
                    Hoạt động
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.isEnabled ? "enable" : "disable"}
                    label="Age"
                    className="w-100"
                    onChange={(event) => {
                      setFieldValue(
                        "isEnabled",
                        event.target.value === "enable" ? true : false
                      );
                    }}
                  >
                    <MenuItem value={"enable"}>Mở</MenuItem>
                    <MenuItem value={"disable"}>Tắt</MenuItem>
                  </Select>
                </Stack>
              </Grid>
              <Grid item md={6} sm={12}></Grid>
              <Grid item md={6} sm={12}>
                <Stack direction="row" spacing={3}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      paddingTop: 1,
                      minWidth: 150,
                      color: "#000",
                    }}
                  >
                    Loại giới hạn
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values?.discountLimitationType}
                    label="Age"
                    className="w-100"
                    onChange={(event) => {
                      setFieldValue(
                        "discountLimitationType",
                        event.target.value
                      );
                    }}
                  >
                    <MenuItem value={0}>Không giới hạn</MenuItem>
                    <MenuItem value={1}>Giới hạn</MenuItem>
                    <MenuItem value={2}>Giới hạn theo người dùng</MenuItem>
                  </Select>
                </Stack>
              </Grid>
              <Grid item md={6} sm={12}>
                <UserInfo
                  type="number"
                  title="Số lượng: "
                  value={values?.limitationTimes}
                  disabled={values?.discountLimitationType === 0}
                  onChange={(event) => {
                    setFieldValue("limitationTimes", event.target.value);
                  }}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <Stack direction="row" spacing={3}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      paddingTop: 1,
                      minWidth: 150,
                      color: "#000",
                    }}
                  >
                    Giảm giá theo
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values?.discountType}
                    className="w-100"
                    onChange={(event) => {
                      setFieldValue("discountType", event.target.value);
                    }}
                  >
                    <MenuItem value={1}>Theo tổng hóa đơn</MenuItem>
                    <MenuItem value={2}>Theo sản phẩm</MenuItem>
                    <MenuItem value={5}>Theo danh mục</MenuItem>
                    <MenuItem value={6}>Theo nhãn hàng</MenuItem>
                    <MenuItem value={7}>Theo phân loại</MenuItem>
                    <MenuItem value={8}>Theo seller</MenuItem>
                    <MenuItem value={10}>Theo giá giao hàng</MenuItem>
                    <MenuItem value={20}>Theo giá đơn hàng</MenuItem>
                    <MenuItem value={30}>Tất cả sản phẩm</MenuItem>
                  </Select>
                </Stack>
              </Grid>
            </Grid>
          </FormDialog>
        );
      }}
    </Formik>
  );
}
