import Button from "components/Atoms/Button";
import React from "react";
import { useHistory } from "react-router-dom";

export default function PageNotFound() {
  const history = useHistory();
  return (
    <div className="container" style={{ height: "100vh" }}>
      <div className="flex-1 h-100 frow d-flex justify-content-center align-items-center">
        <div className="p-5 h-100 w-50 flex-column d-flex justify-content-center align-items-center">
          <h1>KHÔNG TÌM THẤY TRANG NÀY</h1>
          <div className="p-5 flex-column d-flex justify-content-center align-items-center">
            <p>
              Có vẻ như bạn đang cố gắng truy cập đến 1 địa chỉ không tồn tại
            </p>
            <p>Hãy kiểm tra và thử lại</p>
          </div>
          <button
            className="btn btn-primary"
            onClick={() => {
              history.push("/");
            }}
          >
            Về trang chủ
          </button>
        </div>
      </div>
    </div>
  );
}
