import { MAIN_MENU } from "common";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const MainMenuMobileView = () => {
  function responsiveMobileMenu() {
    var $ekkaNav = $(".ec-menu-content, .overlay-menu"),
      $ekkaNavSubMenu = $ekkaNav.find(".sub-menu");
    $ekkaNavSubMenu.parent().prepend('<span class="menu-toggle"></span>');

    $ekkaNav.on("click", "li a, .menu-toggle", function (e) {
      var $this = $(this);
      if ($this.attr("href") === "#" || $this.hasClass("menu-toggle")) {
        e.preventDefault();
        if ($this.siblings("ul:visible").length) {
          $this.parent("li").removeClass("active");
          $this.siblings("ul").slideUp();
          $this.parent("li").find("li").removeClass("active");
          $this.parent("li").find("ul:visible").slideUp();
        } else {
          $this.parent("li").addClass("active");
          $this
            .closest("li")
            .siblings("li")
            .removeClass("active")
            .find("li")
            .removeClass("active");
          $this.closest("li").siblings("li").find("ul:visible").slideUp();
          $this.siblings("ul").slideDown();
        }
      }
    });
  }
  useEffect(() => {
    responsiveMobileMenu();
  }, []);

  return (
    <div id="ec-mobile-menu" className="ec-side-cart ec-mobile-menu">
      <div className="ec-menu-title">
        <span className="menu_title">Danh mục</span>
        <button className="ec-close">×</button>
      </div>
      <div className="ec-menu-inner">
        <div className="ec-menu-content">
          <ul>
            {MAIN_MENU.map((x, mainIndex) => (
              <li
                key={`mobile-main-menu-${mainIndex}`}
                className={`${
                  x.path === window.location.pathname ? "active" : ""
                }`}
              >
                <Link to={x.path}>{x.name}</Link>
                {x.menuSub && x.menuSub.length > 0 ? (
                  <ul className="sub-menu">
                    {x.menuSub?.map((a, subIndex) => (
                      <li
                        key={`mobile-sub-menu-${subIndex}`}
                        className={`${
                          a.path === window.location.pathname ? "active" : ""
                        }`}
                      >
                        <Link to={a.path}>{a.name}</Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MainMenuMobileView;
