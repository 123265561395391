/* eslint-disable import/no-anonymous-default-export */
import { BaseApiService } from "./BaseApiService";
import { ISeller } from "models/ISeller";

class SellerApiService extends BaseApiService {
  public getAllSellers = (params: { page: number; limit: number }) =>
    this.get<ISeller[]>("/get-all-sellers", { params });

  public getSellerById = (params: { id: string }) =>
    this.get<any>(`/get-seller-by-id`, { params });

  public createSeller = (params: {
    name: string;
    ssn: string;
    specifiedCategories: string;
    displayOrder: number;
    maxItem: number;
    rate: number;
    userId: string;
    isDisplay: boolean;
  }) => this.post<any>(`/create-seller`, params);

  public updateSeller = (params: {
    id: string;
    name: string;
    ssn: string;
    specifiedCategories: string;
    displayOrder: number;
    maxItem: number;
    rate: number;
    userId: string;
    isDisplay: boolean;
  }) => this.put<any>(`/update-seller`, params);

  public deleteSeller = (params: { id: string }) =>
    this.delete<any>(`/delete-seller`, { params });
}

export default new SellerApiService("/carriers");
