import { confirmModalRef } from "components/confirm-dialog";

export const confirm = (props: {
  message: string;
  isCancel?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}) => {
  const ref = confirmModalRef.getDefault();
  console.log(ref);

  if (ref) {
    ref._handleOpen({
      open: true,
      message: props?.message,
      type: "confirm",
      isCancel: props?.isCancel,
      onConfirm: props?.onConfirm,
      onCancel: props?.onCancel,
    });
  }
};

export const alert = (props: {
  message: string;
  isCancel?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}) => {
  const ref = confirmModalRef.getDefault();
  if (ref) {
    ref._handleOpen({
      open: true,
      message: props?.message,
      type: "alert",
      isCancel: props?.isCancel,
      onConfirm: props?.onConfirm,
      onCancel: props?.onCancel,
    });
  }
};
