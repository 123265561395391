import {
  ActionReducerMapBuilder,
  createAction,
  createReducer,
} from "@reduxjs/toolkit";
import { IApiResponse } from "services/BaseApiService";
import { handleReducerBuilder, IBaseReducerState } from "utils/redux";

export function generateActions<P = void, S = void, F = any>(
  prefix: string,
  action: string
) {
  const actionName = `${prefix}/${action}`;
  const requestName = `${actionName}_REQUEST`;
  const successName = `${actionName}_SUCCESS`;
  const failedName = `${actionName}_FAILED`;
  const request = createAction<P>(requestName);
  const success = createAction<S>(successName);
  const failed = createAction<IApiResponse<any>>(failedName);
  return {
    request,
    success,
    failed,
    actionName,
    failedName,
    successName,
    requestName,
  };
}
export function generateLocalAction<P = void>(prefix: string, action: string) {
  const actionName = `${prefix}/${action}`;
  const request = createAction<P>(actionName + "_LOCAL");
  return {
    request,
    actionName,
  };
}

export function createHandleReducer<S extends IBaseReducerState>(
  initialState: S,
  builderCallback: (builder: ActionReducerMapBuilder<any>) => void
) {
  return createReducer(initialState, (build) => {
    builderCallback(build);
    build.addDefaultCase(handleReducerBuilder);
  });
}
