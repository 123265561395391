import ClientLayout from "layouts/ClientLayout";
import { isEmpty } from "lodash";
import React from "react";
import { Redirect } from "react-router-dom";
import { useAppSelector } from "redux/configurations";

const ClientGuard = ({ children }) => {
  const token = useAppSelector((state) => state.IdentityReducer.admin);
  const isAuthenticated = !isEmpty(token);
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  return <ClientLayout>{children}</ClientLayout>;
};

export default ClientGuard;
