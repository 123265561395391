import React from "react";
import { Box, OutlinedInput, OutlinedInputProps } from "@mui/material";
type AppButtonProps = {
  title?: string;
  isClient?: boolean;
} & OutlinedInputProps;

export default function AppInput(props: AppButtonProps) {
  const { title, isClient, ...rest } = props;
  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column", mb: 2 }}>
      {title && (
        <p
          style={{
            fontSize: "14px",
            padding: 0,
            margin: 0,
            marginBottom: "6px",
            fontWeight: "bold",
          }}
        >
          {title}
        </p>
      )}
      <OutlinedInput
        fullWidth
        sx={{
          height: 40,
          borderWidth: 1,
          borderRadius: isClient ? 0 : "6px",
          backgroundColor: "white",
          borderColor: "#00000050",
          "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1,
            borderColor: isClient ? "#00000050" : "black",
          },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderWidth: isClient ? 1 : 2,
            borderColor: isClient ? "#00000050" : "black",
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderWidth: isClient ? 1 : 2,
              borderColor: isClient ? "#00000050" : "black",
            },
          ...rest.sx,
        }}
        {...rest}
      />
    </Box>
  );
}
