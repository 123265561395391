import { LocalAtm } from "@mui/icons-material";
import { CircularProgress, Typography } from "@mui/material";
import React from "react";

type ButtonVariant =
  | "primary"
  | "secondary"
  | "success"
  | "warning"
  | "info"
  | "danger"
  | "light"
  | "dark";

type TButtonProps = {
  label: string;
  variant?: ButtonVariant;
  outline?: boolean;
  loading?: boolean;
  icon?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export default function AppButton(props: TButtonProps) {
  const { variant = "primary", outline = false, loading, icon, label } = props;
  const type = outline ? "outline-" : "";
  const className = `${props.className} btn btn-${type}${variant}`;

  const renderChildren = () => {
    if (icon) {
      return (
        <div className="d-flex flex-wrap">
          <Typography
            className={icon}
            sx={{
              color: "white",
              marginRight: 1,
              fontSize: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
          <div className="ml-2">{label}</div>
        </div>
      );
    }
    return label;
  };
  return (
    <button disabled={loading} {...props} className={className}>
      {loading ? (
        <CircularProgress sx={{ color: "white" }} size={20} />
      ) : (
        renderChildren()
      )}
    </button>
  );
}
