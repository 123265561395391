import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "components/Atoms/Button";
import React, { PropsWithChildren, useContext } from "react";
// custom loader component

type TAppContext = {
  showModalConfirm?: (
    title: string,
    message: string,
    onConfirm?: () => void,
    onCancel?: () => void
  ) => void;
};

export const DialogContext = React.createContext<TAppContext>({});
export const useDialogContext = () => useContext<TAppContext>(DialogContext);
export const AppConsumer = DialogContext.Consumer;
const DialogProvider = (props: PropsWithChildren<any>) => {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("Title");
  const [message, setMessage] = React.useState("Message");

  const showModalConfirm = (
    title: string,
    message: string,
    onConfirm?: () => void,
    onCancel?: () => void
  ) => {
    console.log("ủa");

    setOpen(true);
    setTitle(title);
    setMessage(message);
    // handleConfirm(onConfirm);
    // handleClose(onCancel);
  };

  const funcs = {
    showModalConfirm: showModalConfirm,
  };
  const setInitialDialog = () => {
    setOpen(false);
    setTitle("Title");
    setMessage("Message");
  };

  const handleClose = (onCancel?: any) => {
    setInitialDialog();
    onCancel?.();
  };
  const handleConfirm = (onConfirm?: any) => {
    setInitialDialog();
    onConfirm?.();
  };
  return (
    <DialogContext.Provider value={{ ...funcs }}>
      {props.children}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleConfirm} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </DialogContext.Provider>
  );
};

export default DialogProvider;
