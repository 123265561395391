import { Fab } from "@mui/material";
import { Image } from "antd";

import MessengerIcon from "assets/images/icon/message.png";

export default function MessageFab() {
  return (
    <Fab
      color="inherit"
      aria-label="messenger"
      sx={{
        backgroundColor: "transparent",
      }}
      onClick={() =>
        window.open("https://m.me/haistore.vnchuyensansalehangMy", "_target")
      }
    >
      <Image src={MessengerIcon} preview={false} />
    </Fab>
  );
}
