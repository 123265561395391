import { AlertActions } from "redux/actions";
import configureStore from "redux/configurations/configureStore";

export enum AlertType {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

export const showAlert = (message: string, type: AlertType) => {
  configureStore().store.dispatch(AlertActions.showAlert.request({ message, type }));
};
export const closeAlert = () => {
  configureStore().store.dispatch(AlertActions.closeAlert.request());
};
export const loadingPage = (loading: boolean) => {
  configureStore().store.dispatch(AlertActions.loadingPage.request({ loading }));
};

