import { BaseApiService } from "./BaseApiService";

interface IGetCategoriesParams {
  parentCategoryId?: string;
  page?: number;
  limit?: number;
}

interface ICUCategoryParams {
  name: string;
  displayOrder?: number;
  parentCategoryId?: string;
  status?: number;
}

interface IGetDealsParams {
  categoryId?: string;
  page?: number;
  limit?: number;
}

interface ICUDealParams {
  name: string;
  displayOrder: number;
  dealStatus: number;
  url: string;
  condition: string;
  categoryId: string;
  specifications: {
    title: string;
    value: string;
  }[];
  prices: {
    guaranteeId: string;
    price: number;
  }[];
  status: number;
}

class DealApiService extends BaseApiService {
  public getCategories = ({
    parentCategoryId,
    page = 1,
    limit = 500,
  }: IGetCategoriesParams) => {
    const queryString = new URLSearchParams({
      ...(parentCategoryId && { parentCategoryId }),
      page: page.toString(),
      limit: limit.toString(),
    }).toString();
    return this.get<any>(`/categories${queryString ? `?${queryString}` : ""}`);
  };

  public getCategoriesPublic = ({
    parentCategoryId,
    page = 1,
    limit = 500,
  }: IGetCategoriesParams) => {
    const queryString = new URLSearchParams({
      ...(parentCategoryId && { parentCategoryId }),
      page: page.toString(),
      limit: limit.toString(),
    }).toString();
    return this.get<any>(
      `/categories/public${queryString ? `?${queryString}` : ""}`
    );
  };

  public createCategory = (params: ICUCategoryParams) => {
    return this.post<any>("/categories", params);
  };

  public updateCategory = (params: ICUCategoryParams) => {
    return this.put<any>("/categories", params);
  };

  public deleteCategory = (categoryId: string) => {
    return this.delete<any>(`/categories?id=${categoryId}`);
  };

  public getDeals = async ({
    categoryId = "",
    page = 1,
    limit = 500,
  }: IGetDealsParams) => {
    let url = "";
    if (categoryId) {
      url = `?categoryId=${categoryId}`;
    }
    if (page) {
      url += `${url ? "&page=" : "?page="}${page.toString()}`;
    }
    if (limit) {
      url += `${url ? "&limit=" : "?limit="}${limit.toString()}`;
    }
    return this.get<any>(url);
  };

  public getDeal = (dealId: string) => {
    return this.get<any>(`/${dealId}`);
  };

  public getDealsPublic = ({ categoryId = "", page = 1, limit = 500 }) => {
    let url = "";
    if (categoryId) {
      url = `?categoryId=${categoryId}`;
    }
    url += `${url ? "&page=" : "?page="}${page.toString()}`;
    url += `${url ? "&limit=" : "?limit="}${limit.toString()}`;
    return this.get<any>(`/public${url}`);
  };

  public getDealsFlashSale = ({ filterQuery = "", page = 1, limit = 500 }) => {
    let url = "";
    if (filterQuery) {
      url += `${filterQuery}`;
    }
    url += `${url ? "&page=" : "page="}${page.toString()}`;
    url += `${url ? "&limit=" : "?limit="}${limit.toString()}`;
    return this.get<any>(`/flash-sales?${url}`);
  };

  public getDealFilters = ({ page = 1, limit = 999 }) => {
    let url = "";
    if (page) {
      url += `${url ? "&page=" : "?page="}${page.toString()}`;
    }
    if (limit) {
      url += `${url ? "&limit=" : "?limit="}${limit.toString()}`;
    }
    return this.get<any>(`/flash-sales/filters${url}`);
  };

  public createDeal = (params: ICUDealParams) => {
    return this.post<any>("", params);
  };

  public updateDeal = (params: ICUDealParams) => {
    return this.put<any>("", params);
  };

  public deleteDeal = (id: string) => {
    return this.delete<any>(`?id=${id}`);
  };
}

export default new DealApiService("/deals");
