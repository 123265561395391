import { ArrowUpwardTwoTone } from "@mui/icons-material";
import { Fab } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function ScrollToTop() {
  const [yOffset, setYOffset] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = (event: Event) => {
    setYOffset(window.pageYOffset);
  };
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Fab
      color="primary"
      aria-label="hotline-floating-button"
      onClick={handleScrollToTop}
      sx={{
        position: "fixed",
        bottom: 30,
        left: 30,
        display: yOffset > 20 ? "block" : "none",
      }}
    >
      <ArrowUpwardTwoTone />
    </Fab>
  );
}
