import * as React from "react";
import { IMaskInput } from "react-imask";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props: any, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="#000000000"
        definitions={{
          "#": /[0-9]/,
        }}
        inputRef={ref}
        onAccept={(value, ref) => {
          return onChange({
            target: { name: props.name, value: ref.unmaskedValue },
          });
        }}
        overwrite
      />
    );
  }
);
