import { ReduxHelper } from "helpers";
import { AlertType } from "helpers/alertHelper";

export const prefix = "APP";
export const showAlert = ReduxHelper.generateLocalAction<{
  message: string;
  type: AlertType;
}>(prefix, "SHOW_ALERT");
export const closeAlert = ReduxHelper.generateLocalAction(
  prefix,
  "CLOSE_ALERT"
);
export const loadingPage = ReduxHelper.generateLocalAction<{
  loading: boolean;
}>(prefix, "LOADING_PAGE");
