import "./styles.css";

import { AlertType, showAlert } from "helpers/alertHelper";
import {
  Box,
  Divider,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  CodeTypeApiService,
  OrderApiService,
  ShipmentApiService,
} from "services";
import { DateHelper, TableHelper } from "helpers";
import { IShipment, IShipmentDetailHistory } from "models/IShipment";
import { useEffect, useMemo, useState } from "react";

import AdminContainer from "../../Container";
import AppTable from "components/app-table";
import { FormDialog } from "components";
import { ICodeType } from "models/ICodeType";
import Paper from "@mui/material/Paper";
import { RootReducer } from "redux/configurations/rootReducer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import _ from "lodash";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

interface IShipmentPaginationResponse {
  limit: number;
  page: number;
  results: IShipment[];
  resultsLength: number;
  totalItems: number;
}

const textColor = "#000";
const textSecondColor = "#4B4B4B";

const DescriptionItem = (props: { title: string; descriptions: any }) => {
  const { title, descriptions } = props;
  return (
    <Stack direction="row" alignItems="center">
      <Typography sx={{ minWidth: 150, fontWeight: 600 }} color={textColor}>
        {title}:{" "}
      </Typography>
      <Typography
        color={textSecondColor}
        sx={{
          display: "contents",
        }}
      >
        {descriptions}
      </Typography>
    </Stack>
  );
};

const HistoryItem = (props: {
  status: string;
  reason: string;
  dateTime: string;
  bold: boolean;
}) => {
  const { status, dateTime, bold, reason } = props;
  return (
    <Stack sx={{ width: "100%" }}>
      {!bold && <Divider sx={{ margin: 0.5 }} />}
      <Typography
        color={bold ? textColor : textSecondColor}
        sx={{ fontWeight: bold ? 600 : "" }}
      >
        {status}
      </Typography>
      {!_.isEmpty(reason) && (
        <Typography color={textSecondColor}>{reason}</Typography>
      )}
      <Typography color={textSecondColor}>
        {moment(dateTime).format("HH:mm A | DD/MM/YYYY")}
      </Typography>
    </Stack>
  );
};

const getColor = (shipmentStatus: number) => {
  switch (shipmentStatus) {
    case 0:
      return "#f1c232";
    case 1:
      return "#3d85c6";
    case 2:
      return "#cc0000";
    case 3:
      return "#cc0000";
    case 4:
      return "#f1c232";
    case 5:
      return "#018e00";
    case 6:
      return "#f6b26b";
    case 7:
      return "#f1c232";
    default:
      return "";
  }
};

export default function ShipmentManagement() {
  let history = useHistory();
  const user = useSelector((state: RootReducer) => state.IdentityReducer.admin);
  const [shipments, setShipments] = useState<IShipmentPaginationResponse>();
  const [shipmentDetail, setShipmentDetail] = useState<any>({});
  const [shipmentDetailStatus, setShipmentDetailStatus] = useState<number[]>(
    []
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [detailHistories, setDetailHistories] = useState<
    IShipmentDetailHistory[]
  >([]);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [codeType, setCodeType] = useState<ICodeType[]>([]);
  const [itemCodeType, setItemCodeType] = useState<ICodeType[]>([]);
  const [shipmentDetailIndex, setShipmentDetailIndex] = useState<number>();
  const [updateParams, setUpdateParams] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [idHover, setIdHover] = useState<string>();
  const [filterStatus, setFilterStatus] = useState<number>(8);
  const [shipPriceOfShipmentDetails, setShipPriceOfShipmentDetails] =
    useState<any>([]);

  useEffect(() => {
    (() => {
      Promise.all([
        CodeTypeApiService.getAllCodeTypeByType("shipment")
          .then((res: any) =>
            setCodeType(
              res.data.sort((n1: ICodeType, n2: ICodeType) => n1.code - n2.code)
            )
          )
          .catch(() =>
            showAlert("Get Shipment Status Failed", AlertType.ERROR)
          ),
        CodeTypeApiService.getAllCodeTypeByType("ShipmentItem")
          .then((res: any) =>
            setItemCodeType(
              res.data.sort((n1: ICodeType, n2: ICodeType) => n1.code - n2.code)
            )
          )
          .catch(() =>
            showAlert("Get Shipment Detail Status Failed", AlertType.ERROR)
          ),
      ]);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response: any = await ShipmentApiService.getAll({
        nameShipment: searchValue,
        statusShipment: filterStatus,
        page: page,
        limit: 10,
      });
      setLoading(false);
      if (response?.data) {
        setShipments(response.data);
        setTotalPage(Math.ceil((response.data?.totalItems || 0) / 10));
      }
    })();
  }, [reload, searchValue, page, filterStatus]);

  useEffect(() => {
    if (modalVisible) {
      setShipmentDetail({});
      setShipmentDetailStatus([]);
      setShipPriceOfShipmentDetails(undefined);
      setShipmentDetailIndex(undefined);
    }
  }, [modalVisible]);

  const getListShipmentStatus = (shipmentDetailStatus: number) => {
    let listCodeType = itemCodeType;
    switch (shipmentDetailStatus) {
      case 0:
        listCodeType = itemCodeType.filter((x) => x.code === 1 || x.code === 3);
        return listCodeType;
      case 1:
        listCodeType = itemCodeType.filter((x) => x.code === 2 || x.code === 3);
        return listCodeType;

      case 2:
        listCodeType = itemCodeType.filter((x) => x.code === 3);
        return listCodeType;

      default:
        return listCodeType;
    }
  };
  const checkDisable = (i: number) => {
    if (
      JSON.stringify(shipmentDetailStatus) !==
      JSON.stringify(
        shipmentDetail.shipmentDetails.map((x: any) => x.shipmentItemStatus)
      )
    ) {
      return i !== shipmentDetailIndex;
    }
  };

  const checkPriceDisable = (i: number) => {
    if (
      JSON.stringify(shipPriceOfShipmentDetails) !==
      JSON.stringify(
        shipmentDetail.shipmentDetails.map(
          (x: any) => x.thirdShipmentPrice || ""
        )
      )
    ) {
      return i !== shipmentDetailIndex;
    }
  };
  const onConfirmOk = async () => {
    updateThirdShipmentPrice();
    updateShipment();
    setModalVisible(false);
    setConfirmModal(false);
  };

  const updateShipment = async () => {
    const param: any = {
      shipmentHeaderIdUpdate: shipmentDetail.id,
      userId: user?.user.id,
      ...updateParams,
    };
    setLoading(true);
    let response: any;
    if (
      JSON.stringify(shipmentDetailStatus) !==
      JSON.stringify(
        shipmentDetail.shipmentDetails.map((x: any) => x.shipmentItemStatus)
      )
    ) {
      response = await ShipmentApiService.updateShipment(param);
      if (response.succeeded && !_.isEmpty(response?.data?.id)) {
        showAlert(
          "Cập nhật trạng thái giao hàng thành công",
          AlertType.SUCCESS
        );
        setReload(!reload);
      } else {
        showAlert("Lỗi cập nhật trạng thái giao hàng", AlertType.ERROR);
      }
    }
    setLoading(false);
  };

  const updateThirdShipmentPrice = async () => {
    const params = {
      orderDetailIdUpdate:
        shipmentDetail.shipmentDetails![shipmentDetailIndex!]?.orderDetailId!,
      orderHeaderIdUpdate: shipmentDetail?.orderHeaderId,
      userId: user?.user.id,
      shipmentHeaderIdUpdate: shipmentDetail?.id,
      thirdShipmentPriceUpdate: Number(
        shipPriceOfShipmentDetails![shipmentDetailIndex!]
      ),
    };
    setLoading(true);
    let response: any;
    if (
      params.thirdShipmentPriceUpdate &&
      params.thirdShipmentPriceUpdate !== 0
    ) {
      response = await OrderApiService.updateThirdShipmentPrice(params);

      if (response.succeeded && !_.isEmpty(response.data)) {
        showAlert("Cập nhật  thành công", AlertType.SUCCESS);
        setReload(!reload);
      } else {
        showAlert("Lỗi cập nhật ", AlertType.ERROR);
      }
    }

    setLoading(false);
  };

  const onOk = () => {
    setConfirmModal(true);
  };
  const getShipmentDetail = async (id: string) => {
    setLoading(true);
    const shipment: any = await ShipmentApiService.getShipmentById(id);
    if (shipment.succeeded && !_.isEmpty(shipment.data)) {
      const shipmentData = shipment.data;
      const shipmentDetailData = shipment.data.shipmentDetails.map(
        (x: any) => x.shipmentItemStatus
      );
      const shipPriceOfShipmentDetail = shipment.data.shipmentDetails.map(
        (x: any) => x.thirdShipmentPrice || ""
      );

      setShipmentDetail(shipmentData);
      setShipmentDetailStatus(shipmentDetailData);
      setShipPriceOfShipmentDetails(shipPriceOfShipmentDetail);
      setLoading(false);
    } else {
      showAlert("Get shipment error!", AlertType.ERROR);
      setLoading(false);
    }
  };

  const handleClick = (row: IShipment) => {
    setModalVisible(true);
    getShipmentDetail(row.id);
    // history.push({
    //   pathname: "/shipment-detail",
    //   search: `?id=${shipmentItem.id}`,
    // });
  };
  const handleViewHistories = (
    shipmentDetailHistory: IShipmentDetailHistory[]
  ) => {
    setShowHistory(true);
    setDetailHistories(shipmentDetailHistory);
  };

  const OrderStatus = (row: any) => {
    const status = row.shipmentStatus as number;
    return (
      <span
        className="mb-2 mr-2 badge badge-success"
        style={{
          marginTop: 10,
          backgroundColor: getColor(status),
        }}
      >
        {codeType.find((x: ICodeType) => x.code === status)?.name}
      </span>
    );
  };

  const columns = useMemo(
    () => [
      TableHelper.createColumn({
        width: 120,
        field: "shipmentHeaderNo",
        headerName: "Mã vận chuyển",
      }),
      TableHelper.createColumn({
        field: "orderHeaderNo",
        headerName: "Mã vận đơn",
        width: 120,
      }),
      TableHelper.createColumn({
        field: "orderStatus",
        headerName: "Trạng thái",
        width: 450,
        renderCell: ({ row }) => <OrderStatus {...row} />,
      }),
      TableHelper.createColumn({
        field: "name",
        headerName: "Tên",
      }),
      TableHelper.createColumn({
        field: "phone",
        headerName: "SĐT",
      }),
      TableHelper.createColumn({
        field: "email",
        width: 300,
        headerName: "Email",
      }),
      TableHelper.createColumn({
        field: "totalWeight",
        headerName: "Ngày tạo",
        fieldType: "number",
        valueGetter: ({ row }) => DateHelper.mmddyyyyFormat(row.createdDt),
      }),
    ],
    [codeType]
  );

  return (
    <AdminContainer title={"Tiến trình giao hàng"}>
      <AppTable<any, any, any>
        api={ShipmentApiService.getAll}
        columns={columns}
        onEditRowClick={handleClick}
      />

      <FormDialog
        maxWidth="lg"
        cancelText="Đóng"
        visible={modalVisible}
        title={shipmentDetail?.shipmentHeaderNo}
        onCancel={() => setModalVisible(false)}
        onOk={() => onOk()}
        open={false}
        // hiddenBtnCancel
      >
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid container item md={6} sm={12} spacing={1}>
              <Grid item md={12}>
                <Box
                  className="style-4"
                  sx={{
                    ...styles.boxShadow,
                    height: 120,
                    overflowY: "auto",
                  }}
                >
                  <DescriptionItem
                    title={"Tên"}
                    descriptions={shipmentDetail.name || ""}
                  />
                  <DescriptionItem
                    title={"SĐT"}
                    descriptions={shipmentDetail.phone || ""}
                  />
                  <DescriptionItem
                    title={"Email"}
                    descriptions={shipmentDetail.email || ""}
                  />
                  <DescriptionItem
                    title={"Địa chỉ"}
                    descriptions={`${shipmentDetail.address || ""}, ${
                      shipmentDetail.city || ""
                    }`}
                  />
                </Box>
              </Grid>
              <Grid item md={12}>
                <Box sx={styles.boxShadow}>
                  <TextField
                    value={
                      codeType.find(
                        (x: ICodeType) =>
                          x.code === shipmentDetail.shipmentStatus
                      )?.name
                    }
                    variant="standard"
                    fullWidth
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid item md={6} sm={12} sx={{ height: 210 }}>
              <Grid
                className="style-4"
                sx={{
                  ...styles.boxShadow,
                  height: "99%",
                  overflowY: "auto",
                }}
              >
                {shipmentDetail.shipmentHistories?.map((x: any, i: number) => {
                  return (
                    <HistoryItem
                      reason={x.reason}
                      status={
                        codeType?.find((y) => y.code === x.shipmentStatus)
                          ?.name || ""
                      }
                      dateTime={x.updatedDt}
                      bold={i === 0}
                    />
                  );
                })}
              </Grid>
            </Grid>

            <Grid
              className="style-4"
              item
              md={12}
              sm={12}
              sx={{
                maxHeight: 245,
                overflowY: "auto",
                marginTop: 2,
              }}
            >
              <TableContainer component={Paper}>
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">STT</TableCell>
                      <TableCell align="left">Ảnh SP</TableCell>
                      <TableCell align="left">Tên SP</TableCell>
                      <TableCell sx={{ width: 270 }} align="left">
                        Trạng thái
                      </TableCell>
                      <TableCell align="left">Tiền giao hàng</TableCell>
                      <TableCell align="left">Lịch sử</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {shipmentDetail.shipmentDetails?.map(
                      (x: any, i: number) => {
                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {i + 1}
                            </TableCell>
                            <TableCell>
                              <Box
                                component="img"
                                sx={{ width: 100, height: 80 }}
                                src={x.product.mainImage}
                                alt="img"
                                className="blur-up lazyloaded"
                              />
                            </TableCell>
                            <TableCell>{x.product.name}</TableCell>
                            <TableCell>
                              <TextField
                                value={shipmentDetailStatus[i]}
                                onChange={(e) => {
                                  let shipmentStatus =
                                    Array.from(shipmentDetailStatus);
                                  shipmentStatus[i] = Number(e.target.value);
                                  setShipmentDetailStatus(shipmentStatus);
                                  setShipmentDetailIndex(i);
                                  setUpdateParams({
                                    shipmentDetailStatusUpdate: Number(
                                      e.target.value
                                    ),
                                    shipmentDetailIdUpdate: x.id,
                                  });
                                }}
                                variant="standard"
                                disabled={
                                  x.shipmentItemStatus === 2 ||
                                  x.shipmentItemStatus === 3 ||
                                  // x.shipmentItemStatus === 7 ||
                                  // x.shipmentItemStatus === 8 ||
                                  // x.shipmentItemStatus === 9 ||
                                  checkDisable(i)
                                }
                                select
                                fullWidth
                              >
                                {getListShipmentStatus(
                                  x.shipmentItemStatus
                                ).map((x: ICodeType) => (
                                  <MenuItem value={x.code}>{x.name}</MenuItem>
                                ))}
                              </TextField>
                            </TableCell>
                            <TableCell>
                              {!_.isEmpty(shipPriceOfShipmentDetails) && (
                                <TextField
                                  variant="standard"
                                  type="number"
                                  sx={{ width: "100%" }}
                                  size="medium"
                                  value={shipPriceOfShipmentDetails[i]}
                                  onChange={(e) => {
                                    let newList = Array.from(
                                      shipPriceOfShipmentDetails
                                    );
                                    newList[i] = e.target.value;
                                    setShipPriceOfShipmentDetails(newList);
                                    setShipmentDetailIndex(i);
                                  }}
                                  disabled={checkPriceDisable(i)}
                                />
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                cursor: "pointer",
                                color: "blue",
                              }}
                              onClick={() =>
                                handleViewHistories(x?.shipmentDetailsHistories)
                              }
                            >
                              Xem chi tiết
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </FormDialog>
      <FormDialog
        visible={showHistory}
        title={"History"}
        onCancel={() => setShowHistory(false)}
        hiddenBtnOK
        onOk={() => {}}
        open={false}
      >
        <Box sx={{ pt: 1 }}>
          {detailHistories.map((x, i) => (
            <HistoryItem
              reason={x.reason}
              status={
                itemCodeType?.find((y) => y.code === x.shipmentItemStatus)
                  ?.name || ""
              }
              dateTime={x.updatedDt}
              bold={i === 0}
            />
          ))}
        </Box>
      </FormDialog>
      <FormDialog
        visible={confirmModal}
        title={"Confirm"}
        onCancel={() => setConfirmModal(false)}
        onOk={() => onConfirmOk()}
        open={false}
      >
        <Typography variant="h6">
          Bạn có chắc muốn thực hiện hành động này?
        </Typography>
      </FormDialog>
      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}

const styles = {
  boxShadow: { boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", p: 1 },
};
