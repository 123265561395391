import Box from "@mui/material/Box";
import Modal, { ModalProps } from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
interface IProps extends ModalProps {
  visible: boolean;
  title: string;
  onCancel: () => void;
}

const AppModal: React.FC<IProps> = ({ visible, title, onCancel, children }) => {
  return (
    <Modal
      open={visible}
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {children}
        </Typography>
      </Box>
    </Modal>
  );
};

export default AppModal;
