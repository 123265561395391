import { Typography } from "@mui/material";
import AppButton from "components/client/app-button";
import { AlertHelper, CurrencyHelper } from "helpers";
import { AlertType } from "helpers/alertHelper";
import _, { sumBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { CartActions } from "redux/actions";
import { RootReducer } from "redux/configurations/rootReducer";
import { ConfigurationsApiService } from "services";
import { CodeConfiguration } from "utils/constants";

export default function Cart() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [shipmentRate, setShipmentRate] = useState(0);

  const getByCode = async () => {
    const response: any = await ConfigurationsApiService.getByCode(
      CodeConfiguration.SHIPMENTRATE
    );
    let rate = +response.data[0].value;
    setShipmentRate(rate);
  };

  const carts = useSelector((state: RootReducer) => state.CartReducer.carts);

  useEffect(() => {
    getByCode();
  }, []);

  const productTotal = useMemo(() => {
    return carts.reduce(
      (total, currentValue) => (total = total + currentValue.tempPrice),
      0
    );
  }, [carts]);

  const removeToCart = (id: string) => {
    let newCarts = JSON.parse(JSON.stringify(carts));
    let index = _.findIndex(newCarts, { id });
    if (index !== -1) {
      newCarts.splice(index, 1);
    }
    dispatch(CartActions.removeToCard.request(newCarts));
  };

  return (
    <>
      <div className="ec-side-cart-overlay"></div>
      <div id="ec-side-cart" className="ec-side-cart">
        <div className="ec-cart-inner">
          <div className="ec-cart-top">
            <div className="ec-cart-title">
              <span className="cart_title">Giỏ hàng</span>
              <button className="ec-close">×</button>
              <i>(Chưa bao gồm phí bảo hành)</i>
            </div>
            <ul className="eccart-pro-items">
              {carts?.map((x, i) => (
                <li key={i}>
                  <a
                    href={`/product-item?id=${x.id}`}
                    className="sidekka_pro_img"
                  >
                    <img src={x.mainImage} alt={x.mainImage} />
                  </a>
                  <div className="ec-pro-content">
                    <a
                      href={`/product-item?id=${x.id}`}
                      className="cart_pro_title"
                    >
                      {x.name}
                    </a>
                    <span className="cart-price">
                      <span>
                        {CurrencyHelper.vnFormatter(
                          x.sellCurrentPrice +
                            Math.floor(
                              (x.shippingSurchargePrice +
                                x.weight * 10 * shipmentRate) /
                                1000
                            ) *
                              1000
                        )}
                      </span>{" "}
                      x 1
                    </span>
                    <button
                      style={{
                        lineHeight: "15px",
                        paddingTop: "6px",
                        color: "red",
                        fontSize: "16px",
                        background: "#ffffff",
                      }}
                      onClick={() => removeToCart(x.id)}
                    >
                      Xóa
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="ec-cart-bottom">
            <div className="cart-sub-total">
              <table className="table cart-table">
                <tbody>
                  <tr>
                    <td className="text-left">Tổng :</td>
                    <td className="text-right primary-color">
                      {CurrencyHelper.vnFormatter(
                        sumBy(
                          carts,
                          (x) =>
                            Math.floor(
                              (x.sellCurrentPrice +
                                x.shippingSurchargePrice +
                                x.weight * 10 * shipmentRate) /
                                1000
                            ) * 1000
                        )
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="w-100">
              <AppButton
                label="Giỏ hàng"
                onClick={() => history.push("/cart")}
                style={{
                  width: "100%",
                  maxWidth: "100%",
                }}
                variant="primary"
                disabled={carts.length === 0}
              ></AppButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
