import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  Divider,
  Grid,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { styled, useTheme } from "@mui/material/styles";
import { decode as base64_decode } from "base-64";
import { FormDialog } from "components";
import { TableColumName } from "components/AdminComponents/AdminTable";
import AppTable from "components/app-table";
import { useFormik } from "formik";
import { CurrencyHelper, TableHelper, ToastHelper } from "helpers";
import { AlertType, showAlert } from "helpers/alertHelper";
import _ from "lodash";
import { IProduct } from "models/IProductModel";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { ProductActions } from "redux/actions";
import { RootReducer } from "redux/configurations/rootReducer";
import {
  CategoryApiService,
  ProductApiService,
  RateConfigurationApiService,
  SubCategoryApiService,
} from "services";
import { IApiResponse } from "services/BaseApiService";
import CarrierApiService from "services/CarrierApiService";
import UploadBaseService from "services/UploadBaseService";
import AdminContainer from "../../Container";
import "./styles.css";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const Input = styled("input")({
  display: "none",
});

interface IProducts {
  limit: number;
  page: number;
  results: IProduct[];
  resultsLength: number;
  totalItems: number;
}

export default function ProductManagement() {
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: RootReducer) => state.IdentityReducer.admin);

  const [products, setProducts] = useState<IProducts>();
  const [open, setOpen] = useState(false);
  const [productDetail, setProductDetail] = useState<any>({});
  const [reload, setReload] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [listFileImage, setListFileImage] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    buyRegularPrice: undefined,
    buyCurrentPrice: undefined,
    sellPrice: undefined,
    weight: undefined,
    skuNumber: "",
    model: "",
    brand: "",
    orgLink: "",
  });
  const [loading, setLoading] = useState(false);
  const [idHover, setIdHover] = useState<string>();
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = !_.isEmpty(images) ? images.length : 0;
  const [filterStatus, setFilterStatus] = useState<number>(2);
  const [categories, setCategories] = useState<any>([]);
  const [currentCategory, setCurrentCategory] = useState<any>();
  const [subCategories, setSubCategories] = useState<any>();
  const [currentSubCategory, setCurrentSubCategory] = useState<any>();
  const [carriers, setCarriers] = useState<any>();
  const [currentCarrier, setCurrentCarrier] = useState<any>();
  const [optionsList, setOptionsList] = useState<
    {
      type?: string;
      value?: string;
    }[]
  >([
    {
      type: "Ram",
      value: "8GB",
    },
    {
      type: "Disk",
      value: "128GB",
    },
  ]);
  const [isDisplay, setIsDisplay] = useState(true);
  const [recStatus, setRecStatus] = useState(true);
  const [stockStatus, setStockStatus] = useState(true);
  const [topDeal, setTopDeal] = useState(true);

  useEffect(() => {
    if (_.isEmpty(carriers)) {
      getCarriers();
    }
    if (_.isEmpty(categories)) {
      getAllCategory();
    }
    if (!modalVisible) {
      formik.resetForm();
      setInitialValues({
        name: "",
        buyRegularPrice: undefined,
        buyCurrentPrice: undefined,
        sellPrice: undefined,
        weight: undefined,
        skuNumber: "",
        model: "",
        brand: "",
        orgLink: "",
      });
      setProductDetail({});
      // setOptionsList([]);
      setCurrentCategory("");
      setImages([]);
      setListFileImage([]);
    }
  }, [modalVisible]);

  useEffect(() => {
    if (!modalVisible) {
      setOptionsList([]);
    }
  }, [modalVisible]);

  useEffect(() => {
    if (!_.isEmpty(currentCategory) && modalVisible) {
      getSubCategory();
      getAllCategory();
    }
  }, [currentCategory, modalVisible]);

  const getSubCategory = async () => {
    setLoading(true);
    const response = await SubCategoryApiService.getById(currentCategory);
    setLoading(false);
    const resData = response as IApiResponse<any>;
    if (resData.succeeded) {
      setSubCategories(resData.data);
    } else {
      showAlert("Get sub categories failed", AlertType.ERROR);
    }
  };

  const getAllProducts = async () => {
    setLoading(true);
    const response = await ProductApiService.getAllProductPagination({
      name: searchValue,
      status: filterStatus,
      page: page,
      limit: 10,
      // sortDirection: filterStatus,
    });
    setLoading(false);
    const resData = response as IApiResponse<IProducts>;
    if (!_.isEmpty(resData)) {
      setProducts(resData.data);
      setTotalPage(Math.ceil((resData.data?.totalItems || 0) / 10));
    }
  };

  const getAllCategory = async () => {
    setLoading(true);
    const response = await CategoryApiService.getAll();
    setLoading(false);
    const resData = response as IApiResponse<any>;
    if (resData.succeeded) {
      setCategories(resData.data);
    } else {
      showAlert("Get categories failed", AlertType.ERROR);
    }
  };

  const getCarriers = async () => {
    setLoading(true);
    const response = await CarrierApiService.getAll();
    setLoading(false);
    const resData = response as IApiResponse<any>;
    if (resData.succeeded) {
      setCarriers(resData.data);
    } else {
      showAlert("Get carriers failed", AlertType.ERROR);
    }
  };
  console.log(
    "categories",
    categories,
    "currentCategory",
    currentCategory,
    "IMAGE",
    images
  );

  const getSellPrice = async (value: string) => {
    const sellPrice = convertTextToNumber(value);
    const response = await RateConfigurationApiService.getSellPrice(sellPrice);
    if (response?.succeeded) {
      formik.setFieldValue("sellPrice", response.data);
    } else {
      showAlert("Get sell price error!", AlertType.ERROR);
    }
    return response?.data;
  };

  const getProductById = async (id: any) => {
    setLoading(true);
    const product: any = await ProductApiService.getProductItem(id);
    setLoading(false);

    if (product.succeeded && !_.isEmpty(product.data)) {
      const productData: IProduct = product.data;
      setInitialValues(productData);

      let image: any = [];
      let list: any = [];

      if (!_.isEmpty(product.data.listProductDetailsGroup)) {
        productData.listProductDetailsGroup.forEach((y: any) =>
          y.forEach((x: any, i: number) => {
            if (x.type?.toLowerCase() === "image") {
              image.push({ type: x.type, value: x.value });
            } else {
              list.push(x);
            }
          })
        );
      }
      setOptionsList(list);
      setImages(image);
      setInitialValues({ ...productData });
    }
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: any) => {
    setActiveStep(step);
  };

  const convertTextToNumber = (text: string) => {
    let a;
    if (text) {
      a = text.toString().includes(",") ? text.replace(/[,]/g, "") : text;
    }
    return Number(a);
  };

  const uploadImage = async () => {
    let dataPost: any = [];
    if (!_.isEmpty(listFileImage)) {
      setLoading(true);
      await UploadBaseService.uploadImage(listFileImage)
        .then((res: any) => {
          if (res.succeeded) {
            res.data.map((x: any) => {
              dataPost.push({
                type: "Image",
                value: x.imageUrl,
              });
            });
          } else {
            showAlert("Upload Image Error", AlertType.ERROR);
          }
        })
        .catch((err) => {
          showAlert("Upload Image Error", AlertType.ERROR);
          console.log(err);
        });
      setLoading(false);
    }
    return { dataPost };
  };

  const formik: any = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (values: any, action: any) => {
      const { dataPost } = await uploadImage();

      const sellPrice = convertTextToNumber(values.sellPrice);
      const buyRegularPrice = convertTextToNumber(values.buyRegularPrice);
      const buyCurrentPrice = convertTextToNumber(values.buyCurrentPrice);

      console.log(
        "dataPost",
        dataPost,
        "optionsList",
        optionsList,
        "images",
        images
      );

      let listProductDetailsRequest =
        // (dataPost || [])
        //   .concat(
        optionsList
          .filter((x) => !_.isEmpty(x.type) && !_.isEmpty(x.value))
          // )
          .concat(images);

      if (buyRegularPrice < buyCurrentPrice) {
        showAlert(
          "Buy regular must be greater than or equal to the current buy price",
          AlertType.ERROR
        );
        return;
      }

      let params: any = {
        userId: user?.userId,
        productEditRequest: {
          id: values.id,
          seller: values.seller,
          orgLink: values.orgLink,
          skuNumber: values.skuNumber,
          name: values.name,
          statusItem: values.statusItem,
          brand: values.brand,
          model: values.model,
          buyRegularPrice: values.buyRegularPrice,
          buyCurrentPrice: values.buyCurrentPrice,
          sellRegularPrice: values.sellRegularPrice,
          sellCurrentPrice: values.sellCurrentPrice,
          tempSellRegularPrice: values.tempSellRegularPrice,
          tempSellCurrentPrice: values.tempSellCurrentPrice,
          minTempSellCurrentPrice: values.minTempSellCurrentPrice,
          discount: values.discount,
          condition: values.condition,
          quantitySold: values.quantitySold,
          isDisplay: values.isDisplay,
          isDaillyDeal: values.isDaillyDeal,
        },
        listProductDetailsRequest,
      };
      if (!_.isEmpty(productDetail)) {
        console.log({
          ...params,
          productEditRequest: {
            ...params,
            recStatus: recStatus ? 0 : 1, //Ko có trong API
            isDisplay: isDisplay ? 1 : 0,
            statusItem: stockStatus ? 1 : 0,
            isDaillyDeal: topDeal ? 1 : 0,
          },
          listProductDetailsRequest: listProductDetailsRequest,
        });
        const editProduct = async () => {
          setLoading(true);
          const product: any = await ProductApiService.updateProduct({
            userId: user.id,
            productEditRequest: {
              ...params.productEditRequest,
              recStatus: recStatus ? 0 : 1, //Ko có trong API
              isDisplay: isDisplay ? 1 : 0,
              statusItem: stockStatus ? 1 : 0,
              isDaillyDeal: topDeal,
              listProductDetailsRequest: listProductDetailsRequest,
            },
          });
          console.log("====================================");
          console.log(product);
          console.log("====================================");
          setLoading(false);
          if (product.succeeded && !_.isEmpty(product.data)) {
            ToastHelper.toastSuccess("Sửa sản phẩm thành công");
          } else {
            ToastHelper.toastError("Sửa sản phẩm thất bại");
          }
        };
        editProduct();
        setModalVisible(false);
        setReload(!reload);
      } else {
        dispatch(ProductActions.createProduct.request(params));
      }
    },
    validate: (values: any) => {
      const errors: any = {};
      if (_.isEmpty(values.name)) {
        errors.name = "Tên SP không được để trống";
      }
      if (!values.buyCurrentPrice) {
        errors.price = "Giá tiền không được để trống";
      }
      if (
        values.buyCurrentPrice &&
        convertTextToNumber(values.buyCurrentPrice) < 0
      ) {
        errors.buyCurrentPrice = "Giá tiền không hợp lệ";
      }
      if (values.sellPrice && convertTextToNumber(values.sellPrice) < 0) {
        errors.sellPrice = "Giá không hợp lệ";
      }
      if (!values.buyRegularPrice) {
        errors.buyRegularPrice = "Giá tiền không được để trống";
      }
      if (!values.buyCurrentPrice) {
        errors.buyCurrentPrice = "Giá tiền không được để trống";
      }
      // if (!values.sellPrice) {
      //   errors.sellPrice = "Giá tiền không được để trống";
      // }
      if (
        values.buyRegularPrice &&
        convertTextToNumber(values.buyRegularPrice) < 0
      ) {
        errors.buyRegularPrice = "Giá tiền không hợp lệ";
      }
      if (_.isEmpty(values.skuNumber)) {
        errors.skuNumber = "SkuNumber không được để trống";
      }
      if (_.isEmpty(values.model)) {
        errors.model = "Model không được để trống";
      }
      if (_.isEmpty(values.brand)) {
        errors.brand = "Brand không được để trống";
      }
      return errors;
    },
  });

  const handleUploadImage = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let arr: any = Array.from(event.target.files);
      setListFileImage([...listFileImage, ...arr]);
      let newList: any = [];
      if (arr.length > 1) {
        arr.forEach((element: any) => {
          if (element.size < 5e6) {
            const image = {
              // type: element.name,
              type: "Image",
              value: URL.createObjectURL(element),
            };
            newList.push(image);
          } else {
            ToastHelper.toastError(
              `Image ${element.name} size is too large. The image size should be less than 5mb`
            );
          }
        });
      } else {
        if (event.target.files[0].size < 5e6) {
          newList.push({
            type: "Image",
            // type: event.target.files[0].name,
            value: URL.createObjectURL(event.target.files[0]),
          });
        } else {
          ToastHelper.toastError(
            `Image ${event.target.files[0].name} size is too large. The image size should be less than 5mb`
          );
        }
      }
      setImages(images.concat(newList));
    }
  };

  const handleClick = (row: any) => {
    history.push({
      pathname: "/product-detail",
      search: `?id=${row.id}`,
    });
  };

  const handleAddNewProduct = () => {
    setModalVisible(true);
  };

  const handleEditProduct = (productItem: any) => {
    setProductDetail(productItem);
    getProductById(productItem.id);
    setCurrentCarrier(productItem.carrierId);
    setCurrentCategory(productItem.categoryId);
    setCurrentSubCategory(productItem.subCategoryId);
    setStockStatus(productItem.statusItem === 0 ? false : true);
    setIsDisplay(productItem.isDisplay === 1 ? true : false);
    setRecStatus(productItem.recStatus === 1 ? false : true);
    setTopDeal(productItem.isDaillyDeal);
    setModalVisible(true);
  };

  const onOk = async () => {
    const response = await ProductApiService.updateProduct(productDetail);
    if (response?.succeeded) {
      showAlert("Update product successful!", AlertType.SUCCESS);
      setOpen(false);
      setReload(!reload);
    } else {
      showAlert("Update product error!", AlertType.ERROR);
    }
  };

  const rowItem = (index: number, type?: string, value?: string) => {
    return (
      <>
        <Stack direction={"row"} sx={{ width: "100%" }}>
          <Grid
            item
            container
            xs={12}
            direction="row"
            justifyContent="left"
            alignItems="center"
            rowSpacing={3}
            // columnSpacing={1}
          >
            <Grid item xs={6} spacing={1}>
              <TextField
                variant="standard"
                type="text"
                sx={{ width: "100%", pr: 1 }}
                value={type}
                onChange={(e) => {
                  let list = Array.from(optionsList);
                  list[index].type = e.target.value;
                  setOptionsList(list);
                }}
                size="medium"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="standard"
                type="text"
                sx={{ width: "100%", pl: 1 }}
                value={value}
                onChange={(e) => {
                  let list = Array.from(optionsList);
                  list[index].value = e.target.value;
                  setOptionsList(list);
                }}
                size="medium"
              />
            </Grid>
          </Grid>
          <CloseIcon
            sx={{ color: "red", position: "relative", top: 10, left: 8 }}
            onClick={() => {
              let list = Array.from(optionsList);
              list = list.filter((x, i) => i !== index);
              setOptionsList(list);
            }}
          />
        </Stack>
      </>
    );
  };

  const renderTableBody = () => {
    return (
      <table
        id="responsive-data-table"
        className="table dataTable no-footer"
        style={{ width: "100%", cursor: "pointer", marginTop: 16 }}
        aria-describedby="responsive-data-table_info"
      >
        <thead>
          <tr>
            <TableColumName title="STT" />
            <TableColumName title="Hình ảnh" />
            <TableColumName title="Tên SP" />
            <TableColumName title="Hãng" />
            <TableColumName title="Model" />
            <TableColumName title="Sku" />
            <TableColumName title="Giá hiện tại" />
            <TableColumName title="Giá mặc định" />
            <TableColumName title="Giá bán" />
            <TableColumName title="Trạng thái" />
            <TableColumName title="Hành động" />
          </tr>
        </thead>
        <tbody>
          {products?.results?.map((row: any, i: number) => {
            let re =
              /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
            let imageMain = re.test(row.mainImage)
              ? base64_decode(row.mainImage)
              : row.mainImage;
            return (
              <tr
                key={row.id}
                style={{
                  height: 70,
                  backgroundColor:
                    idHover === row.id ? "rgba(61, 133, 198, 0.2)" : "",
                }}
                onMouseEnter={() => setIdHover(row.id)}
                onMouseLeave={() => setIdHover(undefined)}
              >
                <td className="sorting_1">{++i}</td>
                <td>
                  <img
                    src={imageMain}
                    alt=""
                    style={{
                      maxWidth: "100px",
                      maxHeight: "62px",
                    }}
                    onClick={() => handleClick(row)}
                  />
                </td>
                <td>{row.name}</td>
                <td>{row.brand}</td>
                <td>{row.model}</td>
                <td>{row.skuNumber}</td>
                <td>${row.buyCurrentPrice}</td>
                <td>${row.buyRegularPrice || "0.00"}</td>
                <td>{CurrencyHelper.vnFormatter(row.sellPrice)}</td>
                <td>{row.statusItem === 1 ? "In-stock" : "Out-stock"}</td>
                <td onClick={() => handleEditProduct(row)}>
                  <EditIcon
                    style={{ color: "rgba(61, 133, 198)" }}
                    // onClick={() => handleEditProduct(row)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const columns = [
    TableHelper.createColumn({
      field: "orderHeaderNo",
      headerName: "Hình ảnh",
      renderCell: ({ row }) => {
        let re = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
        let imageMain = re.test(row.mainImage)
          ? base64_decode(row.mainImage)
          : row.mainImage;
        return (
          <img
            src={imageMain}
            alt=""
            style={{
              height: "90px",
              width: "90px",
            }}
          />
        );
      },
    }),

    TableHelper.createColumn({
      field: "name",
      headerName: "Tên",
      width: 500,
    }),
    TableHelper.createColumn({
      field: "skuNumber",
      headerName: "SKU",
    }),
    TableHelper.createColumn({
      field: "tempSellCurrentPrice",
      headerName: "Giá bán",
      align: "right",
      renderCell: ({ row }) => {
        return (
          <Typography fontWeight={"bold"}>
            {CurrencyHelper.vnFormatter(row.tempSellCurrentPrice)}
          </Typography>
        );
      },
    }),
    TableHelper.createColumn({
      field: "brand",
      headerName: "Hãng",
    }),
    TableHelper.createColumn({
      field: "seller",
      headerName: "Người bán",
    }),
    TableHelper.createColumn({
      field: "model",
      width: 300,
      headerName: "Model",
    }),
    TableHelper.createColumn({
      field: "statusItem",
      headerName: "Trạng thái",
      renderCell: ({ row }) => {
        return (
          <Typography
            sx={{
              color: "white",
              borderRadius: "3px",
              p: "3px 10px",
              bgcolor: row.statusItem === 1 ? "green" : "red",
            }}
          >
            {row.statusItem === 1 ? "Hoạt động" : "Không hoạt động"}
          </Typography>
        );
      },
    }),
  ];
  //  <td>{row.name}</td>
  //             <td>{row.brand}</td>
  //             <td>{row.model}</td>
  //             <td>{row.skuNumber}</td>
  //             <td>${row.buyCurrentPrice}</td>
  //             <td>${row.buyRegularPrice || "0.00"}</td>
  //             <td>{CurrencyHelper.vnFormatter(row.sellPrice)}</td>
  //             <td>{row.statusItem === 1 ? "In-stock" : "Out-stock"}</td>

  return (
    <AdminContainer title="Sản phẩm">
      {/* <HeaderContainer
        title="Sản phẩm"
        // rightComponent={
        //   <button
        //     style={{
        //       // color: "white",
        //       minWidth: 100,
        //       fontWeight: "bold",
        //       color: "white",
        //       backgroundColor: "#0046be",
        //       borderRadius: 10,
        //       paddingInline: 8,
        //       paddingBlock: 4,
        //     }}
        //     onClick={handleAddNewProduct}
        //   >
        //     Thêm mới
        //   </button>
        // }
        // onSearch={() => (value: string) => {
        //   setSearchValue(value);
        //   setReload(!reload);
        // }}
        // placeholder="Search by product name"
      /> */}

      <AppTable<any, any, any>
        api={ProductApiService.getAllProductPagination}
        columns={columns}
        onEditRowClick={handleEditProduct}
        rowHeight={100}
        onAddNewClick={handleAddNewProduct}
      />

      {/* <div className="row">
        <div className="col-12">
          <div className="card card-default" style={{ borderRadius: 16 }}>
            <div className="card-body">
              <div className="table-responsive">
                <div
                  id="responsive-data-table_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <TableHeaderSearch
                    onSearch={(value) => setSearchValue(value)}
                    rightComponent={
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{ minWidth: 320 }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "#000",
                            fontWeight: "300",
                          }}
                        >
                          Trạng thái
                        </Typography>
                        <TextField
                          fullWidth
                          sx={{ flex: 1 }}
                          variant="standard"
                          select
                          value={filterStatus}
                          onChange={(e) =>
                            setFilterStatus(Number(e.target.value))
                          }
                          defaultValue={filterStatus}
                        >
                          {[
                            { id: "2", code: 2, name: "Tất cả", type: "" },
                            { id: "1", code: 1, name: "Còn hàng", type: "" },
                            { id: "0", code: 0, name: "Hết hàng", type: "" },
                          ].map((x) => (
                            <MenuItem value={x.code}>{x.name}</MenuItem>
                          ))}
                        </TextField>
                      </Stack>
                    }
                  />
                  {renderTableBody()}
                  <TableFooter
                    currentPage={products?.page || 0}
                    onChangePage={(page) => setPage(page)}
                    totalPage={totalPage}
                  />
                  <div className="clear" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <FormDialog
        cancelText="Đóng"
        visible={modalVisible}
        title={!_.isEmpty(productDetail) ? "Sửa sản phẩm" : "Thêm mới"}
        onCancel={() => setModalVisible(false)}
        onOk={formik.submitForm}
        open={false}
        containerStyles={{
          minWidth: window.innerWidth - 64,
          height: window.innerHeight,
        }}
      >
        <Box
          className="style-4"
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 3fr)",
            gap: 1,
            gridTemplateRows: "auto",
            gridTemplateAreas: `"left right"`,
            // height: 500,
            mt: 2,
            overflowY: "auto",
          }}
        >
          {/* uploadImg */}
          <Stack sx={{ gridArea: "left" }} spacing={2}>
            {!_.isEmpty(images) ? (
              <Box sx={{ px: 2 }}>
                <AutoPlaySwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents
                >
                  {images.map((step: any, index: number) => (
                    <Stack key={step.type}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <Box
                          component="img"
                          sx={{
                            height: "auto",
                            display: "block",
                            overflow: "hidden",
                            width: "100%",
                          }}
                          src={step.value}
                          alt={step.type}
                        />
                      ) : null}
                    </Stack>
                  ))}
                </AutoPlaySwipeableViews>
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  nextButton={
                    <Button
                      size="small"
                      onClick={handleNext}
                      disabled={activeStep === maxSteps - 1}
                    >
                      Tiếp theo
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button
                      size="small"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                      Quay lại
                    </Button>
                  }
                />
              </Box>
            ) : (
              <Stack
                sx={{
                  bgcolor: "#F8F8F9",
                  height: 450,
                  textAlign: "center",
                  paddingTop: "40%",
                }}
              >
                Thêm ảnh
              </Stack>
            )}
            <Stack sx={{ alignItems: "center" }}>
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  size={1}
                  onChange={handleUploadImage}
                />
                <Button variant="outlined" component="span">
                  Đăng ảnh
                </Button>
              </label>
            </Stack>
          </Stack>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              rowSpacing={3}
              columnSpacing={3}
              sx={{ px: 2 }}
            >
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  type="text"
                  sx={{ width: "100%" }}
                  id="Category"
                  label="Carrier"
                  // value={formik.values.name || ""}
                  // onChange={formik.handleChange}
                  size="medium"
                  select
                  value={currentCarrier}
                  onChange={(e) => setCurrentCarrier(e.target.value)}
                  defaultValue={!_.isEmpty(carriers) ? carriers[0].id : ""}
                  disabled={!_.isEmpty(productDetail)}
                >
                  {carriers?.map((x: any) => (
                    <MenuItem value={x.id}>{x.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  type="text"
                  sx={{ width: "100%" }}
                  id="Category"
                  label="Danh mục"
                  size="medium"
                  select
                  value={currentCategory}
                  onChange={(e) => setCurrentCategory(e.target.value)}
                  defaultValue={!_.isEmpty(categories) ? categories[0].id : ""}
                  disabled={!_.isEmpty(productDetail)}
                >
                  {categories?.map((x: any) => (
                    <MenuItem value={x.id}>{x.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  type="text"
                  sx={{ width: "100%" }}
                  id="Category"
                  label="Danh mục con"
                  // value={formik.values.name || ""}
                  // onChange={formik.handleChange}
                  size="medium"
                  select
                  value={currentSubCategory}
                  onChange={(e) => setCurrentSubCategory(e.target.value)}
                  defaultValue={
                    !_.isEmpty(subCategories) ? subCategories[0].id : ""
                  }
                  disabled={!_.isEmpty(productDetail)}
                >
                  {subCategories?.map((x: any) => (
                    <MenuItem value={x.id}>{x.subName}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  type="text"
                  sx={{ width: "100%" }}
                  id="name"
                  label="Tên SP *"
                  error={formik.touched.name && !_.isEmpty(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  value={formik.values.name || ""}
                  onChange={formik.handleChange}
                  size="medium"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  type="text"
                  sx={{ width: "100%" }}
                  id="brand"
                  label="Hãng *"
                  error={
                    formik.touched.brand && !_.isEmpty(formik.errors.brand)
                  }
                  helperText={formik.touched.brand && formik.errors.brand}
                  value={formik.values.brand || ""}
                  onChange={formik.handleChange}
                  size="medium"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  type="text"
                  sx={{ width: "100%" }}
                  id="model"
                  label="Model *"
                  error={
                    formik.touched.model && !_.isEmpty(formik.errors.model)
                  }
                  helperText={formik.touched.model && formik.errors.model}
                  value={formik.values.model || ""}
                  onChange={formik.handleChange}
                  size="medium"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  type="text"
                  sx={{ width: "100%" }}
                  id="skuNumber"
                  label="SKU *"
                  error={
                    formik.touched.skuNumber &&
                    !_.isEmpty(formik.errors.skuNumber)
                  }
                  helperText={
                    formik.touched.skuNumber && formik.errors.skuNumber
                  }
                  value={formik.values.skuNumber || ""}
                  onChange={formik.handleChange}
                  size="medium"
                />
              </Grid>
              <Grid item xs={6}>
                <NumberFormat
                  customInput={TextField}
                  variant="standard"
                  sx={{ width: "100%" }}
                  size="medium"
                  thousandSeparator={true}
                  id="buyRegularPrice"
                  label="Buy Regular (USD) *"
                  error={
                    formik.touched.buyRegularPrice &&
                    !_.isEmpty(formik.errors.buyRegularPrice)
                  }
                  helperText={
                    formik.touched.buyRegularPrice &&
                    formik.errors.buyRegularPrice
                  }
                  value={formik.values.buyRegularPrice || ""}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <NumberFormat
                  customInput={TextField}
                  variant="standard"
                  sx={{ width: "100%" }}
                  size="medium"
                  thousandSeparator={true}
                  id="buyCurrentPrice"
                  label="Buy Current (USD) *"
                  error={
                    formik.touched.buyCurrentPrice &&
                    !_.isEmpty(formik.errors.buyCurrentPrice)
                  }
                  helperText={
                    formik.touched.buyCurrentPrice &&
                    formik.errors.buyCurrentPrice
                  }
                  value={formik.values.buyCurrentPrice || ""}
                  onChange={formik.handleChange}
                  onBlur={() => getSellPrice(formik.values.buyCurrentPrice)}
                />
              </Grid>
              <Grid item xs={6}>
                <NumberFormat
                  customInput={TextField}
                  error={
                    formik.touched.sellPrice &&
                    !_.isEmpty(formik.errors.sellPrice)
                  }
                  helperText={
                    formik.touched.sellPrice && formik.errors.sellPrice
                  }
                  value={formik.values.sellPrice || ""}
                  variant="standard"
                  sx={{ width: "100%" }}
                  id="sellPrice"
                  label="Giá bán (VND) *"
                  onChange={formik.handleChange}
                  size="medium"
                  thousandSeparator={true}
                />
              </Grid>

              <Grid item xs={6}>
                <NumberFormat
                  variant="standard"
                  type="text"
                  sx={{ width: "100%" }}
                  id="weight"
                  label="Cân nặng (KG) *"
                  error={
                    formik.touched.weight && !_.isEmpty(formik.errors.weight)
                  }
                  helperText={formik.touched.weight && formik.errors.weight}
                  value={formik.values.weight || ""}
                  onChange={formik.handleChange}
                  size="medium"
                  customInput={TextField}
                  thousandSeparator={true}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  type="text"
                  sx={{ width: "100%" }}
                  id="orgLink"
                  label="ORG Link *"
                  error={
                    formik.touched.orgLink && !_.isEmpty(formik.errors.orgLink)
                  }
                  helperText={formik.touched.orgLink && formik.errors.orgLink}
                  value={formik.values.orgLink || ""}
                  onChange={formik.handleChange}
                  size="medium"
                />
              </Grid>
              {!_.isEmpty(productDetail) && (
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                >
                  <Grid item xs={3}>
                    <Typography
                      sx={{ mb: 0, pb: 0, mt: 1, fontWeight: "bold" }}
                    >
                      Hiển thị:
                      <Switch
                        color="primary"
                        checked={isDisplay}
                        onChange={(e: any) => {
                          setIsDisplay(e.target.checked);
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{ mb: 0, pb: 0, mt: 1, fontWeight: "bold" }}
                    >
                      Xóa:
                      <Switch
                        color="error"
                        checked={recStatus}
                        onChange={(e: any) => {
                          setRecStatus(e.target.checked);
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{ mb: 0, pb: 0, mt: 1, fontWeight: "bold" }}
                    >
                      Còn hàng/ Hết hàng:
                      <Switch
                        color="success"
                        checked={stockStatus}
                        onChange={(e: any) => {
                          setStockStatus(e.target.checked);
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{ mb: 0, pb: 0, mt: 1, fontWeight: "bold" }}
                    >
                      Giá sốc
                      <Switch
                        color="success"
                        checked={topDeal}
                        onChange={(e: any) => {
                          setTopDeal(e.target.checked);
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Box
                className="style-4"
                sx={{
                  width: "100%",
                  height: 300,
                  overflowY: "auto",
                  ml: 3,
                  mt: 4,
                  borderWidth: 0.5,
                  borderStyle: "solid",
                  // background: "red",
                  p: 2,
                }}
              >
                <Stack spacing={2} sx={{ width: "100%" }}>
                  <Stack
                    direction={"row"}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      direction="row"
                      justifyContent="left"
                      alignItems="center"
                      rowSpacing={3}
                      // columnSpacing={1}
                    >
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            // textAlign: "center",
                            borderWidth: 0,
                            borderRightWidth: 0.2,
                            borderStyle: "solid",
                            fontWeight: "bold",
                          }}
                        >
                          Type name
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            // textAlign: "center",
                            pl: 1,
                            fontWeight: "bold",
                          }}
                        >
                          Description
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Divider
                    sx={{
                      bgcolor: "black",
                      mt: 1,
                    }}
                  />
                  {optionsList
                    ?.filter((y) => y.type !== "Image")
                    .map((x, index) => rowItem(index, x.type, x.value))}
                  <Button
                    onClick={() => {
                      let list = Array.from(optionsList);
                      list.push({ type: "", value: "" });
                      setOptionsList(list);
                    }}
                  >
                    Thêm mới
                  </Button>
                </Stack>
              </Box>
            </Grid>
            {/* <Stack sx={{ gridArea: "right", px: 2 }} spacing={2}>
              <Box>
                <TextField
                  variant="standard"
                  type="text"
                  sx={{ width: "100%" }}
                  id="name"
                  label="Name *"
                  error={formik.touched.name && !_.isEmpty(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  value={formik.values.name || ""}
                  onChange={formik.handleChange}
                  size="medium"
                  focused
                />
              </Box>
              <Box>
                <NumberFormat
                  customInput={TextField}
                  error={
                    formik.touched.buyCurrentPrice &&
                    !_.isEmpty(formik.errors.buyCurrentPrice)
                  }
                  helperText={
                    formik.touched.buyCurrentPrice &&
                    formik.errors.buyCurrentPrice
                  }
                  value={formik.values.buyCurrentPrice || ""}
                  variant="standard"
                  sx={{ width: "100%" }}
                  id="buyCurrentPrice"
                  label="Price ($) *"
                  onChange={formik.handleChange}
                  size="medium"
                  focused
                  thousandSeparator={true}
                />
              </Box>

              <Box>
                <TextField
                  variant="standard"
                  type="text"
                  sx={{ width: "100%" }}
                  id="skuNumber"
                  label="SkuNumber *"
                  error={
                    formik.touched.skuNumber &&
                    !_.isEmpty(formik.errors.skuNumber)
                  }
                  helperText={
                    formik.touched.skuNumber && formik.errors.skuNumber
                  }
                  value={formik.values.skuNumber || ""}
                  onChange={formik.handleChange}
                  size="medium"
                  focused
                />
              </Box>
              <Box>
                <TextField
                  variant="standard"
                  type="text"
                  sx={{ width: "100%" }}
                  id="model"
                  label="Model *"
                  error={
                    formik.touched.model && !_.isEmpty(formik.errors.model)
                  }
                  helperText={formik.touched.model && formik.errors.model}
                  value={formik.values.model || ""}
                  onChange={formik.handleChange}
                  size="medium"
                  focused
                />
              </Box>
              <Box>
                <TextField
                  variant="standard"
                  type="text"
                  sx={{ width: "100%" }}
                  id="brand"
                  label="Brand *"
                  error={
                    formik.touched.brand && !_.isEmpty(formik.errors.brand)
                  }
                  helperText={formik.touched.brand && formik.errors.brand}
                  value={formik.values.brand || ""}
                  onChange={formik.handleChange}
                  size="medium"
                  focused
                />
              </Box>

              <Box>
                <Box>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Ram</FormLabel>
                    <RadioGroup
                      id="ram"
                      row
                      name="ram"
                      value={formik.values.ram || ""}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value="4GB"
                        control={<Radio />}
                        label="4GB"
                      />
                      <FormControlLabel
                        value="8GB"
                        control={<Radio />}
                        label="8GB"
                      />
                      <FormControlLabel
                        value="16GB"
                        control={<Radio />}
                        label="16GB"
                      />
                      <FormControlLabel
                        value="32GB"
                        control={<Radio />}
                        label="32GB"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Disk</FormLabel>
                    <RadioGroup
                      row
                      id="disk"
                      name="disk"
                      value={formik.values.disk || ""}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value="120GB"
                        control={<Radio />}
                        label="120GB"
                      />
                      <FormControlLabel
                        value="128GB"
                        control={<Radio />}
                        label="128GB"
                      />
                      <FormControlLabel
                        value="256GB"
                        control={<Radio />}
                        label="256GB"
                      />
                      <FormControlLabel
                        value="512GB"
                        control={<Radio />}
                        label="512GB"
                      />
                      <FormControlLabel
                        value="1TB"
                        control={<Radio />}
                        label="1TB"
                      />
                      <FormControlLabel
                        value="2TB"
                        control={<Radio />}
                        label="2TB"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            </Stack> */}
          </form>
        </Box>
      </FormDialog>
      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}