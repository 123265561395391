import Cart from "components/HomeComponents/cart";
import MainMenuMobileView from "components/HomeComponents/header/components/MainMenuMobileView";
import { useEffect, useState } from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";
// import { Footer } from "../components";
import Header from "components/Header";
import Footer from "components/Footer";
import Copyright from "components/Copyright";
import { Layout } from "antd";
import ScrollToTop from "./_common/ScrollToTop";
import ZaloFloatingButton from "./_common/ZaloFloatingButton";
import { useCheckMaintenance } from "hooks/useCheckMaintenance";
import { CircularIndeterminate } from "components";
import MessageFab from "./_common/MessageFab";
import { Box } from "@mui/material";
import HotlineFloatingButton from "./_common/HotlineFloatingButton";

type TClientLayout = {
  children: React.ReactNode;
};
const ClientLayout = (props: TClientLayout) => {
  const { isChecking } = useCheckMaintenance();

  if (isChecking.value) {
    return <CircularIndeterminate loading />;
  }

  return (
    <Layout>
      {/* Header */}
      <Header />
      {/* End Header */}

      {/* End Main Menu */}
      {/* Page */}
      {props.children}
      {/* End PAge */}
      {/* Footer */}
      <Footer />
      <Copyright />
      <Cart />
      <MainMenuMobileView />
      <ScrollToTop />
      <Box
        sx={{
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          bottom: 30,
          right: 30,
          cursor: "pointer",
          zIndex: 9999,
          gap: 2,
        }}
      >
        <MessageFab />
        <ZaloFloatingButton />
        <HotlineFloatingButton />
      </Box>

      {/* End Footer */}
    </Layout>
  );
};

export default ClientLayout;
