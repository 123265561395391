import i18n from "i18next";
import en_translation from "./lang/en/translation.json";
import vn_translation from "./lang/vn/translation.json";
import { initReactI18next, useTranslation } from "react-i18next";
import { convertLanguageJsonToObject } from "./translations";
export { translations } from "./translations";

export enum ELanguage {
  EN = "en",
  VI = "vn",
}

convertLanguageJsonToObject(en_translation);
export const resources = {
  en: {
    translation: en_translation,
  },
  vn: {
    translation: vn_translation,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "vn",
  resources,
});

export const _t = (id: string): string => {
  if (!id) {
    id = "_NOT_TRANSLATED_";
  }
  return id;
};
export default i18n;
