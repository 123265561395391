import { ReduxHelper } from "helpers";
import { IProduct } from "models/IProductModel";

export const prefix = "CART";
export const addToCart = ReduxHelper.generateLocalAction<IProduct[]>(
  prefix,
  "ADD_TO_CART"
);
export const updateToCard = ReduxHelper.generateLocalAction<IProduct[]>(
  prefix,
  "UPDATE_TO_CART"
);
export const removeToCard = ReduxHelper.generateLocalAction<IProduct[]>(
  prefix,
  "REMOVE_TO_CART"
);

export const clear = ReduxHelper.generateLocalAction(prefix, "CLEAR");
export const clearAction = ReduxHelper.generateLocalAction(
  prefix,
  "CLEAR_ACTION"
);
