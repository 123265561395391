import { Image } from "antd";
import React from "react";
import IconZalo from "assets/images/icon/icon_zalo.png";
import { Fab } from "@mui/material";

export default function ZaloFloatingButton() {
  return (
    <Fab
      color="inherit"
      aria-label="messenger"
      sx={{
        backgroundColor: "transparent",
      }}
      onClick={() => window.open("https://zalo.me/haistorevn24", "_target")}
    >
      <Image src={IconZalo} preview={false} />
    </Fab>
  );
}
