import React, { PropsWithChildren, useContext } from "react";

import DialogProvider from "provider/dialog-context";

// custom loader component

type TAppContext = {
  loading?: boolean;
  showModalConfirm?: () => void;
};

export const AppContext = React.createContext<TAppContext>({});
export const useAppContext = () => useContext<TAppContext>(AppContext);
export const AppConsumer = AppContext.Consumer;
const AppProvider = (props: PropsWithChildren<any>) => {
  const showModalConfirm = () => {};
  const funcs = {
    showModalConfirm: showModalConfirm,
  };

  return (
    <AppContext.Provider value={{ ...funcs }}>
      <DialogProvider>
        {props.children}
        {/* other global component  */}
      </DialogProvider>
    </AppContext.Provider>
  );
};

export default AppProvider;
