import { ReduxHelper } from "helpers";
import { ILoginRequest } from "model/Identity/ILoginRequest";
import { ITokenResponse } from "model/Identity/ITokenResponse";
import { IUserInfo } from "model/Identity/IUserInfo";

export const prefix = "IDENTITY";

export const login = ReduxHelper.generateActions<ILoginRequest, ITokenResponse>(
  prefix,
  "LOGIN"
);
export const setToken = ReduxHelper.generateLocalAction<any>(
  prefix,
  "SET_TOKEN"
);
export const logout = ReduxHelper.generateLocalAction(prefix, "LOG_OUT");
export const getUserInfo = ReduxHelper.generateActions<void, IUserInfo>(
  prefix,
  "GET_USER_INFO"
);
export const setUserInfo = ReduxHelper.generateLocalAction<IUserInfo>(
  prefix,
  "SET_USER_INFO"
);
export const loginAdmin = ReduxHelper.generateLocalAction<IUserInfo | any>(
  prefix,
  "LOG_IN_ADMIN"
);
export const logoutAdmin = ReduxHelper.generateLocalAction(
  prefix,
  "LOG_OUT_ADMIN"
);
