import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { ToastHelper } from "helpers";
import { IProduct } from "models/IProductModel";
import { CartActions } from "redux/actions";
import { handleReducerBuilder, IBaseReducerState } from "utils/redux";

interface ICartState extends IBaseReducerState {
  carts: any[];
  checkoutCart: any[];
}

const initialState: ICartState = {
  action: "",
  carts: [],
  checkoutCart: [],
  prefix: CartActions.prefix,
};

const addToCart = (state: ICartState, action: PayloadAction<IProduct[]>) => {
  ToastHelper.toastSuccess("Cập nhật giỏ hàng thành công");
  return handleReducerBuilder(state, action, () => {
    state.carts = action.payload.map((item) => ({
      ...item,
    }));
  });
};

const updateToCard = (state: ICartState, action: PayloadAction<IProduct[]>) => {
  handleReducerBuilder(state, action, () => {
    state.carts = action.payload;
  });
};

const removeToCard = (state: ICartState, action: PayloadAction<IProduct[]>) => {
  ToastHelper.toastError("Xóa sản phẩm thành công");
  return handleReducerBuilder(state, action, () => {
    state.carts = action.payload;
  });
};

const clear = (state: ICartState, action: PayloadAction) =>
  handleReducerBuilder(state, action, () => {
    state.carts = [];
  });
const clearAction = (state: ICartState, action: PayloadAction) =>
  handleReducerBuilder(state, action, () => {
    state.action = "";
  });

const CartReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CartActions.addToCart.request, addToCart)
    .addCase(CartActions.updateToCard.request, updateToCard)
    .addCase(CartActions.removeToCard.request, removeToCard)
    .addCase(CartActions.clear.request, clear)
    .addCase(CartActions.clearAction.request, clearAction)
    .addDefaultCase(handleReducerBuilder);
});

export default CartReducer;
