import { Box, Stack, Typography } from "@mui/material";
import { Breadcrumb } from "components";
import IndexContextProvider from "./context";
import ListDeal from "./components/ListDeal";

const GoogleShopping = () => {
  return (
    <IndexContextProvider>
      <Box className="container">
        <Breadcrumb title="Danh sách sản phẩm" item="Danh sách sản phẩm" />
        <Stack alignItems="center" marginBottom={2} marginTop={-1}>
          <Typography variant="h1" fontWeight={800}>🔥HOT DEAL🔥</Typography>
        </Stack>
        <ListDeal />
      </Box>
    </IndexContextProvider>
  );
};

export default GoogleShopping;
