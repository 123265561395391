import { IProduct } from "models/IProductModel";
import { BaseApiService } from "./BaseApiService";

class ProductApiService extends BaseApiService {
  public getAllProduct = () => this.get<IProduct[]>("/get-all-products");

  public getAllProductPagination = (params: any) =>
    this.get<{
      totalItems: number;
      page: number;
      limit: number;
      results: IProduct[];
      resultsLength: number;
    }>("/pagination-admin", { params });

  public getAllProductWebsite = () =>
    this.get<IProduct[]>("/get-all-products-website");

  public getAllProductWebsiteByBrand = () =>
    this.get<any>("/get-all-products-website-group-by-brand");
  public getTopNewProduct = () => this.get<any>("/get-top-new-product");

  public getProductItem = (id: any) =>
    this.get<IProduct>(`/get-product-by-id?productId=${id}`);

  public getProductBySku = (sku: string) =>
    this.get<IProduct>(`/get-product-by-sku?productSku=${sku}`);

  public getCurrentPriceByProductItem = (id: string) =>
    this.get<IProduct>(`/get-current-price-product-by-id?productId=${id}`);
  public updateProduct = (param: any) =>
    this.put<any, any>("/update-product", param);

  public createProduct = (params: any) =>
    this.post<any, any>("/create-product", params);

  public getAllProductFilter = (params: any) =>
    this.get<any>("/pagination-client", { params });

  public getAllProductFilter2 = (params: any) =>
    this.get<any>("/pagination-client-v2", { params });

  public getDailyProduct = () =>
    this.get<any>("/get-daily-deal-product-client");
  public getPriceHistoryById = (params: any) =>
    this.get<any>("/get-product-price-history-by-product-id", { params });
  public getAllDailyDealProduct = (params?: {
    page?: number;
    limit?: number;
  }) => this.get<any>("/get-all-daily-deal-product", { params });
}

export default new ProductApiService("/products");
