import { MenuItem, TextField, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { HeaderContainer } from "components";
import { AlertHelper, DateHelper, ToastHelper } from "helpers";
import { AlertType } from "helpers/alertHelper";
import { AdminLayout } from "layouts";
import {
  IShipment,
  IShipmentUpdateRequest,
  ListShipmentDetailQueryResponse,
} from "models/IShipment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CodeTypeApiService, ShipmentApiService } from "services";
import { ICodeType } from "models/ICodeType";
import AdminContainer from "../Container";

export default function ShipmentDetail() {
  const location = useLocation();
  const shipmentId = location.search.split("=")[1];
  const [shipmentDetail, setShipmentDetail] = useState<IShipment>();
  const [codeType, setCodeType] = useState<ICodeType[]>([]);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    (async () => {
      const response: any = await CodeTypeApiService.getAllCodeTypeByType(
        "SHIPMENTITEM"
      );
      if (response?.succeeded) {
        setCodeType(
          response.data.sort(
            (n1: ICodeType, n2: ICodeType) => n1.code - n2.code
          )
        );
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response: any = await ShipmentApiService.getShipmentById(
        shipmentId
      );
      if (response?.data) {
        setShipmentDetail(response.data);
      }
    })();
  }, [reload]);

  const handleChangeStatus = async (
    item: ListShipmentDetailQueryResponse,
    status: number
  ) => {
    const param: IShipmentUpdateRequest = {
      shipmentHeaderIdUpdate: item.shipmentHeaderId,
      shipmentDetailIdUpdate: item.id,
      shipmentDetailStatusUpdate: status,
      userId: shipmentDetail?.userId,
    };
    const response: any = await ShipmentApiService.updateShipment(param);
    if (response.succeeded) {
      ToastHelper.toastError("Update shipment successfully!");
      setReload(!reload);
    }
  };
  return (
    <AdminContainer>
      {/* <!-- Container-fluid starts--> */}
      <HeaderContainer title={"Shipment Detail"} />
      {/* <!-- Container-fluid Ends--> */}
      {/* <!-- Container-fluid starts--> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <Typography variant="h6" sx={{ textTransform: "uppercase" }}>
                  Shipment header: {shipmentDetail?.id}
                </Typography>
              </div>
              <div className="card-body">
                <div
                  id="basicScenario"
                  className="product-physical table-responsive"
                >
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      stickyHeader
                      size="medium"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Shipment Detail ID</TableCell>
                          <TableCell>Image</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Time</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {shipmentDetail?.listShipmentDetailQueryResponse.map(
                          (item: any) => (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {item.shipmentHeaderId}
                              </TableCell>
                              <TableCell>
                                <img
                                  src={item?.productResponse.mainImage}
                                  alt=""
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "62px",
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                {item?.productResponse?.name}
                              </TableCell>
                              <TableCell>
                                {DateHelper.mmddyyyyFormat(item.updatedDt)}
                              </TableCell>
                              <TableCell>
                                {DateHelper.getTimeFormat(item.updatedDt)}
                              </TableCell>
                              <TableCell>
                                <TextField
                                  select
                                  variant="standard"
                                  sx={{ width: "100%" }}
                                  value={item.shipmentItemStatus}
                                  onChange={(e: any) =>
                                    handleChangeStatus(item, e.target.value)
                                  }
                                  size="medium"
                                >
                                  {codeType.map((x: ICodeType) => (
                                    <MenuItem value={x.code}>{x.name}</MenuItem>
                                  ))}
                                </TextField>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}
