import { compose, applyMiddleware, legacy_createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootReducers, { RootReducer } from "./rootReducer";
import rootSagas from "./rootSagas";
import _ from "lodash";
import { PersistConfig } from "redux-persist/es/types";
import i18n from "utils/i18n";

const config: PersistConfig<RootReducer> = {
  key: "root",
  timeout: 0,
  storage: storage,
  debug: true,
  blacklist: ["AppReducer"],
};
const sagaMiddleware = createSagaMiddleware();
const middleware: any = [];
middleware.push(sagaMiddleware);
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  middleware.push(createLogger());
} else {
  // production code
}
const reducers = persistReducer(config, rootReducers);
const enhancers = [applyMiddleware(...middleware)];
const persistConfig = { enhancers };
const store = legacy_createStore(
  reducers,
  undefined,
  compose(...enhancers) as any
);
const persistor = persistStore(store, persistConfig as any, () => {
  const stateData = store.getState();
  const language = stateData.AppConfigReducer.language;
  i18n.changeLanguage(language);
});
const configureStore = () => {
  return { persistor, store };
};
sagaMiddleware.run(rootSagas);
export default configureStore;
