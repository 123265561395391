import * as React from "react";
import Input from "@mui/material/Input";
import { Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import _ from "lodash";

const SearchInput = (props: {
  onSearch: (value: string) => void;
  placeholder?: string;
}) => {
  const [value, setValue] = React.useState("");

  return (
    <Stack
      direction="row"
      sx={{
        borderWidth: 0.1,
        borderStyle: "solid",
        px: 1,
        py: 0.1,
        borderRadius: 2,
      }}
    >
      <Input
        disableUnderline
        value={value}
        onChange={(e) => {
          if (_.isEmpty(e.target.value)) {
            setValue("");
            props.onSearch("");
          } else {
            setValue(e.target.value);
            _.delay(() => props.onSearch(e.target.value), 1000);
          }
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            props.onSearch(value);
          }
        }}
        autoFocus
        placeholder={props.placeholder}
        fullWidth
        sx={{ py: 0.1 }}
      />
      <Stack
        sx={{ cursor: "pointer" }}
        justifyContent="center"
        alignItems="center"
        component={"div"}
        onClick={() => props.onSearch(value)}
      >
        <SearchIcon style={{ color: "#0046be" }} />
      </Stack>
    </Stack>
  );
};

export default SearchInput;
