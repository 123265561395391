import { combineReducers } from "redux";

import {
  UserReducer,
  IdentityReducer,
  AlertReducer,
  AppConfigReducer,
  ProductReducer,
  CartReducer,
  OrderReducer,
  WishlistReducer,
} from "../reducers";

const rootReducer = combineReducers({
  UserReducer,
  IdentityReducer,
  AlertReducer,
  AppConfigReducer,
  ProductReducer,
  CartReducer,
  OrderReducer,
  WishlistReducer,
});

export type RootReducer = ReturnType<typeof rootReducer>;
export default rootReducer;
