import { Key } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Input,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { FormDialog, HeaderContainer } from "components";
import AppTable from "components/app-table";
import { TableHelper, ToastHelper } from "helpers";
import { AlertType, showAlert } from "helpers/alertHelper";
import _ from "lodash";
import { IUser } from "models/IIdentity";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootReducer } from "redux/configurations/rootReducer";
import { IdentityApiService } from "services";
import AdminContainer from "../Container";

const UserInfo = (props: { title: string; value: string }) => {
  const { title, value } = props;
  return (
    <Stack direction="row" spacing={3}>
      <Typography
        sx={{ fontWeight: 600, paddingTop: 1, minWidth: 150, color: "#000" }}
      >
        {title}
      </Typography>
      <Input value={value} fullWidth />
    </Stack>
  );
};

export default function UserManagement() {
  const user = useSelector((state: RootReducer) => state.IdentityReducer.admin);
  const [users, setUsers] = useState<any>();
  const [userDetail, setUserDetail] = useState<IUser>();
  const [openModal, setOpenModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [modalPassword, setModalPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllUsers = async () => {
      const users: any = await IdentityApiService.getAllUser({
        email: searchValue,
        page: page,
        limit: 10,
      });
      if (users.succeeded && !_.isEmpty(users.data)) {
        setUsers(users.data);
        setTotalPage(Math.ceil((users.data?.totalItems || 0) / 10));
      } else {
        showAlert("Get users error!", AlertType.ERROR);
      }
    };
    getAllUsers();
  }, [reload, searchValue, page]);

  useEffect(() => {
    if (openModal) {
      setUserDetail({} as IUser);
    }
  }, [openModal]);

  const handleClick = async (row: any) => {
    setOpenModal(true);
    const userDetail: any = await IdentityApiService.getUserById(row.id);
    if (userDetail.succeeded && !_.isEmpty(userDetail.data)) {
      setUserDetail(userDetail.data);
    } else {
      ToastHelper.toastError("Lấy thông tin user thất bại");
    }
  };

  const resetPassword = async () => {
    setLoading(true);
    const res = await IdentityApiService.resetPassword({
      id: userDetail?.id,
      password: newPassword,
    });
    setLoading(false);
    setModalPassword(false);
    if (res?.succeeded) {
      ToastHelper.toastSuccess("Đổi mật khẩu thành công");
    } else {
      ToastHelper.toastError("Lỗi đổi mật khẩu");
    }
  };

  const onOk = () => {
    setConfirmModal(true);
  };

  const onConfirmOk = async () => {
    const param: any = {
      userId: user?.userId,
      id: userDetail?.id,
      isLocked: userDetail?.isLocked,
    };
    const response: any = await IdentityApiService.updateUserStatus(param);
    if (response.succeeded && !_.isEmpty(response?.data?.id)) {
      ToastHelper.toastSuccess("Cập nhật thông tin thành công");
      setConfirmModal(false);
      setOpenModal(false);
      setReload(!reload);
    } else {
      ToastHelper.toastError("Cập nhật thất bại!");
    }
  };

  const columns = React.useMemo(
    () => [
      TableHelper.createColumn({
        field: "userName",
        headerName: "Tài khoản",
      }),
      TableHelper.createColumn({
        field: "lastName",
        headerName: "Họ",
      }),
      TableHelper.createColumn({
        field: "firstName",
        headerName: "Tên",
      }),
      TableHelper.createColumn({
        field: "phone",
        headerName: "Số điện thoại",
      }),

      TableHelper.createColumn({
        field: "authenFrom",
        headerName: "Loại",
      }),
      TableHelper.createColumn({
        field: "address",
        width: 700,
        headerName: "Địa chỉ",
      }),
    ],
    []
  );
  return (
    <AdminContainer>
      {/* <!-- Container-fluid starts--> */}
      <HeaderContainer title={"Người dùng"} />
      {/* <SearchInput onSearch={(value) => console.log(value)} /> */}
      <AppTable<any, any, any>
        api={IdentityApiService.getAllUser}
        columns={columns}
        onEditRowClick={handleClick}
        renderActions={(row) => {
          return (
            <IconButton
              sx={{
                justifyContent: "center",
              }}
              onClick={() => {
                setModalPassword(true);
              }}
            >
              <Key sx={{ color: "green", fontSize: 20 }} />
            </IconButton>
          );
        }}
      />

      <FormDialog
        maxWidth={"lg"}
        visible={openModal}
        title={`${userDetail?.firstName || ""} ${userDetail?.lastName || ""}`}
        cancelText="Close"
        onCancel={() => setOpenModal(false)}
        onOk={onOk}
        open={openModal}
      >
        {!_.isEmpty(userDetail) && !_.isUndefined(userDetail?.isLocked) ? (
          <Grid container spacing={2} columnSpacing={4} sx={{ pt: 2 }}>
            <Grid item md={6} sm={12}>
              <UserInfo
                title="Tên người dùng: "
                value={`${userDetail?.userName}`}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <UserInfo
                title="Đăng nhập từ: "
                value={`${userDetail?.authenFrom}`}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <UserInfo title="Email: " value={`${userDetail?.email}`} />
            </Grid>
            <Grid item md={6} sm={12}>
              <UserInfo title="SĐT: " value={`${userDetail?.phone}`} />
            </Grid>
            <Grid item md={6} sm={12}>
              <UserInfo
                title="Giới tính: "
                value={`${userDetail?.gender === 0 ? "Nam" : "Nữ"}`}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <UserInfo
                title="Địa chỉ: "
                value={`${userDetail?.address || ""} ${userDetail?.city || ""}`}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <UserInfo title="Quyền: " value={`${userDetail?.role}`} />
            </Grid>
            {/* <Grid item md={6} sm={12}>
              <UserInfo
                title="CCCD: "
                value={`${userDetail?.citizenIdentityCard || ""}`}
              />
            </Grid> */}
            <Grid item md={6} sm={12}>
              <UserInfo
                title="Tiền trong tài khoản: "
                value={`${userDetail?.accountBalance}`}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <Stack direction="row" spacing={3}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    paddingTop: 1,
                    minWidth: 140,
                    color: "#000",
                  }}
                >
                  Lock
                </Typography>
                <Box sx={{ paddingTop: 1 }}>
                  <Switch
                    checked={userDetail?.isLocked === 1 ? true : false}
                    onChange={(e, value) =>
                      setUserDetail({
                        ...userDetail,
                        isLocked: value === true ? 1 : 0,
                      } as IUser)
                    }
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pt: 5,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </FormDialog>
      <FormDialog
        visible={modalPassword}
        title={"Đổi mật khẩu"}
        onCancel={() => setModalPassword(false)}
        onOk={resetPassword}
        open={false}
      >
        <Typography variant="h6">Mật khẩu mới</Typography>
        <TextField
          variant="standard"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </FormDialog>
      <FormDialog
        visible={confirmModal}
        title={"Confirm"}
        onCancel={() => setConfirmModal(false)}
        onOk={() => onConfirmOk()}
        open={false}
      >
        <Typography variant="h6">
          bạn có chắc chắn muốn thực hiện hành động này?
        </Typography>
      </FormDialog>
      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}
