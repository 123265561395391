import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Grid, IconButton, MenuItem, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { decode as base64_decode } from "base-64";
import { FormDialog, HeaderContainer } from "components";
import {
  TableColumName,
  TableFooter,
  TableHeaderSearch,
} from "components/AdminComponents/AdminTable";
import { useFormik } from "formik";
import {
  AlertHelper,
  ConfirmModalHelper,
  CurrencyHelper,
  ToastHelper,
} from "helpers";
import { AlertType, showAlert } from "helpers/alertHelper";
import { AdminLayout } from "layouts";
import _, { isEmpty } from "lodash";
import { IProduct } from "models/IProductModel";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { RootReducer } from "redux/configurations/rootReducer";
import UploadBaseService from "services/UploadBaseService";
import { DailyDealPositionApiService, ProductApiService } from "services";

import { Box } from "@mui/system";
import "./styles.css";
import AdminContainer from "../../Container";
import AppTable from "components/app-table";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { ArtTrack, Check } from "@mui/icons-material";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const Input = styled("input")({
  display: "none",
});

interface IProducts {
  limit: number;
  page: number;
  results: IProduct[];
  resultsLength: number;
  totalItems: number;
}

export default function TopDealsConfiguration() {
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: RootReducer) => state.IdentityReducer.admin);
  const [items, setItems] = React.useState([1, 2, 3, 4]);
  const [allDailyDeal, setAllDailyDeal] = useState<any[]>([]);
  const [currentDeal, setCurrentDeal] = useState<any>([]);
  const [products, setProducts] = useState<IProducts>();
  const [modalVisible, setModalVisible] = useState(false);
  const [addDailyDealVisible, setAddDailyDealVisible] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [listFileImage, setListFileImage] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [idHover, setIdHover] = useState<string>();
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const maxSteps = !_.isEmpty(images) ? images.length : 0;
  const [filterStatus, setFilterStatus] = useState<number>(2);
  const [reselectDailyDealId, setReselectDailyDealId] = useState<
    number | undefined
  >();

  const [optionsList, setOptionsList] = useState<
    {
      type?: string;
      value?: string;
    }[]
  >([
    {
      type: "Ram",
      value: "8GB",
    },
    {
      type: "Disk",
      value: "128GB",
    },
  ]);

  const [dailyDeal, setDailyDeal] = useState<any[]>([]);

  useEffect(() => {
    getDailyDealProduct();
    getAllDailyDealProduct();
  }, []);

  const getDailyDealProduct = async () => {
    const response = await ProductApiService.getDailyProduct();
    if (response?.succeeded) {
      setDailyDeal(response.data);
    } else {
      showAlert("Get top deals failed", AlertType.ERROR);
    }
  };

  const getAllDailyDealProduct = async () => {
    const response = await ProductApiService.getAllDailyDealProduct({
      limit: 1000000,
      page: 1,
    });
    if (response?.succeeded) {
      setAllDailyDeal(response.data.results || []);
    } else {
      showAlert("Get top deals failed", AlertType.ERROR);
    }
  };

  const createNewDailyDealPosition = async (newDailyDeal: any[]) => {
    setLoading(true);
    const params = {
      listPostionDailyRequest: newDailyDeal,
    };
    const response = await DailyDealPositionApiService.createDailyDealPosition(
      params
    );
    setLoading(false);

    if (response?.succeeded) {
      ToastHelper.toastSuccess("Tạo danh sách thành công");
      getDailyDealProduct();
    } else {
      ToastHelper.toastError("Tạo danh sách thất bại");
    }
  };

  const formik = useFormik({
    initialValues: dailyDeal,
    enableReinitialize: true,
    onSubmit: async (values: any, action: any) => {
      createNewDailyDealPosition(
        values.map((e, i) => ({
          productId: e.id,
          position: i,
        }))
      );
    },
  });

  const handleClick = (row: any) => {
    history.push({
      pathname: "/product-detail",
      search: `?id=${row.id}`,
    });
  };

  const handelSaveNewDeal = () => {
    const newDeal = { edited: true, ...currentDeal };
    let arr: any[] = formik.values.map((e, i) =>
      i !== reselectDailyDealId ? e : newDeal
    );

    setDailyDeal(arr);
    setModalVisible(false);
  };

  const handelAddNewDeal = () => {
    const newDeal = { edited: true, ...currentDeal };
    let arr = [...formik.values];
    arr.push(newDeal);
    setDailyDeal(arr);
    setAddDailyDealVisible(false);
  };

  const addNewDailyDeal = () => {
    setAddDailyDealVisible(true);
  };

  const renderTableBody = () => {
    return (
      <table
        id="responsive-data-table"
        className="table dataTable no-footer"
        style={{ width: "100%", cursor: "pointer", marginTop: 16 }}
        aria-describedby="responsive-data-table_info"
      >
        <thead>
          <tr>
            <TableColumName title="STT" />
            <TableColumName title="Hình ảnh" />
            <TableColumName title="Tên SP" />
            <TableColumName title="Hãng" />
            <TableColumName title="Model" />
            <TableColumName title="Sku" />
            <TableColumName title="Giá hiện tại" />
            <TableColumName title="Giá mặc định" />
            <TableColumName title="Giá bán" />
            <TableColumName title="Hành động" />
          </tr>
        </thead>
        <tbody>
          {formik.values?.map((row: any, i: number) => {
            let re =
              /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
            let imageMain = re.test(row.mainImage)
              ? base64_decode(row.mainImage)
              : row.mainImage;
            return (
              <tr
                key={row.id}
                style={{
                  height: 70,
                  backgroundColor:
                    idHover === row.id
                      ? "rgba(61, 133, 198, 0.2)"
                      : row.edited
                      ? "#FF0"
                      : "",
                }}
                onMouseEnter={() => setIdHover(row.id)}
                onMouseLeave={() => setIdHover(undefined)}
              >
                <td className="sorting_1">{++i}</td>
                <td
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={imageMain}
                    alt=""
                    style={{
                      maxWidth: "100px",
                      maxHeight: "62px",
                    }}
                    onClick={() => handleClick(row)}
                  />
                </td>
                <td>{row.name}</td>
                <td>{row.brand}</td>
                <td>{row.model}</td>
                <td>{row.skuNumber}</td>
                <td>${row.buyCurrentPrice}</td>
                <td>${row.buyRegularPrice || "0.00"}</td>
                <td>{CurrencyHelper.vnFormatter(row.sellPrice)}</td>
                <td
                  onClick={() => {
                    console.log("====================================");
                    console.log(i - 1);
                    console.log("====================================");
                    setReselectDailyDealId(i - 1);
                    setModalVisible(true);
                  }}
                >
                  <EditIcon style={{ color: "rgba(61, 133, 198)" }} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "mainImage",
      headerName: "Ảnh",
      width: 130,
      renderCell: ({ value }) => (
        <img
          src={value}
          alt=""
          style={{
            height: "70px",
            width: "70px",
          }}
          // onClick={() => handleClick(row)}
        />
      ),
    },
    {
      field: "id",
      headerName: "Trạng thái",
      width: 200,
      renderCell: ({ value }) => {
        const ids = dailyDeal.map((deal) => deal.id);
        const isDisplay = ids.includes(value);
        return (
          <Typography
            sx={{
              p: "3px 8px",
              backgroundColor: isDisplay ? "green" : "red",
              color: "white",
              borderRadius: "3px",
            }}
          >
            {isDisplay ? "Hiển thị" : "Không hiển thị"}
          </Typography>
        );
      },
    },
    { field: "skuNumber", headerName: "SKU", width: 130 },
    { field: "name", headerName: "Tên sản phẩm", minWidth: 400, flex: 1 },
    { field: "brand", headerName: "Hãng", width: 200 },
    {
      field: "tempSellCurrentPrice",
      headerName: "Giá bán",
      align: "right",
      width: 200,
      renderCell: ({ value }) => (
        <Typography fontWeight={"bold"}>
          {CurrencyHelper.vnFormatter(value)}
        </Typography>
      ),
    },
  ];

  return (
    <AdminContainer loading={loading}>
      <HeaderContainer
        title="Cấu hình mục giá sốc"
        otherStyle
        rightComponent={
          <>
            <button
              style={{
                // color: "white",
                minWidth: 100,
                fontWeight: "bold",
                color: "white",
                backgroundColor: "#0046be",
                borderRadius: 10,
                paddingInline: 8,
                paddingBlock: 4,
              }}
              onClick={addNewDailyDeal}
            >
              Thêm
            </button>
            <button
              style={{
                // color: "white",
                minWidth: 100,
                fontWeight: "bold",
                color: "white",
                backgroundColor: "#0046be",
                borderRadius: 10,
                paddingInline: 8,
                paddingBlock: 4,
              }}
              onClick={formik.submitForm}
            >
              Lưu
            </button>
          </>
        }
      />
      {/* 
      <GridContextProvider onChange={onChange}>
        <GridDropZone
          id="items"
          boxesPerRow={8}
          rowHeight={200}
          style={{ height: "460px" }}
        >
          {dailyDeal.map((item, index) => (
            <GridItem key={item}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    top: 20,
                    right: 20,
                    borderRadius: "10px",
                    height: "20px",
                    width: "20px",
                    backgroundColor: "#00000010",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {index + 1}
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    padding: "10px",
                    backgroundColor: "#00000008",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                    }}
                  >
                    <img
                      src={item.mainImage}
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      height: "48px",
                      overflow: "hidden",
                    }}
                  >
                    <span
                      style={{
                        overflow: "hidden",
                        fontWeight: "600",
                        fontSize: "12px",
                        lineHeight: 1.0,
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 3,
                      }}
                    >
                      {item.name}
                    </span>
                  </div>
                  <div
                    style={{
                      height: "50px",
                      marginTop: "2px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {CurrencyHelper.vnFormatter(item.tempSellCurrentPrice)}
                    </span>
                  </div>
                </div>
              </div>
            </GridItem>
          ))}
        </GridDropZone>
      </GridContextProvider> */}
      {/* <AppTable<any, any, any>
        api={ProductApiService.getAllDailyDealProduct}
        columns={columns}
        onEditRowClick={handleClick}
        rowHeight={80}
        height={700}
        renderActions={(row) => {
          return (
            <IconButton
              sx={{
                justifyContent: "center",
              }}
              onClick={() => {
                const ids = dailyDeal.map((deal) => deal.id);
                const isDisplay = ids.includes(row.id);
                ConfirmModalHelper.confirm({
                  message: isDisplay
                    ? "Bạn có muốn xóa sản phẩm này khỏi danh sách giá sốc"
                    : "Bạn có muốn thêm sản phẩm này khỏi danh sách giá sốc",
                  onConfirm: () => onUpdateDailyPosition(row, isDisplay),
                });
              }}
            >
              <ArtTrack sx={{ color: "green", fontSize: 20 }} />
            </IconButton>
          );
        }}
      /> */}

      <div className="row">
        <div className="col-12">
          <div className="card card-default" style={{ borderRadius: 16 }}>
            <div className="card-body">
              <div className="table-responsive">
                <div
                  id="responsive-data-table_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <TableHeaderSearch
                    onSearch={(value) => setSearchValue(value)}
                    rightComponent={
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{ minWidth: 320 }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "#000",
                            fontWeight: "300",
                          }}
                        >
                          Trạng thái
                        </Typography>
                        <TextField
                          fullWidth
                          sx={{ flex: 1 }}
                          variant="standard"
                          select
                          value={filterStatus}
                          onChange={(e) =>
                            setFilterStatus(Number(e.target.value))
                          }
                          defaultValue={filterStatus}
                        >
                          {[
                            { id: "2", code: 2, name: "Tất cả", type: "" },
                            { id: "1", code: 1, name: "Còn hàng", type: "" },
                            { id: "0", code: 0, name: "Hết hàng", type: "" },
                          ].map((x) => (
                            <MenuItem value={x.code}>{x.name}</MenuItem>
                          ))}
                        </TextField>
                      </Stack>
                    }
                  />
                  {renderTableBody()}
                  <TableFooter
                    currentPage={products?.page || 0}
                    onChangePage={(page) => setPage(page)}
                    totalPage={totalPage}
                  />
                  <div className="clear" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormDialog
        cancelText="Đóng"
        okText="Thay thế"
        visible={modalVisible}
        title={"Đổi sản phẩm"}
        disabledBtnOK={isEmpty(currentDeal)}
        onCancel={() => {
          setCurrentDeal(undefined);
          setModalVisible(false);
        }}
        onOk={handelSaveNewDeal}
        open={false}
        containerStyles={{
          minWidth: window.innerWidth - 300,
          height: window.innerHeight,
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <DataGrid
            rows={allDailyDeal
              .filter(
                (e) => !formik.values.map((item) => item.id).includes(e.id)
              )
              .map((e, i) => {
                return {
                  no: i + 1,
                  ...e,
                };
              })}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[10]}
            rowHeight={70}
            onCellClick={(params) => {
              setCurrentDeal(params.row);
            }}
          />
        </Box>
      </FormDialog>
      <FormDialog
        cancelText="Đóng"
        okText="Thêm"
        visible={addDailyDealVisible}
        title={"Thêm sản phẩm"}
        disabledBtnOK={isEmpty(currentDeal)}
        onCancel={() => {
          setCurrentDeal(undefined);
          setAddDailyDealVisible(false);
        }}
        onOk={handelAddNewDeal}
        open={false}
        containerStyles={{
          minWidth: window.innerWidth - 300,
          height: window.innerHeight,
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <DataGrid
            rows={allDailyDeal
              .filter(
                (e) => !formik.values.map((item) => item.id).includes(e.id)
              )
              .map((e, i) => {
                return {
                  no: i + 1,
                  ...e,
                };
              })}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[10]}
            rowHeight={70}
            onCellClick={(params) => {
              setCurrentDeal(params.row);
            }}
          />
        </Box>
      </FormDialog>
      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}
