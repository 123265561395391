/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";
import { Delete, Edit, Search } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridValidRowModel,
} from "@mui/x-data-grid";
import AppButton from "components/client/app-button";
import { ToastHelper } from "helpers";
import { isEmpty, isUndefined } from "lodash";
import { IBasePaginationRequest } from "model/Base/IBasePaginationRequest";
import { IBasePaginationResponse } from "model/Base/IBasePaginationResponse";
import React, { DependencyList, useCallback } from "react";

type AppTableProps<
  T extends GridValidRowModel,
  P extends IBasePaginationRequest,
  R extends IBasePaginationResponse<T[]>
> = {
  reload?: boolean;
  height?: number;
  deps?: DependencyList;
  data?: any[];
  columns?: GridColDef<T>[];
  params?: P;
  rowHeight?: number;
  api?: (params: P) => Promise<R>;
  onAddNewClick?: () => void;
  onEditRowClick?: (row: T) => void;
  onDeleteRowClick?: (row: T) => void;
  onLoadDataComplete?: () => void;
  renderActions?: (row: T) => JSX.Element;
};

export default function AppTable<
  T extends GridValidRowModel,
  P extends IBasePaginationRequest,
  R extends IBasePaginationResponse<T[]>
>(props: AppTableProps<T, P, R>) {
  const {
    columns = [],
    reload,
    api,
    params,
    deps,
    height,
    rowHeight = 40,
    onAddNewClick,
    onEditRowClick,
    onDeleteRowClick,
    onLoadDataComplete,
    renderActions,
  } = props;

  let searchWaiting: ReturnType<typeof setTimeout>;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(1);
  const [searchText, setSearchText] = React.useState<string>("");
  const [data, setData] = React.useState<T[]>([]);
  const [limit, setLimit] = React.useState<number>(50);
  const [totalResult, setTotalResult] = React.useState<number>(0);

  const getData = async () => {
    setLoading(true);
    api?.({
      page,
      limit,
      searchText,
      name: searchText,
      link: searchText,
      ...params,
    } as P)
      .then((res) => {
        setTotalResult(res.data.totalItems);
        setData(res.data.results || []);
      })
      .catch((err) => {
        ToastHelper.toastError("Xảy ra lỗi khi lấy dữ liệu");
      })
      .finally(() => {
        setLoading(false);
        onLoadDataComplete?.();
      });
  };

  const onSearchTextChange = (value: string) => {
    if (searchWaiting) {
      clearTimeout(searchWaiting);
    }
    searchWaiting = setTimeout(() => {
      setSearchText(value);
    }, 1000);
  };

  React.useEffect(() => {
    if (!reload) {
      getData();
    }
  }, [page, searchText, ...(deps || [])]);

  React.useEffect(() => {
    if (reload) {
      getData();
    }
  }, [reload]);

  const Action = React.memo((row: any) => {
    const onEdit = () => {
      onEditRowClick?.(row);
    };
    const onDelete = () => {
      onDeleteRowClick?.(row);
    };
    return (
      <div className="d-flex flex-wrap gap-2 justify-content-center align-items-center">
        <IconButton
          sx={{
            display: isUndefined(onEditRowClick) ? "none" : "flex",
            justifyContent: "center",
          }}
          onClick={onEdit}
        >
          <Edit sx={{ color: "#2a87fa", fontSize: 20 }} />
        </IconButton>
        <IconButton
          sx={{
            display: isUndefined(onDeleteRowClick) ? "none" : "flex",
            justifyContent: "center",
          }}
          onClick={onDelete}
        >
          <Delete sx={{ color: "#fa6e64", fontSize: 20 }} />
        </IconButton>
        {renderActions?.(row)}
      </div>
    );
  });
  const renderColumn = [
    {
      field: "no",
      headerName: "STT",
      width: 80,
      align: "center",
      headerAlign: "center",
    } as GridColDef<T>,
    {
      field: "action",
      headerName: "Hành động",
      width: 200,
      align: "center",
      renderCell: ({ row }) => {
        return <Action {...row} />;
      },
    } as GridColDef<T>,
  ].concat(columns);

  const renderData = React.useMemo(() => {
    return isEmpty(data)
      ? []
      : data.map((e, i) => ({ no: i + 1 + (page - 1) * limit, ...e }));
  }, [data]);

  const calculatePagesCount = (pageSize, totalCount) => {
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  };
  return (
    <div style={{ display: "flex", height: height ?? "100%" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          initialState={{
            columns: {
              columnVisibilityModel: {
                action:
                  !isUndefined(onEditRowClick) ||
                  !isUndefined(onDeleteRowClick),
              },
            },
          }}
          columnBuffer={columns.length}
          loading={loading}
          rows={renderData}
          columns={renderColumn}
          pageSize={50}
          rowsPerPageOptions={[50]}
          getRowId={(row) => row.no}
          components={{
            Toolbar: GridToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: NoRowsOverlay,
            Pagination: () => (
              <CustomPagination
                page={page}
                count={calculatePagesCount(limit, totalResult)}
                onPageChange={(_, value) => {
                  setPage(value);
                }}
              />
            ),
          }}
          componentsProps={{
            toolbar: {
              onAddNewClick: onAddNewClick,
              onChange: onSearchTextChange,
            },
          }}
          rowHeight={rowHeight}
          sx={{
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              width: "0.5em",
              height: "0.5em",
              borderRadius: "6px",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
            ".MuiDataGrid-cell:focus": {
              outline: "none",
            },
            // pointer cursor on ALL rows
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
          disableSelectionOnClick
        />
      </div>
    </div>
  );
}

function CustomPagination(props: {
  page: number;
  count: number;

  onPageChange: (event: any, value: number) => void;
}) {
  const { page, count, onPageChange } = props;
  return (
    <Pagination
      color="standard"
      defaultPage={1}
      count={count}
      page={page}
      onChange={onPageChange}
    />
  );
}

const SearchInput = React.memo(
  (props: { onChange: (value: string) => void }) => {
    const { onChange } = props;
    const [searchText, setSearchText] = React.useState("");

    const onSearch = useCallback((event: any) => {
      setSearchText(event.target.value);
      onChange?.(event.target.value);
    }, []);
    return (
      <TextField
        value={searchText}
        fullWidth
        id="outlined-basic"
        variant="outlined"
        placeholder="Tìm kiếm"
        InputProps={{
          endAdornment: <Search />,
          sx: { height: "36px" },
        }}
        onChange={onSearch}
      />
    );
  }
);

const GridToolbar = React.memo(
  (props: {
    onAddNewClick?: () => void;
    onChange?: (value: string) => void;
  }) => {
    const { onAddNewClick, onChange } = props;

    return (
      <GridToolbarContainer sx={{ px: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <Grid container item xs={6} direction={"row"} spacing={2}>
            <Grid item xs={8}>
              {onChange && <SearchInput onChange={onChange} />}
            </Grid>
            <Grid item xs={4}>
              {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={10}
                  label="Age"
                  sx={{
                    height: "36px",
                  }}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl> */}
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <AppButton
              label="Thêm mới"
              variant="primary"
              icon="mdi mdi-plus"
              onClick={onAddNewClick}
              style={{
                height: "36px",
                display: onAddNewClick ? "flex" : "none",
              }}
            ></AppButton>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }
);

const StyledGridOverlay = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: "#aeb8c2",
  },
  "& .ant-empty-img-2": {
    fill: "#f5f5f7",
  },
  "& .ant-empty-img-3": {
    fill: "#dce0e6",
  },
  "& .ant-empty-img-4": {
    fill: "#fff",
  },
  "& .ant-empty-img-5": {
    fillOpacity: "0.8",
    fill: "#f5f5f5",
  },
}));
function NoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>Không có dữ liệu</Box>
    </StyledGridOverlay>
  );
}
