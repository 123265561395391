export const getDealSpecs = (dealSpecs: any[]) => {
    const specs: any = {};
    dealSpecs.forEach((x: any) => {
        const key = x.title;
        const value = x.value;
        specs[key] = value;
    });
    return specs;
};

export const getDealSpecValue = (specs: any, title: any) => {
    const value = specs?.find((spec: any) => spec.title === title)?.value || "";
    return value;
};

export const getDealStatusLabel = (status: number) => {
    switch (status) {
        case 1:
            return { title: "Pre-Order", color: "#0046BE" };
        case 2:
            return { title: "Sắp về", color: "#FF9800" };
        case 3:
            return { title: "Mới về", color: "#4caf50" };
        case 4:
            return { title: "Sẵn hàng", color: "#E30019" };
        default:
            return { title: "", color: "" };
    }
};

// export const getDealRender = (deal: any) => {
//   const specs = getDealSpecs(deal.specifications);
//   const dealStatus = getDealStatusLabel(deal.dealStatus);
//   const isEbayUrl = deal.url.toLowerCase().includes("ebay.com");
//   const { productId, thumbnail, skuNumber, tempSellRegularPrice } = isEbayUrl
//     ? deal
//     : specs;
//   return {
//     ...deal,
//     dealStatus,
//     productId,
//     thumbnail,
//     skuNumber,
//     tempSellRegularPrice,
//     specifications: specs,
//   };
// };

export const getDealRender = (deal: any) => {
    const specs = getDealSpecs(deal.specifications);
    const dealStatus = getDealStatusLabel(deal.dealStatus);
    const isEbayUrl = deal.url.toLowerCase().includes("ebay.com");
    return {
        ...deal,
        dealStatus,
        productId: isEbayUrl ? deal.productId : deal.url.match(/(?:[?&]id=)([^&]*)/)?.[1] || "",
        thumbnail: isEbayUrl ? deal.thumbnail : specs.image,
        skuNumber: isEbayUrl ? deal.skuNumber : specs.sku,
        tempSellRegularPrice: specs.regularPrice,
        specifications: specs,
    };
};

export const getParentCategories = (categories: any[]) => {
    return categories.filter((category: any) => !category.parentCategoryId);
};
