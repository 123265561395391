import styled from "@emotion/styled";
import { Flex, Typography } from "antd";

const ContainerStyle = styled(Flex)`
  padding: 0.5rem;
`;

const Copyright = () => {
  return (
    <ContainerStyle justify="center">
      <Typography.Text>
        @Copyright ©{" "}
        <Typography.Link href="https://haistore.vn">
          haistore.vn
        </Typography.Link>{" "}
        | All Rights Reserved
      </Typography.Text>
    </ContainerStyle>
  );
};

export default Copyright;
