import { useContext } from "react";
import { indexContext } from "../../context";
import { Box, Stack, Typography, styled } from "@mui/material";
import SubCategories from "./SubCategories";
import { CircularIndeterminate } from "components";

const ParentTitle = styled(Typography)`
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
  font-weight: 700;
  border-left: 8px solid #ffe000;
  padding-left: 1rem;
`;

const ListDeal = () => {
  const { parentCategories, isMdScreen, loading, hideCategories } =
    useContext(indexContext);
  return loading ? (
    <CircularIndeterminate loading={loading} />
  ) : (
    <>
      {parentCategories.map((parentCategory: any) => {
        if (hideCategories.includes(parentCategory.id))
          return <Box key={parentCategory.id}></Box>;
        return (
          <Stack key={parentCategory.id} marginBottom={4}>
            <ParentTitle variant="h3" marginTop={isMdScreen ? "1rem" : 0}>
              {parentCategory.name}
            </ParentTitle>
            <SubCategories
              parentCategoryId={parentCategory.id}
              parentCategoryName={parentCategory.name}
            />
          </Stack>
        );
      })}
    </>
  );
};

export default ListDeal;
