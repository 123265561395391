import { PayloadAction } from "@reduxjs/toolkit";
import { createHandleReducer } from "helpers/reduxHelpers";
import { ITokenResponse } from "model/Identity/ITokenResponse";
import { IUserInfo } from "model/Identity/IUserInfo";
import { IdentityActions } from "redux/actions";
import { handleReducerBuilder, IBaseReducerState } from "utils/redux";
interface IIdentityState extends IBaseReducerState {
  token?: ITokenResponse;
  expiredAt?: number;
  user?: any;
  isAuthenticated?: boolean;
  tokenAdmin?: ITokenResponse;
  expiredAtAdmin?: number;
  admin?: IUserInfo | any;
}

const initialState: IIdentityState = {
  action: "",
  prefix: IdentityActions.prefix,
};

const setToken = (state: IIdentityState, action: PayloadAction<any>) =>
  handleReducerBuilder(state, action, () => {
    state.token = action.payload.token;
    state.expiredAt = action.payload.exp;
  });
const logout = (state: IIdentityState, action: PayloadAction<any>) =>
  handleReducerBuilder(state, action, () => {
    state.token = undefined;
    state.expiredAt = undefined;
    state.user = undefined;
  });
const getUserInfoSuccess = (
  state: IIdentityState,
  action: PayloadAction<IUserInfo>
) =>
  handleReducerBuilder(state, action, () => {
    state.user = action.payload;
  });
const setUserInfo = (state: IIdentityState, action: PayloadAction<IUserInfo>) =>
  handleReducerBuilder(state, action, () => {
    state.user = action.payload;
  });

const loginAdmin = (state: IIdentityState, action: PayloadAction<any>) =>
  handleReducerBuilder(state, action, () => {
    state.admin = action.payload.admin;
    state.tokenAdmin = action.payload.token;
    state.expiredAtAdmin = action.payload.exp;
  });

const logoutAdmin = (state: IIdentityState, action: PayloadAction<any>) =>
  handleReducerBuilder(state, action, () => {
    state.admin = undefined;
    state.tokenAdmin = undefined;
    state.expiredAtAdmin = undefined;
  });

const GeneralReducer = createHandleReducer(initialState, (builder) => {
  builder
    .addCase(IdentityActions.logout.request, logout)
    .addCase(IdentityActions.setToken.request, setToken)
    .addCase(IdentityActions.getUserInfo.success, getUserInfoSuccess)
    .addCase(IdentityActions.setUserInfo.request, setUserInfo)
    .addCase(IdentityActions.loginAdmin.request, loginAdmin)
    .addCase(IdentityActions.logoutAdmin.request, logoutAdmin);
});

export default GeneralReducer;
