import { IInvoice } from "models/IInvoice";
import { BaseApiService } from "./BaseApiService";

class InvoiceApiService extends BaseApiService {
  public getAll = () => this.get<IInvoice[]>("/get-all-invoice");

  public getInvoiceById = (invoiceId: string) => this.get<any>(`/get-invoice-by-id?invoiceIds=${invoiceId}`);
}

export default new InvoiceApiService("/invoices");
