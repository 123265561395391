import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormDialog, HeaderContainer } from "components";
import {
  TableColumName,
  TableFooter,
  TableHeaderSearch,
} from "components/AdminComponents/AdminTable";
import { AlertType, showAlert } from "helpers/alertHelper";
import _, { toNumber } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootReducer } from "redux/configurations/rootReducer";
import {
  CategoryApiService,
  ShippingSurchargeApiService,
  ShippingSurchargeGroupApiService,
  SubCategoryApiService,
} from "services";
import { IApiResponse } from "services/BaseApiService";
import AdminContainer from "../../Container";
import "./styles.css";
import AppTable from "components/app-table";
import { TableHelper } from "helpers";

const textColor = "#000";
const textSecondColor = "#4B4B4B";

const DescriptionItem = (props: { title: string; descriptions: any }) => {
  const { title, descriptions } = props;
  return (
    <Stack direction="row" alignItems="center">
      <Typography sx={{ minWidth: 150, fontWeight: 600 }} color={textColor}>
        {title}:{" "}
      </Typography>
      <Typography
        color={textSecondColor}
        sx={{
          display: "contents",
        }}
      >
        {descriptions}
      </Typography>
    </Stack>
  );
};

const HistoryItem = (props: {
  status: string;
  reason: string;
  dateTime: string;
  bold: boolean;
}) => {
  const { status, dateTime, bold, reason } = props;
  return (
    <Stack sx={{ width: "100%" }}>
      {!bold && <Divider sx={{ margin: 0.5 }} />}
      <Typography
        color={bold ? textColor : textSecondColor}
        sx={{ fontWeight: bold ? 600 : "" }}
      >
        {status}
      </Typography>
      {!_.isEmpty(reason) && (
        <Typography color={textSecondColor}>{reason}</Typography>
      )}
      <Typography color={textSecondColor}>
        {moment(dateTime).format("HH:mm A | DD/MM/YYYY")}
      </Typography>
    </Stack>
  );
};

export default function ShippingSurchargeManagement() {
  let history = useHistory();
  const user = useSelector((state: RootReducer) => state.IdentityReducer.admin);

  const [modalVisible, setModalVisible] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [idHover, setIdHover] = useState<string>();
  const [listShippingSurcharge, setListShippingSurcharge] = useState<any>([]);
  const [currentShippingSurcharge, setCurrentShippingSurcharge] = useState<any>(
    {}
  );
  const [listSubShippingSurcharge, setSubListShippingSurcharge] =
    useState<any>();
  const [isAddNew, setIsAddNew] = useState(false);

  const [categories, setCategories] = useState<any>([]);
  const [currentCategory, setCurrentCategory] = useState<any>();
  const [subCategories, setSubCategories] = useState<any>();
  const [currentSubCategory, setCurrentSubCategory] = useState<any>();
  const [type, setType] = useState("RANGE");
  useEffect(() => {
    if (!_.isEmpty(currentCategory) && isAddNew) {
      getSubCategory();
      getAllCategory();
    }
  }, [currentCategory, isAddNew]);
  useEffect(() => {
    if (_.isEmpty(categories)) {
      getAllCategory();
    }
    if (!modalVisible) {
      setCurrentCategory("");
    }
  }, [isAddNew]);
  const getSubCategory = async () => {
    setLoading(true);
    const response = await SubCategoryApiService.getById(currentCategory);
    setLoading(false);
    const resData = response as IApiResponse<any>;
    if (resData.succeeded) {
      setSubCategories(resData.data);
    } else {
      showAlert("Get sub categories failed", AlertType.ERROR);
    }
  };

  const getAllCategory = async () => {
    setLoading(true);
    const response = await CategoryApiService.getAll();
    setLoading(false);
    const resData = response as IApiResponse<any>;
    if (resData.succeeded) {
      setCategories(resData.data);
    } else {
      showAlert("Get categories failed", AlertType.ERROR);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await ShippingSurchargeGroupApiService.pagination({
        name: searchValue,
        page,
        limit: 10,
      });
      setLoading(false);
      const resData = response as IApiResponse<any>;
      if (resData.succeeded) {
        setListShippingSurcharge(resData.data);
        setTotalPage(Math.ceil((resData.data?.totalItems || 0) / 10));
      } else {
        showAlert("Get categories failed", AlertType.ERROR);
      }
    })();
  }, [searchValue, page, reload]);

  useEffect(() => {
    if (!modalVisible) {
      setCurrentShippingSurcharge(undefined);
      setSubListShippingSurcharge(undefined);
    }
  }, [modalVisible]);

  const onConfirmOk = async () => {
    updateShippingSurcharge();
  };

  const onDeleteShippingSurChargeGroup = async () => {
    const response: any = await ShippingSurchargeGroupApiService.deleteShipping(
      currentShippingSurcharge.id
    );
    if (response?.succeeded && !_.isEmpty(response?.data)) {
      showAlert("Xóa thành công", AlertType.SUCCESS);
      setReload(!reload);
      setModalVisible(false);
    } else {
      showAlert("Đã có lỗi xảy ra", AlertType.ERROR);
    }
  };
  const updateShippingSurcharge = async () => {
    console.log("listShippingSurcharge", listSubShippingSurcharge);

    const params: any = {
      inforRequest: listSubShippingSurcharge?.map((x) => {
        return {
          ...x,
          id: undefined,
        };
      }),
      shippingSurchargeGroupId: currentShippingSurcharge.id,
      userId: user?.user?.id,
    };
    setLoading(true);
    const response: any = await ShippingSurchargeApiService.create(params);
    setLoading(false);
    if (response.succeeded && !_.isEmpty(response?.data)) {
      showAlert("Thành công", AlertType.SUCCESS);
      setModalVisible(false);
      setConfirmModal(false);
      setReload(!reload);
    } else {
      showAlert("Đã có lỗi xảy ra, vui lòng thử lại ", AlertType.ERROR);
    }
  };
  const defaultRow = (fromValue: number) => {
    return {
      fromValue,
      toValue: 0,
      value: 0,
      unit: "RATE",
    };
  };
  const onOk = () => {
    setConfirmModal(true);
  };

  const handleClick = (row: any) => {
    setIsAddNew(false);
    setModalVisible(true);
    getShippingSurchargeById(row.id);
    setCurrentShippingSurcharge(row);
  };

  const getShippingSurchargeById = async (id: string) => {
    setLoading(true);
    const response =
      await ShippingSurchargeApiService.getShippingSurchargeGroupById({ id });
    setLoading(false);
    const resData = response as IApiResponse<any>;
    if (resData.succeeded) {
      setSubListShippingSurcharge(resData.data);
    } else {
      showAlert("Get sub shipping surcharge failed", AlertType.ERROR);
    }
  };

  const renderTableBody = () => {
    return (
      <table
        id="responsive-data-table"
        className="table dataTable no-footer"
        style={{ width: "100%", cursor: "pointer", marginTop: 16 }}
        aria-describedby="responsive-data-table_info"
      >
        <thead>
          <tr>
            <TableColumName title="STT" />
            <TableColumName title="Danh mục" />
            <TableColumName title="Kiểu" />
            <TableColumName title="Hành động" />
          </tr>
        </thead>
        <tbody>
          {listShippingSurcharge?.results?.map((row: any, index: number) => (
            <tr
              key={row.id}
              style={{
                height: 70,
                backgroundColor:
                  idHover === row.id ? "rgba(61, 133, 198, 0.2)" : "",
              }}
              onMouseEnter={() => setIdHover(row.id)}
              onMouseLeave={() => setIdHover(undefined)}
            >
              <td className="sorting_1">
                <Typography variant="button">{index + 1}</Typography>
              </td>
              <td>
                <Typography>{row.name}</Typography>
              </td>
              <td>
                <Typography>
                  {row.type === "RANGE"
                    ? "Khoảng giá"
                    : row.type === "MODEL"
                    ? "Mẫu giá"
                    : row.type}
                </Typography>
              </td>
              <td
                style={{
                  color: "blue",
                  cursor: "pointer",
                  fontWeight: "bold",
                  fontSize: 15,
                }}
                onClick={() => handleClick(row)}
              >
                Chỉnh sửa
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const onCreate = async () => {
    setLoading(true);
    const params = {
      categoryId: currentCategory,
      subCategoryId: currentSubCategory,
      name: subCategories.find((x) => x.id === currentSubCategory).subName,
      type,
      userId: user?.user?.id,
    };

    const response = await ShippingSurchargeGroupApiService.create(params);

    if (response?.succeeded && !_.isEmpty(response?.data)) {
      showAlert("Thêm mới thành công", AlertType.SUCCESS);
      setReload(!reload);
      setCurrentShippingSurcharge(response?.data);
    } else {
      showAlert("Lỗi", AlertType.ERROR);
    }
    setIsAddNew(false);

    setLoading(false);
  };
  const handleChangeShippingSurchargeGroup = async () => {
    setLoading(true);

    const params = {
      id: currentShippingSurcharge?.id,
      categoryId: currentShippingSurcharge.categoryId,
      subCategoryId: currentShippingSurcharge.subCategoryId,
      name: currentShippingSurcharge?.name,
      type: currentShippingSurcharge?.type,
      userId: user?.user?.id,
    };

    let response: any;
    if (_.isEmpty(params?.id)) {
      response = await ShippingSurchargeGroupApiService.create(params);
      if (response.succeeded && !_.isEmpty(response?.data)) {
        showAlert("Thêm mới thành công", AlertType.SUCCESS);
        setReload(!reload);
        setCurrentShippingSurcharge(response?.data);
      } else {
        showAlert("Lỗi", AlertType.ERROR);
      }
    } else {
      response = await ShippingSurchargeGroupApiService.update(params);
      if (response.succeeded && !_.isEmpty(response?.data?.id)) {
        showAlert("Cập nhật thành công", AlertType.SUCCESS);
        setReload(!reload);
      } else {
        showAlert("Lỗi cập nhật ", AlertType.ERROR);
      }
    }
    setLoading(false);
  };

  const columns = [
    TableHelper.createColumn({
      field: "name",
      flex: 1,
      headerName: "Danh mục",
    }),
    TableHelper.createColumn({
      field: "type",
      flex: 1,
      headerName: "Loại",
      valueGetter: ({ value }) =>
        value === "RANGE" ? "Khoảng giá" : "Mẫu giá",
    }),
  ];
  return (
    <AdminContainer title={"Cấu hình giá vận chuyển"}>
      <AppTable<any, any, any>
        api={ShippingSurchargeGroupApiService.pagination}
        columns={columns}
        reload={reload}
        onEditRowClick={handleClick}
        onAddNewClick={() => {
          setIsAddNew(true);
        }}
      />

      {/* <div className="row">
        <div className="col-12">
          <div className="card card-default" style={{ borderRadius: 16 }}>
            <div className="card-body">
              <div className="table-responsive">
                <div
                  id="responsive-data-table_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <TableHeaderSearch
                    onSearch={(value) => setSearchValue(value)}
                  />
                  {renderTableBody()}
                  <TableFooter
                    currentPage={listShippingSurcharge?.page || 0}
                    onChangePage={(page) => setPage(page)}
                    totalPage={totalPage}
                  />
                  <div className="clear" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <FormDialog
        // maxWidth=""
        cancelText="Đóng"
        visible={modalVisible}
        title={"Chỉnh sửa giá vận chuyển"}
        onCancel={() => setModalVisible(false)}
        onOk={() => onOk()}
        open={false}
        // hiddenBtnCancel
        onDelete={isAddNew ? undefined : onDeleteShippingSurChargeGroup}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
          sx={{ width: "100%", pt: 1 }}
        >
          <Typography sx={{ color: "black", fontWeight: "bold" }}>
            Tên danh mục:
          </Typography>
          <TextField
            sx={{ flex: 1 }}
            value={currentShippingSurcharge?.name}
            onChange={(e) =>
              setCurrentShippingSurcharge({
                ...currentShippingSurcharge,
                name: e.target.value,
              })
            }
            variant="standard"
          />

          <Button
            onClick={() => handleChangeShippingSurchargeGroup()}
            variant="outlined"
            color="primary"
          >
            Cập nhật
          </Button>
        </Stack>
        {/* <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
          sx={{ width: "100%", pt: 1 }}
        >
          <Typography sx={{ color: "black", fontWeight: 500 }}>
            Kiểu giá vận chuyển
          </Typography>
          <TextField
            variant="standard"
            type="text"
            sx={{ width: "100%", mt: 3 }}
            id="Category"
            size="medium"
            select
            value={currentShippingSurcharge?.type}
            onChange={(e) => {
              let newA = Object.assign({}, currentShippingSurcharge);
              newA.type = e.target.value;
              setCurrentShippingSurcharge(newA);
            }}
            defaultValue={"RANGE"}
          >
            {[
              { type: "RANGE", name: "Khoảng giá" },
              { type: "MODEL", name: "Mẫu giá" },
            ]?.map((x: any) => (
              <MenuItem value={x.type}>{x.name}</MenuItem>
            ))}
          </TextField>
        </Stack> */}

        <Divider sx={{ my: 1, color: "black" }} />
        {!_.isEmpty(listSubShippingSurcharge) &&
          listSubShippingSurcharge.map((x: any, index: number) => {
            return (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ width: "100%", pt: 2 }}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Typography sx={{ width: 100 }}>Từ</Typography>
                  <TextField
                    type={"number"}
                    defaultValue={x.fromValue}
                    variant="standard"
                    disabled={index !== listSubShippingSurcharge.length - 1}
                    onChange={(e) => {
                      let newList: any = Array.from(listSubShippingSurcharge);
                      newList[index].fromValue =
                        toNumber(e.target.value) <
                        (index > 0
                          ? toNumber(e.target.value) <
                            listSubShippingSurcharge[index].toValue
                            ? listSubShippingSurcharge[index].toValue
                            : toNumber(e.target.value)
                          : toNumber(e.target.value))
                          ? 0
                          : toNumber(e.target.value);
                      setSubListShippingSurcharge(newList);
                    }}
                  />
                  <Typography>USD</Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Typography sx={{ width: 100 }}>Đến</Typography>
                  <TextField
                    type={"number"}
                    value={x.toValue}
                    variant="standard"
                    disabled={index !== listSubShippingSurcharge.length - 1}
                    onChange={(e) => {
                      let newList: any = Array.from(listSubShippingSurcharge);
                      newList[index].toValue = Number(e.target.value);
                      setSubListShippingSurcharge(newList);
                    }}
                  />
                  <Typography>USD:</Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <TextField
                    type={"number"}
                    defaultValue={x.value}
                    disabled={index !== listSubShippingSurcharge.length - 1}
                    onChange={(e) => {
                      let newList: any = Array.from(listSubShippingSurcharge);
                      newList[index].value = Number(e.target.value);
                      setSubListShippingSurcharge(newList);
                    }}
                    variant="standard"
                  />
                  <TextField
                    select
                    variant="standard"
                    sx={{ width: "80%" }}
                    value={x.unit}
                    onChange={(e: any) => {
                      let newList: any = Array.from(listSubShippingSurcharge);
                      newList[index].unit = e.target.value;
                      setSubListShippingSurcharge(newList);
                    }}
                    size="medium"
                  >
                    {[
                      { id: "1rate", unit: "RATE", show: "$" },
                      { id: "2percent", unit: "PERCENT", show: "%" },
                    ].map((x: any) => (
                      <MenuItem id={x.id} value={x.unit}>
                        {x.show}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Button
                    sx={{ width: 90 }}
                    onClick={() => {
                      const newList = listSubShippingSurcharge.filter(
                        (x: any, i: number) => i !== index
                      );
                      setSubListShippingSurcharge(newList);
                    }}
                    variant="outlined"
                    color="error"
                    disabled={index !== listSubShippingSurcharge.length - 1}
                  >
                    Xóa
                  </Button>
                </Stack>
              </Stack>
            );
          })}

        <Button
          sx={{ float: "right", mt: 2, width: 90 }}
          variant="contained"
          // disabled={
          //   !_.isEmpty(listSubShippingSurcharge)
          //     ? _.isEmpty(
          //         listSubShippingSurcharge[listSubShippingSurcharge?.length - 1]
          //           ?.value
          //       )
          //     : false
          // }
          onClick={() => {
            let newList: any = [
              ...listSubShippingSurcharge,
              defaultRow(
                listSubShippingSurcharge[listSubShippingSurcharge.length - 1]
                  ?.toValue || 0
              ),
            ];
            setSubListShippingSurcharge(newList);
          }}
        >
          Thêm
        </Button>
      </FormDialog>

      <FormDialog
        // maxWidth=""
        cancelText="Đóng"
        visible={isAddNew}
        title={"Tạo danh mục vận chuyển"}
        onCancel={() => setIsAddNew(false)}
        onOk={() => onCreate()}
        open={false}
        // hiddenBtnCancel
        // onDelete={isAddNew ? undefined : onDeleteShippingSurChargeGroup}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              type="text"
              sx={{ width: "100%" }}
              id="Category"
              label="Danh mục cha"
              size="medium"
              select
              value={currentCategory}
              onChange={(e) => setCurrentCategory(e.target.value)}
              defaultValue={!_.isEmpty(categories) ? categories[0].id : ""}
            >
              {categories?.map((x: any) => (
                <MenuItem value={x.id}>
                  <Box>{x.name}</Box>
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              type="text"
              sx={{ width: "100%" }}
              id="Category"
              label="Danh mục con"
              size="medium"
              select
              value={currentSubCategory}
              onChange={(e) => setCurrentSubCategory(e.target.value)}
              defaultValue={
                !_.isEmpty(subCategories) ? subCategories[0].id : ""
              }
            >
              {subCategories?.map((x: any) => (
                <MenuItem value={x.id}>{x.subName}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              type="text"
              sx={{ width: "100%" }}
              id="Category"
              label="Kiểu giá vận chuyển"
              size="medium"
              select
              value={type}
              onChange={(e) => setType(e.target.value)}
              defaultValue={"RANGE"}
            >
              {[
                { type: "RANGE", name: "Khoảng giá" },
                { type: "MODEL", name: "Mẫu giá" },
              ]?.map((x: any) => (
                <MenuItem value={x.type}>{x.name}</MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </FormDialog>

      <FormDialog
        visible={confirmModal}
        title={"Confirm"}
        onCancel={() => setConfirmModal(false)}
        onOk={() => onConfirmOk()}
        open={false}
      >
        <Typography variant="h6">
          Bạn có chắc muốn thực hiện hành động này?
        </Typography>
      </FormDialog>
      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}
