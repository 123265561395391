// @mui
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Logo from "components/Header/components/Logo";

// ----------------------------------------------------------------------

export default function HeaderSimple() {
  return (
    <AppBar>
      <Toolbar
        sx={{
          justifyContent: "space-between",
          height: {
            xs: 76,
          },
        }}
      >
        <Logo />

        <Stack direction="row" alignItems="center" spacing={1}>
          {/* <SettingsButton /> */}

          {/* <Link
            href={paths.faqs}
            component={RouterLink}
            color="inherit"
            sx={{ typography: "subtitle2" }}
          >
            Need help?
          </Link> */}
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
