import { Box, Grid, Stack, Switch, TextField, Typography } from "@mui/material";

import { FormDialog, HeaderContainer, SearchInput } from "components";
import CircularProgress from "@mui/material/CircularProgress";
import { AlertType, showAlert } from "helpers/alertHelper";
import { AdminLayout } from "layouts";
import _ from "lodash";
import { IOrder, IOrderResponse } from "models/IOrderModel";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { CrawlApiService } from "services";

import moment from "moment";
import "./styles.css";

import { useDispatch } from "react-redux";
import { ProductActions } from "redux/actions";
import {
  TableColumName,
  TableFooter,
  TableHeaderSearch,
} from "components/AdminComponents/AdminTable";
import QuoteApiService from "services/QuoteApiService";
import { DateHelper } from "helpers";
import AdminContainer from "../../Container";

export default function CrawlSeller() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [products, setProducts] = useState<any>([]);
  const [idHover, setIdHover] = useState<string>();
  const [searchValue, setSearchValue] = useState("");
  const [searchQuote, setSearchQuote] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [crawlSS, setCrawlSS] = useState<boolean>();
  const [crawlMultiple, setCrawlMultiple] = useState(false);
  useEffect(() => {
    onSearch();
  }, [searchQuote]);

  useEffect(() => {
    getProducts();
  }, [crawlSS, searchValue, page]);

  const onSearch = async () => {
    if (!_.isEmpty(searchQuote)) {
      setLoading(true);
      let response: any;
      if (crawlMultiple) {
        response = await CrawlApiService.crawlMultipleData(searchQuote.trim());
      } else {
        response = await CrawlApiService.crawlData(searchQuote.trim());
      }

      setCrawlSS(!crawlSS);
      setSearchQuote("");
      if (response.succeeded) {
        // dispatch(ProductActions.addProductDetail.request(response?.data));
        // history.push("product-detail");
        showAlert("Thành công", AlertType.INFO);
      } else {
        showAlert("No products found", AlertType.INFO);
        setLoading(false);
      }
    }
  };

  const getProducts = async () => {
    setLoading(true);
    const res: any = await QuoteApiService.pagination({
      link: searchValue,
      page: page,
      limit: 10,
    });
    setLoading(false);
    if (res.succeeded && !_.isEmpty(res.data)) {
      setProducts(res.data);
      setTotalPage(Math.ceil((res.data?.totalItems || 0) / 10));
    } else {
      showAlert("Lấy dữ liệu thất bại", AlertType.ERROR);
    }
  };
  const renderTableBody = () => {
    return (
      <table
        id="responsive-data-table"
        className="table dataTable no-footer"
        style={{ width: "100%", cursor: "pointer", marginTop: 16 }}
        aria-describedby="responsive-data-table_info"
      >
        <thead>
          <tr>
            {/* <TableColumName title="STT" /> */}
            <TableColumName title="Link SP" />
            <TableColumName title="Lấy từ" />
            <TableColumName title="Trạng thái" />
            <TableColumName title="Ngày thực hiện" />
          </tr>
        </thead>
        <tbody>
          {products?.results?.map((row: any, index: number) => (
            <tr
              key={row.id}
              // onClick={() => handleClick(row)}
              style={{
                height: 70,
                backgroundColor:
                  idHover === row.id ? "rgba(61, 133, 198, 0.2)" : "",
              }}
              onMouseEnter={() => setIdHover(row.id)}
              onMouseLeave={() => setIdHover(undefined)}
            >
              {/* <td>
                <Typography>{index + 1}</Typography>
              </td> */}
              <td>
                <Typography>
                  {_.truncate(row.quoteLink, { length: 70 })}
                  {/* {row.quoteLink} */}
                </Typography>
              </td>
              <td>
                <Typography>{row.fromLink}</Typography>
              </td>
              <td>
                <span
                  className="mb-2 mr-2 badge badge-success"
                  style={{
                    marginTop: 10,
                    backgroundColor: row.status === 1 ? "#018e00" : "#cc0000",
                  }}
                >
                  {row.status === 1 ? "Thành công" : "Thất bại"}
                </span>
              </td>
              <td>
                <Typography>
                  {DateHelper.mmddyyyyFormat(row.createdDt)}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <AdminContainer>
      {/* <!-- Container-fluid starts--> */}
      <HeaderContainer
        title={"Cào dữ liệu"}
        // otherStyle
        // rightComponent={
        //   <Stack
        //     sx={{ flex: 1 }}
        //     direction={"row"}
        //     alignItems={"center"}
        //     spacing={2}
        //   >
        //     <Typography>Lấy dữ liệu hàng loạt:</Typography>
        //     <Switch
        //       value={crawlMultiple}
        //       onChange={(e) => setCrawlMultiple(e.target.checked)}
        //     />
        //   </Stack>
        // }
      />
      {/* <!-- Container-fluid Ends--> */}
      {/* <!-- Container-fluid starts--> */}

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            {/* <div className="card-header">
                <h5>Orders</h5>
              </div> */}
            <div className="card-body">
              <div
                id="basicScenario"
                className="product-physical table-responsive"
              >
                {/* <Grid container>
                  <Grid item sx={{ flex: 1 }}>
                    <SearchInput
                      onSearch={(text) => setSearchQuote(text)}
                      placeholder="Crawl Data: Link Ebay,....."
                    />
                  </Grid>
                </Grid>*/}
                <Box sx={{ mt: 2 }} />
                <TableHeaderSearch
                  onSearch={(value) => setSearchValue(value)}
                  rightComponent={
                    <Stack
                      sx={{ flex: 1, marginLeft: 8 }}
                      direction={"row"}
                      alignItems={"center"}
                      spacing={2}
                    >
                      <Typography>Crawl Link:</Typography>
                      <Box sx={{ flex: 1 }}>
                        <SearchInput
                          onSearch={(text) => setSearchQuote(text)}
                          placeholder="Crawl Data: Link Ebay,....."
                        />
                      </Box>
                    </Stack>
                  }
                />
                {!loading ? (
                  renderTableBody()
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // pt: 5,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <TableFooter
                  currentPage={products?.page || 0}
                  onChangePage={(page) => setPage(page)}
                  totalPage={totalPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <FormDialog visible={confirmModal} title={"Confirm"} onCancel={() => setConfirmModal(false)} onOk={() => onConfirmOk()} open={false}>
        <Typography sx={{ pt: 2 }} variant="h6">
          Are you sure?
        </Typography>
      </FormDialog> */}
      {/* <!-- Container-fluid Ends--> */}
    </AdminContainer>
  );
}

const styles = {
  boxShadow: { boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", p: 1 },
};
