import { IDefaultWeight } from "models/IDefaultWeight";
import { BaseApiService } from "./BaseApiService";

class DefaultWeightProductApiService extends BaseApiService {
  public getAllDefaultWeight = () =>
    this.get<IDefaultWeight[]>(`/get-all-default-weight`);
    
  public getDefaultWeightBySubCategoryId = (subCategoryId: string) =>
    this.get<IDefaultWeight>(`/get-default-weight-by-id?id=${subCategoryId}`);
}

export default new DefaultWeightProductApiService("/default-weight");
