import React from "react";
import { Box } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { HeaderDashboard } from "../components";
import Sidebar from "./Sidebar";

function AdminLayout(props: { loading?: boolean; children: any }) {
  const [open, setOpen] = React.useState(false);

  const onOpen = () => {
    setOpen(!open);
  };

  return (
    <Box className="wrapper">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          position: "relative",
        }}
      >
        <HeaderDashboard onOpen={onOpen} />
        <Box
          sx={{
            flexGrow: 1,
            position: "relative",
            display: "flex",
          }}
        >
          <PerfectScrollbar
            component="div"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                padding: "20px",
                width: "100%",
                height: "100%",
              }}
            >
              {props.children}
            </Box>
          </PerfectScrollbar>
        </Box>
      </Box>
    </Box>
  );
}

export default AdminLayout;
