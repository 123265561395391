import { BaseApiService } from "./BaseApiService";

class DiscountApiService extends BaseApiService {
  public getAllDiscounts = (params: any) => this.get<any>("", { params });
  public getDiscountById = (params: any) => this.get<any>("", { params });
  public createDiscount = (params: any) => this.post<any>("", params);
  public updateDiscount = (params: any) => this.put<any>("", params);
  public deleteDiscount = (params: any) => this.delete<any>("", { params });
  public findCoupon = (params: any) =>
    this.get<any>("/find-coupon", { params });
  public getCouponById = (params: { id: string }) =>
    this.get<any>(`/${params.id}/coupons`, { params });
  public createCoupon = (params: { id: string; couponCode: string }) =>
    this.post<any>(`/${params.id}/coupons?couponCode=${params.couponCode}`);
  public deleteCoupon = (params: { id: string; couponCode: string }) =>
    this.delete<any>(`/${params.id}/coupons?couponCode=${params.couponCode}`);

  public getProductsByDiscountId = (params: { id: string }) =>
    this.get<any>(`/${params.id}/products`);

  public createProductByDiscount = (params: { id: string; sku: string[] }) =>
    this.post<any>(`/${params.id}/products`, params.sku);
  public deleteProductByDiscount = (params: { id: string; sku: string[] }) =>
    this.delete<any>(`/${params.id}/products`, { params: params.sku });

  public getSubCategoryByDiscountId = (params: any) =>
    this.get<any>(`/${params.id}/sub-categories`);

  public createSubCategoryByDiscountId = (params: {
    id: string;
    subCategories: string[];
  }) => this.post<any>(`/${params.id}/sub-categories`, params.subCategories);
  public deleteSubCategoryByDiscountId = (params: {
    id: string;
    subCategories: string[];
  }) =>
    this.delete<any>(`/${params.id}/sub-categories`, {
      params: params.subCategories,
    });

  // public deleteCoupon = (params: { id: string; couponCode: string }) =>
  //   this.delete<any>(`/${params.id}/coupons?couponCode=${params.couponCode}`);
  // public deleteCoupon = (params: { id: string; couponCode: string }) =>
  //   this.delete<any>(`/${params.id}/coupons?couponCode=${params.couponCode}`);
  // public deleteCoupon = (params: { id: string; couponCode: string }) =>
  //   this.delete<any>(`/${params.id}/coupons?couponCode=${params.couponCode}`);
  // public deleteCoupon = (params: { id: string; couponCode: string }) =>
  //   this.delete<any>(`/${params.id}/coupons?couponCode=${params.couponCode}`);
}

export default new DiscountApiService("/discounts");
